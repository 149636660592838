import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth } from '~/hooks/Auth';

interface IParams {
  token: string;
}

const AutoLogin: React.FC = () => {
  const params = useParams<IParams>();
  const location = useLocation();
  const { autoLogin } = useAuth();

  useEffect(() => {
    const path = location.search
      .replace('?', '')
      .replace('&', '')
      .split('page=')
      .join('/');
    autoLogin(params.token, path ? 'user' : 'admin', path);
  }, [autoLogin, location.search, params.token]);

  return <div />;
};

export default AutoLogin;
