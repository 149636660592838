import React, { useCallback, useState } from 'react';
import Lottie from 'react-lottie';
import { Form } from '@unform/web';
import { Container, CopyAlert } from './styles';
import bubble from '~/assets/animations/orb.json';
import Select from '~/components/Select';

import sphereLg from '~/assets/defaults/sphere-large.png';
import sphereLgMob from '~/assets/defaults/sphere-large-mob.png';
import sphereSm from '~/assets/defaults/sphere-small.png';
import example from '~/assets/defaults/example-post.png';
import copy from '~/assets/icons/copy-blue.svg';
import instagram from '~/assets/icons/instagram-color.svg';
import youtube from '~/assets/icons/youtube-color.svg';
import tiktok from '~/assets/icons/tiktok-color.svg';
import facebook from '~/assets/icons/facebook-color.svg';
import Carousel from './Carousel';

const PromptEase: React.FC = () => {
  const [showResult, setShowResult] = useState(false);
  const [copied, setCopied] = useState(false);

  const video = [
    {
      src: 'https://cdn.autoaffiliate.ai/videos/AutoAffiliate_v2+en.mp4',
      thumbnail: example,
      isImage: false,
    },
    {
      src: 'https://cdn.autoaffiliate.ai/videos/New_5_min_video.mp4',
      thumbnail: example,
      isImage: false,
    },
    {
      src: example,
      thumbnail: '',
      isImage: true,
    },
  ];

  const category = [
    { id: '1', value: 'Motivational', selected: false },
    { id: '2', value: 'Make Money Online', selected: false },
    { id: '3', value: 'Personal Growth', selected: false },
    { id: '4', value: 'Lifestyle', selected: false },
  ];

  const type = [
    { id: '1', value: 'Reels', selected: false },
    { id: '2', value: 'Videos', selected: false },
    { id: '3', value: 'Images', selected: false },
    { id: '4', value: 'Text', selected: false },
  ];

  const plataform = [
    { id: '1', value: 'Instagram', selected: false },
    { id: '2', value: 'Tiktok', selected: false },
    { id: '3', value: 'Facebook', selected: false },
    { id: '4', value: 'Youtube', selected: false },
  ];

  const handleClickCopy = useCallback((content) => {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 800);
  }, []);

  const handleSlideChange = useCallback(() => {
    console.log('ok');
  }, []);

  return (
    <Container>
      <img
        src={sphereLg}
        alt="Sphere"
        className="d-none d-lg-block sphere-lg zoom"
      />
      <img src={sphereLgMob} alt="Sphere" className="d-lg-none sphere-lg" />
      <div className="container zoom">
        <div className="row justify-content-center">
          <div className="col-12 mt-3 pe-none">
            <div className="d-flex align-items-center lottie">
              <Lottie
                options={{
                  animationData: bubble,
                  autoplay: true,
                  loop: true,

                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                width={70}
                height={70}
              />
              <h1 className="mb-0 ml-sm-2">PostEASE</h1>
            </div>
          </div>
          <div className="col-12">
            <Form onSubmit={() => 'submit'}>
              <div className="row justify-content-center">
                <div className="col-12 position-relative">
                  <img src={sphereSm} alt="Sphere" className="sphere-sm" />
                </div>
                <div className="col-lg-8 px-2 px-lg-3">
                  <div className="bg-all mt-lg-3 p-3 p-md-5 p-lg-4 p-xl-5">
                    <div className="row justify-content-around">
                      <div className="col-lg-4 pb-3">
                        <h3 className="d-flex align-items-center mb-4">
                          <span className="mr-2 bg-number-category">1</span>
                          Choose Category
                          <span className="and-or d-none d-lg-inline-block ml-auto">
                            and/or
                          </span>
                        </h3>

                        <Select
                          name="category"
                          options={category}
                          onlySelect
                          placeholder="Category"
                          className="bg-left-side p-0 mb-3"
                          onChange={() => setShowResult(true)}
                        />
                        <p className="text-center and-or d-lg-none mb-0">
                          and/or
                        </p>
                      </div>
                      <div className="col-lg-4 pb-3">
                        <h3 className="d-flex align-items-center mb-4">
                          <span className="mr-2 bg-number-type">2</span>
                          Choose Post Type
                          <span className="and-or d-none d-lg-inline-block ml-auto">
                            and/or
                          </span>
                        </h3>

                        <Select
                          name="type"
                          options={type}
                          onlySelect
                          placeholder="Type"
                          className="bg-center-side p-0 mb-3"
                          onChange={() => setShowResult(true)}
                        />
                        <p className="text-center and-or d-lg-none mb-0">
                          and/or
                        </p>
                      </div>
                      <div className="col-lg-4 pb-lg-3">
                        <h3 className="d-flex align-items-center mb-4">
                          <span className="mr-2 bg-number-plataform">3</span>
                          Choose Plataform
                        </h3>

                        <Select
                          name="plataform"
                          options={plataform}
                          onlySelect
                          placeholder="Plataform"
                          className="bg-right-side p-0 mb-3"
                          onChange={() => setShowResult(true)}
                        />
                      </div>

                      {showResult && (
                        <>
                          <div className="col-12 mt-3 mt-lg-0 mb-lg-4">
                            <div className="bg-result p-4 h-100">
                              <div className="d-flex justify-content-between align-items-center">
                                <h2 className="mb-0">Hashtags #</h2>
                                <button
                                  onClick={() => handleClickCopy(`test`)}
                                  value="test"
                                  type="button"
                                  className="bg-transparent border-0"
                                  title="Click to copy"
                                >
                                  <img src={copy} alt="Copy" />
                                </button>
                              </div>
                              <div className="mt-4">
                                <span
                                  className="prompt-result-hash"
                                  dangerouslySetInnerHTML={{
                                    __html: 'test',
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mt-3 mt-lg-0">
                            <div className="bg-result p-4 h-100">
                              <div className="d-flex justify-content-between align-items-center">
                                <h2 className="mb-0">
                                  Sample Video Description
                                </h2>
                                <button
                                  onClick={() =>
                                    handleClickCopy(
                                      `Are you tired of feeling like time is slipping away?\n\nDo you wish you had more time to chase your dreams and live life to the fullest?\n\nIf you’re like me, you’ve probably caught yourself thinking, “I’ll do it tomorrow” or “I have plenty of time.” But the truth is, time is our most precious resource, and we can’t afford to waste it.\n\nI understand how you feel, and that’s why I’m excited to share an incredible opportunity that can help you break free from the rat race, buy back your time, achieve your goals, and create a life you love.\n\nLet’s make the most of our time together in 2024 and beyond! ⏳🚀💪🏼`
                                    )
                                  }
                                  type="button"
                                  className="bg-transparent border-0"
                                  title="Click to copy"
                                >
                                  <img src={copy} alt="Copy" />
                                </button>
                              </div>
                              <div className="h-result mt-4">
                                <span
                                  className="prompt-result-body"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      'Are you tired of feeling like time is slipping away?<br><br>Do you wish you had more time to chase your dreams and live life to the fullest?<br><br>If you’re like me, you’ve probably caught yourself thinking, “I’ll do it tomorrow” or “I have plenty of time.” But the truth is, time is our most precious resource, and we can’t afford to waste it.\n\nI understand how you feel, and that’s why I’m excited to share an incredible opportunity that can help you break free from the rat race, buy back your time, achieve your goals, and create a life you love.<br><br>Let’s make the most of our time together in 2024 and beyond! ⏳🚀💪🏼',
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 px-2 px-lg-5 mt-4 mb-5 my-lg-0">
                  {showResult && (
                    <>
                      <div className="video-thumb">
                        <Carousel
                          onSlideChange={handleSlideChange}
                          video={video}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <CopyAlert show={copied} className="alert">
        <p className="mb-0">Copied</p>
      </CopyAlert>
    </Container>
  );
};

export default PromptEase;
