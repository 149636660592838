import styled from 'styled-components';

import signInBackgroung from '~/assets/banners/sign-in-bg.png';
import signInBackgroung2 from '~/assets/banners/sign-in-bg2.png';
import signInBackgroung3 from '~/assets/banners/sign-in-bg3.png';
import signInBackgroung4 from '~/assets/banners/sign-in-bg4.png';

export const Background = styled.div`
  .slick-list {
    //padding-left: 16px;
    border-radius: 30px 0px 0px 30px !important;
    box-shadow: -9px 4px 60px 0px rgba(0, 0, 0, 0.2);
  }
  .banner {
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 830px;
    position: relative;
    //border-radius: 30px 0px 0px 30px;
  }

  .banner-1 {
    background-image: url(${signInBackgroung});
  }

  .banner-2 {
    background-image: url(${signInBackgroung2});
  }

  .banner-3 {
    background-image: url(${signInBackgroung3});
  }

  .banner-4 {
    background-image: url(${signInBackgroung4});
  }

  .w-div {
    max-width: 609px;
  }

  .phrase {
    color: #fff;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: start;
    margin-left: calc(39px + 1.5rem);
  }

  .content {
    position: absolute;
    z-index: 1;
    top: calc(91% - 1.5rem);
    padding: 0 3rem;

    a {
      color: #fff;
    }
  }

  .quotation {
    margin-bottom: -86px;
    position: relative;
    z-index: 1;
    text-align: center;
    top: 48px;

    p {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      color: #ffffff;

      a {
        color: #ffffff;
        font-weight: 600;
      }
    }
  }

  .testimonial {
    margin-top: 7rem;
    h5 {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 41px;
      letter-spacing: 1px;
      color: #ffffff;
    }
  }

  .banner::before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      159.91deg,
      rgba(0, 0, 0, 0.7) 11.3%,
      rgba(20, 73, 131, 0) 103.78%
    );
    //border-radius: 30px 0px 0px 30px;
    position: absolute;
    top: 0;
    right: 0;
  }

  @media screen and (max-width: 1199px) {
    .testimonial h5 {
      font-size: 20px;
      line-height: 28px;
    }
  }

  @media screen and (min-height: 831px) {
    .banner {
      min-height: 100vh;
    }
  }
`;

export const ButtonLeft = styled.button`
  position: absolute;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: transparent;
  border: none;
  transition-duration: 0.3s;
  top: 91%;
  z-index: 2;
  left: unset;
  transform: translateY(-50%);
  color: #fff;
  font-size: 16px;
  line-height: 30px;

  right: 6rem;

  svg {
    fill: #fff;
  }

  :hover,
  :focus {
    svg {
      fill: #ffffff;
    }
    color: #fff;
  }

  :before,
  :after {
    display: none;
  }
`;

export const ButtonRight = styled.button`
  position: absolute;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: transparent;
  border: none;
  top: 91%;
  transition-duration: 0.3s;

  right: 3rem;
  transform: translateY(-50%);
  z-index: 2;
  left: unset;

  color: #fff;
  font-size: 16px;
  line-height: 30px;

  svg {
    fill: #fff;
  }

  :hover,
  :focus {
    color: #fff;

    svg {
      fill: #ffffff;
    }
  }

  :before,
  :after {
    display: none;
  }
`;
