import styled from 'styled-components';
import { Form } from '@unform/web';

interface IContainer {
  close: boolean;
}

export const Container = styled(Form)<IContainer>`
  width: 360px;
  border: 1px solid #e9e9e9;
  background: #f4f4f4;
  box-shadow: 4px 15px 25px 0px rgba(0, 0, 0, 0.08);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

  .header {
    background-color: #0c7cff;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    cursor: ${(props) => (!props.close ? 'default' : 'pointer')};
  }

  .body {
    transition-duration: 0.3s;
    height: ${(props) => (!props.close ? '335px' : '0')};
    padding: ${(props) => (!props.close ? '1rem 1rem 0 1rem' : '0')};

    .scroll-smooth {
      scroll-behavior: smooth;
    }

    .btn-message {
      border-radius: 12px;
      background: #fff;
      width: 100%;
      border: 1px solid #fff;
      transition-duration: 0.3s;

      .lottie {
        width: 42px;
        height: 42px;
        border-radius: 9px;
        border: 1px solid #116bff;
        background: #f4f4f4;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .message {
        width: calc(100% - 56px);
        margin-left: 14px;
        height: 42px;

        > div {
          p {
            color: #021027;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 134.023%;
          }
          .stamp {
            position: absolute;
            top: -10px;
            right: -10px;
          }
        }

        > p {
          color: #000;
          font-family: Inter;
          font-size: 10px;
          font-style: normal;
          font-weight: 300;
          line-height: 134.023%;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 25px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      + .btn-message {
        margin-top: 10px;
      }

      :disabled {
        opacity: 0.7;
      }
    }

    .btn-message.selected {
      border: 1px solid #116bff;
      box-shadow: 4px 15px 25px 0px rgba(0, 0, 0, 0.08);
    }
  }

  .footer {
    transition-duration: 0.3s;
    height: ${(props) => (!props.close ? '90.14px' : '0')};
    padding: ${(props) => (!props.close ? '0 1rem 1rem 1rem' : '0')};
    background: #f9f9f9;
    position: relative;

    .box-input {
      border-radius: 12px;
      background: #fff;
      box-shadow: ${(props) =>
        !props.close ? '0px -28px 25px 0px rgba(249, 249, 249, 0.94)' : 'none'};

      > div {
        width: calc(100% - 49px);
        display: flex;
        align-items: center;

        p {
          color: #021027;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 134.023%; /* 16.083px */
          margin: 0;
        }

        .message-sent {
          p {
            color: #116bff;
          }
        }
      }

      button,
      .btn-loading {
        background: #116bff;
        border-radius: 8px;
        width: 49px;
      }
    }

    ::before {
      content: '';
      position: absolute;
      background: #f9f9f9;
      box-shadow: 0px -28px 25px 0px rgba(249, 249, 249, 0.94);
      opacity: ${(props) => (!props.close ? '1' : '0')};
    }
  }

  + form {
    margin-left: 10px;
  }
`;

export const Loading = styled.div`
  .spinner {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: radial-gradient(farthest-side, #fff 94%, #0000) top/9px 9px
        no-repeat,
      conic-gradient(#0000 30%, #fff);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 9px),
      #000 0
    );
    animation: spinner-c7wet2 1s infinite linear;
  }

  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
