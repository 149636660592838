/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

const BASE_URL = 'https://autoaffliate.api-du.de/api';
// const BASE_URL = 'http://127.0.0.1:5000/api';

export async function apiRequest(
  action: string,
  method: string,
  payload: any
): Promise<any> {
  if (method === 'GET') {
    const response = await axios.get(`${BASE_URL}${action}`);
    return response.data;
  }
  if (method === 'POST') {
    const response = await axios.post(`${BASE_URL}${action}`, payload);
    return response.data;
  }

  return undefined;
}
