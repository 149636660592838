import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IBg {
  src: string;
}

export const Container = styled.div`
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #8c8c8c;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #626262;
  }

  hr {
    width: 90%;
    border-top: 1px solid #e1e0e0;
  }

  .bg-check-border {
    padding: 2px;
    background: #fff;
    border: 2px solid #f2f2f2;
    border-radius: 18px;
    img {
      padding: 9px;
      border-radius: 14px;
      width: 48px;
      height: 48px;
      background: #116bff;
      box-shadow: -1px 4px 10px 0px rgba(17, 107, 255, 0.5);
    }
  }

  .completed {
    border-radius: 22px;
    border: 1px solid #e9e9e9;
    background: #fff;
    padding: 10px 15px;
  }

  .completed p {
    color: #232528;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
  }

  .completed span {
    color: #8a8a8a;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
    line-height: 19.5px;
  }

  .positions {
    top: 19px;
    left: 15px;
    h2 {
      font-weight: 600;
      font-size: 35px;
      line-height: 46px;
      color: #f5f5f5;
      margin-bottom: 0px;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #f5f5f5;
    }
  }

  .sign {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    background: #116bff;
    border-radius: 10px;
    padding: 9px 20px;
    width: 180px;
    transition: 0.3s;
    text-decoration: none;
    :hover {
      opacity: 0.8;
    }
  }

  .add {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #242526;
    background: #d0d0d0;
    border-radius: 10px;
    padding: 9px 20px;
    width: 180px;
    transition: 0.3s;
    :hover {
      opacity: 0.8;
    }
  }

  .h-40vh {
    height: 40vh;
  }

  .text-no-certificates {
    color: #606060;
    font-weight: 600;
  }

  small,
  .price {
    color: var(--graniteGray);
  }

  .radius-20 {
    border-radius: 20px;
  }

  .bg-gray {
    background: var(--coal);
    border-radius: 20px;
  }

  button {
    background-color: transparent;
    border: none;
    color: var(--ultraWhite);
  }

  .filters {
    background: var(--midnight);
    z-index: 99;
    border-radius: 12px;
    right: 0;
    bottom: -130px;

    hr {
      width: 75%;
      margin: 0 auto;
      background-color: var(--mysteriousGrey);
    }
  }

  .button-cancel {
    background-color: transparent;
    color: var(--ultraWhite);
    text-decoration: none !important;
    border: 1px solid var(--greyMineral);
    border-radius: 12px;
    :hover {
      background-color: var(--greyMineral);
    }
  }

  button,
  .button {
    cursor: pointer;
  }

  .graph {
    width: 11%;
    opacity: 0.1;
  }

  div {
    ::-webkit-scrollbar {
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: var(--technologicalGrayOpacity);
    }
  }

  .img-width {
    width: 100%;
    //height: 140px;
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    //display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (min-width: 768px) {
    .skeleton.img-width {
      width: 186px;
      height: 170px;
    }
  }

  @media screen and (max-width: 767px) {
    .img-width {
      width: 400px;
      max-width: 100%;
    }

    .skeleton.img-width {
      max-height: 260px;
      height: 49vw;
    }

    .add,
    .sign {
      font-size: 16px;
      width: 100%;
      padding: 15px 20px;
      border-radius: 15px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  label span {
    font-weight: 400;
    font-size: 20px;

    color: #5d5b5b;
  }

  .modal-content {
    background: #f9f9f9 !important;
    border-radius: 35px;
    position: relative;

    .modal-title {
      font-weight: 600;
      font-size: 24px;
      color: #021027;
    }

    .modal-cache-title {
      color: #116bff;
      text-align: center;
      font-family: 'Quicksand';
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: 45px;
    }

    .cache-subtitle {
      color: #151517;
      text-align: center;
      font-family: 'Archivo';
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px;
    }

    .cache-content {
      color: #585656;
      text-align: center;
      font-family: 'Archivo';
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 28px;
      display: block;
    }

    .btn-clear-cache {
      border-radius: 18px;
      background: #116bff;
      width: 100%;
      height: 70px;
      padding: 18px 10px;
      flex-shrink: 0;
      color: #fff;
      text-align: center;
      font-family: 'Archivo';
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -1.2px;
      text-transform: uppercase;
    }

    .input {
      border: 1px solid #d0d0d0;
      border-radius: 20px;
      height: 72px !important;
      background: transparent;
      font-weight: 300;
      color: #151517;
      padding: 5px 20px;

      input::placeholder {
        color: #8c8c8c;
        font-weight: 300;
        font-size: 14px;
      }
    }

    .modal-close {
      color: #021027;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btn-save {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #ffffff;
      background: #116bff;
      border-radius: 20px;
      transition-duration: 0.3s;
      padding: 20px;
      width: 370px;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-cancel {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #dadada;
      background: #021027;
      border-radius: 20px;
      padding: 20px;
      width: 142px;
    }

    .create {
      padding: 20px 30px;
    }
  }

  .animation div:nth-child(1) {
    width: 110px !important;
    height: 110px !important;
  }

  @media screen and (min-width: 992px) {
    zoom: 0.7;
    .modal-xl {
      max-width: 874px;
    }

    .modal-cache-title {
      font-size: 44px;
    }

    .cache-subtitle {
      font-size: 28px;
      line-height: 45px;
    }

    .cache-content {
      font-size: 18px;
    }

    .btn-clear-cache {
      font-size: 30px;
      padding: 18px 28px;
    }

    .animation div:nth-child(1) {
      width: 203px !important;
      height: 203px !important;
    }
  }

  @media screen and (min-width: 1400px) {
    zoom: 0.8;
  }

  @media screen and (min-width: 1600px) {
    zoom: 0.9;
  }

  @media screen and (min-width: 1700px) {
    zoom: 1;
  }
`;

export const BgCard = styled.div<IBg>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 38px 38px 0 0;

  @media screen and (min-width: 992px) {
    border-radius: 38px 0 0 38px;
  }
`;

export const ModalCard = styled(ModalComponent)`
  background: rgba(0, 0, 0, 0.6) !important;
  backdrop-filter: blur(2.5px) !important;

  h2 {
    font-size: 80px;
    color: #fff;
    line-height: 70px;
    font-family: 'Bebas Neue';
    span {
      font-size: 55px;
    }
  }
  .modal-content {
    border: 0;
    background: #f9f9f9 !important;
    border-radius: 35px;
    position: relative;
    border-radius: 40px;
  }

  .close {
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 1;
    opacity: 1;
    svg {
      color: #fff;
    }
  }

  .logo {
    width: 44px;
    height: 40px;
  }

  .watch {
    opacity: 0;
    pointer-events: none;
    color: #fff;
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    max-width: 262px;
  }

  .bg-card {
    border-radius: 38px 0 0 38px;
  }

  .postease {
    width: 60px;
    height: 60px;
  }

  .left-ride {
    top: 40px;
    left: 40px;
  }

  .right-side {
    h2 {
      color: #021027;
      font-family: 'Archivo';
      font-size: 35px;
      font-weight: 600;
      line-height: 108%;
      margin-bottom: 22px;
    }
    p {
      color: #021027 !important;
      font-size: 18px;
      font-weight: 300;
      line-height: 25px;
      margin-bottom: 35px;
      span {
        font-weight: 500;
      }
    }
    a {
      text-decoration: none;
    }
    h3 {
      color: #116bff;
      font-family: 'Archivo';
      font-size: 28px;
      font-weight: 700;
      line-height: 25px;
      margin-bottom: 16px;
    }
    ul {
      margin-bottom: 35px;
      margin-left: 30px;
    }
    li {
      color: #021027;
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
      span {
        font-weight: 600;
      }
      b {
        color: #116bff;
        font-weight: 700;
      }
    }
    h4 {
      color: #116bff;

      text-align: center;
      font-family: 'Archivo';
      font-size: 30px;
      font-weight: 300;
      line-height: 25px;
      margin-bottom: 35px;
      b {
        font-weight: 700;
      }
    }
    .access {
      color: #000;
      font-family: 'Source Sans Pro';
      font-size: 30px;
      font-weight: 900;
      line-height: 24px;
      border: 0;
      border-radius: 16px;
      background: #ffb74a;
      padding: 20px;
      width: 100%;
    }
  }

  @media screen and (min-width: 992px) {
    zoom: 0.5;

    h2 {
      font-size: 140px;
      line-height: 125px;
      span {
        font-size: 95px;
      }
    }

    .postease {
      width: 80px;
      height: 80px;
    }
    .modal-xl {
      max-width: 1700px;
    }
    .watch {
      font-size: 36px;
    }
    .close svg {
      color: #151517;
    }
    .logo {
      width: 66px;
      height: 60px;
    }
    .right-side {
      h2 {
        font-size: 46px;
      }
      p {
        font-size: 20px;
      }
      li {
        font-size: 18px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    zoom: 0.6;
  }

  @media screen and (min-width: 1400px) {
    zoom: 0.7;
  }

  @media screen and (min-width: 1600px) {
    zoom: 0.8;
  }

  @media screen and (min-width: 1800px) {
    zoom: 0.9;
  }
`;
