import styled from 'styled-components';

interface noteProps {
  textareaSelected: boolean;
}

export const Note = styled.div<noteProps>`
  background: var(--coal);
  border-radius: 15px;

  p {
    color: #242424;
    font-size: 0.9rem;
  }

  button {
    cursor: text !important;
  }

  textarea {
    background: #fff;
    border: none;
    color: #242424;
    padding: ${(props) => (props.textareaSelected ? '.375rem .75rem' : '0px')};
    height: ${(props) => (props.textareaSelected ? '124px' : '104px')};
    //height: 124px;
    transition: all 0.1s ease;
    resize: none;

    :focus {
      background: #fff;
      box-shadow: none;
      color: #242424;
    }
  }

  @media screen and (max-width: 1199px) {
    textarea {
      height: ${(props) => (props.textareaSelected ? '134px' : '114px')};
      //height: 134px;
    }
  }
`;
