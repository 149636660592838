import React from 'react';
import { Elements as StripeProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { AuthProvider } from './Auth';
import { IntercomProvider } from './Intercom';
import { LanguageProvider } from './Language';
import { NextStepProvider } from './NextStep';
import { ChatProvider } from './Chat';
import { TermsProvider } from './Terms';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

const AppProvider: React.FC = ({ children }) => (
  <StripeProvider stripe={stripePromise}>
    <AuthProvider>
      <NextStepProvider>
        <IntercomProvider>
          <ChatProvider>
            <TermsProvider>
              <LanguageProvider>{children}</LanguageProvider>
            </TermsProvider>
          </ChatProvider>
        </IntercomProvider>
      </NextStepProvider>
    </AuthProvider>
  </StripeProvider>
);

export default AppProvider;
