import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  .pad-dash {
    padding: 30px 0;
  }

  @media screen and (min-width: 1200px) {
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1600px) {
    .zoom {
      zoom: 0.9;
    }
  }

  @media screen and (min-width: 1700px) {
    .zoom {
      zoom: 1;
    }
  }
`;

export const AllCourses = styled.div``;

export const Welcome = styled.div`
  h1 {
    color: #021027;
  }
  p {
    color: #8c8c8c;
  }
`;

export const Content = styled.div`
  background: #ffffff;
  border-radius: 20px;

  a {
    font-weight: 300;
    color: #8c8c8c;
    border: 1px solid #dedede;
    border-radius: 16px;
    text-decoration: none !important;
    :hover {
      color: ${darken(0.1, '#8c8c8c')};
      border: 1px solid ${darken(0.1, '#dedede')};
      transition-duration: 0.5s;
    }
  }

  .pad-left {
    padding-left: 15px;
  }

  .pad-right {
    padding-right: 15px;
  }

  .pad-graph {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pad-refferals {
    padding-left: 15px;
    padding-right: 15px;
  }

  .skeleton {
    background-color: #f1f1f1 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: block;
    box-shadow: none;

    .apexcharts-xaxis {
      opacity: 0 !important;
    }

    .apexcharts-text {
      opacity: 0 !important;
    }

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 0) 10%,
        rgba(227, 227, 227, 0.2) 20%,
        rgba(227, 227, 227, 0.5) 40%,
        rgba(227, 227, 227, 0.8) 50%,
        rgba(227, 227, 227, 0.2) 80%,
        rgba(227, 227, 227, 0) 90%,
        rgba(227, 227, 227, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  .title-chart {
    h3,
    p {
      color: #021027;
    }
  }

  .bg-gray {
    background: #f9f9f9;
    border-radius: 20px;
    padding: 25px;

    h3,
    p {
      color: #303030;
    }

    h3 {
      font-size: 18px;
      color: #021027;
    }

    p {
      font-weight: 400;
    }

    li {
      border-color: #d0d0d0 !important;
    }
  }

  .semi-bold {
    font-weight: 600;
  }

  .icons {
    height: 30px;
  }

  /* .text-green {
    color: #20b27f !important;
  } */

  .text-red {
    color: #ff1a50;
  }

  .avatar-coach {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #303030;
  }

  p.total-referrals {
    color: #116bff;
  }

  @media screen and (min-width: 768px) {
    .pad-left {
      padding-left: 15px;
    }

    .pad-right {
      padding-right: 15px;
    }

    .pad-graph {
      padding-left: 30px;
      padding-right: 30px;
    }

    .pad-refferals {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @media screen and (min-width: 992px) {
    .pad-refferals {
      padding-left: 0px;
    }
  }

  @media screen and (min-width: 1400px) {
    .pad-left {
      padding-left: 15px;
    }

    .pad-right {
      padding-right: 15px;
    }

    .pad-graph {
      padding-left: 30px;
      padding-right: 30px;
    }

    .pad-refferals {
      padding-left: 0px;
      padding-right: 30px;
    }
  }

  @media screen and (min-width: 1600px) {
    .pad-left {
      padding-left: 15px;
    }

    .pad-right {
      padding-right: 40px;
    }

    .pad-graph {
      padding-left: 30px;
      padding-right: 40px;
    }

    .pad-refferals {
      padding-left: 0px;
      padding-right: 30px;
    }
  }

  @media screen and (min-width: 1750px) {
    .d-1750-block {
      display: block !important;
    }

    .d-1750-none {
      display: none !important;
    }

    .text-right {
      p:nth-child(2) {
        font-size: 34px;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .bg-gray {
      h3 {
        font-weight: 500;
      }
    }
  }
`;

export const Search = styled.div`
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 25px;
  height: 41px;

  input {
    margin: 5px 0;
    border: none;
    background-color: transparent;
    color: #8c8c8c;
  }
`;

export const Graph = styled.div`
  background: #f9f9f9;
  border-radius: 20px;
  padding: 25px;
`;

export const TopCoach = styled.div`
  background: #202020;
  border-radius: 20px;

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }

  .avatar {
    width: 90px;
    height: 90px;
    padding: 3px;
    background-image: linear-gradient(0deg, #202020, #202020),
      linear-gradient(180deg, #825511 0%, #f3bf5c 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }
`;

export const TopAfiliate = styled.div`
  background: #202020;
  border-radius: 20px;

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }

  .avatar {
    width: 90px;
    height: 90px;
    padding: 3px;
    background-image: linear-gradient(0deg, #202020, #202020),
      linear-gradient(180deg, #825511 0%, #f3bf5c 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }
`;
