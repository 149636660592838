import React, { useCallback, useEffect, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { format, parseISO } from 'date-fns';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import { Container, Orders, Skeleton } from './styles';

import noRegister from '~/assets/icons/no-register-icon.svg';
import master from '~/assets/defaults/master-test.svg';
import visa from '~/assets/defaults/visa-test.svg';

interface IOrderResponse {
  id: string;
  amount_paid: number;
  status: string;
  created_at: string;
  course: {
    title: string;
  };
  product: {
    name: string;
  };
  offer: {
    title: string;
  };
  payment: {
    brand: string;
    number: string;
  };
}

interface IOrder {
  id: string;
  amount_paid: string;
  title: string;
  created_at: string;
  brand: string;
  number: string;
}

interface IReceiptsProps {
  active: boolean;
}

const Receipts: React.FC<IReceiptsProps> = ({ active }) => {
  const { language } = useLanguage();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    api
      .get<IOrderResponse[]>('orders/users')
      .then((response) => {
        const newData = response.data.filter(
          (order) => order.status === 'succeeded' || order.status === 'active'
        );
        const data: IOrder[] = newData.map((order) => {
          let title = '';
          if (order.course) {
            title = order.course.title;
          }
          if (order.product) {
            title = order.product.name;
          }
          if (order.offer) {
            title = order.offer.title;
          }
          return {
            id: order.id,
            title,
            amount_paid: formatPrice(order.amount_paid),
            created_at: format(parseISO(order.created_at), 'yyyy-MM-dd'),
            brand: order.payment.brand,
            number: order.payment.number,
          };
        });
        setOrders(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleClickQuestion = useCallback((e) => {
    const questionBox = e.target.closest('.order-box');
    if (questionBox) {
      questionBox.classList.toggle('active');
    }
  }, []);

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <div className="container-fluid">
        <div className="row">
          {loading && (
            <>
              <Skeleton className="col-md-6">
                <div className="order-box p-2">
                  <button
                    type="button"
                    className="skeleton d-flex justify-content-between align-items-center py-3 px-3 order w-100 border-0"
                  >
                    <p className="mb-0 font-weight-bold">#############</p>
                  </button>
                </div>
              </Skeleton>
              <Skeleton className="col-md-6">
                <div className="order-box p-2">
                  <button
                    type="button"
                    className="skeleton d-flex justify-content-between align-items-center py-3 px-3 order w-100 border-0"
                  >
                    <p className="mb-0 font-weight-bold">#############</p>
                  </button>
                </div>
              </Skeleton>
            </>
          )}
          {orders.length > 0 ? (
            <>
              {orders.map((order) => (
                <Orders className="col-lg-6 px-0 px-sm-3">
                  <div className="order-box p-sm-2">
                    <button
                      type="button"
                      className="d-flex justify-content-between align-items-center px-3 px-xxl-4 order w-100 border-0"
                      onClick={handleClickQuestion}
                    >
                      <b>{order.title}</b>
                      <FiChevronDown />
                    </button>
                    <div className="bg-infos px-3 px-xxl-4">
                      <div className="overflow-auto infos px-0">
                        <p className="d-sm-flex my-2 pb-2 justify-content-between align-items-center ">
                          <span className="d-block text-center text-sm-left">
                            {order.brand === 'Visa' && (
                              <img src={visa} alt="Visa" />
                            )}
                            {order.brand === 'Mastercard' && (
                              <img src={master} alt="Mastercard" />
                            )}
                            {order.brand}
                            ····{order.number}
                          </span>
                          <span className="d-block text-center text-sm-right mt-3 mt-sm-0">
                            {order.created_at}
                          </span>
                        </p>
                        <div className="d-flex justify-content-between pb-2">
                          <b>{language.receipts.b_1}</b>
                          <b>{language.receipts.b_2}</b>
                        </div>
                        <div className="d-flex justify-content-between py-4">
                          <p className="mb-0 product">{order.title}</p>

                          <p className="mb-0 product">{order.amount_paid}</p>
                        </div>
                        <div className="d-flex justify-content-between pt-2">
                          <span className="total">{language.receipts.b_3}</span>

                          <span className="total">{order.amount_paid}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Orders>
              ))}
            </>
          ) : (
            <div className={`${loading === true ? 'd-none' : ''} col-12`}>
              <div className="row h-40vh align-items-center justify-content-center">
                <div className="col-md-8 d-md-flex text-center align-items-md-center justify-content-md-center">
                  <img
                    src={noRegister}
                    className="mb-3 mb-md-0"
                    alt="No Register icon"
                  />
                  <p className="h5 mb-0 pl-md-3 text-no-register text-center">
                    {language.no_register.p}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Receipts;
