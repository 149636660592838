import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2';
import swalError from '~/utils/swalError';
import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, AnimationContainer, Loading } from './styles';
import Input from '~/components/Input';

import logo from '~/assets/logotipos/logo.png';

import api from '~/services/api';
import BannersTestimonials from '~/components/BannersTestimonials';
import SocialBlueMobile from '~/components/SocialBlueMobile';

interface SignInCelphoneFormData {
  celphone: string;
}

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: FC = () => {
  const history = useHistory();
  const formCelphoneRef = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();
  const { language } = useLanguage();
  const [celPhone, setCelPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [celPhoneError, setCelPhoneError] = useState('');
  const [loadingContinue, setLoadingContinue] = useState(false);

  const handleSubmitCelphone = useCallback(
    async (data: SignInCelphoneFormData) => {
      try {
        formCelphoneRef.current?.setErrors({});
        setCelPhoneError('');
        const schema = Yup.object().shape({
          celphone: Yup.string().when('$celphoneData', {
            is: (celphoneData: boolean) => !celphoneData,
            then: Yup.string().required('phone number is required'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: { celphoneData: celPhone.length > 4 },
        });

        const response = await api.post('users/2fa', {
          celphone: `+${celPhone}`,
        });

        localStorage.setItem(
          '@AutoAffiliate:userData',
          JSON.stringify(response.data)
        );

        history.push(`${process.env.PUBLIC_URL}/passcode`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formCelphoneRef.current?.setErrors(errors);
          if (errors.celphone) {
            setCelPhoneError(errors.celphone);
          }
        } else {
          swalError({
            message:
              'Looks like what you were trying to do didn’t work, please try again.',
            textButton: 'Try Again',
          });
        }
      }
    },
    [celPhone, history]
  );

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email(language.sign_in.erro_1)
            .required(language.sign_in.erro_2),
          password: Yup.string().required(language.sign_in.erro_3),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        setLoadingContinue(true);

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (error) {
        setLoadingContinue(false);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          swalError({
            message:
              'Looks like the code you entered is invalid password or email, please try again.',
            textButton: 'Try Again',
          });
        }
      }
    },
    [
      language.sign_in.erro_1,
      language.sign_in.erro_2,
      language.sign_in.erro_3,
      signIn,
    ]
  );

  const handleClickContinue = useCallback(() => {
    if (email && password) {
      formRef.current?.submitForm();
    } else if (celPhone.length > 4) {
      formCelphoneRef.current?.submitForm();
    } else if (email || password) {
      formRef.current?.submitForm();
    }
  }, [celPhone, email, password]);

  return (
    <Container className="d-flex align-items-center">
      <div className="container-fluid h-100">
        <div className="row justify-content-center justify-content-lg-start h-100">
          <AnimationContainer className="col-sm-11 col-lg-6 px-0 mt-4">
            <div className="d-flex justify-content-between pl-4 pl-sm-0">
              <img src={logo} alt="Logo" className="size-mobile" />
            </div>
            <div className="align-items-center px-sm-3 mt-lg-5">
              <div className="p-xxl-6 p-lg-4 p-sm-5 p-4 login">
                <h1 className="pt-4 my-5">Hi there!</h1>
                <h2 className="">Welcome back to AutoAffiliate.</h2>
                <Form
                  ref={formCelphoneRef}
                  onSubmit={handleSubmitCelphone}
                  className="mt-4 d-none"
                >
                  <label htmlFor="celphone" className="d-block mt-3">
                    Enter your phone number below to login
                  </label>
                  <PhoneInput
                    placeholder="Mobile Number"
                    country="us"
                    value={celPhone}
                    onChange={(value) => setCelPhone(value)}
                    masks={{
                      br: '(..) .....-....',
                    }}
                    inputClass="input-phone"
                    containerClass="input-container"
                    buttonClass="input-button"
                  />
                  {celPhoneError && (
                    <span className="small text-danger">{celPhoneError}</span>
                  )}
                </Form>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <Input
                    id="email"
                    name="email"
                    className="bg-transparent input-email"
                    placeholder="Email"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setEmail(event.target.value)
                    }
                  />

                  <Input
                    id="password"
                    type="password"
                    name="password"
                    className="bg-transparent btn-show-custom input-password mt-3"
                    placeholder={language.sign_in.label_2}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setPassword(event.target.value)
                    }
                  />
                  <Link
                    to={`${process.env.PUBLIC_URL}/forgot-password`}
                    className="d-table forgot ml-auto mb-xl-3 mb-xxl-5 mt-2 text-right pr-4"
                  >
                    {language.sign_in.a}
                  </Link>
                  {loadingContinue ? (
                    <Loading className="btn btn-clear-cache d-flex align-items-center justify-content-center my-5 my-lg-4 my-xxl-0">
                      <div className="spinner" />
                    </Loading>
                  ) : (
                    <button
                      type="button"
                      className="button-login my-5 my-lg-4 my-xxl-0 w-100"
                      onClick={handleClickContinue}
                    >
                      <span className="text-white font-weight-bold d-block px-5 py-3">
                        Continue
                      </span>
                    </button>
                  )}
                </Form>
                <div className="d-lg-none">
                  <SocialBlueMobile />
                </div>
              </div>
            </div>
          </AnimationContainer>
          <div className="d-none d-lg-block col-lg-6 pr-0">
            <BannersTestimonials />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SignIn;
