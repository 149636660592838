import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { FiArrowLeft } from 'react-icons/fi';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container } from './styles';
import Player from '~/components/Player';

import thumb1 from '~/assets/defaults/affiliates-crowfunding.png';
import thumb2 from '~/assets/defaults/affiliates-promotional-material.png';
import thumb3 from '~/assets/defaults/affiliates-training.png';
import avatar from '~/assets/defaults/avatar-test.png';

interface IParams {
  slug: string;
}

interface ITraffic {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  video: string;
  slug: string;
  duration: string;
}

const Traffic: React.FC = () => {
  const { language } = useLanguage();
  const params = useParams<IParams>();
  const [traffic, setTraffic] = useState<ITraffic[]>([]);
  const [loading, setLoading] = useState(false);
  const [indexSel, setIndexSel] = useState(-1);

  useEffect(() => {
    setTraffic([
      {
        id: '01',
        title: 'How to get traffic to your links',
        description:
          'In this course I will teach you how to make your illustration look really good. We’re gonna study a lot os cases here, settings your work panel,, making some aewsome actions and presets for fututre. Hope you’ll get some really useful stuff out of this course. Good Luck to all!',
        thumbnail: thumb1,
        video: 'https://cdn.autoaffiliate.ai/videos/AA-2.0-Landing-Page-v1.mp4',
        slug: 'how-to-get-traffic-to-your-links',
        duration: '10:00',
      },
      {
        id: '02',
        title: 'How to get traffic to your links2',
        description:
          'In this course I will teach you how to make your illustration look really good. We’re gonna study a lot os cases here, settings your work panel,, making some aewsome actions and presets for fututre. Hope you’ll get some really useful stuff out of this course. Good Luck to all!',
        thumbnail: thumb2,
        video: 'https://cdn.autoaffiliate.ai/videos/AA-2.0-Landing-Page-v1.mp4',
        slug: 'how-to-get-traffic-to-your-links-2',
        duration: '10:20',
      },
      {
        id: '03',
        title: 'How to get traffic to your links3',
        description:
          'In this course I will teach you how to make your illustration look really good. We’re gonna study a lot os cases here, settings your work panel,, making some aewsome actions and presets for fututre. Hope you’ll get some really useful stuff out of this course. Good Luck to all!',
        thumbnail: thumb3,
        video: 'https://cdn.autoaffiliate.ai/videos/AA-2.0-Landing-Page-v1.mp4',
        slug: 'how-to-get-traffic-to-your-links-3',
        duration: '10:30',
      },
    ]);
  }, []);

  return (
    <>
      {Object.keys(traffic).length > 0 && (
        <Container>
          <div className="container container-lg-fluid py-4 zoom">
            <div className="row">
              {traffic.map((vidSelected) => (
                <>
                  {params.slug === vidSelected.slug && (
                    <>
                      <div className="col-12 order-0">
                        <div className="d-flex title align-items-center">
                          {/* <Link
                            to={`${process.env.PUBLIC_URL}/trainings`}
                            className="mb-4 mr-4"
                          >
                            <FiArrowLeft size={38} color="#8C8C8C" />
                          </Link> */}
                          <h1 className="h2 mb-4">{vidSelected.title}</h1>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))}
              <div className="col-12 p-lg-5 bg-white">
                <div className="row">
                  {traffic.map((vidSelected) => (
                    <>
                      {params.slug === vidSelected.slug && (
                        <>
                          <div className="col-lg-8">
                            <Player
                              src={vidSelected.video}
                              thumbnail={vidSelected.thumbnail}
                              className="video-size player"
                            />
                            <button
                              type="button"
                              className="mt-3 w-100 py-3 border-0 text-white h5 font-weight-bold btn-traffic"
                            >
                              GET TRAFFIC NOW
                            </button>
                            <div className="mt-4">
                              <h3 className="h4 fw-600">What you’ll Learn</h3>
                              <p className="h6 mt-4 font-weight-normal max-h">
                                {vidSelected.description}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
                  <div className="col-lg-4 mt-5 mt-lg-0 others pl-lg-4">
                    <div className="h-others pr-2">
                      {traffic.map((video, index) => (
                        <div className="mb-4">
                          <Link
                            onMouseEnter={() => setIndexSel(index)}
                            onMouseLeave={() => setIndexSel(-1)}
                            to={video.slug}
                            className={`${
                              index === indexSel && 'opacity-80'
                            } h-100 d-block position-relative`}
                          >
                            <img
                              src={video.thumbnail}
                              alt={video.title}
                              className="w-100 mb-2 img-thumb"
                            />
                            <span className="duration">{video.duration}</span>
                            <span className="title">{video.title}</span>
                            <br />
                            <span className="autor">
                              <img
                                src={avatar}
                                alt="Avatar"
                                className="avatar mr-2"
                              />
                              by AutoAffiliate
                            </span>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading && (
            <div className="loading-box">
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </Container>
      )}
    </>
  );
};

export default Traffic;
