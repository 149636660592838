/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { format, parseISO } from 'date-fns';

import { Link } from 'react-router-dom';
import { useLanguage } from '~/hooks/Language';
import { formatPrice } from '~/utils/format';
import api from '~/services/api';

import { Container } from './styles';

import { useAuth } from '~/hooks/Auth';

import noRegister from '~/assets/icons/no-register-icon.svg';

interface IOrdersProps {
  active: boolean;
}

interface IAffiliateProductResponse {
  id: string;
  created_at: string;
  product: {
    id: string;
    name: string;
    type: string;
    created_at: string;
    price: number;
    thumbnail: {
      title: string;
      thumbnail_url: string;
    };
    orders: {
      type: string;
      amount_paid: number;
    }[];
  };
}

interface IPurchase {
  id: string;
  product_id: string;
  title: string;
  by: string;
  date: string;
  commission: string;
  thumbnail: {
    thumbnail_url: string;
    title: string;
  };
}

const Purchase: React.FC<IOrdersProps> = ({ active }) => {
  const { user } = useAuth();
  const [purchases, setPurchases] = useState<IPurchase[]>([]);
  const [loading, setLoading] = useState(true);
  const { language } = useLanguage();

  useEffect(() => {
    api
      .get<IAffiliateProductResponse[]>(
        `affiliates-products/affiliates/${user.affiliate.id}`
      )
      .then((response) => {
        response.data.sort(
          (a, b) =>
            parseISO(a.product.created_at).getTime() -
            parseISO(b.product.created_at).getTime()
        );
        const data = response.data.map<IPurchase>((affiliateProduct) => {
          let commission = '-';

          if (affiliateProduct.product.orders.length > 0) {
            if (affiliateProduct.product.orders[0].type === 'subscription') {
              commission = `${formatPrice(
                affiliateProduct.product.orders[0].amount_paid
              )} per month`;
            } else {
              commission = `${formatPrice(
                affiliateProduct.product.orders[0].amount_paid
              )} x ${affiliateProduct.product.orders.length
                .toString()
                .padStart(2, '0')}`;
            }
          } else {
            commission = formatPrice(0);
          }

          return {
            id: affiliateProduct.id,
            product_id: affiliateProduct.product.id,
            title: affiliateProduct.product.name,
            by: 'AutoAffiliate',
            date: format(
              parseISO(affiliateProduct.created_at),
              'MMMM dd, yyyy'
            ),
            commission,
            thumbnail: affiliateProduct.product.thumbnail,
          };
        });

        setPurchases(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user.affiliate.id]);

  const handleClickAccessCourse = useCallback(async () => {
    const response = await api.post('users/wiserr', {
      email: user.email,
    });

    window.open(
      `${process.env.REACT_APP_WISERR_URL}/check-login/${response.data}`,
      '_blank'
    );
  }, [user.email]);

  return (
    <>
      <Container className={active ? 'd-block' : 'd-none'}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 py-lg-3">
              <div className="row bg-gray p-2">
                {loading ? (
                  <div className="col-12 mt-4 mt-md-5">
                    <div className="row h-xl-100 align-items-start">
                      <div className="col-md-6 col-lg-3 mb-4 mb-md-4 mb-lg-5">
                        <div className="d-flex h-100 justify-content-center">
                          <div className="w-100 position-relative">
                            <div
                              className={`${
                                loading === true ? 'skeleton' : ''
                              } mx-auto d-none d-md-block img-width radius-20`}
                            />
                            <div
                              className={`${
                                loading === true ? 'skeleton' : ''
                              } mx-auto d-md-none img-width radius-20`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 mb-0 mb-md-4 mb-lg-5">
                        <div className="d-flex align-items-center h-100">
                          <div className="mt-lg-2">
                            <h3
                              className={`${
                                loading === true ? 'skeleton' : ''
                              } h6`}
                            >
                              Decentralized Community & AI Tools
                            </h3>
                            <p
                              className={`${
                                loading === true ? 'skeleton' : ''
                              }`}
                            >
                              by PROFITi
                            </p>
                            <h3
                              className={`${
                                loading === true ? 'skeleton' : ''
                              } h6 mt-lg-4 d-none d-md-block`}
                            >
                              Affiliate Link
                            </h3>
                            <p
                              className={`${
                                loading === true ? 'skeleton' : ''
                              } d-none d-md-block`}
                            >
                              -
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 mb-0 mb-md-4 mb-lg-5">
                        <div className="d-flex justify-content-md-center h-100">
                          <div className="mt-lg-2">
                            <h3
                              className={`${
                                loading === true ? 'skeleton' : ''
                              } h6 mt-4 mt-md-0`}
                            >
                              Affiliate Commission
                            </h3>

                            <p
                              className={`${
                                loading === true ? 'skeleton' : ''
                              }`}
                            >
                              Up to 90%
                            </p>
                            <h3
                              className={`${
                                loading === true ? 'skeleton' : ''
                              } h6 mt-4 d-md-none`}
                            >
                              Affiliate Link
                            </h3>
                            <p
                              className={`${
                                loading === true ? 'skeleton' : ''
                              } d-md-none mb-4`}
                            >
                              -
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="h-xl-100 col-md-6 col-lg-3">
                        <div className="h-100 d-flex flex-column align-items-center justify-content-end mt-auto pb-5">
                          <button
                            type="button"
                            className={`${
                              loading === true ? 'skeleton' : ''
                            } sign mb-3`}
                          >
                            Sign up to get your Link
                          </button>
                          <button
                            type="button"
                            className={`${
                              loading === true ? 'skeleton' : ''
                            } add`}
                          >
                            Add Affiliate Link
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {purchases.length > 0 ? (
                      <>
                        {purchases.map((purchase, index) => (
                          <Fragment key={purchase.id}>
                            {index > 0 && (
                              <div className="col-12">
                                <hr className="mx-auto my-0" />
                              </div>
                            )}
                            <div
                              className={`col-12 ${
                                index > 0 ? 'mt-5' : 'mt-4 mt-md-5'
                              }`}
                            >
                              <div
                                className={`row h-xl-100 ${
                                  purchase.by
                                    ? 'align-items-start'
                                    : 'align-items-center'
                                }`}
                              >
                                <div className="col-md-6 col-lg-3 mb-4 mb-md-4 mb-lg-5">
                                  <div className="d-flex h-100 justify-content-center">
                                    <div className="position-relative">
                                      <img
                                        src={purchase.thumbnail.thumbnail_url}
                                        alt="First"
                                        className="d-none d-md-block img-width radius-20"
                                      />
                                      <img
                                        src={purchase.thumbnail.thumbnail_url}
                                        alt="First"
                                        className="d-md-none img-width radius-20"
                                      />
                                      <div className="position-absolute positions">
                                        <h2>
                                          {
                                            purchase.thumbnail.title.split(
                                              ' '
                                            )[0]
                                          }
                                        </h2>
                                        <p>
                                          {purchase.thumbnail.title.slice(
                                            purchase.thumbnail.title.split(
                                              ' '
                                            )[0].length
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-0 mb-md-4 mb-lg-5">
                                  <div className="d-flex align-items-center h-100">
                                    <div className="mt-lg-2">
                                      <h3 className="h6">{purchase.title}</h3>

                                      <p>by {purchase.by}</p>
                                      <h3 className="h6 pt-lg-4 d-none d-md-block">
                                        Purchase Date
                                      </h3>
                                      <p className="d-none d-md-block">
                                        {purchase.date}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-2 mb-0 mb-md-4 mb-lg-5">
                                  <div className="d-flex justify-content-md-center h-100">
                                    <div className="mt-lg-2">
                                      <h3 className="h6 pt-4 pt-md-0">
                                        Amount Paid
                                      </h3>
                                      <p>{purchase.commission}</p>
                                      <h3 className="h6 pt-4 d-md-none">
                                        Purchase Date
                                      </h3>
                                      <p className="d-md-none pb-4">
                                        {purchase.date}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="h-xl-100 col-md-6 col-lg-3">
                                  <div className="h-100 d-flex flex-column align-items-center justify-content-end mt-auto pb-5">
                                    {purchase.product_id ===
                                      process.env.REACT_APP_CHAT_GPT && (
                                      <a
                                        href="https://cdn.autoaffiliate.ai/products/Mastering_Traffic_and_Leads__A_Comprehensive_Manual_for_Success_Using_ChatGPT.pdf"
                                        className="sign mb-3"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Access Purchase
                                      </a>
                                    )}

                                    {purchase.product_id ===
                                      process.env.REACT_APP_PROMPT_EASE && (
                                      <a
                                        href={`${process.env.PUBLIC_URL}/prompt-ease`}
                                        className="sign mb-3"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Access Purchase
                                      </a>
                                    )}
                                    {/* Botão para instalar a extensão */}
                                    {purchase.product_id ===
                                      '43e9692d-005b-4c46-997f-6ad4575c023f' && (
                                      <a
                                        href="https://chromewebstore.google.com/search/PostEASE"
                                        className="sign mb-3"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Install Now
                                      </a>
                                    )}

                                    {(purchase.product_id ===
                                      process.env.REACT_APP_PROMPT_EASE_PRO ||
                                      purchase.product_id ===
                                        process.env.REACT_APP_BLACKFRIDAY) && (
                                      <a
                                        href={`${process.env.PUBLIC_URL}/prompt-ease-pro`}
                                        className="sign mb-3"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Access Purchase
                                      </a>
                                    )}

                                    {(purchase.product_id ===
                                      process.env.REACT_APP_BLACKFRIDAY ||
                                      purchase.product_id ===
                                        process.env.REACT_APP_VIP) && (
                                      <button
                                        type="button"
                                        className="sign course mb-3"
                                        onClick={handleClickAccessCourse}
                                      >
                                        Access Bonus
                                      </button>
                                    )}
                                    {purchase.commission !== '$0.00' && (
                                      <Link
                                        to={`${process.env.PUBLIC_URL}/profile/receipts`}
                                        className="add"
                                      >
                                        View Receipt
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                      </>
                    ) : (
                      <div className="col-12">
                        <div className="row h-40vh align-items-center justify-content-center">
                          <div className="col-md-8 d-md-flex text-center align-items-md-center justify-content-md-center">
                            <img
                              src={noRegister}
                              className="mb-3 mb-md-0"
                              alt="No Register icon"
                            />
                            <p className="h5 mb-0 pl-md-3 text-no-register text-center">
                              {language.no_register.p}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Purchase;
