import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Container, Contents } from './styles';
import ButtonLesson from '~/components/ButtonLesson';
import { IQuestion } from '~/components/Quizzes';

import play from '~/assets/icons/play-icon.svg';
import playModule from '~/assets/icons/play-module.svg';

import { useAuth } from '~/hooks/Auth';

interface ILesson {
  id: string;
  title: string;
  duration: string;
  status: string;
  slug: string;
  finished: boolean;
  block: boolean;
}

interface IModule {
  id: string;
  title: string;
  locked: boolean;
  lessons: ILesson[];
  slug: string;
  type?: 'introduction' | 'exam';
  exam?: {
    id: string;
    questions: IQuestion[];
    answered: boolean;
    allQuestionsCorrect: boolean;
  };
}

interface IModulesProps {
  data: IModule[];
  slugCourse: string;
  nextStep?: string;
  onAnsweredExam?(): void;
  onClickFinished?(lesson_id: string): void;
}

const Modules: React.FC<IModulesProps> = ({
  data,
  slugCourse,
  nextStep,
  onClickFinished,
}) => {
  const { user } = useAuth();
  const location = useLocation();
  const [moduleSelected, setModuleSelected] = useState('');
  const [modules, setModules] = useState<IModule[]>([]);

  const moduleOpen = sessionStorage.getItem('moduleSelected');

  const lessonsModule1 = useMemo(() => {
    if (modules[0]) {
      return modules[0].lessons.reduce((previous, current) => {
        previous.push(current.slug);
        return previous;
      }, [] as string[]);
    }

    return [];
  }, [modules]);

  const lessonsModule2 = useMemo(() => {
    if (modules[1]) {
      return modules[1].lessons.reduce((previous, current) => {
        previous.push(current.slug);
        return previous;
      }, [] as string[]);
    }

    return [];
  }, [modules]);

  const lessonsModule3 = useMemo(() => {
    if (modules[2]) {
      return modules[2].lessons.reduce((previous, current) => {
        previous.push(current.slug);
        return previous;
      }, [] as string[]);
    }

    return [];
  }, [modules]);

  useEffect(() => {
    setModules(data);
    if (nextStep) {
      localStorage.setItem(`next_step_${user.id}`, nextStep);

      if (moduleOpen === null && lessonsModule1.includes(nextStep)) {
        setModuleSelected('0360dd74-ef1a-447e-966b-e420169e74b0');
      }

      if (moduleOpen === null && lessonsModule2.includes(nextStep)) {
        setModuleSelected('07caaf71-190d-4772-b6d3-0a367edc6fab');
      }

      if (moduleOpen === null && lessonsModule3.includes(nextStep)) {
        setModuleSelected('bd48e222-f473-4478-94a2-1874d0f5ef00');
      }
    }
  }, [
    data,
    lessonsModule1,
    lessonsModule2,
    lessonsModule3,
    moduleOpen,
    nextStep,
    user.id,
  ]);

  const handleClick = useCallback(
    (moduleId) => {
      if (moduleSelected === moduleId) {
        setModuleSelected('');
        sessionStorage.removeItem('moduleSelected');
      } else {
        setModuleSelected(moduleId);
        sessionStorage.setItem('moduleSelected', moduleId);
      }
    },
    [moduleSelected]
  );

  return (
    <Contents>
      <Link
        to={`${process.env.PUBLIC_URL}/lessons/welcome`}
        className={`${
          location.pathname === '/lessons/welcome' && 'active'
        } introduction mb-2`}
      >
        <img src={play} alt="Icon Button" />
        Getting Started
      </Link>
      {modules.map((module, index) => (
        <>
          {module.title !== 'introduction' && (
            <Container
              key={module.id}
              active={moduleSelected === module.id || moduleOpen === module.id}
              qtdLessons={
                module.exam ? module.lessons.length + 1 : module.lessons.length
              }
              className="mb-2"
            >
              <button
                type="button"
                className="w-100"
                onClick={() => handleClick(module.id)}
              >
                <div className="d-flex align-items-center p-2 module">
                  <img src={playModule} alt="Play" className="ml-2 mr-3" />

                  <p className="mb-0 font-weight-normal d-flex align-items-center w-100 ml-1 line-height">
                    {module.title}{' '}
                    <span>{modules[index].lessons.length} Videos</span>
                  </p>
                </div>
              </button>
              <div className="pl-3 content">
                {module.lessons.map((lesson) => (
                  <ButtonLesson
                    key={lesson.id}
                    lesson={lesson}
                    slugCourse={slugCourse}
                    onClickFinished={onClickFinished}
                  />
                ))}
              </div>
            </Container>
          )}
        </>
      ))}
    </Contents>
  );
};

export default Modules;
