import styled from 'styled-components';

interface IItem {
  active?: boolean;
}

export const Container = styled.div`
  position: relative;
  button[disabled] {
    svg {
      fill: #676767;
      opacity: 0.1;
    }
  }

  .download {
    border-radius: 24px;
    border: 2px solid #fff;

    background: #116bff;
    padding: 13px;
    width: 368px;
    height: 83px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    :hover {
      opacity: 0.8;
    }
  }

  .quote {
    width: 18px;
  }

  .slick-list {
    border-radius: 40px;
    border: 10px solid #fff;
    filter: drop-shadow(66px 74px 120px rgba(14, 1, 24, 0.2));
  }

  .slick-dots {
    position: absolute;
    bottom: -40px;
    display: flex !important;
    width: 50%;
    padding: 0;
    margin: 0;
    left: 25%;
    list-style: none;
    text-align: center;
    overflow: hidden;
    justify-content: center;
  }

  .slick-dots li button:before {
    color: #fff;
    font-size: 10px;
  }

  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #fff;
  }

  video,
  .video-js {
    height: 850px !important;
  }

  .slider-count {
    position: absolute;
    right: 21%;
    top: -32px;
    font-family: 'Source Code Pro', sans-serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    color: #676767;
    span {
      font-weight: 500;
      color: #000;
    }
  }

  @media screen and (min-width: 576px) {
    .quote {
      width: auto;
    }
    .slider-count {
      right: 16%;
      top: -35px;
    }
  }

  @media screen and (min-width: 768px) {
    .quote {
      width: 18px;
    }
  }

  @media screen and (min-width: 992px) {
    .slider-count {
      right: 16%;
      top: -41px;
    }
  }

  @media screen and (min-width: 1200px) {
    .slider-count {
      right: 14%;
      top: -43px;
    }
  }
`;

export const Item = styled.div<IItem>`
  width: 100%;
  border: none;
  background: transparent;
  /* padding-top: 3rem;
  padding-bottom: 4rem; */

  .bg-black {
    background-color: #000;
    height: 850px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ButtonLeft = styled.button`
  display: none;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid transparent;
  transition-duration: 0.3s;
  bottom: -70px;

  left: unset;
  transform: translateY(-50%);
  top: -2%;
  right: 37%;

  svg {
    fill: #fff;
  }

  :before,
  :after {
    display: none;
  }

  @media screen and (min-width: 576px) {
    right: 27%;
  }

  @media screen and (min-width: 768px) {
    display: block;
    top: 50%;
    right: 100%;
  }

  @media screen and (min-width: 1200px) {
  }
`;

export const ButtonRight = styled.button`
  display: none;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid transparent;

  transition-duration: 0.3s;
  bottom: -70px;
  right: 35%;
  transform: translateY(-50%);

  svg {
    fill: #fff;
  }

  :before,
  :after {
    display: none;
  }

  @media screen and (min-width: 768px) {
    display: block;
    top: 50%;
    right: -8%;
  }
`;
