import React, { useState, useCallback, useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';
import swalError from '~/utils/swalError';

import { Container, CreditCards, Loading } from './styles';
import visa from '~/assets/icons/cc-visa-dark.svg';
import mastercard from '~/assets/icons/cc-mastercard-dark.svg';
import discover from '~/assets/icons/cc-discover-dark.svg';
import amex from '~/assets/icons/cc-amex-dark.svg';
import ccDefault from '~/assets/icons/cc-default.svg';
import RemoveCreditCard from '../RemoveCreditCard';

interface IResponseCreditCard {
  id: string;
  name: string;
  number: string;
  expirity: string;
  brand: string;
  address: string;
  primary_card: boolean;
}

export interface ICreditCard {
  id: string;
  name: string;
  number: string;
  expirity: string;
  brand: string;
  brandIcon: string;
  primary_card: boolean;
}

interface creditCardProps {
  type: 'new' | 'change' | 'sale';
  onChangeCreditCardSelected(creditCard: ICreditCard): void;
  className?: string;
}

const CreditCard: React.FC<creditCardProps> = ({
  type,
  onChangeCreditCardSelected,
  className,
}) => {
  const stripe = useStripe();
  const elements = useElements() as any;
  const { user } = useAuth();
  const [creditCards, setCreditCards] = useState<ICreditCard[]>([]);
  const [creditCardSelected, setCreditCardSelected] = useState(
    {} as ICreditCard
  );
  const [addNewCreditCard, setAddNewCreditCard] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api
      .get<IResponseCreditCard[]>('payments/customers/credit-cards')
      .then((response) => {
        let selectedCard: ICreditCard | undefined;
        const data: ICreditCard[] = response.data.map((creditCard) => {
          let brandIcon;
          switch (creditCard.brand) {
            case 'Visa':
              brandIcon = visa;
              break;

            case 'MasterCard':
              brandIcon = mastercard;
              break;

            case 'Discover':
              brandIcon = discover;
              break;

            case 'American Express':
              brandIcon = amex;
              break;

            default:
              brandIcon = ccDefault;
              break;
          }

          const creditCardData = {
            id: creditCard.id,
            name: creditCard.name,
            number: creditCard.number,
            expirity: creditCard.expirity,
            primary_card: creditCard.primary_card,
            brand: creditCard.brand,
            brandIcon,
          };

          if (creditCard.primary_card) {
            selectedCard = creditCardData;
          }

          return creditCardData;
        });

        if (!selectedCard) {
          // eslint-disable-next-line prefer-destructuring
          selectedCard = data[0];
        }
        setCreditCardSelected(selectedCard);
        setCreditCards(data);
      });
  }, []);
  //
  const handleSubmitPaymentMethod = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setLoading(true);
        const result = await stripe?.createToken(
          elements.getElement(CardElement)
        );

        if (result?.token || Object.keys(creditCardSelected).length === 0) {
          const response = await api.post('payments/payment-methods', {
            type: 'card',
            token: result?.token?.id,
            customer_id: user.customer_id,
          });

          const formData = {
            id: response.data.id,
            user_id: user.id,
            name: result?.token?.card?.name,
            number: result?.token?.card?.last4,
            expirity: `${result?.token?.card?.exp_month}/${result?.token?.card?.exp_year}`,
            brand: result?.token?.card?.brand,
            primary_card: false,
          };

          const responseCreditCard = await api.post('credit-cards', formData);

          let brandIcon;
          switch (responseCreditCard.data.brand) {
            case 'Visa':
              brandIcon = visa;
              break;

            case 'MasterCard':
              brandIcon = mastercard;
              break;

            case 'Discover':
              brandIcon = discover;
              break;

            case 'American Express':
              brandIcon = amex;
              break;

            default:
              brandIcon = ccDefault;
              break;
          }

          onChangeCreditCardSelected({
            id: responseCreditCard.data.id,
            name: responseCreditCard.data.name,
            number: responseCreditCard.data.number,
            expirity: responseCreditCard.data.expirity,
            primary_card: responseCreditCard.data.primary_card,
            brand: responseCreditCard.data.brand,
            brandIcon,
          });
          setCreditCardSelected({
            id: responseCreditCard.data.id,
            name: responseCreditCard.data.name,
            number: responseCreditCard.data.number,
            expirity: responseCreditCard.data.expirity,
            primary_card: responseCreditCard.data.primary_card,
            brand: responseCreditCard.data.brand,
            brandIcon,
          });
        }

        setLoading(false);
        onChangeCreditCardSelected(creditCardSelected);
      } catch (error) {
        setLoading(false);
        swalError({
          message:
            'Looks like what you were trying to do didn’t work, please try again.',
          textButton: 'Try Again',
        });
      }
    },
    [
      creditCardSelected,
      elements,
      onChangeCreditCardSelected,
      stripe,
      user.customer_id,
      user.id,
    ]
  );

  const handleClick = useCallback(async (creditCard) => {
    setCreditCardSelected(creditCard);
  }, []);

  const handleDeleteCreditCard = useCallback(
    async (creditCard) => {
      const newCreditCards = creditCards.slice();
      if (creditCard.primary_card && newCreditCards.length > 1) {
        if (creditCard === newCreditCards[0]) {
          await api.patch(
            `payments/customers/credit-cards/${newCreditCards[1].id}`
          );
          newCreditCards[1].primary_card = true;
        } else {
          await api.patch(
            `payments/customers/credit-cards/${newCreditCards[0].id}`
          );
          newCreditCards[0].primary_card = true;
        }
      }
      const cards = newCreditCards.filter(
        (creditCardData) => creditCardData.id !== creditCard.id
      );

      const primaryCard = newCreditCards.find(
        (creditCardData) => !!creditCardData.primary_card
      );

      if (primaryCard) {
        setCreditCardSelected(primaryCard);
      }
      setCreditCards(cards);
    },
    [creditCards]
  );

  return (
    <Container onSubmit={handleSubmitPaymentMethod} className={className}>
      <div className="row justify-content-center">
        <div className="col-xl-10">
          <h2 className="mb-4">Customer Checkout</h2>
        </div>
        {creditCards.length > 0 && (
          <div className="col-xl-10 credit-cards-group">
            <CreditCards className="row">
              {creditCards.map((creditCard) => (
                <div key={creditCard.id} className="col-lg-6 mb-3">
                  <button
                    type="button"
                    onClick={() => handleClick(creditCard)}
                    className={`btn btn-credit-card p-3 w-100 ${
                      creditCardSelected.id === creditCard.id ? 'selected' : ''
                    }`}
                  >
                    <div className="row">
                      <div className="col-sm-3 text-center d-flex align-items-center justify-content-sm-center">
                        <img
                          src={creditCard.brandIcon}
                          alt={creditCard.brand}
                          className="pl-2 pl-sm-0 mb-3 mb-sm-0"
                        />
                      </div>
                      <div className="col pr-0 px-sm-3 d-flex align-items-center">
                        <p className="mb-0 pl-2 pl-sm-0">
                          {creditCard.brand}:&nbsp;&nbsp;****-
                          {creditCard.number}
                        </p>
                      </div>
                      <div className="col-2 text-center pl-1 pr-4 px-sm-3 d-flex align-items-center justify-content-end justify-content-sm-center">
                        <RemoveCreditCard
                          card={creditCard as any}
                          onRemoveCard={handleDeleteCreditCard}
                        />
                      </div>
                    </div>
                  </button>
                </div>
              ))}
            </CreditCards>
          </div>
        )}
        <div className="col-xl-10">
          <button
            type="button"
            className={`btn btn-primary-outline w-100 py-3 mb-3 ${
              addNewCreditCard || creditCards.length === 0 ? 'hide' : ''
            }`}
            onClick={() => setAddNewCreditCard(true)}
          >
            <AiOutlinePlus size={24} className="mr-2" /> Add new credit card
          </button>
          <div
            className={`row items form-credit-card ${
              addNewCreditCard || creditCards.length === 0 ? 'show' : ''
            }`}
          >
            <div className="col-12 mb-5">
              {creditCards.length > 0 && (
                <>
                  <hr className="mb-4" />
                  <h2 className="mb-3">New credit card</h2>
                </>
              )}
              <CardElement
                options={{
                  hidePostalCode: true,
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-10">
          <button
            type="submit"
            className={`${
              loading && 'pe-none'
            } w-100 d-flex justify-content-center border-0 btn-black font-weight-600 text-white`}
          >
            {loading ? (
              <Loading className="spinner" />
            ) : (
              `${type === 'sale' ? 'Next' : 'Save'}`
            )}
          </button>
        </div>
      </div>
    </Container>
  );
};

export default CreditCard;
