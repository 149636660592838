import styled from 'styled-components';

export const Container = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #116bff;
  }
`;
