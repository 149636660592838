import styled from 'styled-components';

interface IAvatar {
  avatar: string;
}

export const Container = styled.div`
  h3 {
    color: #040404;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
  }

  .height {
    height: auto;
    overflow: auto;

    ::-webkit-scrollbar {
      background-color: transparent;
      width: 5px;
      height: 5px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #021027;
    }
  }

  .rotate180 {
    transform: rotate(180deg);
  }

  .bg-even {
    background: #fff;
  }

  .bg-odd {
    background: transparent;
  }

  .bd-row {
    border-radius: 20px;
    border: 1px solid #f2f2f2;
    height: 100px;
  }

  .position {
    color: #615f5f;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
    border-right: 1px solid #e3e0e0;
    line-height: 40px;
    width: auto;
  }

  .bs-avatar {
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.24);
    border-radius: 100px;
  }

  .name {
    color: #021027;

    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;
  }

  .sales span {
    color: #021027;
    font-family: 'Inter';
    font-size: 16px;

    font-weight: 300;
  }

  .blur-silver {
    border-radius: 200px;
    background: rgba(197, 197, 197, 0.5);

    filter: blur(20px);
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0px;
    top: -12px;
  }

  .blur-gold {
    border-radius: 200px;
    background: rgba(255, 213, 155, 0.4);

    filter: blur(20px);
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0px;
    top: -12px;
  }

  .blur-bronze {
    border-radius: 200px;
    background: rgba(142, 86, 25, 0.2);

    filter: blur(20px);
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0px;
    top: -12px;
  }

  .bd-silver {
    width: 75px;
    height: 75px;
    border-radius: 200px;
    border: 2px solid #c5c5c5;
    padding: 5px;
    box-shadow: 0px 0px 8.1px 0px rgba(197, 197, 197, 0.6);
  }

  .bd-gold {
    width: 75px;
    height: 75px;
    border-radius: 200px;
    border: 2px solid #e9c990;
    padding: 5px;
    box-shadow: 0px 0px 8.1px 0px rgba(210, 171, 104, 0.6);
  }

  .bd-bronze {
    width: 75px;
    height: 75px;
    border-radius: 200px;
    border: 2px solid #8e5619;
    padding: 5px;
    box-shadow: 0px 0px 8.1px 0px rgba(142, 86, 25, 0.6);
  }

  .bs-silver,
  .bs-gold,
  .bs-bronze {
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.24);
    border-radius: 50px;
    width: 62px;
    height: 62px;
  }

  .second-place-text {
    left: 6.5%;
    bottom: 10%;
  }

  .first-place-text {
    left: 37%;
    bottom: 22%;
  }

  .third-place-text {
    right: 8%;
    bottom: 3%;
  }

  .first-place-text p,
  .second-place-text p,
  .third-place-text p {
    color: #021027;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
  }

  .first-place-text img,
  .second-place-text img,
  .third-place-text img {
    width: 24px;
    height: 24px;
  }

  .avatar-second {
    left: 8%;
    top: 10%;
  }

  .avatar-first {
    left: 38%;
    top: -7%;
  }

  .avatar-third {
    right: 8%;
    top: 15%;
  }

  .avatar-first h2,
  .avatar-second h2,
  .avatar-third h2 {
    color: #021027;

    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    width: 100px;
  }
  .avatar-first p,
  .avatar-second p,
  .avatar-third p {
    color: #021027;

    text-align: center;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 300;
  }
  .loading {
    width: 62px;
    height: 62px;
    border-radius: 50px;
  }
  .loading-sales {
    height: 42px;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 0px;
  }

  .text-loading {
    height: 40px;
    width: 99px;
    border-radius: 8px;
  }
  .loading-height {
    height: 100px;
    border-radius: 20px;

    width: 100%;
  }
  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    //display: inline-block;
    box-shadow: none;

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (max-width: 575px) {
    max-width: 400px;
  }

  @media screen and (min-width: 576px) {
    .avatar-second {
      left: 10%;
      top: 15%;
    }
    .second-place-text {
      left: 9%;
      bottom: 11%;
    }
    .avatar-first {
      left: 40%;
      top: -3%;
    }
    .first-place-text {
      left: 40%;
      bottom: 27%;
    }
    .avatar-third {
      right: 10%;
      top: 21%;
    }
    .third-place-text {
      right: 10%;
      bottom: 6%;
    }
  }

  @media screen and (min-width: 768px) {
    .avatar-second {
      left: 12.5%;
      top: 23%;
    }
    .second-place-text {
      left: 11.5%;
      bottom: 14%;
    }
    .avatar-first {
      left: 43%;
      top: 5%;
    }
    .first-place-text {
      left: 42.5%;
      bottom: 30%;
    }
    .avatar-third {
      right: 12.5%;
      top: 30%;
    }
    .third-place-text {
      right: 12.5%;
      bottom: 8%;
    }
  }

  @media screen and (min-width: 992px) {
    h3 {
      font-size: 18px;
      font-weight: 500;
    }
    .zoom {
      zoom: 0.7;
    }
    .height {
      height: 500px;
      padding-right: 10px;
    }

    .name {
      font-size: 20px;
    }

    .sales span {
      font-size: 20px;
    }
    .blur-silver {
      width: 160px;
      height: 160px;

      left: -20px;
      top: -20px;
    }

    .blur-gold {
      width: 160px;
      height: 160px;

      left: -20px;
      top: -20px;
    }

    .blur-bronze {
      width: 160px;
      height: 160px;

      left: -20px;
      top: -20px;
    }

    .bd-silver {
      width: 120px;
      height: 120px;
      padding: 8px;
    }

    .bd-gold {
      width: 120px;
      height: 120px;
      padding: 8px;
    }

    .bd-bronze {
      width: 120px;
      height: 120px;
      padding: 8px;
    }
    .bs-silver,
    .bs-gold,
    .bs-bronze {
      width: 100px;
      height: 100px;
    }

    .first-place-text img,
    .second-place-text img,
    .third-place-text img {
      width: 40px;
      height: 40px;
    }
    .avatar-first h2,
    .avatar-second h2,
    .avatar-third h2 {
      font-size: 20px;
      width: 120px;
    }
    .avatar-first p,
    .avatar-second p,
    .avatar-third p {
      font-size: 16px;
    }

    .avatar-second {
      left: 23.5%;
      top: 13%;
    }

    .avatar-first {
      left: 45%;
      top: 0%;
    }

    .avatar-third {
      right: 23.5%;
      top: 18%;
    }
    .first-place-text {
      left: 42%;
      bottom: 22%;
    }

    .third-place-text {
      right: 21.5%;
      bottom: 7%;
    }
    .second-place-text {
      left: 20.5%;
      bottom: 11%;
    }
    .position {
      font-size: 20px;
      width: 60px;
    }
    .loading {
      width: 100px;
      height: 100px;
    }

    .loading-sales {
      height: 21px;
    }
    .text-loading {
      width: 124px;
      height: 50px;
    }
  }
  @media screen and (min-width: 1200px) {
    .zoom {
      zoom: 0.9;
    }
    .bd-silver,
    .bd-gold,
    .bd-bronze {
      padding: 9px;
    }
  }
  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 1;
    }
    .bd-silver,
    .bd-gold,
    .bd-bronze {
      padding: 8px;
    }
  }
`;

export const AvatarFirst = styled.div<IAvatar>`
  background-image: url(${(props) => props.avatar});
  background-repeat: no-repeat;
  background-size: cover;
  width: 62px;
  height: 62px;
  border-radius: 50px;
  -webkit-box-shadow: inset 0px 0px 0px 4px rgba(233, 201, 144, 0.5);
  -moz-box-shadow: inset 0px 0px 0px 4px rgba(233, 201, 144, 0.5);
  box-shadow: inset 0px 0px 0px 4px rgba(233, 201, 144, 0.5);
  @media screen and (min-width: 992px) {
    width: 100px;
    height: 100px;
  }
`;

export const AvatarSecond = styled.div<IAvatar>`
  background-image: url(${(props) => props.avatar});
  background-repeat: no-repeat;
  background-size: cover;
  width: 62px;
  height: 62px;
  border-radius: 50px;
  -webkit-box-shadow: inset 0px 0px 0px 4px rgba(197, 197, 197, 0.5);
  -moz-box-shadow: inset 0px 0px 0px 4px rgba(197, 197, 197, 0.5);
  box-shadow: inset 0px 0px 0px 4px rgba(197, 197, 197, 0.5);
  @media screen and (min-width: 992px) {
    width: 100px;
    height: 100px;
  }
`;

export const AvatarThird = styled.div<IAvatar>`
  background-image: url(${(props) => props.avatar});
  background-repeat: no-repeat;
  background-size: cover;
  width: 62px;
  height: 62px;
  border-radius: 50px;
  -webkit-box-shadow: inset 0px 0px 0px 4px rgba(142, 86, 25, 0.5);
  -moz-box-shadow: inset 0px 0px 0px 4px rgba(142, 86, 25, 0.5);
  box-shadow: inset 0px 0px 0px 4px rgba(142, 86, 25, 0.5);
  @media screen and (min-width: 992px) {
    width: 100px;
    height: 100px;
  }
`;

export const AvatarOthers = styled.div<IAvatar>`
  background-image: url(${(props) => props.avatar});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 48px;
  height: 48px;
  border-radius: 50px;

  box-shadow: inset 0px 0px 0px 2.5px rgba(228, 228, 228, 0.5);

  @media screen and (min-width: 576px) {
    width: 46px;
    height: 55px;
  }
`;
