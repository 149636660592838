import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Button, Modal } from './styles';
import CreditCard from '../CreditCard';

import addCard from '~/assets/icons/add-card-icon.svg';
import closeIcon from '~/assets/icons/close.svg';

interface ICreditCard {
  id?: string;
  name: string;
  number: string;
  expiration: string;
  cvc: string;
  flag: string;
  primary_card?: boolean;
}

interface IReponseCreditCard {
  id: string;
  name: string;
  number: string;
  expiration: string;
  flag: string;
  primary_card: boolean;
}

interface IAddCreditCardProps {
  className?: string;
  onCreatedCard?(data: IReponseCreditCard): void;
  active?: boolean;
  onClose?(active: boolean): void;
}

const AddCreditCard: React.FC<IAddCreditCardProps> = ({
  className,
  onCreatedCard,
  active,
  onClose,
}) => {
  const { language } = useLanguage();
  const formRef = useRef<FormHandles>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(active as boolean);
  }, [active]);

  const handleShow = useCallback(() => setShow(true), []);
  const handleClose = useCallback(() => {
    setShow(false);
    if (onClose) {
      onClose(active as boolean);
    }
  }, [active, onClose]);

  const handleSubmit = useCallback(
    async (data: ICreditCard) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required(language.buy_component.erro_1),
          number: Yup.string().required(language.buy_component.erro_2),
          expiration: Yup.string().required(language.buy_component.erro_3),
          cvc: Yup.string().required(language.buy_component.erro_4),
          flag: Yup.string().required(language.buy_component.erro_5),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, number, expiration, cvc, flag } = data;
        const [exp_month, exp_year] = expiration.split('/');

        const formData = {
          name,
          number: number.replace(/ /g, ''),
          exp_month,
          exp_year,
          cvc,
          primary_card: false,
        };

        const response = await api.post(
          'payments/customers/credit-cards',
          formData
        );

        if (response.data) {
          const numberMask = `****-${number.substr(-4)}`;
          const creditCardForm: IReponseCreditCard = {
            id: response.data.card.id,
            name,
            number: numberMask,
            expiration,
            flag,
            primary_card: false,
          };
          if (onCreatedCard) {
            onCreatedCard(creditCardForm);
          }
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', language.error_component.error_message, 'error');
        }
      }
    },
    [language, onCreatedCard]
  );

  return (
    <>
      <Button
        type="button"
        className={`${className} colorPayOptions payment-button px-xl-4 py-2 py-md-3 py-xl-2 w-100 w-xl-auto ml-2 ml-xl-0`}
        onClick={handleShow}
      >
        <img src={addCard} alt="Add Card" className="mr-3 width-add-card" />+{' '}
        {language.subscriptions_cards.button_1}
      </Button>
      <Modal
        className="modal-credit-card px-0 border-credit-card"
        centered
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="border-0">
          <Modal.Title className="px-2">
            {language.add_credit_card_component.title}
          </Modal.Title>
          <button type="button" className="close-button" onClick={handleClose}>
            <img src={closeIcon} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className="mx-auto w-100">
          <Form ref={formRef} onSubmit={handleSubmit}>
            <CreditCard
              type="new"
              onChangeCreditCardSelected={(creditCard) =>
                console.log(creditCard)
              }
            />
            <div className="row mt-2 justify-content-end">
              <div className="col-lg-6">
                <button
                  type="submit"
                  className="mt-5 payment-button py-2 w-100"
                >
                  <span>{language.add_credit_card_component.button}</span>
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0" />
      </Modal>
    </>
  );
};

export default AddCreditCard;
