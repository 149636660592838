import React from 'react';

import { Container } from './styles';

import twitter from '~/assets/icons/twitter-icon-blue.svg';
import facebook from '~/assets/icons/facebook-icon-blue.svg';
import telegram from '~/assets/icons/telegram-icon-blue.svg';
import instagram from '~/assets/icons/instagram-icon-blue.svg';
import { useLanguage } from '~/hooks/Language';

const SocialBlueMobile: React.FC = () => {
  const { language } = useLanguage();
  return (
    <Container className="d-flex justify-content-center">
      <div className="text-center">
        <div className="d-flex justify-content-center">
          <a
            href="https://www.twitter.com/AutoAffiliateHQ"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={twitter} alt={language.sign_in.img_1} className="mx-2" />
          </a>
          <a
            href="https://www.facebook.com/AutoAffiliatePage"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={facebook} alt={language.sign_in.img_2} className="mx-2" />
          </a>
          <a
            href="https://t.me/AutoAffiliateHQ"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={telegram} alt={language.sign_in.img_3} className="mx-2" />
          </a>
          <a
            href="https://www.instagram.com/AutoAffiliateHQ"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={instagram}
              alt={language.sign_in.img_4}
              className="mx-2"
            />
          </a>
        </div>
        <p className="d-block my-2">@autoaffiliateHQ</p>
      </div>
    </Container>
  );
};

export default SocialBlueMobile;
