import OT from '@opentok/client';
import socket from '~/services/socket';

function handleError(error: unknown) {
  if (error) {
    // console.error(error);
  }
}

interface IUserData {
  userID: string;
  user_id: string;
  name: string;
  avatar?: string;
  host: boolean;
  screen?: boolean;
}

interface IMessage {
  id: string;
  content: string;
  created_at: string;
  user: {
    id: string;
    name: string;
    avatar: string;
  };
}

let session: OT.Session | undefined;
let publisher: OT.Publisher | undefined;
let publisherScreen: OT.Publisher | undefined;
let dataUser = {} as IUserData;

export const initializeSocketEvents = (
  setSocketConnected: React.Dispatch<React.SetStateAction<boolean>>,
  setUsers: React.Dispatch<React.SetStateAction<IUserData[]>>,
  setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>,
  setUserTyping: React.Dispatch<React.SetStateAction<IUserData>>
): void => {
  socket.connect();

  socket.on('connect', () => {
    setSocketConnected(true);
  });
  socket.on('disconnect', () => {
    // console.log('socket disconnected --');
  });
  socket.on('error', () => {
    // console.log('socket error --', err);
  });
  socket.on('new-user-connect', (data: IUserData) => {
    socket.emit('send-me', dataUser);

    setUsers((state) => {
      const checkUser = state.find((user) => user.user_id === data.user_id);
      if (!checkUser) {
        if (data.host) {
          return [data, ...state];
        }
        return [...state, data];
      }

      return state;
    });
  });
  socket.on('new-message', (data: IMessage) => {
    setMessages((state) => [data, ...state]);
  });
  socket.on('user-start-typing', (data: IUserData) => {
    setUserTyping(data);
  });
  socket.on('user-stop-typing', () => {
    setUserTyping({} as IUserData);
  });
  socket.on('user-disconnected', (data: IUserData) => {
    setUsers((state) => {
      const newUsers = state.filter((user) => user.user_id !== data.user_id);
      return newUsers;
    });
  });
  socket.on('receive-you', (data: IUserData) => {
    setUsers((state) => {
      const checkUser = state.find((user) => user.user_id === data.user_id);
      if (!checkUser) {
        if (data.host) {
          return [data, ...state];
        }
        return [...state, data];
      }
      return state;
    });
  });
};

export const joinRoom = (roomID: string, userData: IUserData): void => {
  socket.emit('join-room', { roomID, userData });
};

const createVideo = (userData: IUserData, element: HTMLElement) => {
  const videoContainerCheck = document.querySelector(
    `div[hash="video-${userData.user_id}"]`
  );

  if (!videoContainerCheck) {
    const roomContainer = document.getElementById(
      'room-container'
    ) as HTMLElement;
    const videoContainerElement = document.createElement('div');
    videoContainerElement.setAttribute('hash', `video-${userData.user_id}`);
    videoContainerElement.id = `video-${userData.userID}`;
    if (userData.host) {
      videoContainerElement.className = 'col-lg-12 order-0 mb-2 host';
    } else {
      videoContainerElement.className = 'audience';
    }

    const videoContentElement = document.createElement('button');
    videoContentElement.className =
      'w-100 video-content border-0 d-flex justify-content-center align-items-center position-relative';

    const avatarContainer = document.createElement('div');
    avatarContainer.className = 'avatar';

    if (userData.avatar) {
      avatarContainer.style.backgroundImage = `url('${userData.avatar}')`;
    } else {
      const firstLetter = userData.name.slice(0, 1).toUpperCase();
      const pElement = document.createElement('p');
      pElement.innerText = firstLetter;
      avatarContainer.appendChild(pElement);
    }

    const nameContainer = document.createElement('div');
    nameContainer.className = 'username';
    const pElement = document.createElement('p');
    pElement.innerText = userData.name;
    nameContainer.appendChild(pElement);

    const video = document.createElement('div');
    video.className = 'video w-100 h-100 hide';
    video.appendChild(element);
    videoContentElement.appendChild(avatarContainer);
    videoContentElement.appendChild(nameContainer);
    videoContentElement.appendChild(video);
    videoContainerElement.appendChild(videoContentElement);
    if (userData.host) {
      roomContainer.insertBefore(
        videoContainerElement,
        roomContainer.firstChild
      );
    } else {
      roomContainer.append(videoContainerElement);
    }
  }
};

const createScreenVideo = (userData: IUserData, element: HTMLElement) => {
  const videoContainerCheck = document.querySelector(
    `div[hash="screen-${userData.user_id}"]`
  );

  if (!videoContainerCheck) {
    const roomContainer = document.getElementById(
      'room-container'
    ) as HTMLElement;
    const videoHost = document.querySelector('.host') as HTMLElement;
    if (videoHost) {
      videoHost.className = 'col-lg-3 order-1 mt-2 old-host';
    }
    const videoContainerElement = document.createElement('div');
    videoContainerElement.setAttribute('hash', `screen-${userData.user_id}`);
    videoContainerElement.id = `video-${userData.userID}`;
    videoContainerElement.className = 'col-lg-12 order-0 mb-2 screen';

    const videoContentElement = document.createElement('button');
    videoContentElement.className =
      'w-100 video-content border-0 d-flex justify-content-center align-items-center position-relative';

    const avatarContainer = document.createElement('div');
    avatarContainer.className = 'avatar';

    if (userData.avatar) {
      avatarContainer.style.backgroundImage = `url('${userData.avatar}')`;
    } else {
      const firstLetter = userData.name.slice(0, 1).toUpperCase();
      const pElement = document.createElement('p');
      pElement.innerText = firstLetter;
      avatarContainer.appendChild(pElement);
    }

    const nameContainer = document.createElement('div');
    nameContainer.className = 'username';
    const pElement = document.createElement('p');
    pElement.innerText = userData.name;
    nameContainer.appendChild(pElement);

    const video = document.createElement('div');
    video.className = 'video w-100 h-100';
    video.appendChild(element);
    videoContentElement.appendChild(avatarContainer);
    videoContentElement.appendChild(nameContainer);
    videoContentElement.appendChild(video);
    videoContainerElement.appendChild(videoContentElement);
    roomContainer.append(videoContainerElement);
  }
};

const removeVideo = (id: string) => {
  const videoContainer = document.getElementById(`video-${id}`);
  if (videoContainer) {
    videoContainer.remove();
  }
};

const removeScreenVideo = (id: string) => {
  const videoHost = document.querySelector('.old-host') as HTMLElement;
  if (videoHost) {
    videoHost.className = 'col-12 order-0 mb-2 host';
  }

  const videoContainer = document.getElementById(`video-${id}`);
  if (videoContainer) {
    videoContainer.remove();
  }
};

const toggleCam = (id: string) => {
  const element = document.getElementById(`video-${id}`);
  if (element) {
    const video = element.querySelector('.video') as HTMLDivElement;
    if (video) {
      if (video.classList.contains('hide')) {
        video.style.transitionDuration = '0.3s';
        setTimeout(() => {
          video.classList.remove('hide');
        }, 750);
      } else {
        video.style.transitionDuration = '0s';
        video.classList.add('hide');
      }
    }
  }
};

export function initializeSession(apiKey: string, sessionId: string): boolean {
  session = OT.initSession(apiKey, sessionId);
  session.on('streamCreated', function streamCreated(event) {
    const subscriberOptions: OT.SubscriberProperties = {
      insertMode: 'append',
      width: '100%',
      height: '100%',
    };

    const userDataEvent = JSON.parse(event.stream.name) as IUserData;
    if (session) {
      const subscribe = session.subscribe(
        event.stream,
        'loadVideo',
        subscriberOptions,
        handleError
      );

      if (subscribe.element && subscribe.id) {
        userDataEvent.userID = subscribe.id;
        if (subscribe.stream) {
          subscribe.stream.name = JSON.stringify(userDataEvent);
        }
        if (userDataEvent.screen) {
          createScreenVideo(userDataEvent, subscribe.element);
        } else {
          createVideo(userDataEvent, subscribe.element);
        }
      }
    }
  });

  session.on('streamPropertyChanged', (event) => {
    if (event.changedProperty === 'hasVideo') {
      const userDataEvent = JSON.parse(event.stream.name) as IUserData;
      toggleCam(userDataEvent.userID);
    }
  });

  session.on('streamDestroyed', (event) => {
    const userDataEvent = JSON.parse(event.stream.name) as IUserData;
    if (userDataEvent.screen) {
      removeScreenVideo(userDataEvent.userID);
    } else {
      removeVideo(userDataEvent.userID);
    }
  });

  session.on('sessionDisconnected', function sessionDisconnected() {
    // console.log('You were disconnected from the session.', event.reason);
  });

  return !!session;
}

export function initializePublisher(token: string, userData: IUserData): void {
  const publisherOptions: OT.PublisherProperties = {
    insertMode: 'append',
    name: JSON.stringify(userData),
    publishVideo: false,
    publishAudio: true,
    width: '100%',
    height: '100%',
  };

  publisher = OT.initPublisher(undefined, publisherOptions, handleError);

  publisher.on('mediaStopped', () => {
    // console.log(event);
  });

  publisher.on('streamDestroyed', () => {
    if (publisher && publisher.id) {
      removeVideo(publisher.id);
    }
  });

  session?.connect(token, function callback(error) {
    if (error) {
      handleError(error);
    } else if (publisher) {
      session?.publish(publisher, handleError);
      if (publisher.element && publisher.id) {
        // eslint-disable-next-line no-param-reassign
        userData.userID = publisher.id;
        createVideo(userData, publisher.element);
      }
    }
  });
}

export function initializePublisherScreen(
  token: string,
  userData: IUserData,
  setSharedScreen: React.Dispatch<React.SetStateAction<boolean>>
): void {
  // eslint-disable-next-line no-param-reassign
  userData.screen = true;

  const publisherOptions: OT.PublisherProperties = {
    insertMode: 'append',
    name: JSON.stringify(userData),
    publishVideo: true,
    publishAudio: false,
    width: '100%',
    height: '100%',
    videoSource: 'screen',
  };

  publisherScreen = OT.initPublisher(undefined, publisherOptions, handleError);

  publisherScreen.on('mediaStopped', () => {
    // console.log(event);
  });

  publisherScreen.on('accessDenied', () => {
    if (publisherScreen && publisherScreen.id) {
      removeScreenVideo(publisherScreen.id);
    }
    setSharedScreen(false);
  });

  publisherScreen.on('streamDestroyed', () => {
    if (publisherScreen && publisherScreen.id) {
      removeScreenVideo(publisherScreen.id);
    }
    setSharedScreen(false);
  });

  if (session && publisherScreen) {
    session?.publish(publisherScreen, handleError);
    if (publisherScreen.element && publisherScreen.id) {
      // eslint-disable-next-line no-param-reassign
      userData.userID = publisherScreen.id;
      createScreenVideo(userData, publisherScreen.element);
      setSharedScreen(true);
    }
  }
}

export async function getCameras(): Promise<OT.Device[]> {
  let videoInputs: OT.Device[] = [];
  const videoInputsPromise = new Promise<void>((resolve) => {
    OT.getDevices((err, devices) => {
      if (!err && devices) {
        videoInputs = devices.filter((device) => device.kind === 'videoInput');
        resolve();
      } else {
        resolve();
      }
    });
  });

  await videoInputsPromise;

  return videoInputs;
}

export async function getCurrentCamera(): Promise<OT.Device | undefined> {
  let currentCamera: OT.Device | undefined;
  const currentCameraPromise = new Promise<void>((resolve) => {
    const getCameraLoop = async (attemp: number) => {
      if (attemp < 20) {
        if (publisher) {
          const cameras = await getCameras();
          const videoSource = publisher.getVideoSource();
          const camera = cameras.find(
            (cameraData) => cameraData.deviceId === videoSource.deviceId
          );
          if (camera) {
            currentCamera = camera;
            resolve();
          } else {
            getCameraLoop(attemp + 1);
          }
        } else {
          getCameraLoop(attemp + 1);
        }
      } else {
        resolve();
      }
    };

    getCameraLoop(1);
  });

  await currentCameraPromise;

  return currentCamera;
}

export function setCamera(id: string): void {
  if (publisher) {
    publisher.setVideoSource(id);
  }
}

export async function getMicrophones(): Promise<OT.Device[]> {
  let audioInputs: OT.Device[] = [];
  const audioInputsPromise = new Promise<void>((resolve) => {
    OT.getDevices((err, devices) => {
      if (!err && devices) {
        audioInputs = devices.filter((device) => device.kind === 'audioInput');
        resolve();
      } else {
        resolve();
      }
    });
  });

  await audioInputsPromise;

  return audioInputs;
}

export async function getCurrentMicrophone(): Promise<OT.Device | undefined> {
  let currentMicrophone: OT.Device | undefined;
  const currentMicrophonePromise = new Promise<void>((resolve) => {
    const getMicrophoneLoop = async (attemp: number) => {
      if (attemp < 20) {
        if (publisher) {
          const microphones = await getMicrophones();
          const microphoneSource = publisher.getAudioSource();
          if (microphoneSource) {
            const microphone = microphones.find(
              (microphoneData) =>
                microphoneData.label === microphoneSource.label
            );
            if (microphone) {
              currentMicrophone = microphone;
              resolve();
            } else {
              getMicrophoneLoop(attemp + 1);
            }
          } else {
            getMicrophoneLoop(attemp + 1);
          }
        } else {
          getMicrophoneLoop(attemp + 1);
        }
      } else {
        resolve();
      }
    };

    getMicrophoneLoop(1);
  });

  await currentMicrophonePromise;

  return currentMicrophone;
}

export function setMicrophone(id: string): void {
  if (publisher) {
    publisher.setAudioSource(id);
  }
}

export async function getHeadsets(): Promise<OT.AudioOutputDevice[]> {
  return OT.getAudioOutputDevices();
}

export async function getCurrentHeadset(): Promise<OT.AudioOutputDevice> {
  return OT.getActiveAudioOutputDevice();
}

export function setHeadset(id: string): void {
  OT.setAudioOutputDevice(id);
}

export function showCam(): void {
  if (publisher) {
    publisher.publishVideo(true);
    if (publisher.id) {
      toggleCam(publisher.id);
    }
  }
}

export function unShowCam(): void {
  if (publisher) {
    publisher.publishVideo(false);
    if (publisher.id) {
      toggleCam(publisher.id);
    }
  }
}

export function audioEnable(): void {
  if (publisher) {
    publisher.publishAudio(true);
  }
}

export function audioDisabled(): void {
  if (publisher) {
    publisher.publishAudio(false);
  }
}

export function stopSharedScreen(): void {
  if (publisherScreen) {
    publisherScreen.destroy();
  }
}

export function disconnect(): void {
  if (publisher) {
    publisher.destroy();
  }

  if (publisherScreen) {
    publisherScreen.destroy();
  }

  if (session) {
    session.disconnect();
  }

  socket.disconnect();
}

export function startTyping(userData: IUserData): void {
  socket.emit('user-start-typing-back', userData);
}

export function stopTyping(): void {
  socket.emit('user-stop-typing-back');
}

export function saveUser(userData: IUserData): void {
  dataUser = userData;
}
