import styled, { keyframes } from 'styled-components';
import { shade, lighten } from 'polished';

export const Container = styled.div`
  background-color: #fff;
  min-height: 100vh;

  .position-signIn {
    right: 0;
    top: 0;
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  animation: ${appearFromLeft} 1s;

  .btn-idiom {
    padding: 8px 10px;
    border: 1px solid #eeeeee;
    border-radius: 200px;
    height: max-content;

    img {
      border-radius: 50%;
    }
  }

  h1 {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 45px;
    color: #151517;
  }

  h2 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    color: #151517;
  }

  > div {
    border: none;
    padding: 1px;
    transition-duration: 0.3s;

    @media screen and (min-width: 992px) {
      width: 85%;
      margin: 0 auto;
    }

    @media screen and (min-width: 1700px) {
      width: 60%;
    }
  }

  .normal-sm a {
    color: #303030;
  }

  .font-weigth-500 {
    font-weight: 500 !important;
  }

  .button-login {
    background-color: var(--technologicalGray);
    color: #f5f5f5;
    text-decoration: none;
    border: none;
    border-radius: 21px;
    font-size: 22px;
    letter-spacing: -0.04em;
    font-weight: 500;

    :hover {
      background-color: ${lighten(0.1, '#606060')};
      transition: 0.5s;
    }
  }

  div .button-show {
    top: 1px;
    border-left: none;
  }

  label {
    font-family: 'Archivo';
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;

    color: #5f5f61;
  }

  form {
    > div {
      padding: 11.5px 20px;
      border-radius: 21px;
      border: 1px solid #939393;
    }

    input {
      width: 100%;
      height: 40px;
      color: #151517;
      font-size: 18px;
    }

    input[id='password'] {
      width: 87%;
    }

    > div:focus-within {
      border: 1px solid #116bff !important;
    }

    .button-show {
      position: absolute;
      top: -8px;
      right: 0px;
      width: 70px;
      text-align: center;
      background: none;
      border-width: 0px 0px 0px 1px;
      border-top-style: initial;
      border-right-style: initial;
      border-bottom-style: initial;
      border-top-color: initial;
      border-right-color: initial;
      border-bottom-color: initial;
      border-image: initial;
      font-weight: bold;
      padding: 6px;
      color: #151517;
      height: 56px !important;
      border-left: none;
    }

    @media screen and (max-width: 1199px) {
    }

    @media screen and (max-height: 740px) {
      margin: 20px 0;
    }

    @media screen and (max-width: 450px) {
      input[id='password'] {
        width: 82%;
      }
    }

    a {
      color: #565656;
      display: block;
      text-decoration: none;
      transition-duration: 0.2s;

      :hover {
        color: ${shade(0.2, '#565656')};
      }
    }
  }

  .separator {
    position: relative;

    hr {
      width: 100%;
      height: 0.5px;
      opacity: 1;
      background-color: #ababab;
      position: absolute;
    }

    span {
      position: relative;
      z-index: 1;
      background-color: #fff;
      padding: 10px;
    }
  }

  @media screen and (max-width: 1400px) {
    form {
      margin: 40px 0px 0px 0px;
      input {
      }
    }
  }

  @media screen and (max-width: 1199px) {
    form {
      width: 100%;
      margin: 25px 0px 0px 0px;
    }

    .content {
      position: relative;
      z-index: 1;
      opacity: 0.8;

      a {
        color: #cbceceff;
      }
    }
  }

  @media screen and (max-width: 991px) {
    form {
      margin: 40px 0px 0px 0px;
    }

    .size-mobile {
      width: 55px;
      height: 50px;
    }
  }
`;
