import React, { useCallback, useState } from 'react';
import { io } from 'socket.io-client';
import Swal from 'sweetalert2';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container, Modal } from './styles';
import binanceLogo from '~/assets/logotipos/binance-logo.png';
import binanceSvg from '~/assets/logotipos/binance-logo.svg';
import closeIcon from '~/assets/icons/close.svg';

interface IBinancePayProps {
  order_id?: string;
  product_id: string;
  title: string;
  price: string;
  totalPrice?: string;
  installments?: number;
  anticipated?: boolean;
  onSucceededPaid?(data?: { id: string }): void;
}

const socket = io(process.env.REACT_APP_API_URL as string, {
  reconnection: true,
});

const BinancePay: React.FC<IBinancePayProps> = ({
  order_id,
  product_id,
  title,
  price,
  totalPrice,
  installments,
  anticipated,
  onSucceededPaid,
}) => {
  const { language } = useLanguage();
  const [showBinance, setShowBinance] = useState(false);
  const [qrCodeLink, setQrCodeLink] = useState('');

  const handleBinanceClose = useCallback(() => setShowBinance(false), []);
  const handleBinanceShow = useCallback(async () => {
    try {
      const data = {
        order_id,
        product_id,
        amount: parseFloat(price),
        installments,
        anticipated,
      };
      const response = await api.post('payments/binance-pay', data);
      if (response.data) {
        let orderId = response.data.id;
        if (!anticipated) {
          orderId = order_id;
        }
        socket.emit('connectRoom', 'payment');
        socket.on(`order-${orderId}`, (status: string) => {
          if (status === 'succeeded' && onSucceededPaid) {
            onSucceededPaid(response.data);
            handleBinanceClose();
          }
        });
        setQrCodeLink(response.data.qrcodeLink);
        setShowBinance(true);
      } else {
        Swal.fire('Opss...', language.error_component.error_message, 'error');
      }
    } catch (error) {
      Swal.fire('Opss...', language.error_component.error_message, 'error');
    }
  }, [
    anticipated,
    handleBinanceClose,
    installments,
    language.error_component.error_message,
    onSucceededPaid,
    order_id,
    price,
    product_id,
  ]);

  return (
    <Container>
      <button
        type="button"
        className="h4 h-100 w-100 payment-button"
        onClick={handleBinanceShow}
      >
        <img src={binanceLogo} alt="Binace" className="mb-5 mb-xl-4" />
        Bincance Pay
      </button>

      <Modal
        className="modal-binance-pay px-0"
        centered
        size="xl"
        show={showBinance}
      >
        <Modal.Header className="border-0 p-3">
          <Modal.Title className="text-center w-100" />
          <button
            type="button"
            className="close-button"
            onClick={handleBinanceClose}
          >
            <img src={closeIcon} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className="px-sm-5">
          <div className="col-12">
            <h3 className="h5 h3-sm payment-color mb-4">
              Binance Pay
              <img src={binanceSvg} alt="Binance" className="ml-4 img-icon" />
            </h3>
          </div>
          <div className="row">
            <div className="col-lg-6 px-xl-0">
              <iframe
                width="100%"
                height="287"
                src="https://www.youtube.com/embed/lMDLU8xeTmk"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="b-radius"
              />
              <h4 className="my-4">{language.buy_product_button_binance.h4}</h4>
              <p>
                <span> {language.buy_product_button_binance.span_1}</span>{' '}
                {language.buy_product_button_binance.p_1}
              </p>
              <p>
                <span> {language.buy_product_button_binance.span_2}</span>{' '}
                {language.buy_product_button_binance.p_2}
              </p>
              <div className="text-center w-100 mt-5">
                <a
                  href="https://bit.ly/binance-pay-account"
                  target="blank"
                  className="w-100 btn modal-binance-btn font-weight-bold"
                >
                  {language.buy_product_button_binance.a}
                </a>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <h4 className="text-center">{title}</h4>
              <div className="d-flex justify-content-center my-5">
                <img
                  src={qrCodeLink}
                  alt="QR CODE"
                  className="qr-code-binance"
                />
              </div>
              <div className="d-flex flex-column aling-items-center text-center">
                <p className="h3 font-weight-bold mb-1 text-white">${price}</p>
                {totalPrice && <s className="old-price small">${totalPrice}</s>}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0" />
      </Modal>
    </Container>
  );
};

export default BinancePay;
