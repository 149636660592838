import styled from 'styled-components';

export const Container = styled.div`
  .video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
  }
`;

export const VideoPlayer = styled.video`
  width: 100%;
  height: 42vh;
`;
