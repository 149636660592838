import React, { useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import Lottie from 'react-lottie';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import { apiRequest } from '~/services/aiConnectorApi';
import bubble from '~/assets/animations/orb.json';
import Select, { IOption } from '~/components/Select';
import Table from '~/components/Table';
import sphereLg from '~/assets/defaults/sphere-large.png';
import sphereLgMob from '~/assets/defaults/sphere-large-mob.png';
import sphereSm from '~/assets/defaults/sphere-small.png';
import Copy from '~/components/Copy';
import api from '~/services/api';

interface IOptionPrompt {
  prompt: never | string;
  category: never | string;
  target: never | string;
}

const PromptEase: React.FC = () => {
  const history = useHistory();
  const [filterData, setFilterData] = useState<{
    categories?: [];
    targets?: [];
  }>();
  const [category, setCategory] = useState('');
  const [target, setTarget] = useState('');
  const [selectedTargets, setSelectedTargets] = useState<{ data?: [] }>();
  const [selectedCategory] = useState<{ data?: [] }>();
  const [showResult, setShowResult] = useState(false);
  const [categoryData, setCategoryData] = useState<IOption[]>([]);
  const [targetData, setTargetData] = useState<IOption[]>([]);
  const [initialRun, setInitial] = useState(true);
  const [tableData, setTableData] = useState<IOptionPrompt[]>([]);
  const [tmpTableData, tmpSetTableData] = useState<IOptionPrompt[]>([]);
  const [searchResult, setSearchResult] = useState<{ data?: [] }>();
  const [showPage, setShowPage] = useState(false);

  useEffect(() => {
    if (initialRun === true) {
      apiRequest('/filter_data', 'GET', {})
        .then((response) => {
          setFilterData(response);
        })
        .catch(() => {
          // console.error('API call error:', error);
        });
      setInitial(false);
    }
  }, [initialRun]);

  const clearData = () => {
    setShowResult(false);
    setCategory('Select Category');
    setTarget('Select Target');
    categoryLists();
    targetLists();
  };

  useEffect(() => {
    api
      .get(`affiliates-products/products/${process.env.REACT_APP_PROMPT_EASE}`)
      .then(() => {
        setShowPage(true);
      })
      .catch(() => {
        history.push(`${process.env.PUBLIC_URL}/profile/my-purchases`);
      });
  }, [history]);

  useEffect(() => {
    const tabelDataArray = [] as IOptionPrompt[];
    if (searchResult) {
      searchResult?.data?.forEach((item) =>
        tabelDataArray.push({
          prompt: item[0],
          category: item[1],
          target: item[2],
        })
      );
      setTableData(tabelDataArray);
      tmpSetTableData(tabelDataArray);
      setShowResult(true);
    }
  }, [searchResult]);

  const categoryLists = useCallback(() => {
    const categoriesArray = [];
    categoriesArray.push({ id: '', value: 'Select Category', selected: false });

    filterData?.categories?.forEach((item) =>
      categoriesArray.push({ id: item, value: item, selected: false })
    );
    setCategoryData(categoriesArray);
  }, [filterData]);

  const targetLists = useCallback(() => {
    const targetsArray = [];
    targetsArray.push({ id: '', value: 'Select Target', selected: true });
    filterData?.targets?.forEach((item) =>
      targetsArray.push({ id: item, value: item, selected: false })
    );
    setTargetData(targetsArray);
  }, [filterData]);

  const categoryChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (Object.values(e)[0] !== '') {
      setCategory(Object.values(e)[0]);
      setTarget('Select Target');
      apiRequest(`/targets?category=${Object.values(e)[0]}`, 'GET', {})
        .then((response) => {
          setSelectedTargets(response);
        })
        .catch(() => {
          // console.error('API call error:', error);
        });
    }
  };

  const targetChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (Object.values(e)[0] !== '') {
      setTarget(Object.values(e)[0]);
    }
  };

  const changeCategoryByTarget = useCallback(() => {
    const categoryArray = [];
    categoryArray.push({ id: '', value: 'Select Category', selected: false });
    selectedCategory?.data?.forEach((item) =>
      categoryArray.push({ id: item, value: item, selected: false })
    );
    setCategoryData(categoryArray);
  }, [selectedCategory]);

  const changeTargetByCategory = useCallback(() => {
    const targetsArray = [];
    targetsArray.push({ id: '', value: 'Select Target', selected: false });
    selectedTargets?.data?.forEach((item) =>
      targetsArray.push({ id: item, value: item, selected: false })
    );
    setTargetData(targetsArray);
  }, [selectedTargets]);

  useEffect(() => {
    categoryLists();
  }, [categoryLists, filterData]);

  useEffect(() => {
    targetLists();
  }, [filterData, targetLists]);

  useEffect(() => {
    changeCategoryByTarget();
  }, [changeCategoryByTarget, selectedCategory]);

  useEffect(() => {
    changeTargetByCategory();
  }, [changeTargetByCategory, selectedTargets]);

  const searchData = () => {
    if (
      (category === '' && target === '') ||
      (category === 'Select Category' && target === 'Select Target')
    ) {
      Swal.fire(
        'Opss...',
        'Please select category or target to filter data',
        'error'
      );
    } else {
      apiRequest(`/filter?target=${target}&category=${category}`, 'GET', {})
        .then((response) => {
          setSearchResult(response);
        })
        .catch(() => {
          // console.error('API call error:', error);
        });
    }
  };

  const column = [
    {
      name: 'Prompt',
      selector: 'prompt',
      sortable: true,
      cell: (row: IOptionPrompt) => {
        return (
          <Copy
            content={row.prompt}
            className="copy text-left"
            title="Click to copy"
          />
        );
      },
    },
    {
      name: 'Category',
      selector: 'category',
      sortable: true,
    },
    {
      name: 'Target',
      selector: 'target',
      sortable: true,
    },
  ];
  const handleSearch = useCallback(
    (value) => {
      if (value) {
        const filteredData = tableData.filter(
          (item) =>
            item?.prompt?.toLowerCase().includes(value?.toLowerCase()) ||
            item?.category?.toLowerCase().includes(value?.toLowerCase()) ||
            item?.target?.toLowerCase().includes(value?.toLowerCase())
        );
        setTableData(filteredData);
      } else {
        setTableData(tmpTableData);
      }
    },
    [tableData, tmpTableData]
  );

  const handleChangeQuantity = useCallback(() => {
    // console.log(qtd);
  }, []);

  const handleSubmit = useCallback(() => {
    // console.log(qtd);
  }, []);

  return (
    <Container>
      {showPage && (
        <>
          <img
            src={sphereLg}
            alt="Sphere"
            className="d-none d-lg-block sphere-lg zoom"
          />
          <img src={sphereLgMob} alt="Sphere" className="d-lg-none sphere-lg" />
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-12 mt-3 mt-lg-5 pe-none">
                <div className="d-flex align-items-center lottie">
                  <Lottie
                    options={{
                      animationData: bubble,
                      autoplay: true,
                      loop: true,

                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    width={70}
                    height={70}
                  />
                  <h1 className="mb-0 ml-sm-2">PromptEASE</h1>
                </div>
              </div>
              <div className="col-12">
                <Form onSubmit={handleSubmit}>
                  <div className="row justify-content-center">
                    <div className="col-lg-10 mt-4 mt-sm-5">
                      <div className="bd-bottom d-lg-flex justify-content-between pb-4">
                        <h2 className="bd-bottom-title pb-4 pb-lg-0 mb-3 mb-lg-2">
                          What prompt are you looking for?
                        </h2>
                        <div className="d-flex justify-content-center justify-content-lg-start mb-4 mb-lg-0">
                          <button
                            type="button"
                            onClick={clearData}
                            className="btn-clear mx-2 mx-lg-3"
                          >
                            Clear Search
                          </button>
                          <button
                            type="button"
                            onClick={searchData}
                            className="btn-result mx-2 mx-lg-3"
                          >
                            Get Result
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 position-relative">
                      <img src={sphereSm} alt="Sphere" className="sphere-sm" />
                    </div>
                    <div className="col-12 px-2 px-lg-3">
                      <div className="bg-all mt-lg-5 p-4 p-sm-5">
                        <div className="row justify-content-around">
                          <div className="col-lg-5 mb-5">
                            <h3 className="d-flex align-items-center mb-4 mb-lg-5">
                              <span className="mr-2 bg-number-category">1</span>
                              Search by category or niche?
                            </h3>
                            <Select
                              onChange={categoryChange}
                              name="category"
                              options={categoryData}
                              onlySelect
                              value={category}
                              placeholder="Select Category"
                              className="bg-left-side p-0"
                            />
                          </div>
                          <div className="col-lg-5 mb-4 mb-lg-5">
                            <h3 className="d-flex align-items-center mb-4 mb-lg-5">
                              <span className="mr-2 bg-number-target">2</span>
                              What do you want to create?
                            </h3>

                            <Select
                              name="target"
                              onChange={targetChange}
                              options={targetData}
                              onlySelect
                              value={target}
                              placeholder="Select Target"
                              className="bg-right-side p-0"
                            />
                          </div>
                        </div>

                        {showResult && (
                          <div className="row">
                            <div className="col-12 px-0 px-sm-3">
                              <h2 className="pl-lg-4 pb-4 pb-lg-0 mb-5 mb-lg-3 result">
                                Result
                              </h2>
                              <div className="position-relative">
                                <Table
                                  pagination
                                  searchable
                                  onSearch={handleSearch}
                                  data={tableData}
                                  columns={column}
                                  onChangeQuantity={handleChangeQuantity}
                                />
                                <div className="shadow-left d-lg-none" />
                                <div className="shadow-right d-lg-none" />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default PromptEase;
