import React, { useCallback, useEffect, useState } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { Card, Container, Flag, LinkCopy } from './styles';
import { useLanguage } from '~/hooks/Language';
import { useAuth } from '~/hooks/Auth';
import facebook from '~/assets/icons/facebook-icon.svg';
import twitter from '~/assets/icons/twitter-icon.svg';
import whatsapp from '~/assets/icons/whatsapp-circle-icon.svg';
import api from '~/services/api';
import { Skeleton } from '../styles';

interface ISwipeFile {
  id: string;
  subject: string;
  body: string;
  title: string;
  description: string;
  content: string;
  file: {
    file_url: string;
  };
}

interface IEmail {
  id: string;
  subject: string;
  body: string;
}

interface ISocial {
  id: string;
  title: string;
  subtitle: string;
  body: string;
}

interface IImages {
  id: string;
  url: string;
}

const Swipe: React.FC = () => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const [copied, setCopied] = useState(false);
  const [linkSelected, setLinkSelected] = useState('');
  const [choiseShare, setChoiseShare] = useState('emails');
  const [emails, setEmails] = useState<IEmail[]>([]);
  const [socials, setSocials] = useState<ISocial[]>([]);
  const [images, setImages] = useState<IImages[]>([]);
  const [loading, setLoading] = useState(true);
  const [isPostEase, setIsPostEase] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (choiseShare === 'emails') {
      api
        .get<ISwipeFile[]>('swipe-files', {
          params: {
            type: 'email',
            category: isPostEase ? 'postease' : 'autoaffiliate',
          },
        })
        .then((response) => {
          const data = response.data.map<IEmail>((swipeFile) => ({
            id: swipeFile.id,
            subject: swipeFile.subject,
            body: swipeFile.body,
          }));

          setEmails(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (choiseShare === 'social') {
      api
        .get<ISwipeFile[]>('swipe-files', {
          params: {
            type: 'social post',
            category: isPostEase ? 'postease' : 'autoaffiliate',
          },
        })
        .then((response) => {
          const data = response.data.map<ISocial>((swipeFile) => ({
            id: swipeFile.id,
            title: swipeFile.title,
            subtitle: swipeFile.description,
            body: swipeFile.content,
          }));

          setSocials(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (choiseShare === 'images') {
      api
        .get<ISwipeFile[]>('swipe-files', {
          params: {
            type: 'image',
            category: isPostEase ? 'postease' : 'autoaffiliate',
          },
        })
        .then((response) => {
          const data = response.data.map<IImages>((swipeFile) => ({
            id: swipeFile.id,
            url: swipeFile.file.file_url,
          }));

          setImages(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [choiseShare, isPostEase]);

  const handleClickCopyTextArea = useCallback((e, link_id) => {
    const element = e.target.closest('.link').querySelector('textarea');
    element.select();
    document.execCommand('copy');
    const selection = window.getSelection;
    if (selection) {
      selection()?.removeAllRanges();
      setCopied(true);
      setLinkSelected(link_id);
      setTimeout(() => {
        setCopied(false);
        setLinkSelected('');
      }, 500);
    }
  }, []);

  console.log('up');
  return (
    <>
      <Container className=" mx-auto overflow-auto my-3">
        <div className="btn-choise min-width d-flex justify-content-center">
          <button
            type="button"
            className={`${choiseShare === 'emails' && 'active'}`}
            onClick={() => setChoiseShare('emails')}
          >
            Emails
          </button>
          <button
            type="button"
            className={`${choiseShare === 'social' && 'active'}`}
            onClick={() => setChoiseShare('social')}
          >
            Social Posts
          </button>

          <button
            type="button"
            className={`${choiseShare === 'images' && 'active'}`}
            onClick={() => setChoiseShare('images')}
          >
            Images
          </button>
          {/* <button
            type="button"
            className={`${choiseShare === 'headlines' && 'active'}`}
            onClick={() => setChoiseShare('headlines')}
          >
            Headlines
          </button>
          <button
            type="button"
            className={`${choiseShare === 'videos' && 'active'}`}
            onClick={() => setChoiseShare('videos')}
          >
            Videos
          </button>
          <button
            type="button"
            className={`${choiseShare === 'flyers' && 'active'}`}
            onClick={() => setChoiseShare('flyers')}
          >
            Flyers
          </button>
          <button
            type="button"
            className={`${choiseShare === 'business' && 'active'}`}
            onClick={() => setChoiseShare('business')}
          >
            Business Cards
          </button> */}
        </div>
        <div className="text-center mt-4 btn-product">
          <button
            type="button"
            className={`${isPostEase ? 'choise-product' : ''}`}
            onClick={() => setIsPostEase(true)}
          >
            PostEASE
          </button>
          <button
            type="button"
            className={`${!isPostEase ? 'choise-product' : ''}`}
            onClick={() => setIsPostEase(false)}
          >
            AutoAffiliate
          </button>
        </div>
      </Container>

      {choiseShare === 'emails' && (
        <>
          {emails.map((banner, index) => (
            <div key={index} className="col-md-6 col-xl-4 my-3">
              <Card className="p-3 p-sm-4 h-100">
                <h3 className="mb-0">Subject:</h3>
                <LinkCopy
                  title="Copy subject email"
                  className="subject link d-flex justify-content-between align-items-end py-1 p-relative"
                >
                  <textarea
                    className={`py-2 w-100 bg-transparent border-0 `}
                    value={banner.subject}
                    readOnly
                    aria-label="button"
                    onClick={(e) => handleClickCopyTextArea(e, banner.id)}
                  />
                </LinkCopy>

                <h3 className="mb-0">Body:</h3>
                <LinkCopy
                  title="Copy body email"
                  className="body link d-flex justify-content-between align-items-end py-1 p-relative"
                >
                  <textarea
                    className={`py-2 w-100 bg-transparent border-0 `}
                    value={banner.body}
                    readOnly
                    aria-label="button"
                    onClick={(e) => handleClickCopyTextArea(e, banner.id)}
                  />
                </LinkCopy>
                <Flag
                  show={copied && linkSelected === banner.id}
                  className="px-3 pt-1 p-absolute"
                >
                  <p className="mb-0 text-green">{language.footer.copy}</p>
                </Flag>
              </Card>
            </div>
          ))}
        </>
      )}
      {choiseShare === 'social' && (
        <>
          {socials.map((social, index) => (
            <div key={index} className="col-md-6 col-xl-4 my-3">
              <Card className="body p-3 p-sm-4 h-100">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="d-flex align-items-center w-100">
                    <img
                      src={user.avatar.avatar_url}
                      alt="Avatar"
                      className="img-profile"
                    />
                    <p className="username small mb-0 ml-2">
                      <b>{user.name || user.username}</b>{' '}
                      {language.social_media.p}
                    </p>
                  </div>
                </div>
                <h2 className="article-title">
                  {`Post ${index + 1}: ${social.title}`}
                </h2>
                <h3
                  className="article-title"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: social.subtitle.replace(/\n/g, '<br>'),
                  }}
                />
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: social.body.replace(/\n/g, '<br>'),
                  }}
                  className="h-body py-2 w-100 bg-transparent border-0"
                />

                <div className="d-flex flex-wrap mt-4 pb-3">
                  <small className="w-100 text-center mb-4">
                    {language.social_media.small}
                  </small>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <FacebookShareButton
                      quote={social.body.replace(/<\/?b>/g, '')}
                      url={`https://autoaffiliate.ai/i/${user.referral_code}`}
                      className="btn-share rounded-pill w-100"
                    >
                      <img
                        src={facebook}
                        alt={language.social_media.img_1}
                        className="my-2"
                      />
                    </FacebookShareButton>
                  </div>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <TwitterShareButton
                      title={`${social.body.replace(/<\/?b>/g, '')}\n`}
                      url={`https://autoaffiliate.ai/i/${user.referral_code}`}
                      className="btn-share rounded-pill w-100"
                    >
                      <img
                        src={twitter}
                        alt={language.social_media.img_2}
                        className="my-2"
                      />
                    </TwitterShareButton>
                  </div>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <WhatsappShareButton
                      title={`${social.title} \n\n${
                        social.subtitle
                      }\n\n ${social.body.replace(/<\/?b>/g, '*')}\n\n`}
                      separator={`\n`}
                      url={`https://autoaffiliate.ai/i/${user.referral_code}`}
                      className="btn-share rounded-pill w-100"
                    >
                      <img
                        src={whatsapp}
                        alt={language.social_media.img_3}
                        className="my-2"
                      />
                    </WhatsappShareButton>
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </>
      )}
      {choiseShare === 'images' && (
        <>
          {images.map((image, index) => (
            <div key={index} className="col-md-6 col-xl-4 my-3">
              <Card className="body p-3 p-sm-4 h-100">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="d-flex align-items-center w-100">
                    <img
                      src={user.avatar.avatar_url}
                      alt="Avatar"
                      className="img-profile"
                    />
                    <p className="username small mb-0 ml-2">
                      <b>{user.name || user.username}</b>{' '}
                      {language.social_media.p}
                    </p>
                  </div>
                </div>

                <div className="">
                  <img
                    src={image.url}
                    alt="Promotional"
                    className="w-100 imgs"
                  />
                </div>

                <div className="d-flex flex-wrap mt-4 pb-3">
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <FacebookShareButton
                      quote={image.url}
                      url={image.url}
                      className="btn-share rounded-pill w-100"
                    >
                      <img
                        src={facebook}
                        alt={language.social_media.img_1}
                        className="my-2"
                      />
                    </FacebookShareButton>
                  </div>

                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <TwitterShareButton
                      title={`${image.url.replace(/<\/?b>/g, '')}\n`}
                      url={image.url}
                      className="btn-share rounded-pill w-100"
                    >
                      <img
                        src={twitter}
                        alt={language.social_media.img_2}
                        className="my-2"
                      />
                    </TwitterShareButton>
                  </div>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <WhatsappShareButton
                      title={`${image.url.replace(/<\/?b>/g, '*')}`}
                      separator={`\n`}
                      url={image.url}
                      className="btn-share rounded-pill w-100"
                    >
                      <img
                        src={whatsapp}
                        alt={language.social_media.img_3}
                        className="my-2"
                      />
                    </WhatsappShareButton>
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </>
      )}
      {loading && (
        <>
          <Skeleton className="col-md-6 col-xl-4 my-3">
            <Card className="p-3 p-sm-4 h-100">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center w-100">
                  <div className="skeleton img-profile" />
                  <p className="skeleton username small mb-0 ml-2">
                    ######### ########## #########
                  </p>
                </div>
              </div>
              <p className="skeleton tinytext small form-control py-1 border-0 mb-2 bg-transparent description-h">
                ######### ########## #########
              </p>
              <div className="skeleton d-flex img-height flex-column justify-content-center align-items-center label-image mt-2 mb-4" />
              <LinkCopy className="skeleton link rounded-pill d-flex justify-content-between align-items-center py-1 px-3 mt-3 p-relative">
                <input className="small my-1 py-2 w-100 bg-transparent border-0" />
              </LinkCopy>
              <div className="d-flex flex-wrap mt-4 pb-3">
                <div className="w-100 text-center">
                  <small className="skeleton text-center mb-3">
                    {language.social_media.small}
                  </small>
                </div>
                <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                  <div className="skeleton h-100 btn-share rounded-pill w-100">
                    <p>####</p>
                  </div>
                </div>
                <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                  <div className="skeleton h-100 btn-share rounded-pill w-100">
                    <p>####</p>
                  </div>
                </div>
                <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                  <div className="skeleton h-100 btn-share rounded-pill w-100">
                    <p>####</p>
                  </div>
                </div>
              </div>
            </Card>
          </Skeleton>
          <Skeleton className="col-md-6 col-xl-4 my-3">
            <Card className="p-3 p-sm-4 h-100">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center w-100">
                  <div className="skeleton img-profile" />
                  <p className="skeleton username small mb-0 ml-2">
                    ######### ########## #########
                  </p>
                </div>
              </div>
              <p className="skeleton tinytext small form-control py-1 border-0 mb-2 bg-transparent description-h">
                ######### ########## #########
              </p>
              <div className="skeleton d-flex img-height flex-column justify-content-center align-items-center label-image mt-2 mb-4" />
              <LinkCopy className="skeleton link rounded-pill d-flex justify-content-between align-items-center py-1 px-3 mt-3 p-relative">
                <input className="small my-1 py-2 w-100 bg-transparent border-0" />
              </LinkCopy>
              <div className="d-flex flex-wrap mt-4 pb-3">
                <div className="w-100 text-center">
                  <small className="skeleton text-center mb-3">
                    {language.social_media.small}
                  </small>
                </div>
                <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                  <div className="skeleton h-100 btn-share rounded-pill w-100">
                    <p>####</p>
                  </div>
                </div>
                <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                  <div className="skeleton h-100 btn-share rounded-pill w-100">
                    <p>####</p>
                  </div>
                </div>
                <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                  <div className="skeleton h-100 btn-share rounded-pill w-100">
                    <p>####</p>
                  </div>
                </div>
              </div>
            </Card>
          </Skeleton>
          <Skeleton className="col-md-6 col-xl-4 my-3">
            <Card className="p-3 p-sm-4 h-100">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center w-100">
                  <div className="skeleton img-profile" />
                  <p className="skeleton username small mb-0 ml-2">
                    ######### ########## #########
                  </p>
                </div>
              </div>
              <p className="skeleton tinytext small form-control py-1 border-0 mb-2 bg-transparent description-h">
                ######### ########## #########
              </p>
              <div className="skeleton d-flex img-height flex-column justify-content-center align-items-center label-image mt-2 mb-4" />
              <LinkCopy className="skeleton link rounded-pill d-flex justify-content-between align-items-center py-1 px-3 mt-3 p-relative">
                <input className="small my-1 py-2 w-100 bg-transparent border-0" />
              </LinkCopy>
              <div className="d-flex flex-wrap mt-4 pb-3">
                <div className="w-100 text-center">
                  <small className="skeleton text-center mb-3">
                    {language.social_media.small}
                  </small>
                </div>
                <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                  <div className="skeleton h-100 btn-share rounded-pill w-100">
                    <p>####</p>
                  </div>
                </div>
                <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                  <div className="skeleton h-100 btn-share rounded-pill w-100">
                    <p>####</p>
                  </div>
                </div>
                <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                  <div className="skeleton h-100 btn-share rounded-pill w-100">
                    <p>####</p>
                  </div>
                </div>
              </div>
            </Card>
          </Skeleton>
        </>
      )}
    </>
  );
};

export default Swipe;
