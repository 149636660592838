import styled from 'styled-components';
import { Form as FormComponent } from '@unform/web';
import { lighten } from 'polished';

interface IAvatar {
  src: string;
}

interface ISettings {
  show: boolean;
}

interface ITyping {
  active: boolean;
}

export const Container = styled.div`
  #loadVideo {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  #room-container {
    height: calc(100% - 48px);
  }

  .video-content {
    border-radius: 30px;
    background-color: #242526;
    position: relative;
    overflow: hidden;

    ::before {
      content: '';
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.06) 27.6%,
        rgba(0, 0, 0, 0.6) 100%
      );
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25%;
      position: absolute;
      z-index: 1;
    }

    .video {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #18191a;
      opacity: 1;
      pointer-events: none;

      h1 {
        display: none;
      }
    }

    .hide {
      opacity: 0;
    }

    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      background-color: #333;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        margin: 0;
        padding: 5px;
        color: #e4e4e4;
        font-size: 64px;
      }
    }

    .username {
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      bottom: 10px;
      left: 10px;
      width: 150px;
      border-radius: 50px;
      background-color: #333;
      z-index: 1;

      p {
        margin: 0;
        padding: 5px;
        color: #e4e4e4;
      }
    }

    :hover {
      .username {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .host,
  .screen {
    height: 100%;

    .video-content {
      height: 100%;

      .avatar {
        width: 100px;
        height: 100px;
      }
    }
  }

  .old-host,
  .audience {
    position: absolute;
    z-index: 1;
    bottom: 25px;
    transform: translateX(-50%);

    .video-content {
      border: 2px solid #ffffff !important;
      border-radius: 15px;
      height: 96px !important;
      width: 113px !important;

      .avatar {
        width: 50px;
        height: 50px;
      }
    }
  }

  .audience:nth-child(2) {
    left: calc(50% - 128px);
  }

  .audience:nth-child(3) {
    left: 50%;
  }

  .audience:nth-child(1),
  .audience:nth-child(4) {
    left: calc(50% + 128px);
  }

  .buttons {
    position: absolute;
    width: 100%;
    bottom: 150px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    > div {
      position: relative;

      button {
        width: 48px;
        height: 48px;
        background: rgba(0, 0, 0, 0.52);
        backdrop-filter: blur(40px);
        border: none;
        border-radius: 50px;
        color: #e4e4e4 !important;
        font-weight: bold;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      button.btn-call-end {
        width: 74px;
        height: 64px;
        background: #f8000f;
        border-radius: 12px;

        svg {
          transform: rotateZ(135deg);
        }
      }

      button.btn-shared-screen {
        span {
          width: 20px;
          height: 18px;
          border-radius: 5px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .col-lg-0 {
      flex: 0 0 0%;
      max-width: 0%;
    }
  }
`;

export const LiveChat = styled.div`
  transition-duration: 0.3s;

  .btn-dark-gray {
    width: 32px;
    height: 32px;
    background: #292929;
    backdrop-filter: blur(4px);
    border-radius: 6px;
  }

  span.btn-dark-gray {
    height: 27px;
    width: 60px;
    font-weight: 100;
    cursor: default !important;
  }

  button > span.btn-dark-gray {
    height: 22px;
    width: 22px;
    cursor: pointer !important;
  }
`;

export const Settings = styled.div<ISettings>`
  position: absolute;
  left: calc(-320px / 2);
  bottom: 60px;
  width: 320px;
  transform: translateX(20px);
  background: rgba(41, 41, 41, 0.9);
  border-radius: 10px;
  padding: 20px 30px;
  z-index: 1;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? '1' : '0')};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};

  :after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: rgba(41, 41, 41, 0);
    background: linear-gradient(
      135deg,
      rgba(41, 41, 41, 0) 0%,
      rgba(41, 41, 41, 0) 55%,
      rgba(41, 41, 41, 0.9) 55%,
      rgba(41, 41, 41, 0.9) 100%
    );
    bottom: -12px;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
  }

  select {
    width: 100%;
    background: #18191a;
    padding: 5px;
    border-radius: 8px;
    border: 0;
    color: #ffffff;
  }
`;

export const Chat = styled.div`
  background: #ffffff;
  box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(64px);
  height: calc(100vh - 40px);
  border-radius: 20px;
  transition-duration: 0.3s;
  overflow: hidden;

  .user {
    + .user {
      margin-left: 20px;
    }
  }

  .messages-group {
    height: calc(100vh - 340px);
  }
`;

export const Avatar = styled.div<IAvatar>`
  width: 79px;
  height: 79px;
  border-radius: 50%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    padding: 5px;
    color: #e4e4e4;
    font-size: 64px;
  }
`;

export const MessagesArea = styled.div`
  > div {
    width: 100%;

    .message-box {
      max-width: 90%;
    }

    .your-message {
      max-width: calc(90% - 50px);

      > div {
        background: #f9f9f9;
        border-radius: 0px 6px 6px 6px;

        p {
          color: #202020;
        }
      }
    }

    .my-message {
      margin-left: auto;

      > small {
        text-align: right;
      }

      > div {
        background: #18191a;
        border-radius: 6px 0px 6px 6px;

        p {
          color: #fff;
        }
      }
    }

    .your-message,
    .my-message {
      > div {
        p {
          width: calc(100% - 50px);
        }

        small {
          font-size: 7.77678px;
          line-height: 9px;
          width: 40px;
          color: #bfbfbf;
        }
      }
    }
  }

  + div {
    margin-top: 10px;
  }
`;

export const MiniAvatar = styled.div<IAvatar>`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    padding: 5px;
    color: #e4e4e4;
    font-size: 64px;
  }
`;

export const Typing = styled.div<ITyping>`
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
`;

export const Form = styled(FormComponent)`
  .typing {
    background: #f9f9f9;
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    pointer-events: none;
  }

  .text-typing {
    color: #bfbfbf;
  }

  .actions {
    > div,
    > button {
      background: #f9f9f9;
      box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      border: 1px solid #202020 !important;
    }

    > div {
      width: calc(100% - 100px);

      .textarea {
        width: calc(100% - 50px);

        textarea {
          resize: none;
          color: #202020;
          position: relative;

          ::placeholder {
            color: ${lighten(0.3, '#202020')};
          }
        }
      }

      .btn-white {
        background: #202020;
        border-radius: 6.48065px;
      }
    }

    > button {
      width: 80px;
    }
  }
`;
