import React, { useCallback, useEffect, useRef } from 'react';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import 'videojs-contrib-hls';

import { Container, VideoPlayer } from './styles';

interface IPlayerProps {
  src: string;
  id?: string;
  controls?: boolean;
  autoplay?: boolean;
  className?: string;
  thumbnail?: string;
  onPlay?(): void;
  onPause?(): void;
  callChangeTimeBySeconds?: number;
  onChangeTime?(time: number): void;
  onComplete?(): void;
}

const Player: React.FC<IPlayerProps> = ({
  src,
  id,
  controls,
  autoplay,
  className,
  thumbnail,
  onPlay,
  onPause,
  onComplete,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayVideo = useCallback(() => {
    if (onPlay) {
      onPlay();
    }
  }, [onPlay]);

  const handlePauseVideo = useCallback(() => {
    if (onPause) {
      onPause();
    }
  }, [onPause]);

  const handleCompleteVideo = useCallback(() => {
    if (onComplete) {
      onComplete();
    }
  }, [onComplete]);

  useEffect(() => {
    const fileType = src.split('.')[src.split('.').length - 1];
    const sources = [
      {
        src,
        type: 'video/mp4',
      },
    ];

    if (fileType === 'm3u8') {
      sources[0].type = 'application/x-mpegURL';
    }

    const options: VideoJsPlayerOptions = {
      fluid: true,
      preload: 'auto',
      html5: {
        hls: {
          enableLowInitialPlaylist: true,
          smoothQualityChange: true,
          overrideNative: true,
        },
      },

      autoplay: false,
      controls: true,
      sources,
      // ended: handleCompleteVideo,
    };

    if (videoRef.current) {
      const player = videojs(videoRef.current, options);
      // eslint-disable-next-line no-underscore-dangle
      // player.updateSourceCaches_(src);
      player.on('ended', handleCompleteVideo);
    }
  }, [handleCompleteVideo, src]);

  return (
    <Container>
      <div data-vjs-player>
        <VideoPlayer
          ref={videoRef}
          onPlay={handlePlayVideo}
          onPause={handlePauseVideo}
          id={id}
          className={`${className} video-js`}
          poster={thumbnail}
          controls={controls}
          src={src}
          playsInline
          autoPlay={autoplay}
        >
          Sorry, your browser doesn't support embedded videos.
        </VideoPlayer>
      </div>
    </Container>
  );
};

export default Player;
