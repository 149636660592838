import styled from 'styled-components';

interface CopyAlertProps {
  active: boolean;
}

export const CopyButton = styled.button``;

export const CopyAlert = styled.div<CopyAlertProps>`
  background: #116bff;
  position: absolute;
  transition: all 0.3s ease;
  bottom: 70px;
  right: 20px;
  //right: ${(props) => (props.active ? '10px' : '-1000px')};
  opacity: ${(props) => (props.active ? '1' : '0')};
  z-index: 100;

  p {
    color: #fff !important;
  }
`;
