import { darken, lighten } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  p,
  td,
  p > small {
    color: var(--grayImmensity);
  }

  small,
  .price {
    color: var(--graniteGray);
  }

  .bg-gray {
    background: var(--coal);
    border-radius: 20px;
  }

  .text-no-register {
    color: var(--ultraWhite);
    font-weight: 600;
  }

  .button-cancel {
    background-color: #d0d0d0;
    border-radius: 12px;
    border: none;
    transition-duration: 0.3s;
    color: #242526;

    :hover {
      background-color: ${darken(0.1, '#D0D0D0')};
    }
  }

  .button-change-payment {
    background-color: #116bff;
    border-radius: 12px;
    border: none;
    transition-duration: 0.3s;
    color: #fff;

    :hover {
      background-color: ${darken(0.1, '#116BFF')};
    }
  }

  button,
  .button {
    cursor: pointer;
  }

  .graph {
    width: 11%;
    opacity: 0.1;
  }

  div {
    ::-webkit-scrollbar {
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: var(--technologicalGrayOpacity);
    }
  }

  table {
    th,
    td {
      padding: 0 10px;
    }

    th {
      font-size: 0.9rem;
    }

    .cardId {
      width: 1%;
    }

    .cardNumber {
      width: 16%;
    }

    .cardExpirationDate {
      width: 15%;
    }

    .cardName {
      width: 15%;
    }

    .cardBillingAddress {
      width: 20%;
    }

    .cardPrimary {
      width: 13%;
    }

    .cardAction {
      width: 1%;
    }
  }

  .radius-20 {
    border-radius: 20px;
  }

  .img-width {
    width: 100%;
    //height: 140px;
  }

  .positions {
    top: 19px;
    left: 15px;
    h2 {
      font-weight: 600;
      font-size: 36px;
      line-height: 46px;
      color: #f5f5f5;
      margin-bottom: 0px;
    }
    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #f5f5f5;
    }
  }

  @media screen and (max-width: 767px) {
    table {
      min-width: 800px;
    }

    .img-width {
      width: 400px;
      max-width: 100%;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    background: #ffffff;
    box-shadow: 19px 49px 112px rgba(192, 192, 192, 0.4),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(138, 138, 138, 0.5);
    border-radius: 30px;
    border: 0 !important;

    .terms {
      h4,
      p {
        color: #8c8c8c;
        font-family: 'Inter';
        font-size: 10px;
        font-weight: 200;
        line-height: 14px;
        b {
          font-weight: 500;
        }
      }
    }
  }
`;
