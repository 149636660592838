import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import Info from '~/components/Info';

import Header from '~/components/Header';

import { Wrapper, HeaderSpacer, Separator, FooterSpacer } from './styles';
import { useIntercom } from '~/hooks/Intercom';
import ModalCompleteProfile from '~/components/ModalCompleteProfile';

const DefaultLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const { user, signOut } = useAuth();

  const { Intercom } = useIntercom();

  const noFullSize = useMemo(
    () =>
      !location.pathname.match(/\/commission-history.*/) &&
      !location.pathname.match(/\/trainings.*/) &&
      !location.pathname.match(/\/promotional-material.*/) &&
      !location.pathname.match(/\/crowdfunding.*/) &&
      !location.pathname.match(/\/affiliates.*/) &&
      !location.pathname.match(/\/training.*/) &&
      !location.pathname.match(/\/prompt-ease.*/) &&
      !location.pathname.match(/\/post-ease.*/) &&
      !location.pathname.match(/\/leaderboard.*/) &&
      !location.pathname.includes('my-referrals') &&
      !location.pathname.includes('live'),
    [location.pathname]
  );

  const pageLessons = location.pathname.match(/\/lessons.*/);

  const pagePromptEase = location.pathname.match(/\/prompt-ease.*/);
  const pagePostEase = location.pathname.match(/\/post-ease.*/);

  useEffect(() => {
    window.scrollTo(0, 0);
    api.get(`/`).catch(() => {
      signOut();
    });
  }, [location, signOut, user]);

  return (
    <Wrapper>
      {!pagePromptEase && !pagePostEase && (
        <HeaderSpacer>
          <Header />
        </HeaderSpacer>
      )}
      <div
        className={`d-flex flex-wrap flex-xl-nowrap ${
          pagePromptEase || pagePostEase ? 'w-100' : 'separator-container'
        }`}
      >
        <Separator
          id="separator"
          className={`${
            pageLessons && 'bg-mobile'
          } order-0 order-lg-1 order-xl-0 height-xl ${
            (pagePromptEase || pagePostEase) && 'p-0'
          }`}
          noPadding={!!location.pathname.match(/\/trainings\/.*/)}
          fullSize={!noFullSize}
        >
          {children}
        </Separator>
        {noFullSize && (
          <FooterSpacer
            className={`${
              pageLessons && 'bg-footer-mob d-md-none d-xl-block'
            } order-1 order-lg-0 order-xl-1 px-0 px-lg-2 px-xl-0`}
          >
            <Info />
          </FooterSpacer>
        )}
      </div>
      {Intercom}
      {/* <ModalCompleteProfile /> */}
    </Wrapper>
  );
};

export default DefaultLayout;
