import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import CheckLogin from '~/pages/CheckLogin';
import SignIn from '~/pages/SignIn';
import AutoLogin from '~/pages/AutoLogin';
import Passcode from '~/pages/Passcode';
import ForgotPassword from '~/pages/ForgotPassword';
import Home from '~/pages/Home';
import CommissionHistory from '~/pages/CommissionHistory';
import Trainings from '~/pages/Trainings/List';
import Training from '~/pages/Training';
import MaterialPromocional from '~/pages/MaterialPromocional';
import Referrals from '~/pages/Referrals';
import TeamView from '~/pages/Referrals/TeamView';
import Wallet from '~/pages/Wallet';
import Profile from '~/pages/Profile';
import Waitlist from '~/pages/Waitlist';
import LiveVonage from '~/pages/Live/Vonage';
import Lesson from '~/pages/Lesson';
import Notifications from '~/pages/Notifications';
import PromptEasePro from '~/pages/PromptEasePro';
import PostEase from '~/pages/PostEase';
import Leaderboard from '~/pages/Leaderboard/indes';

const En: React.FC = () => {
  return (
    <Switch>
      <Route
        path={`${process.env.PUBLIC_URL}/en/waitlist/:waitlist_id`}
        component={Waitlist}
      />
      <Route path={`${process.env.PUBLIC_URL}/en/`} exact component={SignIn} />
      <Route
        path={`${process.env.PUBLIC_URL}/en/auto-login/:token`}
        exact
        component={AutoLogin}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/passcode`}
        exact
        component={Passcode}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/forgot-password`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/dashboard`}
        component={Home}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/commission-history`}
        exact
        component={CommissionHistory}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/trainings`}
        exact
        component={Trainings}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/trainings/category/:slug`}
        exact
        component={Trainings}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/trainings/:slug`}
        exact
        component={Training}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/promotional-material`}
        exact
        component={MaterialPromocional}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/my-referrals`}
        exact
        component={Referrals}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/my-referrals/team-view/:slug`}
        exact
        component={TeamView}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/wallet/:slug`}
        exact
        component={Wallet}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/check-login/:token`}
        exact
        component={CheckLogin}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/profile`}
        isPrivate
        component={Profile}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/live/:roomID`}
        exact
        isPrivate
        component={LiveVonage}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/lessons`}
        exact
        isPrivate
        component={Lesson}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/en/lessons/:slugLesson`}
        exact
        isPrivate
        component={Lesson}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/notifications`}
        exact
        isPrivate
        component={Notifications}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/en/prompt-ease-pro`}
        exact
        isPrivate
        component={PromptEasePro}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/post-ease`}
        exact
        isPrivate
        component={PostEase}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/en/leaderboard/:slug`}
        exact
        isPrivate
        component={Leaderboard}
      />
    </Switch>
  );
};

export default En;
