import styled, { keyframes } from 'styled-components';
import { shade, lighten } from 'polished';

export const Container = styled.div`
  background-color: #fff;

  .position-signIn {
    right: 0;
    top: 0;
  }

  @media screen and (min-width: 992px) {
    min-height: 100vh;
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  animation: ${appearFromLeft} 1s;

  .input-button {
    background: #fff;
    border: none;
    height: 30px;
    top: 17px;
    left: 17px;
    transform: scale(1.5);
  }

  .input-container {
    border-radius: 21px;
    border: 1.5px solid #939393;
    height: 65px;
    transform: 0.3s;
  }

  .input-phone {
    font-family: 'Archivo';
    font-size: 18px;
    height: 42px;
    width: 100%;
    color: #333;
    padding: 0 0 0 60px;
    border: none;
    :focus {
      box-shadow: none;
    }
    ::placeholder {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      color: #ababab;
    }
  }

  .input-container:focus-within,
  .input-email:focus-within,
  .input-password:focus-within {
    border: 1.5px solid #116bff !important;
  }

  .input-email,
  .input-password {
    input::placeholder {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 300 !important;
      font-size: 16px;
      color: #ababab;
    }
  }

  .btn-idiom {
    padding: 8px 10px;
    border: 1px solid #eeeeee;
    border-radius: 200px;
    height: max-content;

    img {
      border-radius: 50%;
    }
  }

  h1 {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    line-height: 100px;
    color: #151517;
  }

  h2 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    color: #151517;
  }

  label {
    font-family: 'Archivo';
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    color: #5f5f61;
  }

  > div {
    border: none;
    padding: 1px;
    transition-duration: 0.3s;
    top: 0px;
    @media screen and (min-width: 992px) {
      width: 85%;
      margin: 0 auto;
    }

    @media screen and (min-width: 1700px) {
      width: 60%;
    }
  }

  .forgot {
    font-family: 'Archivo';
    font-weight: 300;
    font-size: 14px;
    line-height: 32px;
    color: #5f5f61;
  }

  .normal-sm a {
    color: #303030;
  }

  .font-weigth-500 {
    font-weight: 500 !important;
  }

  .button-login {
    background-color: var(--technologicalGray);
    border-radius: 21px;
    text-decoration: none;
    border: none;

    font-family: 'Archivo';
    font-weight: 500;
    font-size: 22px;
    color: #ffffff;

    :hover {
      background-color: ${lighten(0.1, '#606060')};
      transition: 0.5s;
    }
  }

  .small {
    color: #828282;
  }

  form {
    > div {
      padding: 11.5px 20px;
      border-radius: 21px;
      border: 1px solid #939393;
    }

    input {
      width: 100%;
      height: 40px;
      font-size: 18px;
      color: #151517;
    }

    input[id='password'] {
      width: 77%;
    }

    .btn-show-custom {
      .button-show {
        position: absolute;
        top: -8px;
        right: 0px;
        width: 70px;
        text-align: center;
        background: none;
        border-width: 0px 0px 0px 1px;
        border-top-style: initial;
        border-right-style: initial;
        border-bottom-style: initial;
        border-top-color: initial;
        border-right-color: initial;
        border-bottom-color: initial;
        border-image: initial;
        font-weight: bold;
        padding: 6px;
        color: #303030;
        height: 56px !important;
        border-left: none;
      }
    }

    @media screen and (max-width: 1199px) {
    }

    @media screen and (max-width: 450px) {
      input[id='password'] {
        width: 68%;
      }
    }

    a {
      color: #565656;
      display: block;
      text-decoration: none;
      transition-duration: 0.2s;

      :hover {
        color: ${shade(0.2, '#565656')};
      }
    }
  }

  .separator {
    position: relative;

    hr {
      width: 90%;
      height: 0.5px;
      opacity: 1;
      background-color: #ababab;
      position: absolute;
    }

    span {
      position: relative;
      z-index: 1;
      background-color: #fff;
      padding: 10px;
      font-family: 'Archivo';
      font-weight: 300;
      font-size: 16px;
      color: #5f5f61;
    }
  }

  @media screen and (max-width: 1199px) {
    h1 {
      font-size: 80px;
    }
    form {
      width: 100%;
    }

    .content {
      position: relative;
      z-index: 1;
      opacity: 0.8;

      a {
        color: #cbceceff;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .size-mobile {
      width: 55px;
      height: 50px;
    }
  }
`;

export const Loading = styled.div`
  //margin-top: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  border-radius: 20px;
  background-color: #116bff;
  width: 100% !important;
  border: none !important;
  cursor: not-allowed !important;
  .spinner {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: radial-gradient(farthest-side, #fff 94%, #0000) top/9px 9px
        no-repeat,
      conic-gradient(#0000 30%, #fff);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 9px),
      #000 0
    );
    animation: spinner-c7wet2 1s infinite linear;
  }

  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
