import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory, NavLink } from 'react-router-dom';

import { FiChevronDown } from 'react-icons/fi';
import { useLanguage } from '~/hooks/Language';

import { Container, Welcome, Options } from './styles';
import MyProfile from './MyProfile';
import SubscriptionsCards from './SubscriptionsCards';
import Subscriptions from './Subscriptions';
import MyOffers from './MyOffers';
import Purchase from './Purchase';
import Receipts from './Receipts';
import PaymentOptions from './PaymentOptions';
import PaymentPlans from './PaymentPlans';
import Documents from './Documents';

const Profile: React.FC = () => {
  const { language } = useLanguage();
  const location = useLocation();
  const history = useHistory();
  const [optionSelected, setOptionSelected] = useState(() => {
    const lang = location.pathname.substr(0, 3);
    if (lang !== '/en' && lang !== '/es') {
      return location.pathname.slice(9);
    }
    return location.pathname.slice(12);
  });
  const [optionMobile, setOptionMobile] = useState(language.profile.button_1);
  const [langSelected] = useState(() => {
    return location.pathname.substr(0, 3) === '/en' ||
      location.pathname.substr(0, 3) === '/es'
      ? location.pathname.substr(0, 3)
      : '';
  });

  useEffect(() => {
    switch (optionSelected) {
      case 'my-offers':
        setOptionMobile(language.profile.button_2);
        break;
      case 'my-purchases':
        setOptionMobile('My purchases');
        break;
      case 'subscriptions':
        setOptionMobile('Suscripciones');
        break;
      case 'documents':
        setOptionMobile('Documents');
        break;
      case 'payment-options':
        setOptionMobile(language.profile.button_3);
        break;
      case 'payment-plans':
        setOptionMobile(language.profile.button_5);
        break;
      case 'receipts':
        setOptionMobile(language.profile.button_4);
        break;
      default:
        setOptionMobile(language.profile.button_1);
        break;
    }
  }, [language.profile, optionSelected]);

  useEffect(() => {
    let page = '';
    if (langSelected !== '/en' && langSelected !== '/es') {
      page = location.pathname.slice(9);
    } else {
      page = location.pathname.slice(12);
    }

    const box = document.querySelector('.options-box');
    if (box) {
      box.classList.remove('active');
    }

    setOptionSelected(page);
  }, [history, location.pathname, langSelected]);

  const handleClickBox = useCallback((e) => {
    const box = e.target.closest('.options-box');
    if (box) {
      box.classList.toggle('active');
    }
  }, []);

  return (
    <Container className="py-3 py-lg-0">
      <div className="container">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h2 my-4 text-center text-md-left">
                      {language.profile.h1}
                    </h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <Options>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-11 col-md-12 mx-3 mx-md-0 py-3 mb-4 border-profile">
                    <div className="options-box d-lg-flex align-items-center justify-content-between justify-content-md-center justify-content-lg-between justify-content-xl-start text-center text-lg-left">
                      <button
                        type="button"
                        className="d-flex d-md-none justify-content-between align-items-center py-1 py-lg-3 px-3 w-100 options-button"
                        onClick={handleClickBox}
                      >
                        <p className="w-100 mb-0 bg-btn rounded-pill m-1 my-lg-0 mx-lg-3 p-2 px-xxl-4 py-xxl-2 font-weight-lighter">
                          {optionMobile}
                        </p>
                        <FiChevronDown size={25} />
                      </button>
                      <div className="options d-md-flex justify-content-space-evenly align-items-center w-100">
                        <div className="bg-btn w-75 w-lg-auto mx-auto py-2 p-lg-4">
                          <NavLink
                            to={`${process.env.PUBLIC_URL}${langSelected}/profile/my-profile`}
                            activeClassName="active"
                          >
                            {language.profile.button_1}
                          </NavLink>
                        </div>
                        <div className="bg-btn w-75 w-lg-auto mx-auto py-2 p-lg-4">
                          <NavLink
                            to={`${process.env.PUBLIC_URL}${langSelected}/profile/my-offers`}
                            activeClassName="active"
                          >
                            {language.profile.button_2}
                          </NavLink>
                        </div>

                        <div className="bg-btn w-75 w-lg-auto mx-auto py-2 p-lg-4">
                          <NavLink
                            to={`${process.env.PUBLIC_URL}${langSelected}/profile/my-purchases`}
                            activeClassName="active"
                          >
                            My purchases
                          </NavLink>
                        </div>

                        <div className="bg-btn w-75 w-lg-auto mx-auto py-2 p-lg-4">
                          <NavLink
                            to={`${process.env.PUBLIC_URL}${langSelected}/profile/subscriptions`}
                            activeClassName="active"
                          >
                            Subscriptions
                          </NavLink>
                        </div>
                        <div className="bg-btn w-75 w-lg-auto mx-auto py-2 p-lg-4">
                          <NavLink
                            to={`${process.env.PUBLIC_URL}${langSelected}/profile/payment-options`}
                            activeClassName="active"
                          >
                            {language.profile.button_3}
                          </NavLink>
                        </div>
                        <div className="bg-btn w-75 w-lg-auto mx-auto py-2 p-lg-4">
                          <NavLink
                            to={`${process.env.PUBLIC_URL}${langSelected}/profile/receipts`}
                            activeClassName="active"
                          >
                            {language.profile.button_4}
                          </NavLink>
                        </div>
                        <div className="bg-btn w-75 w-lg-auto mx-auto py-2 p-lg-4">
                          <NavLink
                            to={`${process.env.PUBLIC_URL}${langSelected}/profile/documents`}
                            activeClassName="active"
                          >
                            Documents
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Options>
            {optionSelected === 'my-profile' && (
              <MyProfile active={optionSelected === 'my-profile'} />
            )}
            {optionSelected === 'my-offers' && (
              <MyOffers active={optionSelected === 'my-offers'} />
            )}
            {optionSelected === 'my-purchases' && (
              <Purchase active={optionSelected === 'my-purchases'} />
            )}
            {optionSelected === 'payment-plans' && (
              <PaymentPlans active={optionSelected === 'payment-plans'} />
            )}
            {optionSelected === 'subscriptions-cards' && (
              <SubscriptionsCards
                active={optionSelected === 'subscriptions-cards'}
              />
            )}
            {optionSelected === 'subscriptions' && (
              <Subscriptions active={optionSelected === 'subscriptions'} />
            )}
            {optionSelected === 'payment-options' && (
              <PaymentOptions active={optionSelected === 'payment-options'} />
            )}
            {optionSelected === 'receipts' && (
              <Receipts active={optionSelected === 'receipts'} />
            )}
            {optionSelected === 'documents' && (
              <Documents active={optionSelected === 'documents'} />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Profile;
