import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (min-width: 1200px) {
    zoom: 0.7;
  }

  @media screen and (min-width: 1400px) {
    zoom: 0.8;
  }

  @media screen and (min-width: 1600px) {
    zoom: 0.9;
  }

  @media screen and (min-width: 1700px) {
    zoom: 1;
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #021027;
  }
  p {
    color: var(--technologicalGray);
  }
  .search {
    input {
      background-color: transparent;
      border: 1px solid var(--closedGray);
      border-radius: 20px;
    }
    button {
      right: 10px;
      top: 7px;
      background: transparent;
      border: none;
    }
  }
`;

export const Options = styled.div`
  button,
  a {
    text-decoration: none;
    border: none;
    background: none;
    font-weight: 400;
    color: #8c8c8c;

    :hover {
      font-weight: 700;
      color: #021027;
    }
  }

  .border-profile {
    border-bottom: 1px solid var(--closedGray);
  }

  button.active,
  a.active {
    font-weight: 700;
    color: #021027;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .box-menu {
      z-index: 1;
      width: 200px;
      right: 13px;
      top: 30px;
      background: var(--closedGray);
      border-radius: 12px 0px 12px 12px;
      padding: 10px;
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;

      .item-menu {
        border-bottom: 1px solid var(--grayImmensity);
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }

    .box-menu.active {
      opacity: 1;
      visibility: visible;
    }
  }

  @media screen and (max-width: 767px) {
    .border-profile {
      border-bottom: none;
    }
    .options-button {
      transition-duration: 0.3s;
      background: transparent;
      border-radius: 15px;
      border: 1px solid #d0d0d0;

      p {
        color: var(--ultraWhite);
        -webkit-text-stroke: 0.5px var(--ultraWhite);
        font-weight: 700 !important;
      }

      svg {
        transition-duration: 0.3s;
        color: var(--ultraWhite);
      }
    }

    .options {
      transition-duration: 0.3s;
      background: var(--coal);
      color: var(--technologicalGray);
      border-radius: 0 0 20px 20px;
      height: 0;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;

      .bg-btn {
        background-color: transparent !important;
        border: none;
        border-radius: 0 !important;
        width: 100%;

        + .bg-btn {
          border-top: solid 1px var(--closedGray);
        }
      }
    }

    .options-box.active {
      .options-button {
        border-radius: 20px 20px 0px 0px;

        svg {
          transform: rotate(180deg);
        }
      }

      .options {
        height: 250px;
        padding: 0;
        overflow: none;

        ::-webkit-scrollbar {
          background-color: var(--coal);
          width: 5px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background: var(--asphaltGray);
        }
      }
    }
  }

  @media (min-width: 0px) and (max-width: 767px) {
    .box-menu {
      z-index: 1;
      width: 200px;
      right: 13px;
      top: 30px;
      background: var(--closedGray);
      border-radius: 12px 0px 12px 12px;
      padding: 10px;
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;

      .item-menu {
        border-bottom: 1px solid var(--grayImmensity);
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }

    .box-menu.active {
      opacity: 1;
      visibility: visible;
    }
  }
`;
