import Swal, { SweetAlertResult } from 'sweetalert2';
import success from '~/assets/icons/success.svg';

interface ISwalSuccess {
  title: string;
  message: string;
  textButton: string;
}

export default async function swalSuccess({
  title,
  message,
  textButton,
}: ISwalSuccess): Promise<SweetAlertResult<any>> {
  return Swal.fire({
    title: '',
    html: `
  <div>
    <img src="${success}" alt="success" />
    <p class="h1">${title}</p>
    <p>${message}</p>
  </div>
  `,
    customClass: 'success-swal-box',
    confirmButtonText: textButton,
  });
}
