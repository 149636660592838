import styled, { css } from 'styled-components';

import dashboard from '~/assets/icons/dashboard-icon.svg';
import dashboardActive from '~/assets/icons/dashboard-icon-active.svg';
import courses from '~/assets/icons/courses-icon.svg';
import coursesActive from '~/assets/icons/courses-icon-active.svg';
import profile from '~/assets/icons/profile-icon.svg';
import profileActive from '~/assets/icons/profile-icon-active.svg';
import resources from '~/assets/icons/resources-icon.svg';
import resourcesActive from '~/assets/icons/resources-icon-active.svg';
import wallets from '~/assets/icons/wallet-icon.svg';
import walletsActive from '~/assets/icons/wallet-icon-active.svg';
import referrals from '~/assets/icons/my-referrals-icon.svg';
import referralsActive from '~/assets/icons/my-referrals-icon-active.svg';
import socialMedia from '~/assets/icons/social-media-icon.svg';
import socialMediaActive from '~/assets/icons/social-media-icon-active.svg';
import notification from '~/assets/icons/notification-icon.svg';
import notificationActive from '~/assets/icons/notification-icon-active.svg';
import trainings from '~/assets/icons/trainings-icon.svg';
import trainingActive from '~/assets/icons/trainings-icon-active.svg';
import traffic from '~/assets/icons/traffic-icon.svg';
import trafficActive from '~/assets/icons/traffics-icon-active.svg';
import leaderboard from '~/assets/icons/leaderboard-icon.svg';
import leaderboardActive from '~/assets/icons/leaderboard-icon-active.svg';

import logout from '~/assets/icons/log-out-icon.svg';
import logoutActive from '~/assets/icons/log-out-icon-active.svg';

interface IMenuProps {
  active: boolean;
}

export const Container = styled.header`
  position: fixed;
  top: 0;
  background: #ffffff;
  z-index: 100;

  .btn-idiom {
    color: #dadada !important;
    img {
      width: 22px;
      height: 22px;
      transition-duration: 0.3s;
      filter: grayscale(1);
      border-radius: 50%;
    }

    :hover img,
    .active-idiom {
      filter: grayscale(0);
    }
    :hover,
    :focus {
      color: #8c8c8c !important;
    }
  }

  .flag-text-light {
    color: #8c8c8c !important;
  }

  @media screen and (max-width: 991px) {
    height: 60px;
  }
`;

export const Menu = styled.div<IMenuProps>`
  > button {
    width: 30px;
    height: 30px;

    span {
      display: block;
      width: 100%;
      height: 4px;
      background-color: #021027;
      border-radius: 30px;
      transition-duration: 0.5s;

      :nth-child(2) {
        width: 90%;
        margin: 6px 0 6px auto;
      }

      ${(props) =>
        props.active &&
        css`
          :nth-child(1) {
            transform: rotate(225deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: rotate(225deg);
          }

          :nth-child(3) {
            transform: rotate(315deg);
            margin-top: -20px;
          }
        `}
    }
  }

  .min-height {
    min-height: 50px;
  }

  button:focus {
    outline: none;
  }

  .menu-group {
    height: calc(100vh - 100px);
  }

  .menu,
  .logoff {
    a,
    button {
      background: #ffffff;
      color: #116bff;
      position: relative;

      > div:not(.notification-pop-up, .notify) {
        width: 22px;
        height: 22px;
        margin: 0 auto;
        transition-duration: 0.3s;
      }

      .dashboard {
        background-image: url(${dashboard});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .resources {
        background-image: url(${resources});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .social-media {
        background-image: url(${socialMedia});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .notifications {
        background-image: url(${notification});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .trainings {
        background-image: url(${courses});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .traffic {
        background-image: url(${traffic});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .trainings-course {
        background-image: url(${trainings});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .leaderboard {
        background-image: url(${leaderboard});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .wallets {
        background-image: url(${wallets});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .referrals {
        background-image: url(${referrals});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .profile {
        background-image: url(${profile});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .logout {
        background-image: url(${logout});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .notification-pop-up {
        width: max-content;
        position: absolute;
        transition-duration: 0.3s;
        z-index: -1;
        left: 110%;
        padding: 0 20px;
        border-radius: 15px;
        border: 1px solid #dedede;
        background: #fff;
        box-shadow: 4px 15px 25px 0px rgba(0, 0, 0, 0.08);
        opacity: 0;
        visibility: hidden;

        > p {
          color: #021027;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
        }

        .notify {
          position: relative;
          height: 74px;
          border-radius: 12px;
          transition-duration: 0.3s;

          .lottie {
            width: 43px;
            height: 43px;
            border-radius: 9px;
            overflow: hidden;
            margin-right: 6px;
          }

          .content {
            color: #021027;
            font-size: 12px;
            font-weight: 400;
          }

          .small {
            color: #116bff;
            font-size: 10px;
            font-weight: 400;
          }

          .detail {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: absolute;
            right: 1rem;
            bottom: 1rem;
            background: #ff1a50;
          }

          :hover {
            background: #f4f4f4;
          }
        }

        button {
          border-radius: 16px;
          border: 1px solid #8c8c8c;
          width: 180px;
          height: 32px;
          color: #737373;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
        }
      }

      small {
        width: max-content;
        position: absolute;
        transition-duration: 0.3s;
        z-index: -1;
        left: -100%;
        background: #ffffff;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 5px 5px 0;
      }

      > .detail {
        width: 8px !important;
        height: 8px !important;
        border-radius: 50%;
        position: absolute;
        right: 35px;
        top: 25px;
        background: #ff1a50;
      }
    }

    .become-affiliate-link {
      line-height: 1;
    }

    a:hover,
    button:hover,
    a.active {
      text-decoration: none;

      .dashboard {
        background-image: url(${dashboardActive});
      }

      .trainings {
        background-image: url(${coursesActive});
      }

      .traffic {
        background-image: url(${trafficActive});
      }

      .trainings-course {
        background-image: url(${trainingActive});
      }

      .leaderboard {
        background-image: url(${leaderboardActive});
      }

      .resources {
        background-image: url(${resourcesActive});
      }

      .social-media {
        background-image: url(${socialMediaActive});
      }

      .notifications {
        background-image: url(${notificationActive});
      }

      .wallets {
        background-image: url(${walletsActive});
      }

      .referrals {
        background-image: url(${referralsActive});
      }

      .profile {
        background-image: url(${profileActive});
      }

      .logout {
        background-image: url(${logoutActive});
      }
    }

    a:hover,
    button:hover {
      small {
        padding: 0 20px;
        left: 100%;

        @media screen and (min-width: 992px) {
          min-height: 50px;
        }
      }

      .notification-pop-up {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .menu-group {
      width: 100%;
      height: calc(100vh - 60px);
      background: #ffffff;
      transition-duration: 0.3s;
      top: 60px;
      left: ${(props) => (props.active ? '0px' : '1000px')};
    }

    .overflow {
      overflow: auto;
      ::-webkit-scrollbar {
        background-color: #ffffff !important;
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #ffffff !important;
      }
    }

    .menu,
    .logoff {
      a,
      button {
        div {
          margin: 0 10px;
          width: 22px;
          height: 22px;
        }

        small {
          font-family: Quicksand, sans-serif;
          position: relative;
          z-index: 0;
          text-align: left;
          opacity: 1;
          font-size: 24px;
          justify-content: start;
          left: 0 !important;
          padding: 5px 0 !important;
          color: #000;
          font-size: 28px;
        }

        > .detail {
          right: calc(50% - 77px);
        }
      }
    }
  }
`;
