import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  border-radius: 20px;

  hr {
    width: 75%;
    margin: 0.5em auto 1em;
    border-top: 1px solid #828282;
  }
`;

export const Pagination = styled.div`
  p,
  button {
    color: #8c8c8c;
  }

  button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-weight: bold;

    :first-child,
    :last-child {
      width: auto;
      border-radius: 5px;
    }
  }

  button.active,
  button:hover {
    background-color: rgba(63, 63, 63, 1) !important;
  }
`;
