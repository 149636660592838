/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from 'react';
import { useLanguage } from '~/hooks/Language';

import { Container, Pagination } from './styles';

export interface IData {
  id: string | number;
  [key: string]: any;
}

export interface IColumn {
  id?: string | number;
  name: string | number;
  selector: string;
  className?: string;
  cellClassName?: string;
  customCell?: (row: IData | any, index: number) => React.ReactNode;
}

interface ITableProps {
  data: IData[];
  columns: IColumn[];
  className?: string;
  pagination?: boolean;
}

const Table: React.FC<ITableProps> = ({
  data,
  columns,
  className,
  pagination,
}) => {
  const { language } = useLanguage();
  const [totalData] = useState(0);
  const [partialData, setPartialData] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [firstButton, setFirstButton] = useState(2);
  const [centerButton, setCenterButton] = useState(3);
  const [lastButton, setLastButton] = useState(4);

  const tableDataShow = useMemo(() => {
    const newDataList = data.filter(
      (_, index) =>
        index >= (selectedPage - 1) * 10 && index < selectedPage * 10
    );
    if (partialData === 0) {
      setPartialData(newDataList.length);
    }
    return newDataList;
  }, [partialData, selectedPage, data]);

  const totalPages = useMemo(() => {
    const pages = Math.ceil(data.length / 10);
    return pages;
  }, [data]);

  const onChangePage = useCallback(
    (page) => {
      setSelectedPage(page);
      if (page >= 3) {
        if (page <= totalPages - 2) {
          setFirstButton(page - 1);
        } else if (page === totalPages) {
          if (totalPages === 3) {
            setFirstButton(page - 1);
          } else if (totalPages === 4) {
            setFirstButton(page - 2);
          } else {
            setFirstButton(page - 3);
          }
        } else if (totalPages === 4) {
          setFirstButton(page - 1);
        } else {
          setFirstButton(page - 2);
        }
      } else if (page === 1) {
        setFirstButton(page + 1);
      } else {
        setFirstButton(page);
      }

      if (page >= 3) {
        if (page <= totalPages - 2) {
          setCenterButton(page);
        } else if (page === totalPages) {
          if (totalPages === 4) {
            setCenterButton(page - 1);
          } else {
            setCenterButton(page - 2);
          }
        } else if (totalPages === 4) {
          setCenterButton(page);
        } else {
          setCenterButton(page - 1);
        }
      } else if (page === 1) {
        setCenterButton(page + 2);
      } else {
        setCenterButton(page + 1);
      }

      if (page >= 3) {
        if (page <= totalPages - 2) {
          setLastButton(page + 1);
        } else if (page === totalPages) {
          setLastButton(page - 1);
        } else {
          setLastButton(page);
        }
      } else if (page === 1) {
        setLastButton(page + 3);
      } else {
        setLastButton(page + 2);
      }

      setPartialData(10 * page);
    },
    [totalPages]
  );

  return (
    <Container className={className}>
      <div className="align-items-center text-center py-4 p-relative text-md-left">
        <div className="d-none d-md-flex flex-wrap justify-content-xl-around w-100">
          {columns.map((column) => (
            <>
              <div className={column.className}>
                <p>{column.name}</p>
              </div>
            </>
          ))}
        </div>
        <div className="flex-wrap justify-content-xl-around w-100">
          {tableDataShow.map((content, index) => (
            <div className="mt-3" key={content.id}>
              {index > 0 && <hr className="d-md-none" />}
              <div className="d-flex flex-wrap align-items-center">
                {columns.map((column) => (
                  <div className={column.cellClassName}>
                    <div className="d-md-none">
                      <p className="mb-0 text-left">{column.name}</p>
                    </div>
                    <div>
                      {column.customCell ? (
                        column.customCell(content, index)
                      ) : (
                        <p className="mb-0">{content[column.selector]}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {pagination && (
        <Pagination className="row pb-1 pt-3 w-100 align-items-center justify-content-center menu-table mx-0">
          <div className="col-md-6 px-4 text-center text-md-left">
            <p className="mb-md-0">
              {language.commission_history.p_2_1}{' '}
              {partialData < 10 ? 1 : partialData - 9}-
              {partialData > totalData ? totalData : partialData}{' '}
              {language.commission_history.p_2_2} {totalData}
            </p>
          </div>
          <div className="col-md-6">
            <div className="d-flex w-100 justify-content-center justify-content-md-end">
              <button
                type="button"
                className="border-0 bg-transparent mx-1 px-1"
                onClick={() => onChangePage(1)}
              >
                {language.commission_history.button_1}
              </button>
              <button
                type="button"
                className={`border-0 bg-transparent mx-1 px-1 ${
                  selectedPage === 1 ? 'active' : ''
                }`}
                onClick={() => onChangePage(1)}
              >
                01
              </button>
              {totalPages > 1 && (
                <>
                  {totalPages > 5 && (
                    <>
                      {selectedPage > 3 && (
                        <span className="border-0 bg-transparent mx-1 px-1">
                          ...
                        </span>
                      )}
                    </>
                  )}
                  {totalPages > 2 && (
                    <button
                      type="button"
                      className={`border-0 bg-transparent mx-1 px-1 ${
                        selectedPage === firstButton ? 'active' : ''
                      }`}
                      onClick={() => onChangePage(firstButton)}
                    >
                      {`0${firstButton}`.slice(-2)}
                    </button>
                  )}
                  {totalPages > 3 && (
                    <button
                      type="button"
                      className={`border-0 bg-transparent mx-1 px-1 ${
                        selectedPage === centerButton ? 'active' : ''
                      }`}
                      onClick={() => onChangePage(centerButton)}
                    >
                      {`0${centerButton}`.slice(-2)}
                    </button>
                  )}
                  {totalPages > 4 && (
                    <button
                      type="button"
                      className={`border-0 bg-transparent mx-1 px-1 ${
                        selectedPage === lastButton ? 'active' : ''
                      }`}
                      onClick={() => onChangePage(lastButton)}
                    >
                      {`0${lastButton}`.slice(-2)}
                    </button>
                  )}
                  {totalPages > 5 && (
                    <>
                      {selectedPage < totalPages - 2 && (
                        <span className="border-0 bg-transparent mx-1 px-1">
                          ...
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
              {totalPages > 1 && (
                <button
                  type="button"
                  className={`border-0 bg-transparent mx-1 px-1 ${
                    selectedPage === totalPages ? 'active' : ''
                  }`}
                  onClick={() => onChangePage(totalPages)}
                >
                  {`0${totalPages}`.slice(-2)}
                </button>
              )}
              <button
                type="button"
                className="border-0 bg-transparent mx-1 px-1"
                onClick={() => onChangePage(totalPages)}
              >
                {language.commission_history.button_2}
              </button>
            </div>
          </div>
        </Pagination>
      )}
    </Container>
  );
};

export default Table;
