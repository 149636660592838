/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import { io } from 'socket.io-client';

import { formatDistanceToNow, parseISO } from 'date-fns';
import notification from '~/assets/animations/notification.json';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

interface INotificationResponse {
  id: string;
  created_at: string;
}

interface INotification {
  id: string;
  timeAgo: string;
}

const Notications: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();

  const [notifications, setNotifications] = useState<INotification[]>([]);

  const lang = useMemo(() => {
    const langSymbol = location.pathname.substr(0, 3);
    if (langSymbol !== '/en' && langSymbol !== '/es') {
      return ``;
    }

    return langSymbol;
  }, [location.pathname]);

  const handleLoadNotifications = useCallback(async () => {
    const response = await api.get<INotificationResponse[]>(
      `notifications/news`
    );
    const data = response.data.map((notificationData) => {
      return {
        id: notificationData.id,
        timeAgo: `${formatDistanceToNow(
          parseISO(notificationData.created_at)
        )} ago`,
      };
    });
    setNotifications(data.slice(0, 3));
  }, []);

  useEffect(() => {
    if (user) {
      const socket = io(process.env.REACT_APP_API_URL as string, {
        reconnection: true,
      });

      socket.on('connect', () => {
        socket.emit('join-room', { roomID: `notifications-${user.id}` });
      });

      socket.on('user-disconnected', () => {
        // console.log('user disconnected-- closing peers', data);
      });

      socket.on('disconnect', () => {
        // console.log('socket disconnected --');
      });

      socket.on('error', () => {
        // console.log('socket error --', err);
      });

      socket.on('check-notification', () => {
        handleLoadNotifications();
      });

      socket.on('new-notification', () => {
        handleLoadNotifications();
      });
    }
  }, [handleLoadNotifications, user]);

  useEffect(() => {
    handleLoadNotifications();
  }, [handleLoadNotifications]);

  return (
    <Link
      to={`${process.env.PUBLIC_URL}${lang}/notifications`}
      className={`menu-item px-2 px-lg-0 my-2 my-lg-0 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center ${
        location.pathname.match(/\/notifications.*/) ? 'active' : ''
      }`}
    >
      <small className="d-lg-none">Messages</small>
      <div className="notifications d-none d-lg-block" />
      <div className="notification-pop-up p-3">
        {notifications.length > 0 ? (
          <>
            <p className="mb-3">Unread Gems Inside 💎</p>
            {notifications.map((notificationData) => (
              <div
                key={notificationData.id}
                className="notify d-flex align-items-center p-3 mb-1"
              >
                <div className="lottie">
                  <Lottie
                    options={{
                      animationData: notification,
                      autoplay: true,
                      loop: true,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    width={43}
                  />
                </div>
                <div>
                  <p className="content mb-0 text-left">
                    <b>A new message</b> is waiting for you to read
                  </p>
                  <p className="small mb-0 text-left">
                    {notificationData.timeAgo}
                  </p>
                </div>
                <div className="detail" />
              </div>
            ))}
            <button type="button" className="mt-3">
              See all notifications
            </button>
          </>
        ) : (
          <p className="mb-0">
            Chirp, Chirp... <b>No Alerts Today</b> 🐦
          </p>
        )}
      </div>
      {notifications.length > 0 && <div className="detail" />}
    </Link>
  );
};

export default Notications;
