import React, { useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { Container } from './styles';

import Today from './Today';
import AllTime from './AllTime';

const Leaderboard: React.FC = () => {
  const location = useLocation();
  const [btnToday, setBtnToday] = useState(true);

  useEffect(() => {
    if (location.pathname === '/leaderboard/today') {
      setBtnToday(true);
    } else {
      setBtnToday(false);
    }
  }, [location.pathname]);

  return (
    <Container>
      <div className="container mb-5 mb-lg-0">
        <div className="row">
          <div className="col-12 zoom">
            <h1>Leaderboard</h1>
            <div className="d-inline-block mr-4">
              <Link
                to={`${process.env.PUBLIC_URL}/leaderboard/today`}
                className={`${btnToday && 'active'}`}
                onClick={() => setBtnToday(true)}
              >
                Today
              </Link>
              <hr className={`${btnToday ? 'd-block' : 'd-none'}`} />
            </div>
            <div className="d-inline-block">
              <Link
                to={`${process.env.PUBLIC_URL}/leaderboard/all-time`}
                className={`${!btnToday && 'active'}`}
                onClick={() => setBtnToday(false)}
              >
                All Time
              </Link>
              <hr className={`${!btnToday ? 'd-block' : 'd-none'}`} />
            </div>
          </div>
        </div>
      </div>
      {btnToday ? <Today /> : <AllTime />}
    </Container>
  );
};

export default Leaderboard;
