import styled from 'styled-components';

interface IAvatarProps {
  src: string;
}

export const Container = styled.div`
  h3 {
    color: #021027;
  }

  input {
    color: #242424 !important;
  }

  input#phone {
    color: #8c8c8c !important;
  }

  div .input-signIn {
    height: 58px !important;
  }

  div .button-show {
    top: -16px;
    border-left: solid 1.5px #dedede;
    color: #8c8c8c;
  }

  .w-75-profile {
    input {
      width: 75%;
    }
  }
  .border-color {
    border: solid 1.5px #dedede;
    border-radius: 15px;
  }

  .border-red {
    border-color: #dc3545 !important;
  }
  .bg-gray {
    background: var(--coal);
    padding: 32px;
    border-radius: 20px;
  }

  .border-custom {
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: #d0d0d0;
  }

  .profile-photo-icon {
    right: 0px;
    bottom: 0px;
    cursor: pointer;
  }

  label {
    color: #8c8c8c;
  }

  .founder-icon {
    width: 100px;
    height: 100px;
  }

  .profile-button {
    border: none;
    background: #116bff;
    border-radius: 15px;
    font-weight: 700;
    font-size: 16px;
    color: #f5f5f5;

    :hover {
      opacity: 0.8;
      transition: 0.3s;
    }
  }

  .py-adress {
    > div {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
  }

  .profile-photo {
    width: 100px;
    cursor: pointer;
    height: 100px;
    padding: 3px;
    background-image: linear-gradient(0deg, var(--coal), var(--coal)),
      linear-gradient(180deg, var(--blizzardOpacity), var(--blizzardOpacity));
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }

  .contact-info {
    input {
      width: 100%;
    }
  }

  .button-color {
    font-weight: 500;
    font-size: 18px;
    line-height: normal;
    color: #116bff;
    border-bottom: 1px solid #116bff !important;
    transition-duration: 0.3s;
    :hover {
      opacity: 0.8;
    }
  }

  .checkbox-width {
    width: 46px;
  }

  .checkbox {
    label {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #6f6e6e;
    }
  }

  input[type='checkbox'] {
    height: 20px;
  }

  .checkbox,
  .checkbox-active {
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #6f6e6e;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .checkbox {
    label > div {
      border: none;
      background: #d0d0d0;
      border-radius: 6px;
      width: 26px;
      height: 26px;
    }
  }

  .checkbox-active {
    label > div {
      border: none;
      background: #116bff;
      border-radius: 6px;
      width: 26px;
      height: 26px;
      svg {
        color: #f5f5f5 !important;
      }
    }
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .border-custom {
      border-bottom-width: 0;
    }
  }

  @media screen and (max-width: 991px) {
    .bg-gray {
      padding: 20px;
    }
  }

  @media screen and (max-width: 575px) {
    .max-width {
      width: 172px;
    }
  }
`;

export const Avatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 91px;
  height: 91px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px var(--blizzardOpacity);
  box-sizing: border-box;
  background-color: #303030;
`;

export const Loading = styled.div`
  //margin-top: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  border-radius: 15px;
  background-color: #116bff;
  width: 100% !important;
  border: none !important;
  cursor: not-allowed !important;
  .spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: radial-gradient(farthest-side, #fff 94%, #0000) top/9px 9px
        no-repeat,
      conic-gradient(#0000 30%, #fff);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 9px),
      #000 0
    );
    animation: spinner-c7wet2 1s infinite linear;
  }

  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
