import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { IDataTableColumn as IColumn } from 'react-data-table-component';

import { format, parseISO } from 'date-fns';
import { formatPrice } from '~/utils/format';

import { Container, Welcome, NameUserTable, Skeleton } from './styles';
import TableData from '~/components/Table';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import logo from '~/assets/logotipos/logo-profile.png';

interface ITableData {
  id: string;
  transactionDate: string;
  orderID: string;
  avatar: string;
  name: string;
  level: string;
  referrer: string;
  product: string;
  amountPaid: string;
  commission: string;
  refund: boolean;
}

interface IUser {
  avatar: {
    avatar_url: string;
  };
  name: string;
}
interface ICourse {
  title: string;
}

interface IProduct {
  name: string;
}
interface IOrder {
  affiliate_id: string;
  amount_paid: number;
  created_at: string;
  id: string;
  product_id: string;
  user: IUser;
  course: ICourse;
  product: IProduct;
}

interface ICommission {
  id: string;
  amount_paid: number;
  earning: number;
  gain_origin: string;
  created_at: string;
  order?: IOrder;
}

const Users: React.FC = () => {
  const { language } = useLanguage();
  const [users, setUsers] = useState<ITableData[]>([]);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31, 23, 59, 59, 99)
  );

  useEffect(() => {
    if (firstLoading) {
      setLoading(true);
      setFirstLoading(false);
    }

    api
      .get(`commissions`, {
        params: {
          search,
          startDate,
          endDate,
        },
      })
      .then((response) => {
        const data = response.data.map((commission: ICommission) => ({
          transactionDate: format(
            parseISO(commission.created_at),
            'MM-dd-yyyy'
          ),

          orderID: commission.order
            ? commission.order.id
            : `${
                commission.gain_origin.match('Crowdfunding')
                  ? 'Crowdfunding Distribution'
                  : commission.gain_origin
              }`,
          avatar: commission.order
            ? commission.order.user.avatar.avatar_url
            : logo,
          name: commission.order ? commission.order.user.name : 'Wiser',
          product:
            commission.order && commission.order.course
              ? `Course: ${commission.order.course.title}`
              : `${
                  commission.gain_origin.match('Crowdfunding')
                    ? 'Crowdfunding Distribution'
                    : commission.gain_origin
                }${
                  commission.order ? `: ${commission.order.product.name}` : ''
                }`,
          amountPaid: formatPrice(commission.amount_paid),
          commission: formatPrice(commission.earning),
          refund: commission.earning < 0,
        }));
        setUsers(data);
        setLoading(false);
      });
  }, [endDate, firstLoading, search, startDate]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: language.commission_history.th_1,
        selector: 'transactionDate',
        sortable: true,
      },
      {
        name: language.commission_history.th_2,
        selector: 'orderID',
        sortable: true,
      },
      {
        name: language.commission_history.th_3,
        selector: 'name',
        sortable: true,
        cell: (row: any) => (
          <NameUserTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.name} className="mr-2" />
            <p className="mb-0">{row.name}</p>
          </NameUserTable>
        ),
      },
      {
        name: language.commission_history.th_4,
        selector: 'product',
        sortable: true,
      },
      {
        name: language.commission_history.th_5,
        selector: 'amountPaid',
        sortable: true,
        cell: (row: any) => <p className="mb-0">{row.amountPaid}</p>,
      },
      {
        name: language.commission_history.th_6,
        selector: 'commission',
        sortable: true,
        cell: (row: any) => (
          <p className={`mb-0 ${row.refund ? 'text-red' : 'text-green'}`}>
            {row.commission}
          </p>
        ),
      },
    ],
    [
      language.commission_history.th_1,
      language.commission_history.th_2,
      language.commission_history.th_3,
      language.commission_history.th_4,
      language.commission_history.th_5,
      language.commission_history.th_6,
    ]
  );

  const handleSearch = useCallback(async (value) => {
    setSearch(value);
  }, []);

  const handleChangeStartDate = useCallback((value) => {
    setStartDate(value);
  }, []);

  const handleChangeEndDate = useCallback((value) => {
    setEndDate(value);
  }, []);

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome className="mb-3">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-sm my-4 text-center text-lg-left">
                      {language.commission_history.h1}
                    </h1>
                  </div>
                </div>
              </div>
            </Welcome>
            {loading && (
              <Skeleton className="col-12 mt-4 mt-lg-0 px-lg-4 py-5 d-flex flex-column">
                <div className="row justify-content-between">
                  <div className="col-lg-4 my-2">
                    <div className="skeleton h-36 w-100" />
                  </div>
                  <div className="col-lg-7 col-xxl-5 my-2 d-flex justify-content-between justify-content-lg-end">
                    <button
                      type="button"
                      className="w-40 h-36 skeleton px-5 py-1 mr-lg-2"
                    >
                      00/00/0000
                    </button>
                    <button
                      type="button"
                      className="w-40 h-36 skeleton px-5 py-1 mr-lg-2"
                    >
                      00/00/0000
                    </button>
                    <button
                      type="button"
                      className="w-15 h-36 skeleton px-4 py-1 mr-lg-2"
                    >
                      00/00
                    </button>
                  </div>
                </div>

                <div className="row mt-5 text-center align-items-center">
                  <div className="col-6 col-sm-4 col-md-3 col-lg-2 my-3 small">
                    <p className="skeleton mb-0 px-2">
                      Fecha de la transacción
                    </p>
                  </div>
                  <div className="col-6 col-sm-4 col-lg-2 col-md-3 my-3 small">
                    <p className="skeleton mb-0 px-2">Id del pedido</p>
                  </div>
                  <div className="col-sm-4 col-md-3 col-lg-2 d-none d-sm-block my-3 small">
                    <p className="skeleton mb-0 px-2">Usuario</p>
                  </div>
                  <div className="col-md-3 col-lg-2 d-none d-md-block my-3 small">
                    <p className="skeleton mb-0 px-2">Producto</p>
                  </div>
                  <div className="col-lg-2 d-none d-lg-block my-3 small">
                    <p className="skeleton mb-0 px-2">Cantidad pagada</p>
                  </div>
                  <div className="col-lg-2 d-none d-lg-block my-3 small">
                    <p className="skeleton mb-0 px-2">Comisión</p>
                  </div>
                </div>
                <div className="row mb-4 text-center align-items-center">
                  <div className="col-6 col-sm-4 col-md-3 col-lg-2 my-3 small">
                    <p className="skeleton mb-0 px-2">00-00-0000</p>
                  </div>
                  <div className="col-6 col-sm-4 col-lg-2 col-md-3 my-3 small">
                    <p className="skeleton mb-0 px-2">#######-#######</p>
                  </div>
                  <div className="col-sm-4 col-md-3 col-lg-2 d-none d-sm-flex justify-content-center my-3 small">
                    <div className="skeleton img-height rounded-circle" />
                    <p className="skeleton ml-2 mb-0 px-2">######</p>
                  </div>
                  <div className="col-md-3 col-lg-2 d-none d-md-block my-3 small">
                    <p className="skeleton mb-0 px-2">#########</p>
                  </div>
                  <div className="col-lg-2 d-none d-lg-block my-3 small">
                    <p className="skeleton mb-0 px-2">######</p>
                  </div>
                  <div className="col-lg-2 d-none d-lg-block my-3 small">
                    <p className="skeleton mb-0 px-2">######</p>
                  </div>
                </div>

                <div className="row pb-1 pt-3 mt-auto w-100 align-items-center justify-content-center menu-table mx-0 sc-jqCOkK hpTZXp">
                  <div className="col-lg-6 px-lg-4 text-center text-lg-left">
                    <p className="skeleton mb-lg-0">Demostración 1-1 de 1</p>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex w-100 justify-content-center justify-content-lg-end">
                      <button
                        type="button"
                        className="skeleton border-0 bg-transparent mx-1 px-1"
                      >
                        Primero
                      </button>
                      <button
                        type="button"
                        className="skeleton border-0 bg-transparent mx-0 mx-sm-0 px-1"
                      >
                        01
                      </button>
                      <button
                        type="button"
                        className="skeleton border-0 bg-transparent mx-1 px-1"
                      >
                        Último
                      </button>
                    </div>
                  </div>
                </div>
              </Skeleton>
            )}
            <div className={loading === true ? 'd-none' : ''}>
              <TableData
                title={language.commission_history.h1}
                data={users}
                columns={columns}
                pagination
                searchable
                onSearch={handleSearch}
                exportable
                date
                initialDate={startDate}
                finalDate={endDate}
                onChangeStartDate={handleChangeStartDate}
                onChangeLastDate={handleChangeEndDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Users;
