import styled, { keyframes } from 'styled-components';
import { shade, lighten } from 'polished';

export const Container = styled.div`
  background-color: #fff;
  min-height: 100vh;

  .position-signIn {
    right: 0;
    top: 0;
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  animation: ${appearFromLeft} 1s;

  .btn-idiom {
    padding: 8px 10px;
    border: 1px solid #eeeeee;
    border-radius: 200px;
    height: max-content;

    img {
      border-radius: 50%;
    }
  }

  h1 {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 54px;
    color: #151517;
  }

  h2 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    color: #151517;
  }

  > div {
    border: none;
    padding: 1px;
    transition-duration: 0.3s;
  }
  @media screen and (min-width: 992px) {
    > div {
      width: 85%;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 1700px) {
    > div {
      width: 60%;
    }
  }

  .different {
    font-family: 'Archivo';
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #021027;
  }

  .normal-sm a {
    color: #303030;
  }

  .font-weigth-500 {
    font-weight: 500 !important;
  }

  .button-login {
    background-color: var(--technologicalGray);
    color: #f5f5f5;
    text-decoration: none;
    border: none;
    border-radius: 15px;

    :hover {
      background-color: ${lighten(0.1, '#606060')};
      transition: 0.5s;
    }
  }

  .small {
    color: #828282;
  }

  form {
    > div {
      padding: 6.5px 10px;
    }

    .input {
      display: flex;
      justify-content: center;
      .pincode-input-container {
        input {
          width: 78.29px !important;
          height: 100px !important;
          background: #fafafa !important;
          border: 1px solid #116bff !important;
          border-radius: 20px !important;
          font-family: 'Archivo';
          font-style: normal;
          font-weight: 900;
          font-size: 32px;
          line-height: 32px;
          color: #116bff;

          ::placeholder {
            color: #ababab;
          }
        }
      }
    }

    .btn-show-custom {
      .button-show {
        position: absolute;
        top: -8px;
        right: 0px;
        width: 70px;
        text-align: center;
        background: none;
        border-width: 0px 0px 0px 1px;
        border-top-style: initial;
        border-right-style: initial;
        border-bottom-style: initial;
        border-top-color: initial;
        border-right-color: initial;
        border-bottom-color: initial;
        border-image: initial;
        font-weight: bold;
        padding: 6px;
        color: #303030;
        height: 56px !important;
        border-left: none;
      }
    }

    button {
      background: var(--technologicalGray);
      border-radius: 21px;
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 24px;
      letter-spacing: -0.04em;
      color: #ffffff;
      border: none;
      height: 65px;
      width: 100%;
    }

    button.resend {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 24px;
      letter-spacing: -0.04em;
      color: #116bff;
      border: none;
      height: 65px;
      width: 100%;
    }

    @media screen and (max-width: 1199px) {
      .button-login {
        border-radius: 12px;
      }
    }

    @media screen and (max-height: 740px) {
      margin: 20px 0;
    }

    @media screen and (max-width: 450px) {
      input[id='password'] {
        width: 68%;
      }
    }

    a {
      color: #565656;
      display: block;
      text-decoration: none;
      transition-duration: 0.2s;

      :hover {
        color: ${shade(0.2, '#565656')};
      }
    }
  }

  .separator {
    position: relative;

    hr {
      width: 100%;
      height: 0.5px;
      opacity: 1;
      background-color: #ababab;
      position: absolute;
    }

    span {
      position: relative;
      z-index: 1;
      background-color: #fff;
      padding: 10px;
    }
  }

  @media screen and (max-width: 1400px) {
    form {
      margin: 40px 0px 0px 0px;
      input {
      }
    }
  }

  @media screen and (max-width: 1199px) {
    form {
      width: 100%;
      margin: 25px 0px 0px 0px;
    }

    .content {
      position: relative;
      z-index: 1;
      opacity: 0.8;

      a {
        color: #cbceceff;
      }
    }
  }

  @media screen and (max-width: 991px) {
    form {
      margin: 40px 0px 0px 0px;
    }

    .size-mobile {
      width: 55px;
      height: 50px;
    }
  }

  @media screen and (max-width: 430px) {
    h1 {
      font-size: 11vw;
      line-height: 11vw;
    }

    form .input .pincode-input-container input {
      width: 64.29px !important;
      height: 80px !important;
    }
  }
`;
