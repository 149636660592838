import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.form`
  h2 {
    color: #303030;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
  }

  .credit-cards-group {
    overflow: auto;
    max-height: 275px;
  }

  .btn-primary-outline {
    border: 1px solid #116bff;
    color: #116bff;
    border-radius: 15px;
    transition-duration: 0.3s;

    svg {
      color: #116bff;
      transition-duration: 0.3s;
    }

    :hover {
      background-color: #116bff;
      color: #fff;

      svg {
        color: #fff;
      }
    }
  }

  .btn-primary-outline.hide {
    opacity: 0;
    visibility: hidden;
    height: 0;
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
  }

  .form-credit-card {
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition-duration: 0.3s;
  }

  .form-credit-card.show {
    opacity: 1;
    visibility: visible;
    height: unset;
  }

  .btn-black {
    background-color: #116bff;
    border-radius: 15px;
    font-size: 24px;
    padding: 13px 20px;
    transition-duration: 0.3s;
    font-family: 'Inter';
    font-weight: 600;
    span {
      font-size: 24px !important;
      font-weight: 600 !important;
    }

    :hover {
      background-color: ${lighten(0.1, '#116BFF')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }
`;

export const CreditCards = styled.div`
  .btn-credit-card {
    border: 1px solid #69696a;
    border-radius: 20px;
    transition-duration: 0.3s;

    p,
    img[alt='deletar cartão'] {
      transition-duration: 0.3s;
    }
  }

  .btn-credit-card:hover,
  .btn-credit-card.selected {
    border-color: #116bff;
    background-color: #116bff;

    p,
    img[alt='deletar cartão'] {
      color: #fff;
      filter: grayscale(100%) brightness(500%);
    }
  }
`;

export const Loading = styled.span`
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #fff 94%, #0000) top/9px 9px
      no-repeat,
    conic-gradient(#0000 30%, #fff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-c7wet2 1s infinite linear;

  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
