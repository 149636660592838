import styled from 'styled-components';
import bannerEase from '~/assets/banners/banner-prompt-ease.png';

interface ICopyAlertProps {
  show: boolean;
}

export const Container = styled.div`
  min-height: 100vh;
  background: url(${bannerEase});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  h1 {
    font-family: 'Zen Dots';
    font-size: 32px;
    font-weight: 700;
    background: linear-gradient(
      270deg,
      #4786ee 2.07%,
      #d8a681 29.58%,
      #9d66ff 57.19%,
      #53c0b4 76.97%,
      #d398cf 96.19%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0.1px #fff;
  }

  h2 {
    color: #61718b;
    font-family: 'Inter';
    font-size: 40px;
    font-weight: 300;
  }

  h3 {
    color: #61718b;
    font-family: 'Inter';
    font-size: 22px;
    font-weight: 300;
    span:nth-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      color: #2f3745;
      font-family: 'Poppins';
      font-size: 20px;
      font-weight: 500;
      border-radius: 25px;
      border: 2px solid #fff;
    }
  }

  button:disabled {
    filter: grayscale(1);
    cursor: not-allowed;
  }

  .and-or {
    color: #61718b;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
  }

  .bg-number-category {
    background: #abdfff;
  }

  .bg-number-type {
    background: #a8fff3;
  }

  .bg-number-plataform {
    background: #f7c7ef;
  }

  .lottie > div {
    margin: 0px !important;
  }

  .btn-clear {
    color: #ff1a50;

    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
    border-radius: 40px;
    border: 1px solid #fff;
    backdrop-filter: blur(40px);
    background: rgba(255, 255, 255, 0.15);
    width: 216px;
    height: 61px;
  }

  .btn-result {
    color: #116bff;

    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
    border-radius: 40px;
    border: 1px solid #fff;
    backdrop-filter: blur(40px);
    background: rgba(255, 255, 255, 0.15);
    width: 216px;
    height: 61px;
  }

  .bd-bottom {
    border-bottom: 2px solid rgba(255, 255, 255, 0.85);
  }

  .bg-all {
    border-radius: 40px;
    border: 2px solid #fff;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(40px);
  }

  .bg-left-side {
    border-radius: 24px !important;
    border: 2px solid #fff;
    background: #abdfff;
    height: 107px;

    input {
      height: 100%;
      padding: 20px 30px;
      color: #2f3745;
      font-family: 'Poppins';
      font-size: 24px;
      font-weight: 500;
    }

    input::placeholder {
      color: #2f3745;
      font-family: 'Poppins';
      font-size: 24px;
      font-weight: 500;
    }
  }

  .bg-center-side {
    border-radius: 24px !important;
    border: 2px solid #fff;
    background: #a8fff3;
    height: 107px;

    input {
      height: 100%;
      padding: 20px 30px;
      color: #2f3745;
      font-family: 'Poppins';
      font-size: 24px;
      font-weight: 500;
    }

    input::placeholder {
      color: #2f3745;
      font-family: 'Poppins';
      font-size: 24px;
      font-weight: 500;
    }
  }

  .bg-right-side {
    border-radius: 24px !important;
    border: 2px solid #fff;
    background: #f7c7ef;
    height: 107px;

    input {
      height: 100%;
      padding: 20px 30px;
      color: #2f3745;
      font-family: 'Poppins';
      font-size: 24px;
      font-weight: 500;
    }

    input::placeholder {
      color: #2f3745;
      font-family: 'Poppins';
      font-size: 24px;
      font-weight: 500;
    }
  }

  .bg-result {
    border-radius: 30px;
    border: 2px solid #fff;
    background: rgba(255, 255, 255, 0.4);
    //backdrop-filter: blur(40px);
  }

  .bg-result h2 {
    color: #2f3745;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 300;
  }

  .bg-result p {
    color: #2f3745;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 300;
    line-height: 34px;

    span {
      font-weight: 500;
    }
  }

  .prompt-result-hash {
    color: #2f3745;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
    line-height: 34px;
  }

  .prompt-result-body {
    color: #2f3745;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }

  .bg-result button img {
    width: 20px;
    height: 20px;
  }

  input::placeholder {
    color: #2f3745;
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 500;
  }

  .options {
    top: 120px !important;
    border-radius: 24px !important;
    border: 2px solid #abdfff !important;
    padding: 30px 0px;
    background-color: rgba(255, 255, 255, 0.7) !important;
    max-height: 450px !important;
    box-shadow: 0px 9px 40px 0px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(40px);
    button {
      color: #2f3745 !important;
      border-bottom: none !important;
      padding: 10px 20px;
      font-family: 'Poppins';
      font-size: 22px !important;
      font-weight: 400;
      text-align: start;
      :hover {
        background-color: rgba(255, 255, 255, 1) !important;

        //backdrop-filter: blur(40px);
      }
    }
  }

  .btns-share a {
    border-radius: 18px;
    background: rgba(100, 97, 97, 0.12);

    backdrop-filter: blur(7.5px);
    border: none;
    width: 20%;
    height: 103px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    :hover {
      transform: scale(1.1);
      box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 0.7);
    }
  }

  .video-thumb img {
    /* border-radius: 40px;
    border: 6px solid #fff; */
    /* filter: drop-shadow(66px 74px 120px rgba(14, 1, 24, 0.2)); */
  }

  .h-result {
    max-height: 380px;
    overflow: auto;
  }

  .result {
    color: #2f3745;
    font-family: 'Inter';
    font-size: 30px;
    font-weight: 300;
  }

  .prompt-mg-negative {
    margin-top: -8rem !important;
    padding-bottom: 3rem !important;
  }

  .pad-prompt {
    padding-bottom: 1.5rem !important;
    border-radius: 30px;
    border: 2px solid #fff;

    background: rgba(255, 255, 255, 0.4);

    backdrop-filter: blur(40px);
  }

  div.tableData div {
    background-color: transparent;
  }

  div.rdt_TableCol div {
    color: #2f3745;
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 500;
  }

  div.rdt_TableRow div {
    color: #4e5869;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 400;
  }

  .rdt_TableCol_Sortable span {
    font-size: 13px !important;
  }

  div.tableData .rdt_TableRow:hover div:not(.card-success) {
    background-color: transparent !important;
  }

  div.tableData .rdt_TableRow:hover {
    background-color: rgba(255, 255, 255, 0.4) !important;
    backdrop-filter: blur(40px) !important;
    cursor: pointer;
  }

  .menu-table .search input {
    background-color: transparent !important;
  }

  .btn-page {
    background: rgba(255, 255, 255, 0.8) !important;
    backdrop-filter: blur(40px);
    border: 1px solid #ddd;
    color: #393f48 !important;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
  }

  .btn-first,
  .btn-last {
    color: #4e5869;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
  }

  .showing {
    color: #4e5869;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
  }

  .search input {
    color: #7f8898 !important;

    font-family: 'Inter';
    font-size: 20px;
    font-weight: 400;
  }

  .search > div {
    background: transparent !important;
    border-radius: 0 !important;
  }

  .menu-table .search {
    border-radius: 0 !important;
  }

  .search.border-gray {
    border: none !important;
    border-bottom: solid 2px #fff !important;
    padding: 0 10px;
  }

  .rdt_TableHeadRow > div:nth-child(1),
  .rdt_TableRow > div:nth-child(1) {
    min-width: 900px;
  }

  div.tableData {
    max-width: 100%;
  }

  .copy {
    background: transparent;
    border: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #4e5869;
    width: 100%;
    margin: 0 !important;
    height: 100%;
    img {
      display: none;
    }
  }

  .card-success {
    background: #116bff !important;
    bottom: 33px;
    right: 40%;
  }

  .sphere-sm {
    position: absolute;
    top: 13rem;
    left: -3rem;
    width: 93px;
    height: 93px;
  }

  .sphere-lg {
    position: fixed;
    bottom: 0;
    right: 7%;
    width: 383px;
    height: 243px;
  }

  .label-select {
    color: #4e5869;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
  }

  .select-quantity {
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .select-quantity option {
    appearance: none;
    cursor: pointer;
    text-align: center;
    background-color: #e5eaf8;
  }

  @media screen and (max-width: 1199px) {
    h3 {
      font-size: 19px;
    }
  }

  @media screen and (max-width: 991px) {
    h3 {
      font-size: 22px;
    }
    div.rdt_TableCol div {
      font-size: 20px;
    }

    .copy {
      font-size: 18px;
    }

    .prompt-mg-negative {
      padding-bottom: 1rem !important;
    }

    .result {
      font-size: 24px;
    }

    .h-result {
      max-height: inherit;
      overflow: auto;
    }

    .label-select,
    .select-quantity {
      margin-bottom: 1rem !important;
    }

    .options {
      top: 80px !important;
    }

    .options button {
      font-size: 20px;
    }

    .sphere-sm {
      top: 200px;
      left: 0;
      width: 64px;
      height: 64px;
    }

    .sphere-lg {
      width: 220px;
      height: 330px;
      right: 0;
    }

    .bd-bottom {
      border-bottom: none;
    }

    .bd-bottom-title {
      border-bottom: 2px solid rgba(255, 255, 255, 0.85);
    }

    .btn-clear {
      font-size: 18px;
    }

    .btn-result {
      font-size: 18px;
    }

    .bg-left-side {
      height: auto;
    }

    .bg-left-side,
    .bg-center-side,
    .bg-right-side {
      height: 73px;
    }

    .bg-left-side input {
      font-size: 22px;
    }
    .bg-left-side input,
    .bg-center-side input,
    .bg-right-side input {
      font-size: 22px;
    }

    .rdt_TableHeadRow > div:nth-child(1),
    .rdt_TableRow > div:nth-child(1) {
      min-width: 500px;
    }

    div.tableData div::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #fff !important;
    }

    div.tableData div::-webkit-scrollbar {
      background-color: transparent !important;
    }

    .shadow-left {
      position: absolute;
      top: 2px;
      left: 2px;
      height: 100%;
      width: 50px;
      border-radius: 30px 0px 0px 30px;
      background: linear-gradient(
        90deg,
        #dbdff2 10%,
        rgba(236, 233, 242, 0) 100%
      );
    }

    .shadow-right {
      position: absolute;
      top: 2px;
      right: 2px;
      height: 100%;
      width: 50px;
      border-radius: 0px 30px 30px 0px;
      background: linear-gradient(
        270deg,
        #dbdff2 10%,
        rgba(236, 233, 242, 0) 100%
      );
    }
  }

  @media screen and (min-width: 576px) {
    .video-thumb img {
      /* border: 10px solid #fff; */
    }

    .bg-result h2 {
      font-size: 28px;
    }

    .prompt-result-hash {
      font-size: 26px;
    }

    .prompt-result-body {
      font-size: 24px;
    }
  }
  @media screen and (min-width: 992px) {
    .btns-share a {
      height: 73px;
    }
    .zoom {
      zoom: 0.7;
    }
    .container {
      max-width: 1380px !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .btns-share a {
      height: 85px;
    }
    .container {
      max-width: 1600px !important;
    }
  }

  @media screen and (min-width: 1300px) {
    .container {
      max-width: 1720px !important;
    }
  }

  @media screen and (min-width: 1400px) {
    .btns-share a {
      width: 103px;
      height: 103px;
    }
    .zoom {
      zoom: 0.75;
    }
  }

  @media screen and (min-width: 1600px) {
    .zoom {
      zoom: 0.85;
    }
  }

  @media screen and (min-width: 1800px) {
    .zoom {
      zoom: 1;
    }
  }
`;
export const CopyAlert = styled.div<ICopyAlertProps>`
  transition-duration: 0.3s;
  position: fixed;
  right: ${(props) => (props.show ? '25px' : '-150px')};
  bottom: 50px;
  z-index: 1400;
  color: #fff;
  background-color: #116bff;
  border-color: 2px solid #fff;
`;
