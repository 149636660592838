import styled, { css } from 'styled-components';

import { Modal as ModalComponent } from 'react-bootstrap';

interface IGiftInputProps {
  isError: boolean;
}

export const Container = styled.div`
  height: 100%;
`;

export const ModalGiftCard = styled(ModalComponent)`
  label {
    color: var(--grayImmensity);
    font-weight: 400;
    font-size: 14px;
  }

  form {
    button {
      background-color: var(--greyMineral);
      color: var(--blizzard);
      border-radius: 10px;
      border: 1px solid var(--greyMineral);

      :hover {
        background-color: var(--greyMineralLighten);
        transition: 0.5s;
      }
    }
  }

  .height-gift {
    height: 22px;
  }

  .border-input {
    border-radius: 10px;
    border: 1.5px solid var(--closedGray);
  }

  @media screen and (max-width: 350px) {
    .w-80-350 {
      width: 80% !important;
    }
  }
`;

export const GiftInput = styled.div<IGiftInputProps>`
  span {
    position: absolute;
    padding: 0 10px;
  }

  ${(props) =>
    props.isError
      ? css`
          border-color: var(--error) !important;
        `
      : ''}
`;
