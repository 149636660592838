import styled from 'styled-components';

interface ISeparatorProps {
  noPadding: boolean;
  fullSize: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;

  @media (min-width: 768px) and (max-width: 1199px) {
    .bg-mobile {
      background: #f3f2f2;
    }
  }

  @media screen and (min-width: 992px) {
    .separator-container {
      width: calc(100% - 90px);
    }
  }

  @media screen and (min-width: 1200px) {
    .height-xl {
      height: 100vh;
      overflow: auto;

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: transparent;
      }
    }
  }

  @media screen and (max-width: 991px) {
    display: block;
  }

  @media screen and (max-width: 767px) {
    .bg-footer-mob {
      background: #f3f2f2;
    }
  }
`;

export const HeaderSpacer = styled.div`
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 991px) {
    width: 100%;
    height: 60px;
    justify-content: flex-start;
  }
`;

export const Separator = styled.div<ISeparatorProps>`
  background-color: #f9f9f9;
  width: ${(props) => (props.fullSize ? '100%' : 'calc(100% - 298px)')};
  padding: 20px;

  @media screen and (min-width: 1400px) {
    width: ${(props) => (props.fullSize ? '100%' : 'calc(100% - 322px)')};
  }

  @media screen and (max-width: 1199px) {
    width: 100%;
    padding: ${(props) => (props.noPadding ? '0px' : '8px')};
  }

  @media screen and (max-width: 575px) {
    padding: 15px;
  }
`;

export const FooterSpacer = styled.div`
  background: #fff;

  @media screen and (max-width: 1199px) {
    width: 100%;
    background: #fff;
  }

  @media screen and (min-width: 1200px) {
    min-width: 270px;
    max-width: 298px;
  }

  @media screen and (min-width: 1400px) {
    min-width: 308px;
    max-width: 322px;
  }
`;
