import styled from 'styled-components';

interface IContainer {
  disabled?: boolean;
  active?: boolean;
}

export const Container = styled.div<IContainer>`
  display: block;
  border: ${(props) =>
    props.active ? 'solid 2px #e0e0e0' : 'solid 2px transparent'};
  background: ${(props) =>
    props.active ? '#e0e0e0' : 'transparent'}!important;
  text-align: left;
  border-radius: 15px;
  padding: 1px;
  background-image: none;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-image-slice: 1;
  //transition-duration: 0.3s;

  :hover {
    text-decoration: none;
    border: 2px solid #e0e0e0;
    background: #e0e0e0 !important;
    .tip-block {
      opacity: 1;
      display: flex;
    }
  }

  img {
    width: 24px;
    height: 24px;
  }

  p {
    color: #242424;
    font-size: 14px;
  }

  .tip-block {
    justify-content: space-evenly;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    top: -5px;
    left: 0px;
    background: #ffffff;
    border: 1px solid #dedede;
    box-shadow: 4px 15px 25px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    //width: 280px;
    transition: all 0.3s ease;
    opacity: 0;
    p {
      font-weight: 300;
      font-size: 12px;
      line-height: 134.02%;
      color: #021027;
      max-width: 285px;
      span {
        font-weight: 500;
      }
    }
  }

  .duration {
    font-size: 12px;
    color: #626262;
  }

  .opacity-0 {
    opacity: 0;
  }

  .opacity-05 {
    opacity: 0.5;
  }

  .opacity-1 {
    opacity: 1;
  }

  .line-height {
    line-height: 1.7;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 82%;
  }

  a {
    text-decoration: none;
    pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
  }

  > button {
    border: none !important;
    background-color: transparent !important;
    pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};

    .completed {
      transform: rotateZ(0) !important;
    }
  }
`;
