import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  background: #f9f9f9;
  border-radius: 20px;

  .skeleton {
    background-color: #f1f1f1 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 0) 10%,
        rgba(227, 227, 227, 0.2) 20%,
        rgba(227, 227, 227, 0.5) 40%,
        rgba(227, 227, 227, 0.8) 50%,
        rgba(227, 227, 227, 0.2) 80%,
        rgba(227, 227, 227, 0) 90%,
        rgba(227, 227, 227, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  .TitleBox {
    color: #303030;
    display: table;
    margin: 0 auto;
  }

  .ValueBox {
    display: table;
    margin: 0 auto;
    color: #021027;
    font-weight: bolder;
    font-size: 30px;
    margin-bottom: 5%;
  }

  .TimeBox {
    color: #828282;
  }

  .MonthBox {
    margin-top: 1%;
    margin-bottom: 5%;
  }

  .ItemMonthBox {
    background-color: #20202000 !important;
    border: none;
    padding: 2px;
    background-image: linear-gradient(180deg, #24252600, #24252600),
      linear-gradient(269.95deg, #e323ff00 0.02%, #79d1f800 97.45%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    color: #fff;
  }

  .ItemMonthBox:hover,
  .ItemMonthBox.Active {
    background-color: #202020 !important;
    border: 1px solid #828282 !important;
    padding: 2px;
    color: #fff;
  }

  .WithDrawBox {
    background: #ffffff;
    box-shadow: -2px 5px 25px rgba(0, 0, 0, 0.03);
    border-radius: 20px;
  }

  .WithDrawBox .TitleWithDrawBox {
    color: #8c8c8c;
  }

  .WithDrawBox .ValueWithDrawBox {
    color: #242526;
    font-weight: bolder;
    font-size: 20px;
  }

  .border-gold {
    background-color: #202020 !important;
    border: none;
    padding: 1px;
    background-image: linear-gradient(180deg, #242526, #242526),
      linear-gradient(
        143.88deg,
        #825511 -9.17%,
        #f3bf5c 108.32%,
        #f3bf5c 108.32%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    color: #fff;
  }

  .ButtonWithDrawBox {
    border-radius: 20px;
    background: #116bff;
    color: #f5f5f5;
    font-size: 16px;
    font-weight: 700;
    height: 64px;
    padding: 0.5rem 1.5rem;
    transition-duration: 0.2s;
    opacity: 1;

    :hover {
      opacity: 0.9;
    }

    :disabled {
      background: #116bff55;
      cursor: default;
      cursor: not-allowed;
    }
  }

  .WithDrawBox .ButtonBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .WithDrawBox .ButtonBox .ButtonWithDrawBox {
  }

  .ItemTimeBox {
    transition-duration: 0.3s;
    color: #8c8c8c;
  }

  .ItemTimeBox:hover,
  .ItemTimeBox:focus,
  .ItemTimeBox.Active {
    color: #303030;
    font-weight: 700;
  }
`;

export const Modal = styled(ModalComponent)`
  backdrop-filter: blur(2.5px);
  background-color: rgba(0, 0, 0, 60%);
  label span {
    font-weight: 400;
    font-size: 20px;

    color: #5d5b5b;
  }

  .modal-content {
    background: #f9f9f9 !important;
    border-radius: 35px;
    position: relative;

    .modal-title {
      font-weight: 600;
      font-size: 22px;
      color: #021027;
    }

    .modal-cache-title {
      color: #116bff;

      text-align: center;
      font-family: 'Quicksand';
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
      letter-spacing: 0.96px;
    }

    .cache-subtitle {
      color: #021027;

      text-align: center;
      font-family: 'Archivo';
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 118%;
    }

    .btn-clear-cache {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 18px;
      background: #116bff;
      width: 100%;
      height: 70px;
      padding: 18px 15px;
      color: #fff;
      text-align: center;
      font-family: 'Inter';
      font-size: 18px;
      font-weight: 700;
      line-height: normal;
    }

    .info-tooltip {
      position: absolute;
      font-size: 14px !important;
      width: 310px;
      line-height: 24px !important;
      text-align: center !important;
      bottom: 40px;
      left: -149px;
      background: #f9f9f9 !important;
      border-radius: 16px;
      border: 1px solid #116bff;
      padding: 10px;
      opacity: 0;
      visibility: hidden;
      transition-duration: 0.3s;

      ::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        bottom: -9px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #f9f9f9;
        border-right: 0.5px solid #116bff;
        border-bottom: 0.5px solid #116bff;
        transform: rotateZ(45deg);
      }
    }

    .info-tooltip.show {
      opacity: 1;
      visibility: visible;
    }

    .bg-values {
      border-radius: 12px;
      background: #f3f3f5;
      span:nth-child(1) {
        color: #021027;
        font-family: 'Archivo';
        font-size: 20px;
        font-weight: 300;
        line-height: 26px;
        width: 220px;
      }
      span:nth-child(2) {
        color: #021027;

        text-align: right;
        font-family: 'Archivo';
        font-size: 24px;
        font-weight: 400;
        line-height: 26px;
      }
    }

    .fw-600 {
      font-weight: 600 !important;
    }

    .btn-close {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .btn-cancel {
      color: #ff1a50;

      text-align: center;
      font-family: 'Inter';
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btn-save {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #ffffff;
      background: #116bff;
      border-radius: 20px;
      transition-duration: 0.3s;
      padding: 20px;
      width: 370px;
      :hover {
        opacity: 0.8;
      }
    }

    .create {
      padding: 20px 30px;
    }
  }

  @media screen and (min-width: 992px) {
    zoom: 0.7;
    .modal-xl {
      max-width: unset;
      width: 1066px;
    }

    .btn-clear-cache {
      font-size: 24px;
    }

    .modal-content {
      .modal-title {
        font-size: 48px;
      }
      .bg-values {
        span:nth-child(1) {
          line-height: 32px;
          width: auto;
        }
        span:nth-child(2) {
          line-height: 32px;
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    zoom: 0.8;
  }

  @media screen and (min-width: 1600px) {
    zoom: 0.9;
  }

  @media screen and (min-width: 1700px) {
    zoom: 1;
  }
`;

export const ModalPayout = styled(ModalComponent)`
  label span {
    font-weight: 400;
    font-size: 20px;

    color: #5d5b5b;
  }

  .modal-content {
    background: #f9f9f9 !important;
    border-radius: 35px;
    position: relative;

    .modal-title {
      font-weight: 600;
      font-size: 24px;
      color: #021027;
    }

    .modal-cache-title {
      color: #116bff;
      text-align: center;
      font-family: 'Quicksand';
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: 45px;
    }

    .cache-subtitle {
      color: #151517;
      text-align: center;
      font-family: 'Archivo';
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
    }

    .cache-content {
      color: #585656;
      text-align: center;
      font-family: 'Archivo';
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 28px;
      display: block;
    }

    .btn-clear-cache {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 18px;
      background: #116bff;
      width: 100%;
      height: 70px;
      padding: 18px 10px;
      flex-shrink: 0;
      color: #fff;
      text-align: center;
      font-family: 'Archivo';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      text-transform: uppercase;
    }

    .input {
      border: 1px solid #d0d0d0;
      border-radius: 20px;
      height: 72px !important;
      background: transparent;
      font-weight: 300;
      color: #151517;
      padding: 5px 20px;

      input::placeholder {
        color: #8c8c8c;
        font-weight: 300;
        font-size: 14px;
      }
    }

    .modal-close {
      color: #021027;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btn-save {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #ffffff;
      background: #116bff;
      border-radius: 20px;
      transition-duration: 0.3s;
      padding: 20px;
      width: 370px;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-cancel {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #dadada;
      background: #021027;
      border-radius: 20px;
      padding: 20px;
      width: 142px;
    }

    .create {
      padding: 20px 30px;
    }
  }

  .animation div:nth-child(1) {
    width: 180px !important;
    height: 180px !important;
    pointer-events: none;
  }

  .animation.almost div:nth-child(1) {
    width: 110px !important;
    height: 110px !important;
  }

  @media screen and (min-width: 992px) {
    zoom: 0.7;
    .modal-xl {
      max-width: unset;
      width: 870px;
    }

    .modal-content {
      .modal-cache-title {
        font-size: 44px;
      }

      .cache-subtitle {
        font-size: 28px;
        line-height: 45px;
      }

      .cache-content {
        font-size: 20px;
      }

      .btn-clear-cache {
        font-size: 30px;
        padding: 18px 28px;
      }
    }

    .animation div:nth-child(1) {
      width: 281px !important;
      height: 281px !important;
    }

    .animation.almost div:nth-child(1) {
      width: 164px !important;
      height: 164px !important;
    }
  }

  @media screen and (min-width: 1400px) {
    zoom: 0.8;
  }

  @media screen and (min-width: 1600px) {
    zoom: 0.9;
  }

  @media screen and (min-width: 1700px) {
    zoom: 1;
  }
`;

export const Loading = styled.div`
  .spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: radial-gradient(farthest-side, #fff 94%, #0000) top/9px 9px
        no-repeat,
      conic-gradient(#0000 30%, #fff);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 9px),
      #000 0
    );
    animation: spinner-c7wet2 1s infinite linear;
  }

  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
