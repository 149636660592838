import React, { useCallback, useEffect, useRef } from 'react';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import { VideoPlayer } from './styles';

interface IPlayerProps {
  src: string;
  id?: string;
  className?: string;
  thumbnail?: string;
  onPlay?(): void;
  onPause?(): void;
  callChangeTimeBySeconds?: number;
  onChangeTime?(time: number): void;
  onComplete?(): void;
}

const PlayerPostEase: React.FC<IPlayerProps> = ({
  src,
  id,
  className,
  thumbnail,
  onPlay,
  onPause,
  onComplete,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayVideo = useCallback(() => {
    if (onPlay) {
      onPlay();
    }
  }, [onPlay]);

  const handlePauseVideo = useCallback(() => {
    if (onPause) {
      onPause();
    }
  }, [onPause]);

  const handleCompleteVideo = useCallback(() => {
    if (onComplete) {
      onComplete();
    }
  }, [onComplete]);

  useEffect(() => {
    const options: VideoJsPlayerOptions = {
      fluid: true,
      preload: 'auto',
      html5: {
        hls: {
          enableLowInitialPlaylist: true,
          smoothQualityChange: true,
          overrideNative: true,
        },
      },

      autoplay: false,
      controls: true,
      sources: [
        {
          src,
          type: 'video/mp4',
        },
      ],
      // ended: handleCompleteVideo,
    };

    if (videoRef.current) {
      const player = videojs(videoRef.current, options);
      // eslint-disable-next-line no-underscore-dangle
      // player.updateSourceCaches_(src);
      player.on('ended', handleCompleteVideo);

      player.on('play', () => {
        const allPlayers = videojs.getPlayers();
        Object.keys(allPlayers).forEach((playerId) => {
          if (playerId !== player.id()) {
            allPlayers[playerId].pause();
          }
        });
      });

      return () => {
        if (player) {
          player.dispose();
        }
      };
    }
    return () => {
      console.log('ok');
    };
  }, [handleCompleteVideo, src]);

  return (
    <div data-vjs-player>
      <VideoPlayer
        ref={videoRef}
        onPlay={handlePlayVideo}
        onPause={handlePauseVideo}
        id={id}
        className={`${className} video-js`}
        poster={thumbnail}
        src={src}
        playsInline
      >
        Sorry, your browser doesn't support embedded videos.
      </VideoPlayer>
    </div>
  );
};

export default PlayerPostEase;
