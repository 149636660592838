import styled, { css } from 'styled-components';

interface ILocation {
  location: string;
}

export const Container = styled.div`
  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;
    height: 102px;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
export const Action = styled.div`
  background: #fff;
  border-radius: 20px;
`;

export const Options = styled.div`
  border-radius: 15px;
  padding: 1px;
  border: 1px solid #dedede;
  background: #f9f9f9;
  box-shadow: 0px 4px 8px transparent;

  button:not(.options-button) {
    color: #8c8c8c;
    font-weight: 400;
    border-bottom: 1px solid transparent !important;
    padding: 5px 0;
    font-size: 16px;

    :hover {
      color: #021027;
      border-color: #021027 !important;
      font-weight: 700 !important;
    }
  }

  button.active {
    color: #021027;
    border-color: #021027 !important;
    font-weight: 700 !important;
  }

  @media screen and (min-width: 992px) {
    button:not(.options-button) {
      font-size: 12px;
    }
  }

  @media screen and (min-width: 1200px) {
    button:not(.options-button) {
      font-size: 14px;
    }
  }

  @media screen and (min-width: 1400px) {
    button:not(.options-button) {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 991px) {
    .options-button {
      transition-duration: 0.3s;
      background: var(--midnight);
      border-radius: 20px;

      p {
        color: var(--ultraWhite);
        font-weight: 700 !important;
      }

      svg {
        transition-duration: 0.3s;
        color: var(--ultraWhite);
      }
    }

    .options {
      transition-duration: 0.3s;
      background: var(--coal);
      color: var(--technologicalGray);
      border-radius: 0 0 20px 20px;
      height: 0;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;

      .bg-btn {
        background-color: transparent !important;
        border: none;
        border-radius: 0 !important;
        width: 100%;

        + .bg-btn {
          border-top: solid 1px var(--blizzardOpacity) !important;
        }
      }
    }

    .options-box.active {
      .options-button {
        border-radius: 20px 20px 0px 0px;

        svg {
          transform: rotate(180deg);
        }
      }

      .options {
        height: 200px !important;
        padding: 0;
        overflow: none;

        ::-webkit-scrollbar {
          background-color: var(--coal);
          width: 5px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background: var(--asphaltGray);
        }
      }
    }

    button:hover,
    button.active {
      border-bottom: none !important;
    }
  }

  @media screen and (max-width: 767px) {
    .w-33-mobile {
      width: 33.333%;
    }
  }
`;

export const Content = styled.div<ILocation>`
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.5rem;

  ${(props) => {
    if (props.location === '/lessons/welcome') {
      return css`
        height: 535px;
      `;
    }

    if (
      props.location.includes(
        'the-difference-between-traditional-and-online-businesses'
      ) ||
      props.location.includes('the-truth-about-money') ||
      props.location.includes('time-management') ||
      props.location.includes('paradigm-shift') ||
      props.location.includes('setup-your-ai-connector-bot')
    ) {
      return css`
        height: 590px;
      `;
    }
    if (
      props.location.includes('setting-up-your-offers') ||
      props.location.includes('setting-up-the-profiti-offer')
    ) {
      return css`
        height: 660px;
      `;
    }

    return css`
      height: 725px;
    `;
  }}

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #c0c0c0;
  }
  p {
    color: var(--graniteGray);
  }

  h2 {
    color: var(--blizzard);
    font-size: 1.5rem;
  }

  input {
    background-color: transparent;
    border: 1px solid var(--deepGrey);
    border-radius: 35px;
  }

  .about,
  .next-step,
  .special-note {
    h2 {
      font-weight: 700;
      font-size: 26px;
      line-height: 31px;
      color: #242424;
      margin-top: 24px;
      margin-bottom: 27px;
    }

    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      color: #242424;
      margin-bottom: 30px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 172.52%;
      color: #242526;
      margin-bottom: 30px;
    }
  }

  .img-height {
    height: 150px;
  }

  .send {
    right: 38px;
    top: 12px;
  }

  .color-light {
    color: var(--graniteGray);
  }

  .comment {
    background-color: var(--closedGray);
    border-radius: 20px;
    min-width: 170px;

    p:nth-child(1) {
      color: var(--ultraWhite);
    }

    p:nth-child(2) {
      color: var(--technologicalGray);
    }
  }

  .comment-actions {
    min-width: 170px;
  }

  .comment-button {
    background-color: transparent;
    border: none;
    color: var(--deepGrey);
  }

  .next-step {
    .coach {
      padding: 2px;
      background-color: var(--asphaltGray);
      border-radius: 20px;

      .coach-avatar {
        padding: 3px;
        background-image: linear-gradient(0deg, var(--coal), var(--coal)),
          radial-gradient(
            circle at top left,
            var(--blizzardOpacity),
            var(--blizzardOpacity)
          );
        background-origin: border-box;
        background-clip: content-box, border-box;
        border-image-slice: 1;
        border-radius: 60px;
      }
    }
  }

  .discuss-img {
    width: 70px;
    height: 70px;
  }

  .spacer {
    width: 70px;
  }

  .special-note {
    padding: 3px;
  }

  .downloads {
    img {
      width: 100%;
      border-radius: 10px;
    }

    .bg-shadow {
      border-radius: 10px;
      background: var(--midnight) 5e;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 10px;
      width: calc(100% - 30px);

      p {
        color: var(--ultraWhite);
      }
    }
  }

  .box {
    border-radius: 15px;
    padding: 1px;
    background-image: linear-gradient(
        0deg,
        var(--asphaltGray),
        var(--asphaltGray)
      ),
      linear-gradient(180deg, var(--greyMineral) 0%, var(--greyMineral) 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    box-shadow: 0px 4px 8px transparent;

    img {
      border-radius: 15px;
    }

    p {
      color: var(--blizzard);
    }

    :hover {
      text-decoration: none !important;
      p {
        color: var(--blizzardLighten);
      }
    }
  }

  @media screen and (max-width: 1199px) {
    height: 345px;
  }

  @media screen and (max-width: 991px) {
    .discuss-img {
      width: 50px;
      height: 50px;
    }

    .spacer {
      width: 50px;
    }
  }

  @media screen and (max-width: 575px) {
    .img-height {
      height: 220px;
    }
  }

  @media screen and (max-width: 490px) {
    .img-height {
      height: 200px;
    }
  }
`;
