import React from 'react';
import { Switch, Route as RouteComponent } from 'react-router-dom';

import Route from './Route';

import Waitlist from '~/pages/Waitlist';
import CheckLogin from '~/pages/CheckLogin';
import SignIn from '~/pages/SignIn';
import Passcode from '~/pages/Passcode';
import ForgotPassword from '~/pages/ForgotPassword';
import Home from '~/pages/Home';
import CommissionHistory from '~/pages/CommissionHistory';
import Trainings from '~/pages/Trainings/List';
import Training from '~/pages/Training';
import MaterialPromocional from '~/pages/MaterialPromocional';
import Referrals from '~/pages/Referrals';
import TeamView from '~/pages/Referrals/TeamView';
import Wallet from '~/pages/Wallet';
import Profile from '~/pages/Profile';
import LiveVonage from '~/pages/Live/Vonage';
import Lesson from '~/pages/Lesson';
import Notifications from '~/pages/Notifications';
import AutoLogin from '~/pages/AutoLogin';
import PromptEase from '~/pages/PromptEase';
import PromptEasePro from '~/pages/PromptEasePro';
import PostEase from '~/pages/PostEase';
import Leaderboard from '~/pages/Leaderboard/indes';
import Traffic from '~/pages/Traffic';

const routes: React.FC = () => {
  return (
    <Switch>
      {/* <RouteComponent
        path={`${process.env.PUBLIC_URL}/`}
        component={() => (
          <div className="d-flex align-items-center justify-content-center w-100 vh-100">
            <p className="h3 text-center">
              Sorry for the inconvenience but we're currently undergoing
              maintenance
            </p>
          </div>
        )}
      /> */}
      <Route
        path={`${process.env.PUBLIC_URL}/waitlist/:waitlist_id`}
        component={Waitlist}
      />
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={SignIn} />
      <Route
        path={`${process.env.PUBLIC_URL}/auto-login/:token`}
        exact
        component={AutoLogin}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/passcode`}
        exact
        component={Passcode}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/forgot-password`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/forgot-password/:slug`}
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        component={Home}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/commission-history`}
        exact
        component={CommissionHistory}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/trainings`}
        exact
        component={Trainings}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/trainings/category/:slug`}
        exact
        component={Trainings}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/trainings/:slug`}
        exact
        component={Training}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/traffic/:slug`}
        exact
        component={Traffic}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/promotional-material`}
        exact
        component={MaterialPromocional}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/my-referrals`}
        exact
        component={Referrals}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/my-referrals/team-view/:slug`}
        exact
        component={TeamView}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/wallet/:slug`}
        exact
        component={Wallet}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/check-login/:token`}
        exact
        component={CheckLogin}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/profile`}
        isPrivate
        component={Profile}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/live/:roomID`}
        exact
        isPrivate
        component={LiveVonage}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/lessons`}
        exact
        isPrivate
        component={Lesson}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/lessons/:slug`}
        exact
        isPrivate
        component={Lesson}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/lessons/:slug/:slugLesson`}
        exact
        isPrivate
        component={Lesson}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/notifications`}
        exact
        isPrivate
        component={Notifications}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/prompt-ease`}
        exact
        isPrivate
        component={PromptEase}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/prompt-ease-pro`}
        exact
        isPrivate
        component={PromptEasePro}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/post-ease`}
        exact
        isPrivate
        component={PostEase}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/leaderboard/:slug`}
        exact
        isPrivate
        component={Leaderboard}
      />
    </Switch>
  );
};

export default routes;
