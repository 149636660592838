import React, { createContext, useState, useContext } from 'react';

interface INextStep {
  slug: string;
}

interface NextStepContextData {
  nextStep: INextStep;
  setNextStep: React.Dispatch<React.SetStateAction<INextStep>>;
}

export const NextStepContext = createContext<NextStepContextData>(
  {} as NextStepContextData
);

export const NextStepProvider: React.FC = ({ children }) => {
  const [nextStep, setNextStep] = useState({} as INextStep);

  return (
    <NextStepContext.Provider
      value={{
        nextStep,
        setNextStep,
      }}
    >
      {children}
    </NextStepContext.Provider>
  );
};

export function useNextStep(): NextStepContextData {
  const context = useContext(NextStepContext);

  if (!context) {
    throw new Error('useNextStep must be used within an NextStepProvider');
  }

  return context;
}
