import styled from 'styled-components';

interface noteProps {
  textareaSelected: boolean;
}

export const Container = styled.div<noteProps>`
  background: #ffffff;
  border-radius: 15px;

  p {
    color: #303030;
    font-size: 0.8rem;
  }

  button {
    cursor: text !important;
  }

  textarea {
    background: #ffffff;
    border: none;
    color: #303030;
    min-height: ${(props) => (props.textareaSelected ? '100%' : '92.5%')};

    :focus {
      background: #ffffff;
      box-shadow: none;
      color: #606060;
    }
  }

  @media screen and (max-width: 991px) {
    min-height: ${(props) => (props.textareaSelected ? '210px' : '200px')};
  }
`;
