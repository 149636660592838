/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';

import En from './En';
import Es from './Es';
import Default from './Default';
import { useAuth } from '~/hooks/Auth';

const routes: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();

  useEffect(() => {
    if (location.pathname.includes('auto-login') && !!user) {
      const path = location.search
        .replace('?', '')
        .replace('&', '')
        .split('page=')
        .join('/');
      if (path) {
        history.push(`${process.env.PUBLIC_URL}${path}`);
      }
    }
  }, [history, location, user]);

  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/en`} component={En} />
      <Route path={`${process.env.PUBLIC_URL}/es`} component={Es} />
      <Route path={`${process.env.PUBLIC_URL}/`} component={Default} />
    </Switch>
  );
};

export default routes;
