import React, { useCallback, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { IoIosClose } from 'react-icons/io';
import { io } from 'socket.io-client';

import { formatPrice } from '~/utils/format';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

import { Container, Loading, Modal, ModalPayout } from './styles';
import { useLanguage } from '~/hooks/Language';
import withdraw from '~/assets/animations/withdraw.json';
import payout from '~/assets/animations/payout.json';
import withdrawTax from '~/assets/animations/withdraw-tax.json';
import success from '~/assets/animations/success.json';

interface IUser {
  id: string;
}

interface ICommission {
  id: string;
  earning: string;
  amount_paid: string;
  created_at: string;
}

interface ITransaction {
  id: string;
  wallet_id: string;
  commission_id: string;
  amount: string;
  type: string;
  member: string;
  status: string;
  created_at: string;
}

const socket = io(process.env.REACT_APP_API_URL as string, {
  reconnection: true,
});

const MyEarnings: React.FC = () => {
  const { setWalletPrice, user, updateUser } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showModalTax, setShowModalTax] = useState(false);
  const [allGains, setAllGains] = useState(0);
  const [availableWithdrawal, setAvailableWithdrawal] = useState(0);
  const [taxFee, setTaxFee] = useState(0);
  const { language } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  const [selectEarnings, setSelectEarnings] = useState('lifetime');
  const [showComplete, setShowComplete] = useState(false);
  const [showSuccefully, setShowSuccefully] = useState(false);
  const [showFinalized, setShowFinalized] = useState(false);
  const [selectedTooltip, setSelectedTooltip] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleLoadAllGains = useCallback(async (typeDate) => {
    const response = await api.get('commissions', {
      params: {
        typeDate,
      },
    });
    const gains = response.data.reduce(
      (acumulador: number, currentValue: ICommission) => {
        return acumulador + parseFloat(currentValue.earning);
      },
      0
    );

    setAllGains(gains);
  }, []);

  useEffect(() => {
    handleLoadAllGains(selectEarnings);
  }, [
    handleLoadAllGains,
    selectEarnings,
    user.affiliate.wallet.request_amount,
  ]);

  useEffect(() => {
    socket.on(`awaitingReview`, (userData: IUser) => {
      if (userData.id === user.id) {
        setShowComplete(false);
        setShowModal(false);
        setShowSuccefully(true);
      }
    });

    //

    socket.on(`active`, (userData: IUser) => {
      if (userData.id === user.id) {
        setShowFinalized(true);
        updateUser({
          ...user,
          affiliate: {
            ...user.affiliate,
            wallet: {
              ...user.affiliate.wallet,
              request_amount: availableWithdrawal.toString(),
              status: 'Ready to pay',
            },
          },
        });
        setShowSuccefully(false);
        setShowModalTax(false);
        setShowModal(false);
        setShowComplete(false);
        setLoadingWithdraw(false);
      }
    });
  }, [availableWithdrawal, updateUser, user, user.id]);

  useEffect(() => {
    api
      .get<ITransaction[]>(
        `wallets-history/wallets/${user.affiliate.wallet.id}`
      )
      .then(async (response) => {
        const gains = response.data.reduce((acumulador, currentValue) => {
          if (
            currentValue.status === 'Withdraw' ||
            currentValue.status === 'Processing' ||
            currentValue.status === 'Purchased'
          ) {
            return acumulador - parseFloat(currentValue.amount);
          }

          if (
            currentValue.status === 'Refund' ||
            currentValue.status === 'Pending' ||
            currentValue.status === 'Paid' ||
            currentValue.status === 'Completed'
          ) {
            return acumulador + 0;
          }

          return acumulador + parseFloat(currentValue.amount);
        }, 0);

        setAvailableWithdrawal(gains);
      });
  }, [
    setWalletPrice,
    user.affiliate.wallet.id,
    user.affiliate.wallet.request_amount,
  ]);

  const handleClickWithdraw = useCallback(() => {
    setTaxFee(availableWithdrawal * 0.03);
    setShowModal(true);
  }, [availableWithdrawal]);

  const handleClickConfirmWithdraw = useCallback(async () => {
    setLoadingWithdraw(true);
    const response = await api.post('wallets/withdraw');

    if (response.data) {
      setShowModal(false);
      setShowComplete(true);
      setLoadingWithdraw(false);
    } else {
      setShowFinalized(true);
      updateUser({
        ...user,
        affiliate: {
          ...user.affiliate,
          wallet: {
            ...user.affiliate.wallet,
            request_amount: availableWithdrawal.toString(),
            status: 'Ready to pay',
          },
        },
      });
      setShowSuccefully(false);
      setShowModalTax(false);
      setShowModal(false);
      setShowComplete(false);
      setLoadingWithdraw(false);
    }
  }, [availableWithdrawal, updateUser, user]);

  const handleClickBackToDashboard = useCallback(() => {
    updateUser({
      ...user,
      affiliate: {
        ...user.affiliate,
        wallet: {
          ...user.affiliate.wallet,
          request_amount: availableWithdrawal.toString(),
          status: 'Ready to pay',
        },
      },
    });

    setShowSuccefully(false);
  }, [availableWithdrawal, updateUser, user]);

  const handleSelectFilter = useCallback(async (filter) => {
    setSelectEarnings(filter);
  }, []);

  const handleMouseEnter = useCallback((tooltipSelected) => {
    setSelectedTooltip(tooltipSelected);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setSelectedTooltip('');
  }, []);

  return (
    <Container className="p-3 py-5 py-xl-0 h-100">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <h5 className="TitleBox my-4 font-weight-600">
            {language.wallet.h5}
          </h5>
        </div>
        <div className="col-lg-12">
          <span
            className={`${loading && 'skeleton px-2'} ValueBox font-weight-600`}
          >
            {formatPrice(allGains)}
          </span>
        </div>

        <div className="col-lg-12 TimeBox my-3">
          <div className="d-flex justify-content-around">
            <button
              type="button"
              onClick={() => handleSelectFilter('today')}
              className={`${
                selectEarnings === 'today' && 'Active'
              } border-0 bg-transparent ItemTimeBox`}
            >
              {language.wallet.button_1}
            </button>
            <button
              type="button"
              onClick={() => handleSelectFilter('month')}
              className={`${
                selectEarnings === 'month' && 'Active'
              } border-0 bg-transparent ItemTimeBox`}
            >
              {language.wallet.button_2}
            </button>
            <button
              type="button"
              onClick={() => handleSelectFilter('year')}
              className={`${
                selectEarnings === 'year' && 'Active'
              } border-0 bg-transparent ItemTimeBox`}
            >
              {language.wallet.button_3}
            </button>
            <button
              type="button"
              onClick={() => handleSelectFilter('lifetime')}
              className={`${
                selectEarnings === 'lifetime' && 'Active'
              } border-0 bg-transparent ItemTimeBox`}
            >
              {language.wallet.button_4}
            </button>
          </div>
        </div>

        <div className="col-10 mt-5 mb-4 p-3 py-4 WithDrawBox">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="row">
                <div className="col-12 mb-2 TitleWithDrawBox mb-2">
                  <small>{language.wallet.small_1}</small>
                </div>
              </div>
              <div className="ValueWithDrawBox my-2 my-sm-0">
                <span className={`${loading && 'skeleton px-2'} h4`}>
                  {formatPrice(availableWithdrawal)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-10 pb-xl-3 mb-xl-4 px-0 ButtonBox">
          <button
            className="w-100 border-0 ButtonWithDrawBox"
            type="button"
            onClick={handleClickWithdraw}
            disabled={
              user.id !== '01038f22-4558-41e3-851c-cb269e62be29' &&
              (user.affiliate.wallet.status === 'Ready to pay' ||
                availableWithdrawal < 50)
            }
          >
            {language.wallet.button_5}
          </button>
        </div>
      </div>
      <Modal
        onHide={() => setShowModal(false)}
        show={showModal}
        size="xl"
        centered
      >
        <button
          onClick={() => setShowModal(false)}
          type="button"
          className="border-0 bg-transparent btn-close"
        >
          <IoIosClose color="#021027" size={30} />
        </button>
        <Modal.Header className="justify-content-center border-0 px-3 px-sm-5 pb-0 pb-0 pt-4">
          <div className="pe-none mt-4 d-flex flex-column align-items-center overflow-hidden w-100 px-sm-4">
            <Lottie
              options={{
                animationData: withdraw,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              width={180}
              height={180}
            />
            <Modal.Title className="modal-cache-title mt-lg-4">
              Confirm withdraw
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 px-3 px-sm-5">
          <p className="cache-subtitle mb-4">Review details below:</p>
          <div className="bg-values align-items-center py-3 px-3 px-sm-4 px-lg-5 mb-3 d-flex justify-content-between">
            <span className="d-flex align-items-center">
              Available Withdraw:{' '}
              <div
                title="Available Withdraw"
                // type="button"
                // onClick={() => setShowModalTax(true)}
                className="bg-transparent border-0 position-relative"
                onMouseEnter={() => handleMouseEnter('available-withdraw')}
                onMouseLeave={handleMouseLeave}
              >
                <AiOutlineInfoCircle
                  size={18}
                  color="#116BFF"
                  className="ml-1"
                />
                <span
                  className={`info-tooltip ${
                    selectedTooltip === 'available-withdraw' ? 'show' : 'hide'
                  }`}
                >
                  The estimated total includes processing and transaction fees,
                  covering operational and transfer costs, respectively, plus
                  any tax reporting obligations of your earnings.
                </span>
              </div>
            </span>
            <span>{formatPrice(availableWithdrawal)}</span>
          </div>
          <div className="bg-values align-items-center py-3 px-3 px-sm-4 px-lg-5 mb-3 d-flex justify-content-between">
            <span className="d-flex align-items-center">
              Estimated Processing and Transaction Fees:
              <div
                title="Review Tax"
                // type="button"
                // onClick={() => setShowModalTax(true)}
                className="bg-transparent border-0 position-relative"
                onMouseEnter={() => handleMouseEnter('transaction-fees')}
                onMouseLeave={handleMouseLeave}
              >
                <AiOutlineInfoCircle
                  size={18}
                  color="#116BFF"
                  className="ml-1"
                />
                <span
                  className={`info-tooltip ${
                    selectedTooltip === 'transaction-fees' ? 'show' : 'hide'
                  }`}
                >
                  We issue payouts on the 15th of each month. You can request
                  withdrawals for any available funds, following a 45-day refund
                  period, from the 1st to the 10th of each month. This timing
                  allows us to process and complete your payout by the 15th.
                </span>
              </div>
            </span>
            <span>{formatPrice(taxFee)}</span>
          </div>
          <div className="bg-values align-items-center py-3 px-3 px-sm-4 px-lg-5 mb-lg-3 d-flex justify-content-between">
            <span>Net Total To Receive:</span>
            <span className="fw-600">
              {formatPrice(availableWithdrawal - taxFee)}
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 px-3 px-sm-5 py-4 mb-2 mb-sm-4 justify-content-around">
          {loadingWithdraw ? (
            <Loading className="btn btn-clear-cache d-flex align-items-center justify-content-center">
              <div className="spinner" />
            </Loading>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-clear-cache"
                onClick={handleClickConfirmWithdraw}
              >
                CONFIRM WITHDRAW
              </button>
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="w-100 mt-4 bg-transparent border-0 btn-cancel"
              >
                Cancel
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        onHide={() => setShowModalTax(false)}
        show={showModalTax}
        size="xl"
        centered
      >
        <button
          onClick={() => setShowModalTax(false)}
          type="button"
          className="border-0 bg-transparent btn-close"
        >
          <IoIosClose color="#021027" size={30} />
        </button>
        <Modal.Header className="justify-content-center border-0 px-3 px-sm-5 pb-0 pb-0 pt-4">
          <div className="pe-none mt-4 d-flex flex-column align-items-center overflow-hidden w-100 px-sm-4">
            <Lottie
              options={{
                animationData: withdraw,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              width={180}
              height={180}
            />
            <Modal.Title className="modal-cache-title mt-lg-4">
              Review Tax
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 pt-lg-4 px-3 px-sm-5">
          <p className="cache-subtitle mb-4 mb-lg-5">Review Tax below:</p>
          <div className="bg-values py-3 px-3 px-sm-4 px-lg-5 mb-3 d-flex justify-content-between">
            <span>Tax 1:</span>
            <span>$000.00</span>
          </div>
          <div className="bg-values py-3 px-3 px-sm-4 px-lg-5 mb-3 d-flex justify-content-between">
            <span>Tax 2:</span>
            <span>$000.00</span>
          </div>
          <div className="bg-values py-3 px-3 px-sm-4 px-lg-5 mb-3 d-flex justify-content-between">
            <span>Tax 3:</span>
            <span>$000.00</span>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 px-3 px-sm-5 pt-lg-5 pb-4 mb-2 mb-sm-4 justify-content-around">
          <button
            type="button"
            onClick={() => setShowModalTax(false)}
            className="w-100 mt-lg-4 bg-transparent border-0 btn-cancel"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <ModalPayout
        className="modal-link"
        show={showComplete}
        size="xl"
        centered
      >
        <Modal.Header className="justify-content-center border-0 px-3 px-sm-5 pb-0 pb-lg-4 pt-0">
          <div className="animation almost mt-5 d-flex flex-column align-items-center overflow-hidden w-100 px-sm-4">
            <Lottie
              options={{
                animationData: payout,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
            />
            <Modal.Title className="mt-3 mt-lg-5 modal-cache-title">
              Almost there!
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 px-3 px-sm-5 mx-lg-5">
          <p className="cache-subtitle mb-4">
            Looks like your payout settings isn't fully set up yet. <br />
            Let's finish it so we can get you paid&nbsp;
            <span role="img" aria-label="rocket">
              🚀
            </span>
          </p>
          <small className="cache-content mb-lg-4">
            Click the link below to complete your tax forms and setup the payout
            wallet. <br />
            Please make sure to use the same email for AutoAffiliate{' '}
            <b className="text-primary font-weight-bold">{user.email}</b> to
            setup your payout wallet.
          </small>
        </Modal.Body>
        <Modal.Footer className="border-0 px-3 px-sm-5 py-4 mb-2 mb-sm-4 mb-lg-5 mx-lg-5 justify-content-around">
          <a
            href={process.env.REACT_APP_SMARTTECH_URL}
            className="btn btn-clear-cache"
            rel="noopener noreferrer"
            target="_blank"
          >
            COMPLETE PAYOUT SETTINGS NOW
          </a>
        </Modal.Footer>
      </ModalPayout>
      <ModalPayout
        className="modal-link"
        show={showSuccefully}
        size="xl"
        centered
        onHide={() => setShowSuccefully(false)}
      >
        <Modal.Header className="justify-content-center border-0 px-3 px-sm-5 pb-0 pb-lg-4 pt-0">
          <div className="animation d-flex flex-column align-items-center overflow-hidden w-100 px-sm-4">
            <Lottie
              options={{
                animationData: withdrawTax,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
            />
            <Modal.Title className=" mt-n3 modal-cache-title">
              Well done!
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 px-3 px-sm-5">
          <p className="cache-subtitle px-lg-5 mb-4">
            We are processing your tax documents, this can take some time...
          </p>
          <small className="cache-content mb-lg-4 px-lg-5">
            Once they are confirmed you will be able to proceed with the payout
            request, meanwhile keep exploring our dashboard and tools to help
            you earn more!{' '}
            <span role="img" aria-label="rocket">
              🚀
            </span>
          </small>
        </Modal.Body>
        <Modal.Footer className="border-0 px-3 px-sm-5 py-4 mb-2 mb-sm-4 mb-lg-5 mx-lg-5 justify-content-around">
          <button
            type="button"
            className="btn btn-clear-cache"
            onClick={handleClickBackToDashboard}
          >
            BACK TO DASHBOARD
          </button>
        </Modal.Footer>
      </ModalPayout>
      <ModalPayout
        className="modal-link"
        size="xl"
        show={showFinalized}
        centered
        onHide={() => setShowFinalized(false)}
      >
        <Modal.Header className="justify-content-center border-0 px-3 px-sm-5 pb-0 pb-lg-4 pt-0">
          <div className="animation success d-flex flex-column align-items-center overflow-hidden w-100 px-sm-4">
            <Lottie
              options={{
                animationData: success,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
            />
            <Modal.Title className="mt-n3 modal-cache-title">
              Withdraw Requested
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 px-3 px-sm-5 mx-lg-4">
          <p className="cache-subtitle mb-4">
            We have added your withdraw request to our next payout. Our payouts
            are processed on the 15th of every month.
          </p>
          <small className="cache-content mb-lg-4">
            No further action is required from you at this time. Keep an eye for
            an email confirmation when your payout is fully processed on the
            15th.
            <span role="img" aria-label="money-bag">
              💰
            </span>
          </small>
        </Modal.Body>
        <Modal.Footer className="border-0 px-3 px-sm-5 py-4 mx-lg-4 mb-2 mb-sm-4 mb-lg-5 justify-content-around">
          <button
            type="button"
            className="btn btn-clear-cache"
            onClick={() => setShowFinalized(false)}
          >
            RETURN TO DASHBOARD
          </button>
        </Modal.Footer>
      </ModalPayout>
    </Container>
  );
};

export default MyEarnings;
