import styled from 'styled-components';

export const VideoPlayer = styled.video`
  width: 100%;
  height: 42vh;

  .vjs-control-bar {
    display: none !important;
  }
`;
