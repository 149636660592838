import styled from 'styled-components';

import { Modal as ModalComponent } from 'react-bootstrap';

interface ICopyAlertProps {
  show: boolean;
}

export const Container = styled.div`
  height: 100%;
`;

export const CopyAlert = styled.div<ICopyAlertProps>`
  transition-duration: 0.3s;
  position: fixed;
  right: ${(props) => (props.show ? '25px' : '-150px')};
  bottom: 50px;
  z-index: 1400;
  color: var(--success);
  background-color: transparent;
  border-color: var(--success);
`;

export const Modal = styled(ModalComponent)`
  .btn-confirm,
  .btn-submit-tether {
    background: var(--technologicalGray);
    border-radius: 15px;
    color: var(--ultraWhite);
    border: 0;
    width: 100%;
    padding: 10px 0;
    height: 54px;
    margin-top: 35px;

    :hover {
      background-color: var(--technologicalGrayLighten) !important;
      transition: 0.5s !important;
    }
  }
`;
