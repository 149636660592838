import styled from 'styled-components';
import bannerEase from '~/assets/banners/banner-prompt-ease.png';

interface ICopyAlertProps {
  show: boolean;
}

export const Container = styled.div`
  min-height: 100vh;
  position: relative;
  ::before {
    content: '';
    width: 100%;
    min-height: 100vh;
    background: url(${bannerEase});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
  }
  h1 {
    font-family: 'Zen Dots';
    font-size: 28px;
    font-weight: 700;
    background: linear-gradient(
      270deg,
      #4786ee 2.07%,
      #d8a681 29.58%,
      #9d66ff 57.19%,
      #53c0b4 76.97%,
      #d398cf 96.19%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0.1px #fff;
  }

  h2 {
    color: #61718b;
    font-family: 'Inter';
    font-size: 40px;
    font-weight: 300;
  }

  h3 {
    color: #61718b;
    font-family: 'Inter';
    font-size: 22px;
    font-weight: 300;
    height: 34px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 34px;
      min-height: 34px;
      color: #2f3745;
      font-family: 'Poppins';
      font-size: 20px;
      font-weight: 500;
      border-radius: 25px;
      border: 2px solid #fff;
    }
  }

  .prompt-output {
  }

  .btn-history {
    width: 76px;
    height: 60px;
    border-radius: 40px;
    border: 1px solid #fff;

    background: rgba(255, 255, 255, 0.3);

    backdrop-filter: blur(40px);
  }

  strong {
    font-weight: bold !important;
  }

  .prompt-result {
    font-size: 20px;
    font-family: 'Times New Roman';
    white-space: pre-wrap;
  }

  .pro {
    width: 71px;
    height: 33px;
    margin-bottom: 2px;
  }

  .bg-category {
    background: #abdfff;
  }

  .bg-target {
    background: #a8fff3;
  }

  .bg-act {
    background: #cbffbe;
  }

  .bg-sentiment {
    background: #ffdbc0;
  }

  .bg-context {
    background: #ffbcdc;
  }

  .lottie > div {
    margin: 0px !important;
  }

  .btn-clear {
    color: #ff1a50;

    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
    border-radius: 40px;
    border: 1px solid #fff;
    backdrop-filter: blur(40px);
    background: rgba(255, 255, 255, 0.15);
    width: 216px;
    height: 61px;
  }

  .btn-result {
    color: #116bff;

    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
    border-radius: 40px;
    border: 1px solid #fff;
    backdrop-filter: blur(40px);
    background: rgba(255, 255, 255, 0.15);
    width: 216px;
    height: 61px;
  }

  .bd-bottom {
    border-bottom: 2px solid rgba(255, 255, 255, 0.85);
  }

  .bg-all {
    border-radius: 40px;
    border: 2px solid #fff;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(40px);
  }

  .all-boxes {
    border-radius: 24px !important;
    border: 2px solid #fff;
    height: 107px;

    input {
      height: 100%;
      padding: 20px 30px;
      color: #2f3745;
      font-family: 'Poppins';
      font-size: 24px;
      font-weight: 500;
    }

    input::placeholder {
      color: #2f3745;
      font-family: 'Poppins';
      font-size: 24px;
      font-weight: 500;
    }
  }

  .all-boxes div:first-child {
    height: 100%;
  }

  .disabled-gray {
    filter: grayscale(1);
    input {
      cursor: not-allowed;
    }
  }

  .options {
    top: 120px !important;
    border-radius: 24px !important;
    border: 2px solid #abdfff !important;
    padding: 30px 0px;
    background-color: rgba(255, 255, 255, 0.4) !important;
    max-height: 450px !important;
    box-shadow: 0px 9px 40px 0px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(40px);
    button {
      color: #2f3745 !important;
      border-bottom: none !important;
      padding: 10px 20px;
      font-family: 'Poppins';
      font-size: 22px !important;
      font-weight: 400;
      text-align: start;
      :hover {
        background-color: rgba(255, 255, 255, 0.4) !important;

        backdrop-filter: blur(40px);
      }
    }
  }

  .bg-result {
    font-family: 'Twemoji Mozilla', 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol', 'Noto Color Emoji', 'EmojiOne Color', 'Android Emoji',
      sans-serif;
  }

  .btn-page {
    background: rgba(255, 255, 255, 0.8) !important;
    backdrop-filter: blur(40px);
    border: 1px solid #ddd;
    color: #393f48 !important;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
  }

  .btn-first,
  .btn-last {
    color: #4e5869;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
  }

  .showing {
    color: #4e5869;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
  }

  .card-success {
    background: #116bff !important;
    bottom: 33px;
    right: 40%;
  }

  .sphere-sm {
    position: absolute;
    top: 13rem;
    left: -3rem;
    width: 93px;
    height: 93px;
  }

  .sphere-lg {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 383px;
    height: 243px;
  }

  .label-select {
    color: #4e5869;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
  }

  .select-quantity {
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .select-quantity option {
    appearance: none;
    cursor: pointer;
    text-align: center;
    background-color: #e5eaf8;
  }

  .bg-output,
  .bg-result {
    border-radius: 30px;
    border: 2px solid #fff;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(40px);
  }

  .bg-result h2,
  .bg-output h2 {
    color: #2f3745;
    font-family: 'Inter';
    font-size: 30px;
    font-weight: 300;
  }

  .bg-output p {
    color: #2f3745;
    font-family: 'Inter';
    font-size: 30px;
    font-weight: 500;
    line-height: 34px;
    height: 140px;
    overflow: auto;
  }

  .bg-output button {
    width: 173px;
    height: 48px;
    border-radius: 25px;
    border: 1px solid #116bff;
    background: rgba(255, 255, 255, 0.41);
    backdrop-filter: blur(40px);
    color: #116bff;
    text-align: center;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
  }

  .bg-result p {
    color: #2f3745;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 300;
    line-height: 34px;

    span {
      font-weight: 500;
    }
  }

  .color-sentiment {
    color: #ff8a33;
    font-weight: 700;
  }

  .color-act-as {
    color: #55b63e;
    font-weight: 700;
  }

  .color-context {
    color: #ef4d9a;
    font-weight: 700;
  }

  .color-target {
    color: #0adbbf;
    font-weight: 700;
  }

  .color-category-niche {
    color: #0094f1;
    font-weight: 700;
    font-style: italic;
  }

  .h-result {
    max-height: 235px;
    overflow: auto;
  }

  .bg-result button img {
    width: 22px;
    height: 22px;
  }

  p.prompt-output {
    color: #2f3745;
    font-family: 'Inter';
    font-size: 30px;
    font-weight: 500;
    line-height: 34px;

    overflow: auto;
  }

  .title {
    font-size: 34px;
  }

  .output-close {
    max-height: 370px;
    overflow: hidden;
    transition: max-height 0.3s ease, overflow 0.3s ease;
  }

  .output-open {
    max-height: 600px;
    overflow: auto;
    transition: max-height 0.3s ease, overflow 0.3s ease;
  }

  .output-min {
    max-height: 150px !important;
    overflow: hidden !important;
    transition: max-height 0.3s ease, overflow 0.3s ease;
  }

  .btn-copy-output {
    position: absolute;
    right: 25px;
  }

  .btn-max-min {
    position: absolute;
    right: 25px;
    bottom: 25px;
    img {
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 23px;
    }

    h3 {
      font-size: 21px;
    }

    .h-result {
      max-height: none;
      overflow: inherit;
    }

    .bg-output p {
      height: auto;
      overflow: inherit;
    }

    .copy {
      background: transparent;
      border: none;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #4e5869;
      width: 100%;
      margin: 0 !important;
      height: 100%;
      img {
        display: none;
      }
    }

    .label-select,
    .select-quantity {
      margin-bottom: 1rem !important;
    }

    .options {
      top: 80px !important;
    }

    .options button {
      font-size: 20px;
    }

    .sphere-sm {
      top: -12px;
      left: 0;
      width: 64px;
      height: 64px;
    }

    .sphere-lg {
      width: 220px;
      height: 330px;
    }

    .bd-bottom {
      border-bottom: none;
    }

    .bd-bottom-title {
      border-bottom: 2px solid rgba(255, 255, 255, 0.85);
    }

    .btn-clear {
      font-size: 18px;
    }

    .btn-result {
      font-size: 18px;
    }

    .all-boxes {
      height: auto;
    }

    .all-boxes input {
      font-size: 22px;
    }
  }

  @media screen and (min-width: 450px) {
    h1 {
      font-size: 32px;
    }
  }

  @media screen and (min-width: 992px) {
    .zoom {
      zoom: 0.7;
    }
    .container {
      max-width: 1380px !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      max-width: 1600px !important;
    }
  }

  @media screen and (min-width: 1300px) {
    .container {
      max-width: 1720px !important;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 0.75;
    }
  }

  @media screen and (min-width: 1600px) {
    .zoom {
      zoom: 0.85;
    }
  }

  @media screen and (min-width: 1800px) {
    .zoom {
      zoom: 1;
    }
  }
`;

export const CopyAlert = styled.div<ICopyAlertProps>`
  transition-duration: 0.3s;
  position: fixed;
  right: ${(props) => (props.show ? '25px' : '-150px')};
  bottom: 50px;
  z-index: 1400;
  color: #fff;
  background-color: #116bff;
  border-color: 2px solid #fff;
`;
