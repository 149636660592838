import React from 'react';
import { Container } from './styles';

const Support: React.FC = () => {
  return (
    <Container className="p-4 text-center">
      <h4 className="bg-title-transparent text-center mt-3 mb-3 mx-2">
        Next <span className="font-weight-bold">Important Steps</span>
      </h4>

      <a
        href="https://www.facebook.com/groups/autoaffiliate/"
        className="link-facebook mb-3 mx-2"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="title">
          Step 1 - <b>Join Our Facebook Community</b>
        </span>
        <span className="py-3 px-4 px-xxl-0 w-xxl-75 mx-auto">
          Join our FB Group and <b>Introduce yourself</b>, we can’t wait to meet
          you!
        </span>
      </a>

      <a
        href="https://chat.whatsapp.com/CC32Xavj6wC11093mP1XEA"
        className="link-whatsapp mb-3 mx-2"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="title">
          Step 2 - <b>Join Our WhatsApp Community</b>
        </span>
        <span className="py-3 px-4 px-xxl-0 w-xxl-75 mx-auto">
          <b>Stay in the loop!</b> Join our WhatsApp Channel for instant
          updates.
        </span>
      </a>
      <a
        href="http://aieducation.blog:8080/yfyq2"
        className="link-zoom mb-3 mx-2"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="title">
          Step 3 - <b>Register for Our Live Webinars</b>
        </span>
        <span className="py-3 px-4 px-xxl-0 w-xxl-75 mx-auto">
          <b>Don't miss out!</b> Register now to stay ahead and gain key
          insights.
        </span>
      </a>
    </Container>
  );
};

export default Support;
