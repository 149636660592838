import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { AxiosResponse } from 'axios';
import { useAuth, User } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Avatar, Loading } from './styles';
import Input from '~/components/Input';
import founderIcon from '~/assets/icons/founder-icon.svg';
import profilePhoto from '~/assets/defaults/photo-profile.png';
import profilePhotoAdd from '~/assets/icons/profile-photo.svg';
import swalError from '~/utils/swalError';
import InputCheckbox from '~/components/InputCheckbox';
import swalSuccess from '~/utils/swalSuccess';

interface IMyProfileProps {
  active: boolean;
}

interface IAddress {
  id?: string;
  user_id?: string;
  zip_code?: string;
  city: string;
  complement: string;
  country: string;
  neighborhood?: string;
  number: string;
  state: string;
  street: string;
  zipCode?: string;
}

interface IUser {
  first: string;
  last: string;
  email: string;
  username: string;
  phone: string;
  referral_code: string;
  email_notifications: boolean;
  sms_notifications: boolean;
  password?: string;
  confirm_password?: string;
  old_password?: string;
  address?: IAddress;
}

const MyProfile: React.FC<IMyProfileProps> = ({ active }) => {
  const formRef = useRef<FormHandles>(null);
  const { user, updateUser } = useAuth();
  const { language } = useLanguage();
  const [avatarData, setAvatarData] = useState<File | null>(null);
  const [avatar, setAvatar] = useState(user.avatar.avatar_url || profilePhoto);
  const [userData, setUserData] = useState({} as IUser);
  const [changePassword, setChangePassword] = useState(false);
  const [emailNotification, setEmailNotification] = useState(
    user.email_notifications
  );
  const [smsNotification, setSmsNotification] = useState(
    user.sms_notifications
  );
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [addressId, setAddressId] = useState('');
  const [zipCodeData, setZipCode] = useState('');
  const [countryData, setCountry] = useState('');
  const [streetData, setStreet] = useState('');
  const [numberData, setNumber] = useState('');
  const [neighborhoodData, setNeighborhood] = useState<string | undefined>(
    undefined
  );
  const [stateData, setState] = useState('');
  const [cityData, setCity] = useState('');
  const [complementData, setComplement] = useState('');
  const [partName, setPartName] = useState('');
  const fullName = user?.referrer?.affiliate?.user?.name;

  useEffect(() => {
    if (fullName) {
      const names = fullName.split(' ');
      const firstName = names[0];
      const lastNameInitial =
        names.length > 1 ? `${names[names.length - 1].charAt(0)}.` : '';

      const abbreviatedName = `${firstName} ${lastNameInitial}`;
      setPartName(abbreviatedName);
    }
  }, [fullName]);

  useEffect(() => {
    const nameParts = user.name.split(' ');
    const data = {
      first: nameParts[0] === 'Pending' ? '' : nameParts[0],
      last:
        nameParts[nameParts.length - 1] === 'Pending'
          ? ''
          : nameParts[nameParts.length - 1],
      email: user.email,
      username: user.username,
      phone: user.phone,
      referral_code: user.referral_code,
      email_notifications: user.email_notifications,
      sms_notifications: user.sms_notifications,
      address: user.address,
    };

    if (user.address) {
      setAddressId(user.address.id);
      setZipCode(user.address.zip_code);
      setCountry(user.address.country);
      setStreet(user.address.street);
      setNumber(user.address.number);
      setNeighborhood(user.address.neighborhood);
      setState(user.address.state);
      setCity(user.address.city);
      setComplement(user.address.complement);
    }
    setUserData((data as unknown) as IUser);
    setLoading(false);
  }, [user]);

  const handleChange = useCallback((e) => {
    const file = e.target.files[0];
    setAvatarData(file);
    setAvatar(URL.createObjectURL(file));
  }, []);

  const handleClickChangePassword = useCallback(() => {
    setChangePassword((state) => !state);
  }, []);

  const handleChangeZipCode = useCallback((e) => {
    setZipCode(e.target.value);
  }, []);

  const handleChangeCountry = useCallback((e) => {
    setCountry(e.target.value);
  }, []);

  const handleChangeStreet = useCallback((e) => {
    setStreet(e.target.value);
  }, []);

  const handleChangeNumber = useCallback((e) => {
    setNumber(e.target.value);
  }, []);

  const handleChangeNeighborhood = useCallback((e) => {
    setNeighborhood(e.target.value);
  }, []);

  const handleChangeState = useCallback((e) => {
    setState(e.target.value);
  }, []);

  const handleChangeCity = useCallback((e) => {
    setCity(e.target.value);
  }, []);

  const handleChangeComplement = useCallback((e) => {
    setComplement(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (data: IUser) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          first: Yup.string().required('First name is required'),
          last: Yup.string().required('Last name is required'),
          email: Yup.string().required(language.my_profile.erro_2),
          username: Yup.string(),
          phone: Yup.string().required(language.my_profile.erro_4),
          referral_code: Yup.string().required(language.my_profile.erro_5),
          email_notifications: Yup.boolean(),
          sms_notifications: Yup.boolean(),
          password: Yup.string(),
          confirm_password: Yup.string().when('password', {
            is: (password: string) => password && password.length > 0,
            then: Yup.string().oneOf(
              [Yup.ref('password'), undefined],
              language.my_profile.erro_7
            ),
          }),
          old_password: Yup.string().when('password', {
            is: (password: string) => password && password.length > 0,
            then: Yup.string().required(language.my_profile.erro_8),
          }),
          zipCode: Yup.string().when('$dataZipCode', {
            is: (dataZipCode: boolean) => !dataZipCode,
            then: Yup.string().required('zip code is required'),
            otherwise: Yup.string(),
          }),

          number: Yup.string().when('$dataNumber', {
            is: (dataNumber: boolean) => !dataNumber,
            then: Yup.string().required('number is required'),
            otherwise: Yup.string(),
          }),

          neighborhood: Yup.string().when('$dataNeighborhood', {
            is: (dataNeighborhood: boolean) => !dataNeighborhood,
            then: Yup.string(),
            otherwise: Yup.string(),
          }),

          city: Yup.string().when('$dataCity', {
            is: (dataCity: boolean) => !dataCity,
            then: Yup.string().required('city is required'),
            otherwise: Yup.string(),
          }),

          state: Yup.string().when('$dataState', {
            is: (dataState: boolean) => !dataState,
            then: Yup.string().required('state is required'),
            otherwise: Yup.string(),
          }),

          country: Yup.string().when('$dataCountry', {
            is: (dataCountry: boolean) => !dataCountry,
            then: Yup.string().required('country is required'),
            otherwise: Yup.string(),
          }),

          street: Yup.string().when('$dataStreet', {
            is: (dataStreet: boolean) => !dataStreet,
            then: Yup.string().required('street is required'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoadingUpdate(true);

        let avatar_id: string;
        let avatar_url: string;
        if (avatarData) {
          const avatarFormData = new FormData();
          avatarFormData.append('avatar', avatarData as File);
          const responseAvatar = await api.post('avatars', avatarFormData);

          if (responseAvatar.data) {
            avatar_url = responseAvatar.data.avatar_url;
            avatar_id = responseAvatar.data.id;
            await api.patch(`users/profile/avatar`, {
              avatar_id,
            });
          } else {
            avatar_id = user.avatar.id;
            avatar_url = avatar;
          }
        } else {
          avatar_id = user.avatar.id;
          avatar_url = avatar;
        }

        if (zipCodeData) {
          const formDataAddress = {
            user_id: user.id,
            zip_code: zipCodeData,
            number: numberData,
            neighborhood: neighborhoodData,
            city: cityData,
            state: stateData,
            country: countryData,
            street: streetData,
            complement: complementData,
          };

          if (user.address) {
            await api.put(`adresses/users/${user.address.id}`, formDataAddress);
          } else {
            await api.post('adresses/users', formDataAddress);
          }
        }

        const {
          first,
          last,
          email,
          username,
          phone,
          referral_code,
          address,
          old_password,
          password,
          confirm_password,
        } = data;

        const formData = {
          avatar_id,
          customer_id: user.customer_id,
          name: `${first} ${last}`,
          email,
          username,
          phone,
          referral_code,
          address,
          email_notifications: emailNotification,
          sms_notifications: smsNotification,
        };

        if (
          old_password &&
          password &&
          confirm_password &&
          password === confirm_password
        ) {
          Object.assign(formData, { old_password, password });
        }

        const response = await api.put<User>('users/profile', formData);
        if (response.data) {
          let addressData: IAddress;
          if (zipCodeData) {
            const formAddressData = {
              user_id: response.data.id,
              zip_code: zipCodeData,
              country: countryData,
              street: streetData,
              number: numberData,
              neighborhood: neighborhoodData,
              city: cityData,
              complement: complementData,
              state: stateData,
            };

            let responseAddress: AxiosResponse<IAddress>;

            if (addressId.length > 0) {
              responseAddress = await api.put(
                `adresses/users/${addressId}`,
                formAddressData
              );
            } else {
              responseAddress = await api.post(
                'adresses/users',
                formAddressData
              );
            }

            addressData = {
              id: responseAddress.data.id,
              user_id: response.data.id,
              zip_code: zipCodeData,
              country: countryData,
              street: streetData,
              number: numberData,
              neighborhood: neighborhoodData,
              city: cityData,
              complement: complementData,
              state: stateData,
            };
          }

          swalSuccess({
            title: 'Success',
            message: 'Your profile was updated',
            textButton: 'Ok',
          }).then(() => {
            const profileData = {
              id: response.data.id,
              avatar_id,
              temp_pass: response.data.temp_pass,
              customer_id: response.data.customer_id,
              name: response.data.name,
              email: response.data.email,
              username: response.data.username,
              phone: response.data.phone,
              referral_code: response.data.referral_code.toLowerCase(),
              email_notifications: response.data.email_notifications,
              sms_notifications: response.data.sms_notifications,
              created_at: user.created_at,
              avatar: {
                id: avatar_id,
                avatar_url,
              },
              address: addressData,
              affiliate: user.affiliate,
              termUser: user.termUser,
              referrer: user.referrer,
            };

            updateUser(profileData as User);
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          swalError({
            message:
              'Looks like what you were trying to do didn’t work, please try again.',
            textButton: 'Try Again',
          });
          setLoading(false);
          setLoadingUpdate(false);
        }
      } finally {
        setLoading(false);
        setLoadingUpdate(false);
      }
    },
    [
      language.my_profile.erro_2,
      language.my_profile.erro_4,
      language.my_profile.erro_5,
      language.my_profile.erro_7,
      language.my_profile.erro_8,
      avatarData,
      zipCodeData,
      user.customer_id,
      user.avatar.id,
      user.id,
      user.address,
      user.created_at,
      user.affiliate,
      user.termUser,
      user.referrer,
      emailNotification,
      smsNotification,
      avatar,
      numberData,
      neighborhoodData,
      cityData,
      stateData,
      countryData,
      streetData,
      complementData,
      addressId,
      updateUser,
    ]
  );

  return (
    <>
      <Container className={active ? 'd-block' : 'd-none'}>
        <div className="container-fluid">
          <Form
            ref={formRef}
            initialData={userData}
            onSubmit={handleSubmit}
            className="row"
          >
            <div className="mb-3 mb-xl-0 col-xl-3 px-2">
              <div className="bg-gray h-100">
                <div className="row">
                  <div className="col-md-4 col-xl-12 order-1 order-xl-0">
                    <div className="text-center pr-4 pr-xl-0 pb-xl-4 mb-xl-4 border-custom">
                      <label htmlFor="avatar" className="p-relative py-3">
                        <Avatar
                          src={loading !== true ? avatar : ''}
                          className={
                            loading === true ? 'skeleton rounded-circle' : ''
                          }
                        />
                        <img
                          src={profilePhotoAdd}
                          alt="profilePhotoAdd"
                          className={`${
                            loading === true ? 'd-none' : ''
                          } p-absolute profile-photo-icon`}
                        />
                      </label>
                      <Input
                        type="file"
                        id="avatar"
                        name="avatar"
                        className="d-none"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 order-0 order-xl-1">
                    <h3
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } h5 my-4`}
                    >
                      Your Referrer
                    </h3>
                    <p>{partName}</p>

                    <h3
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } h5 my-4`}
                    >
                      Contact Details
                    </h3>
                  </div>
                  <div className="col-md-8 col-xl-12 order-2 order-xl-1">
                    <div className="row contact-info">
                      <div className="col-md-6 col-xl-12 mb-3 mb-lg-4">
                        <label
                          htmlFor="username"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } mb-1`}
                        >
                          Username
                        </label>
                        <Input
                          id="username"
                          name="username"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } py-3 border-color`}
                        />
                      </div>
                      <div className="col-md-6 col-xl-12 mb-3 mb-lg-4">
                        <label
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } mb-1`}
                        >
                          {language.my_profile.label_3}
                        </label>
                        <Input
                          id="phone"
                          name="phone"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } py-3 border-color ${
                            user.phone && user.phone.length > 6 ? 'pe-none' : ''
                          }`}
                        />
                      </div>
                      <div className="col-md-6 col-xl-12 mb-4">
                        <label
                          htmlFor="referral_code"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } mb-1`}
                        >
                          {language.my_profile.label_4}
                        </label>
                        <Input
                          id="referral_code"
                          name="referral_code"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } py-3 border-color pe-none`}
                        />
                      </div>
                      <div className="col-12 text-center">
                        {user.affiliate.type === 'Founder' && (
                          <img
                            src={founderIcon}
                            alt="Founder"
                            className="founder-icon"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 mt-xl-0 col-xl-9 px-2">
              <div className="bg-gray h-100">
                <div className="row align-items-center">
                  <div className="col-md-6 col-lg-3 mb-1 mb-lg-3 pt-4">
                    <label
                      htmlFor="name"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      First Name
                    </label>
                    <Input
                      id="name"
                      name="first"
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-3 border-color`}
                    />
                  </div>
                  <div className="col-md-6 col-lg-3 mb-1 mb-lg-3 pt-4">
                    <label
                      htmlFor="last"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      Last Name
                    </label>
                    <Input
                      id="last"
                      name="last"
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-3 border-color`}
                    />
                  </div>
                  <div className="col-lg-6 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="email"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      Email
                    </label>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-3 border-color`}
                    />
                  </div>
                  <div className="col-md-6 col-lg-2 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="number"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_11}
                    </label>
                    <Input
                      name="number"
                      value={numberData}
                      onChange={handleChangeNumber}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-3 border-color`}
                    />
                  </div>
                  <div className="col-lg-6 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="street"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_10}
                    </label>
                    <Input
                      name="street"
                      value={streetData}
                      onChange={handleChangeStreet}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-3 border-color`}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="city"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_14}
                    </label>
                    <Input
                      name="city"
                      value={cityData}
                      onChange={handleChangeCity}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-3 border-color`}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="state"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_13}
                    </label>
                    <Input
                      name="state"
                      value={stateData}
                      onChange={handleChangeState}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-3 border-color`}
                    />
                  </div>

                  <div className="col-md-6 col-lg-3 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="zipCode"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_8}
                    </label>
                    <Input
                      name="zipCode"
                      value={zipCodeData}
                      onChange={handleChangeZipCode}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-3 border-color`}
                    />
                  </div>
                  <div className="col-md-6 col-lg-5 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="country"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_9}
                    </label>
                    <Input
                      name="country"
                      value={countryData}
                      onChange={handleChangeCountry}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-3 border-color`}
                    />
                  </div>

                  <div className="col-md-6 col-lg-4 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="neighborhood"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_12}
                    </label>
                    <Input
                      name="neighborhood"
                      value={neighborhoodData}
                      onChange={handleChangeNeighborhood}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-3 border-color`}
                    />
                  </div>
                  <div className="col-lg-8 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="complement"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_15}
                    </label>
                    <Input
                      name="complement"
                      value={complementData}
                      onChange={handleChangeComplement}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-3 border-color`}
                    />
                  </div>
                  <div className="col-12 pt-4 pt-lg-5">
                    <h3 className={`${loading === true ? 'skeleton' : ''} h5`}>
                      {language.my_profile.h3_3}
                    </h3>
                  </div>
                  {changePassword ? (
                    <>
                      <div className="col-12">
                        <h3 className="h6 pt-4 ">
                          Fill in all the fields below to change your password
                        </h3>
                      </div>
                      <div className="col-md-6 mb-1 mb-lg-3 pt-1 pt-lg-3">
                        <label
                          htmlFor="old-password"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } mb-1`}
                        >
                          {language.my_profile.label_16}
                        </label>
                        <div className="p-relative">
                          <Input
                            type="password"
                            id="old-password"
                            name="old_password"
                            className={`${
                              loading === true ? 'skeleton' : ''
                            } py-3 w-75-profile border-color`}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 mb-1 mb-lg-3 pt-1 pt-lg-3">
                        <label
                          htmlFor="password"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } mb-1`}
                        >
                          {language.my_profile.label_17}
                        </label>
                        <Input
                          type="password"
                          id="password"
                          name="password"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } py-3 border-color`}
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <label
                          htmlFor="confirm-password"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } mb-1`}
                        >
                          {language.my_profile.label_18}
                        </label>
                        <Input
                          type="password"
                          id="confirm-password"
                          name="confirm_password"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } py-3 border-color`}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="col-md-12 mb-5">
                      <button
                        type="button"
                        className={`${
                          loading === true ? 'skeleton' : ''
                        } border-0 bg-transparent button-color mt-4`}
                        onClick={handleClickChangePassword}
                      >
                        {language.my_profile.button_1}
                      </button>
                    </div>
                  )}

                  <div className="col-md-7">
                    <h3
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } h5 mb-5`}
                    >
                      Notifications
                    </h3>
                    <div className="d-sm-flex">
                      <div
                        className={`${
                          emailNotification ? 'checkbox-active' : 'checkbox'
                        } ${
                          loading === true ? 'd-none' : 'd-flex'
                        }  justify-content-center justify-content-md-start align-items-center w-100`}
                      >
                        <InputCheckbox
                          type="checkbox"
                          name="email_notifications"
                          className="bg-transparent border-0 max-width justify-content-start"
                          onChange={() =>
                            setEmailNotification(!emailNotification)
                          }
                          options={[
                            {
                              label: 'Email notifications',
                              value: 'Email notifications',
                              labelHtml: true,
                              selected: emailNotification,
                            },
                          ]}
                        />
                      </div>
                      <div
                        className={`${
                          smsNotification ? 'checkbox-active' : 'checkbox'
                        } ${
                          loading === true ? 'd-none' : 'd-flex'
                        }  justify-content-center justify-content-md-start align-items-center w-100`}
                      >
                        <InputCheckbox
                          type="checkbox"
                          name="sms_notifications"
                          className="bg-transparent border-0 max-width justify-content-start"
                          onChange={() => setSmsNotification(!smsNotification)}
                          options={[
                            {
                              label: 'SMS notifications',
                              value: 'SMS notifications',
                              labelHtml: true,
                              selected: smsNotification,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto">
                    {loadingUpdate ? (
                      <Loading className="btn d-flex align-items-center justify-content-center mt-4 mb-4 mb-lg-0">
                        <div className="spinner" />
                      </Loading>
                    ) : (
                      <button
                        type="submit"
                        className={`${
                          loading === true ? 'skeleton' : ''
                        } profile-button py-3 w-100 mt-4 mb-4 mb-lg-0`}
                      >
                        <span>{language.my_profile.button_2}</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default MyProfile;
