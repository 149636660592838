import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, AnimationContainer } from './styles';

import logo from '~/assets/logotipos/logo.png';

import InputCode from '~/components/InputCode';
import api from '~/services/api';
import BannersTestimonials from '~/components/BannersTestimonials';
import SocialBlueMobile from '~/components/SocialBlueMobile';
import swalError from '~/utils/swalError';

interface IFormData {
  code: string;
}

const Passcode: FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { signInWithPass } = useAuth();

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [userId, setUserId] = useState('');
  const [resendMessage, setResendMessage] = useState(false);

  useEffect(() => {
    const data = localStorage.getItem('@AutoAffiliate:userData');
    if (data) {
      setUserId(JSON.parse(data).id);
    } else {
      history.push(`${process.env.PUBLIC_URL}/`);
    }
  }, [history]);

  const handleChangeCode = useCallback((e) => {
    setCode(e.target.value);
  }, []);

  const handleClickResendCode = useCallback(async () => {
    await api.post(
      `users/2fa`,
      {
        user_id: userId,
      },
      {
        params: {
          resendSms: true,
        },
      }
    );
    setResendMessage(true);
    setTimeout(() => {
      setResendMessage(false);
    }, 5000);
  }, [userId]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          code: Yup.string().when('$codeData', {
            is: (codeData: boolean) => !codeData,
            then: Yup.string().required('Code is required'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: { codeData: code.length === 4 },
        });

        await api.get(`users/2fa/${code}/${userId}`);

        signInWithPass(userId);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          if (errors.code) {
            setCodeError(errors.code);
          }
        } else {
          swalError({
            message:
              'Looks like the code you entered is invalid or expired, please try again.',
            textButton: 'Try Again',
          });
        }
      }
    },
    [code, userId, signInWithPass]
  );

  return (
    <Container className="d-flex align-items-lg-center">
      <div className="container-fluid h-100">
        <div className="row justify-content-center justify-content-lg-start h-100">
          <AnimationContainer className="col-sm-11 col-lg-6 mt-4 px-0">
            <div className="d-flex justify-content-between pl-4 pl-sm-0">
              <img src={logo} alt="Logo" className="size-mobile" />
            </div>
            <div className="align-items-center px-sm-3">
              <div className="p-xxl-6 py-lg-4 px-lg-0 p-xl-4 p-sm-5 p-4 login">
                <h1 className="font-weight-bold mb-3 mt-4">
                  Enter Passcode
                  <br />
                  below
                </h1>
                <h2 className="mb-1 mb-md-3 ">
                  It was sent to the mobile number you entered.
                </h2>
                <Form ref={formRef} onSubmit={handleSubmit} className="mt-5">
                  <InputCode
                    name="code"
                    length={4}
                    className="input mt-4"
                    error={codeError}
                    onChange={handleChangeCode}
                  />
                  <button type="submit" className="mt-5">
                    Verify Passcode
                  </button>
                  <button
                    type="button"
                    className="border-0 bg-transparent resend mt-4"
                    onClick={handleClickResendCode}
                  >
                    Resend code
                  </button>
                  {resendMessage && (
                    <span className="small d-block text-center text-success error">
                      The code was resent to the number informed
                    </span>
                  )}
                  <button
                    type="button"
                    className="border-0 bg-transparent different mt-2"
                    onClick={() => history.push(`${process.env.PUBLIC_URL}`)}
                  >
                    Try a different number
                  </button>
                </Form>
                <div className="d-lg-none mt-5 pt-5">
                  <SocialBlueMobile />
                </div>
              </div>
            </div>
          </AnimationContainer>
          <div className="d-none d-lg-block col-lg-6 pr-0">
            <BannersTestimonials />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Passcode;
