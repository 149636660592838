import React, { useCallback, useEffect, useState } from 'react';
import { addMonths, format, parseISO } from 'date-fns';
import { IoMdClose } from 'react-icons/io';

import Swal from 'sweetalert2';
import { formatPrice } from '~/utils/format';
import api from '~/services/api';

import { Container, Modal } from './styles';

import noRegister from '~/assets/icons/no-register-icon.svg';

import safe from '~/assets/defaults/safe_secure.svg';
import money from '~/assets/defaults/money_back.svg';
import privacy from '~/assets/defaults/privacy_proteced.svg';
import CreditCard from '~/components/CreditCard';
import Toast from '~/utils/toast';

interface ISubscriptionsCardsProps {
  active: boolean;
}

interface IOrderResponse {
  id: string;
  created_at: string;
  product: {
    name: string;
    thumbnail: {
      title: string;
      thumbnail_url: string;
    };
  };
  price: {
    period: number;
    value: number;
  };
  orders: IOrderResponse[];
}

interface IOrder {
  id: string;
  thumbnail: string;
  title: string;
  product: string;
  next_billing_date: string;
  next_payment: string;
  expiration_date: string;
  subscription_price: string;
  started: string;
}

const Subscriptions: React.FC<ISubscriptionsCardsProps> = ({ active }) => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [show, setShow] = useState(false);
  const [subscriptionSelected, setSubscriptionSelected] = useState(
    {} as IOrder
  );

  useEffect(() => {
    api.get<IOrderResponse[]>('orders/subscriptions').then((response) => {
      const data: IOrder[] = response.data.map((order) => ({
        id: order.id,
        thumbnail: order.product.thumbnail.thumbnail_url,
        title: order.product.thumbnail.title,
        product: order.product.name,
        next_billing_date: format(
          addMonths(
            parseISO(order.created_at),
            order.price.period + order.orders.length
          ),
          'yyyy- MMM - dd'
        ),
        next_payment: formatPrice(order.price.value),
        expiration_date: format(
          addMonths(
            parseISO(order.created_at),
            order.price.period + order.orders.length
          ),
          'yyyy- MMM - dd'
        ),
        subscription_price: `${formatPrice(order.price.value)}/${
          order.price.period === 1 ? 'mo' : 'yr'
        }`,
        started: format(parseISO(order.created_at), 'MMMM d, yyyy'),
      }));
      setOrders(data);
    });
  }, []);

  const handleClickChangePaymentMethod = useCallback(async (order) => {
    setSubscriptionSelected(order);
    setShow(true);
  }, []);

  const handleClose = useCallback(async () => {
    setShow(false);
    setSubscriptionSelected({} as IOrder);
  }, []);

  const handleClickCancelSubscription = useCallback(async (order) => {
    Swal.fire({
      title: 'Cancel subscription?',
      text: `Would you like to cancel your ${order.product} subscription?`,
      icon: 'warning',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#d0d0d0',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: '#116bff',
      reverseButtons: true,
      background: '#fff',
      customClass: {
        popup: 'swal-cancel-subscription',
      },
      iconColor: '#116bff',
    }).then(async (e) => {
      if (e.isConfirmed) {
        await api.delete(`orders/subscriptions/${order.id}`);

        setOrders((oldState) => {
          return oldState.filter((orderData) => orderData.id !== order.id);
        });

        Toast.fire({
          icon: 'success',
          title: 'Subscription canceled!',
          customClass: {
            popup: 'swal-cancel-subscription',
          },
        });
      }
    });
  }, []);

  const handleChangeCreditCardSelected = useCallback(
    async (creditCard) => {
      console.log(creditCard);
      console.log(subscriptionSelected);
      await api.patch(`orders/subscriptions/${subscriptionSelected.id}`, {
        payment_id: creditCard.id,
      });
      handleClose();
    },
    [handleClose, subscriptionSelected]
  );

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 py-3">
            <div className="row bg-gray p-2 pb-5">
              {orders.length > 0 ? (
                <>
                  {orders.map((order) => (
                    <div key={order.id} className="col-12 mb-md-3 mb-lg-0">
                      <div className="row justify-content-md-end justify-content-lg-start">
                        <div className="col-md-4 col-lg-3 mt-5">
                          <div className="d-flex align-items-center h-100 justify-content-center">
                            <div className="position-relative">
                              <img
                                src={order.thumbnail}
                                alt="First"
                                className="d-none d-md-block img-width radius-20"
                              />
                              <img
                                src={order.thumbnail}
                                alt="First"
                                className="d-md-none img-width radius-20"
                              />
                              <div className="position-absolute positions">
                                <h2>{order.title.split(' ')[0]}</h2>
                                <p>
                                  {order.title.slice(
                                    order.title.split(' ')[0].length
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-lg-3 mt-5">
                          <div className="d-flex h-100">
                            <div>
                              <h3 className="h6">Product</h3>
                              <p>{order.product}</p>
                              <h3 className="h6">Next billing date</h3>
                              <p>{order.next_billing_date}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-6 mt-5">
                          <div className="d-flex h-100">
                            <div>
                              <h3 className="h6">Next payment</h3>
                              <p>{order.next_payment}</p>
                              <h3 className="h6">Expiration date</h3>
                              <p>{order.expiration_date}</p>
                              <p>Started {order.started}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-6 mt-md-n4 mt-lg-5">
                          <div className="d-flex align-items-center h-100">
                            <div>
                              <h3 className="h6">{order.subscription_price}</h3>
                              <div>
                                <button
                                  type="button"
                                  className="px-4 py-2 button-change-payment w-100"
                                  onClick={() =>
                                    handleClickChangePaymentMethod(order)
                                  }
                                >
                                  Change payment method
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="col-12 d-md-flex justify-content-md-center text-center">
                  <img
                    src={noRegister}
                    className="mt-5"
                    alt="No Register icon"
                  />
                  <h2 className="h5 pl-md-3 pt-md-5 my-3 my-md-5 text-no-register text-center">
                    You do not currently have an active paid subscription
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-sales-funnel px-0"
        centered
        size="xl"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="d-block border-0 pb-0 pt-4 px-4">
          <div className="w-100 text-right">
            <button
              type="button"
              className="h4 modal-close m-2 mb-0 ms-auto border-0 bg-transparent"
              onClick={handleClose}
            >
              <IoMdClose color="#151517" size={24} />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="container py-0 px-4 px-lg-5">
          <CreditCard
            type="change"
            onChangeCreditCardSelected={handleChangeCreditCardSelected}
          />
        </Modal.Body>
        <Modal.Footer className="container border-0">
          <div className="row w-100 justify-content-center">
            <div className="col-xl-10 my-2">
              <div className="row align-items-center justify-content-center">
                <div className="col-sm-6 col-lg-4 text-center">
                  <img src={safe} alt="GUARANTEED SAFE & SECURE CHECKOUT" />
                </div>
                <div className="col-sm-6 col-lg-4 text-center mt-4 mt-sm-0">
                  <img src={money} alt="MONEY-BACK GUARANTEE" />
                </div>
                <div className="col-sm-6 col-lg-4 text-center my-4 my-lg-0">
                  <img src={privacy} alt="PRIVACY PROTECTED" />
                </div>
                <div className="col-12 terms mt-1 pb-5">
                  <h4 className="mb-1">
                    <b>Terms of Sale:</b>
                  </h4>
                  <p className="font-weight-200 mb-0">
                    Your purchase will appear on your bank statement under the
                    name "<b>SmartTech Labs, Inc.</b>" The geographic location
                    of your computer has been determined and recorded. All
                    attempts at fraud will be prosecuted. Information submitted
                    in conjunction with this order is handled within the
                    constraints of our privacy policy. Your name, phone, and
                    email address may be provided to 3rd parties completion of
                    this purchase. You agree to receive marketing emails from
                    our company. By purchasing you agree to allow us to send
                    information to you via phone and SMS messaging. Message &
                    Data rates may apply. Reply stop to be removed. We will
                    instantly send your login information to our online training
                    program once your purchase is completed. Please review our
                    terms and conditions for the 30 day MBG refund policy on
                    this purchase.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Subscriptions;
