import styled from 'styled-components';

export const Container = styled.div`
  img {
    width: 24px;
    height: 24px;
  }
  .categories {
    overflow: auto;
    height: auto;
    a {
      display: block;
      background-color: transparent;
      border: none;
      text-align: start;
      color: #8c8c8c !important;
      font-weight: 400;

      :hover {
        background-color: #d0d0d0;
        border-radius: 15px;
        color: #021027 !important;
        text-decoration: none;
        font-weight: 700;
      }
    }

    a.active {
      background-color: #d0d0d0;
      border-radius: 15px;
      color: #021027 !important;
      text-decoration: none;
      font-weight: 700;
    }
  }

  @media screen and (min-width: 992px) {
    .categories {
      height: 328px;
    }
  }
`;
