import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IContainer {
  disabled?: boolean;
  active?: boolean;
  qtdLessons?: number;
}

export const Contents = styled.div`
  .introduction {
    font-size: 16px;
    text-decoration: none;
    color: #242424;
    display: flex;
    padding: 12px;

    :hover {
      background: #ececec;
      border-radius: 15px;
    }

    img {
      width: 24px;
      height: 24px;
      margin-right: 20px;
    }
  }

  .active {
    background: #ececec;
    border-radius: 15px;
  }
`;

export const Introduction = styled(Link)<IContainer>`
  display: block;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
  border: ${(props) =>
    props.active ? 'solid 2px var(--greyMineral)' : 'solid 2px transparent'};
  background: ${(props) => (props.active ? 'var(--coal)' : 'transparent')};
  text-align: left;
  border-radius: 15px;
  padding: 1px;
  background-image: none;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-image-slice: 1;
  transition-duration: 0.3s;

  :hover {
    text-decoration: none;
    border: solid 2px var(--greyMineral);
    background: var(--coal);
  }

  img {
    width: 24px;
    height: 24px;
  }

  p {
    color: ${(props) => (props.active ? 'none' : 'var(--grayImmensity)')};
  }

  .opacity-0 {
    opacity: 0;
  }

  .opacity-05 {
    opacity: 0.5;
  }

  .opacity-1 {
    opacity: 1;
  }

  .line-height {
    line-height: 1.7;
  }
`;

export const Container = styled.div<IContainer>`
  button:not(.btn-exam) {
    display: block;
    pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
    border: ${(props) =>
      props.active ? 'solid 2px #e0e0e0' : 'solid 2px transparent'};
    background: ${(props) => (props.active ? '#e0e0e0' : 'transparent')};
    text-align: left;
    border-radius: 15px;
    padding: 1px;

    transition-duration: 0.3s;

    :hover {
      text-decoration: none;
      border: solid 2px #e0e0e0;
      background: #e0e0e0;
    }
    .module {
      img {
        transition-duration: 0.3s;
        transform: ${(props) =>
          props.active ? 'rotateZ(90deg)' : 'rotateZ(0)'};
      }

      img {
        width: 10px;
        height: 10px;
      }
    }

    p {
      color: #242424;
      font-weight: 500;
      span {
        margin-left: auto;
        margin-right: 10px;
        font-size: 12px;
        color: #626262;
      }
    }

    .opacity-0 {
      opacity: 0;
    }

    .opacity-05 {
      opacity: 0.5;
    }

    .opacity-1 {
      opacity: 1;
    }

    .line-height {
      line-height: 1.7;
    }
  }

  .btn-exam {
    display: block;
    border: solid 2px transparent;
    background: transparent;
    text-align: left;
    border-radius: 15px;
    padding: 1px;
    background-image: none;
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    transition-duration: 0.3s;

    :hover {
      text-decoration: none;
      border: solid 2px var(--greyMineral);
      background: var(--coal);
    }

    img {
      width: 24px;
      height: 24px;
    }

    p {
      color: ${(props) => (props.active ? 'none' : 'var(--grayImmensity)')};
    }

    .opacity-0 {
      opacity: 0;
    }

    .opacity-05 {
      opacity: 0.5;
    }

    .opacity-1 {
      opacity: 1;
    }
  }

  .content {
    overflow: hidden;
    transition-duration: 0.3s;
    height: ${(props) =>
      props.active && props.qtdLessons ? `${props.qtdLessons * 55}px` : '0'};
  }
`;
