import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 70px;
  max-width: calc(100% - 160px);
  z-index: 2147483001;

  @media screen and (max-width: 991px) {
    max-width: calc(100% - 70px);
  }
`;
