import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  BsFillCameraVideoFill,
  BsFillCameraVideoOffFill,
  BsFillMicFill,
  BsFillMicMuteFill,
  BsPause,
  BsArrowUpShort,
  BsChatSquareText,
  BsFillImageFill,
} from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import {
  IoIosArrowBack,
  IoIosCall,
  IoMdSend,
  IoMdSettings,
} from 'react-icons/io';
import Lottie from 'react-lottie';
import { AiOutlinePlus } from 'react-icons/ai';
import { FaUserFriends } from 'react-icons/fa';
import List from 'react-chatview';

import { FormHandles } from '@unform/core';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';
import {
  initializeSocketEvents,
  initializeSession,
  initializePublisher,
  joinRoom,
  getCameras,
  getCurrentCamera,
  setCamera,
  getMicrophones,
  getCurrentMicrophone,
  setMicrophone,
  getHeadsets,
  getCurrentHeadset,
  setHeadset,
  showCam,
  unShowCam,
  audioEnable,
  audioDisabled,
  initializePublisherScreen,
  stopSharedScreen,
  disconnect,
  startTyping,
  stopTyping,
  saveUser,
} from '~/services/chatConnection';

import {
  Container,
  LiveChat,
  Settings,
  Chat,
  Avatar,
  MessagesArea,
  MiniAvatar,
  Typing,
  Form,
} from './styles';
import Textarea from '~/components/Textarea';

import typing from '~/assets/animations/typing.json';

interface IParams {
  roomID: string;
}

interface IUserData {
  userID: string;
  user_id: string;
  name: string;
  avatar?: string;
  host: boolean;
}

interface ISession {
  sessionId: string;
  token: string;
}

interface ILiveData {
  userData: IUserData;
  live: ISession;
}

interface IMessage {
  id: string;
  content: string;
  created_at: string;
  user: {
    id: string;
    name: string;
    avatar: string;
  };
}

interface IMessageData {
  data: IMessage[];
  last_page: number;
  total: number;
}

let typingTimeout: NodeJS.Timeout;

const Live: React.FC = () => {
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const history = useHistory();
  const [vonageData, setVonageData] = useState({} as ISession);
  const [camStatus, setCamStatus] = useState(false);
  const [micStatus, setMicStatus] = useState(true);
  const [sharedScreen, setSharedScreen] = useState(false);
  const [vonageConnected, setVonageConnected] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showChat, setShowChat] = useState(true);
  const [userData, setUserData] = useState<IUserData>({} as IUserData);
  const [cameras, setCameras] = useState<OT.Device[]>([]);
  const [cameraSelected, setCameraSelected] = useState({} as OT.Device);
  const [microphones, setMicrophones] = useState<OT.Device[]>([]);
  const [microphoneSelected, setMicrophoneSelected] = useState({} as OT.Device);
  const [headsets, setHeadsets] = useState<OT.AudioOutputDevice[]>([]);
  const [headsetSelected, setHeadsetSelected] = useState(
    {} as OT.AudioOutputDevice
  );
  const [users, setUsers] = useState<IUserData[]>([]);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [userTyping, setUserTyping] = useState({} as IUserData);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    initializeSocketEvents(
      setSocketConnected,
      setUsers,
      setMessages,
      setUserTyping
    );
  }, []);

  useEffect(() => {
    api
      .get<IMessageData>(`messages/${params.roomID}`, {
        params: {
          page,
        },
      })
      .then((response) => {
        if (page === 1) {
          setMessages(response.data.data);
        } else {
          setMessages((state) => [...state, ...response.data.data]);
        }
        setLastPage(response.data.last_page);
      });
  }, [params.roomID, page]);

  useEffect(() => {
    if (socketConnected) {
      api.get<ILiveData>(`appointments/${params.roomID}`).then((response) => {
        const sessionInitialized = initializeSession(
          '47502611',
          response.data.live.sessionId
        );
        setUsers([response.data.userData]);
        setUserData(response.data.userData);
        setVonageData(response.data.live);
        setVonageConnected(sessionInitialized);
        saveUser(response.data.userData);
      });
    }
  }, [params.roomID, socketConnected]);

  useEffect(() => {
    if (vonageConnected) {
      initializePublisher(vonageData.token, userData);
      joinRoom(params.roomID, userData);
      getCameras().then(async (camerasData) => {
        const currentCamera = await getCurrentCamera();

        const microphonesData = await getMicrophones();
        const currentMicrophone = await getCurrentMicrophone();

        const headsetsData = await getHeadsets();
        const currentHeadset = await getCurrentHeadset();

        if (currentCamera) {
          setCameraSelected(currentCamera);
        }

        if (currentMicrophone) {
          setMicrophoneSelected(currentMicrophone);
        }

        setCameras(camerasData);
        setMicrophones(microphonesData);
        setHeadsets(headsetsData);
        setHeadsetSelected(currentHeadset);
      });
    }
  }, [vonageConnected, userData, vonageData.token, params.roomID]);

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, []);

  const handleClickCamera = useCallback(() => {
    if (camStatus) {
      unShowCam();
    } else {
      showCam();
    }
    setCamStatus(!camStatus);
  }, [camStatus]);

  const handleClickMic = useCallback(() => {
    if (micStatus) {
      audioDisabled();
    } else {
      audioEnable();
    }
    setMicStatus(!micStatus);
  }, [micStatus]);

  const handleClickSharedScreen = useCallback(() => {
    if (sharedScreen) {
      stopSharedScreen();
    } else if (vonageConnected && Object.keys(userData).length > 0) {
      initializePublisherScreen(vonageData.token, userData, setSharedScreen);
    }
  }, [sharedScreen, vonageConnected, userData, vonageData.token]);

  const handleClickDisconnect = useCallback(() => {
    disconnect();
    history.push('/');
  }, [history]);

  const handleClickSettings = useCallback(() => {
    setShowSettings((state) => !state);
  }, []);

  const handleChangeCamera = useCallback(
    (e) => {
      const id = e.target.value;
      const camera = cameras.find((cameraData) => cameraData.deviceId === id);
      if (camera) {
        setCameraSelected(camera);
      }
      setCamera(id);
    },
    [cameras]
  );

  const handleChangeMicrophone = useCallback(
    (e) => {
      const id = e.target.value;
      const microphone = microphones.find(
        (microphoneData) => microphoneData.deviceId === id
      );
      if (microphone) {
        setMicrophoneSelected(microphone);
      }
      setMicrophone(id);
    },
    [microphones]
  );

  const handleChangeHeadset = useCallback(
    (e) => {
      const id = e.target.value;
      const headset = headsets.find(
        (headsetData) => headsetData.deviceId === id
      );
      if (headset) {
        setHeadsetSelected(headset);
      }
      setHeadset(id);
    },
    [headsets]
  );

  const handleClickChat = useCallback(() => {
    setShowChat((state) => !state);
  }, []);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        setPage(page + 1);
      }
    } catch (error) {
      // console.log(error);
    }
  }, [lastPage, page]);

  const handleChange = useCallback(() => {
    startTyping(userData);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    typingTimeout = setTimeout(() => {
      stopTyping();
    }, 1000);
  }, [userData]);

  const handleSubmitMessage = useCallback(
    async (data) => {
      try {
        if (data.message.length > 0) {
          setMessages((state) => [
            ...state,
            {
              id: new Date().getTime().toString(),
              content: data.message,
              created_at: new Date().toDateString(),
              user: {
                id: new Date().getTime().toString(),
                name: user.name,
                avatar: user.avatar.avatar_url,
              },
            },
            {
              id: new Date().getTime().toString(),
              content: data.message,
              created_at: new Date().toDateString(),
              user: {
                id: user.id,
                name: user.name,
                avatar: user.avatar.avatar_url,
              },
            },
          ]);
          const formData = {
            chat_id: params.roomID,
            user_id: user.id,
            content: data.message,
          };

          await api.post('messages', formData);

          if (formRef.current) {
            formRef.current.reset();
          }
        }
      } catch (error) {
        // console.log(error);
      }
    },
    [params, user]
  );

  return (
    <Container className="container-fluid h-100 position-relative">
      <div className="row h-100">
        <LiveChat className={`${showChat ? 'col-lg-10' : 'col-12'} h-100`}>
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="p-1 mr-2 btn btn-dark-gray d-flex align-items-center justify-content-center"
              >
                <IoIosArrowBack size={20} color="#fff" />
              </button>
              <p className="mb-0 text-gray">Meeting with Coach Joan</p>
            </div>
            <div className="d-flex align-items-center">
              <span className="mr-3 p-1 btn btn-dark-gray d-flex align-items-center justify-content-center text-white">
                <FaUserFriends size={15} color="#fff" className="mr-2" />
                {users.length.toString().padStart(2, '0')}
              </span>
              <button
                type="button"
                className="d-flex align-items-center border-0 bg-transparent"
              >
                <span className="p-1 mr-2 btn btn-dark-gray d-flex align-items-center justify-content-center">
                  <AiOutlinePlus size={11} color="#fff" />
                </span>
                <small className="text-gray">Add user to the call</small>
              </button>
              <button
                type="button"
                className="d-flex align-items-center border-0 bg-transparent ml-3"
                onClick={handleClickChat}
              >
                <BsChatSquareText size={24} color="#202020" />
              </button>
            </div>
          </div>
          <div id="room-container" className="row" />
          <div className="buttons">
            <div className="text-center">
              <button
                type="button"
                onClick={handleClickSharedScreen}
                className="btn-shared-screen"
              >
                <span>
                  {sharedScreen ? (
                    <BsPause size={20} color="#000000" />
                  ) : (
                    <BsArrowUpShort size={20} color="#000000" />
                  )}
                </span>
              </button>
            </div>
            <div className="text-center mx-3">
              <button type="button" onClick={handleClickCamera}>
                {camStatus ? (
                  <BsFillCameraVideoFill size={20} color="#ffffff" />
                ) : (
                  <BsFillCameraVideoOffFill size={20} color="#ffffff" />
                )}
              </button>
            </div>
            <div className="text-center">
              <button
                type="button"
                onClick={handleClickDisconnect}
                className="btn btn-call-end"
              >
                <IoIosCall size={38} color="#fff" />
              </button>
            </div>
            <div className="text-center mx-3">
              <button type="button" onClick={handleClickMic}>
                {micStatus ? (
                  <BsFillMicFill size={20} color="#ffffff" />
                ) : (
                  <BsFillMicMuteFill size={20} color="#ffffff" />
                )}
              </button>
            </div>
            <div className="text-center">
              <Settings show={showSettings} className="d-flex flex-column">
                <label className="text-left text-gray d-block mb-3">
                  Câmera
                  <select
                    name="camera"
                    className="mt-2"
                    onChange={handleChangeCamera}
                  >
                    {Object.keys(cameraSelected).length > 0 &&
                      cameras.map((camera) => (
                        <option
                          key={camera.deviceId}
                          value={camera.deviceId as string}
                          selected={cameraSelected.deviceId === camera.deviceId}
                        >
                          {camera.label}
                        </option>
                      ))}
                  </select>
                </label>
                <label className="text-left text-gray d-block mb-3">
                  Dispositivo de entrada
                  <select
                    name="microphone"
                    className="mt-2"
                    onChange={handleChangeMicrophone}
                  >
                    {Object.keys(microphoneSelected).length > 0 &&
                      microphones.map((microphone) => (
                        <option
                          key={microphone.deviceId}
                          value={microphone.deviceId as string}
                          selected={
                            microphoneSelected.deviceId === microphone.deviceId
                          }
                        >
                          {microphone.label}
                        </option>
                      ))}
                  </select>
                </label>
                <label className="text-left text-gray d-block mb-3">
                  Dispositivo de saída
                  <select
                    name="headset"
                    className="mt-2"
                    onChange={handleChangeHeadset}
                  >
                    {Object.keys(headsetSelected).length > 0 &&
                      headsets.map((headset) => (
                        <option
                          key={headset.deviceId}
                          value={headset.deviceId as string}
                          selected={
                            headsetSelected.deviceId === headset.deviceId
                          }
                        >
                          {headset.label}
                        </option>
                      ))}
                  </select>
                </label>
              </Settings>
              <button type="button" onClick={handleClickSettings}>
                <IoMdSettings size={20} color="#fff" />
              </button>
            </div>
          </div>
        </LiveChat>
        <Chat className={`${showChat ? 'col-lg-2 px-4' : 'col-lg-0'} py-4`}>
          <div className="d-flex overflow-auto pb-2">
            {users.map((userContent) => (
              <div key={userContent.user_id} className="user">
                <Avatar src={userContent.avatar || ''} />
                <p className="mb-0 text-gray text-center mt-2">
                  {userContent.user_id === user.id
                    ? 'You'
                    : userContent.name.split(' ')[0]}
                </p>
              </div>
            ))}
          </div>
          <div>
            <h2 className="h4 text-gray mb-4">Chat</h2>
            <List
              scrollLoadThreshold={100}
              onInfiniteLoad={handleLoad}
              flipped
              className="messages-group pr-2"
            >
              {messages.map((message) => (
                <MessagesArea key={message.id}>
                  <div className="d-flex">
                    {message.user.id !== user.id && (
                      <MiniAvatar src={message.user.avatar} className="mr-3" />
                    )}
                    <div
                      className={`message-box ${
                        message.user.id !== user.id
                          ? 'your-message'
                          : 'my-message'
                      }`}
                    >
                      <small className="text-gray mb-1 d-block">
                        {message.user.id !== user.id
                          ? message.user.name.split(' ')[0]
                          : 'You'}
                      </small>
                      <div className="d-flex align-items-center p-2">
                        <p className="small mb-0 mr-2">{message.content}</p>
                        <small>{message.created_at}</small>
                      </div>
                    </div>
                  </div>
                </MessagesArea>
              ))}
            </List>
            <Form ref={formRef} onSubmit={handleSubmitMessage} className="mt-3">
              <Typing
                active={Object.keys(userTyping).length > 0}
                className="d-flex align-items-center py-3"
              >
                <div className="typing mr-2">
                  <Lottie
                    options={{
                      animationData: typing,
                      autoplay: true,
                      loop: true,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={30}
                    width={30}
                  />
                </div>
                <small className="text-typing">
                  {Object.keys(userTyping).length > 0 &&
                    userTyping.name.split(' ')[0]}{' '}
                  is typing...
                </small>
              </Typing>
              <div className="d-flex justify-content-between actions">
                <div className="d-flex align-itens-center p-3">
                  <Textarea
                    name="message"
                    rows={2}
                    className="border-0 bg-transparent textarea"
                    placeholder="Write message here"
                    onChange={handleChange}
                  />
                  <button type="submit" className="btn btn-white">
                    <IoMdSend size={24} color="#fff" />
                  </button>
                </div>
                <button type="button" className="border-0">
                  <BsFillImageFill size={24} color="#202020" />
                </button>
              </div>
            </Form>
          </div>
        </Chat>
      </div>
      <div id="loadVideo" />
    </Container>
  );
};

export default Live;
