import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  FaEnvelope,
  FaFacebookF,
  FaLinkedinIn,
  FaTelegramPlane,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';
import Lottie from 'react-lottie';
import { FormHandles } from '@unform/core';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';

import { AxiosError } from 'axios';
import swalError from '~/utils/swalError';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

import { Container, Step, StepLine } from './styles';
import Input from '~/components/Input';
import InputCheckbox from '~/components/InputCheckbox';
import Copy from '~/components/Copy';
import { useIntercom } from '~/hooks/Intercom';

import logo from '~/assets/logotipos/logo.png';
import rocket from '~/assets/defaults/rocket.png';
import copy from '~/assets/icons/copy-blue.svg';
import confeti from '~/assets/animations/confeti.json';
import facebook from '~/assets/defaults/facebook-group.png';
import whatsapp from '~/assets/defaults/whatsapp-group.png';
import zoom from '~/assets/defaults/zoom-group.png';

interface IParams {
  waitlist_id: string;
}

interface IFormData {
  firstName: string;
  lastName: string;
  phone: string;
}

const Waitlist: React.FC = () => {
  const { signIn } = useAuth();
  const { setUserData } = useIntercom();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const [step, setStep] = useState(1);
  const [showConfeti, setShowConfeti] = useState(true);
  const [checked, setChecked] = useState(false);
  const [position, setPosition] = useState(0);
  const [userId, setUserId] = useState('');
  const [celPhone, setCelPhone] = useState('');
  const [celPhoneError, setCelPhoneError] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [facebookCheck, setFacebookCheck] = useState(false);
  const [whatsappCheck, setWhatsappCheck] = useState(false);
  const [zoomCheck, setZoomCheck] = useState(false);

  useEffect(() => {
    api.get(`waitlist/${params.waitlist_id}`).then((response) => {
      setPosition(response.data.position);
      setUserId(response.data.user_id);
      setUserData({
        email: response.data.user.email,
        created_at: response.data.user.created_at,
      });
    });
  }, [params.waitlist_id, setUserData]);

  useEffect(() => {
    if (step === 4) {
      api.get(`waitlist/${params.waitlist_id}`).then((response) => {
        setPosition(response.data.position);
        setUserId(response.data.user_id);
        setShowConfeti(true);
        setReferralCode(response.data.user.referral_code);
      });
    }
  }, [params.waitlist_id, step]);

  const handleAnimationComplete = useCallback(() => {
    setShowConfeti(false);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        setCelPhoneError('');
        const schema = Yup.object().shape({
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          celphone: Yup.string().when('$celphoneData', {
            is: (celphoneData: boolean) => !celphoneData,
            then: Yup.string().required('phone number is required'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: { celphoneData: celPhone.length > 4 },
        });

        const formData = {
          name: `${data.firstName} ${data.lastName}`,
          phone: `+${celPhone}`,
        };

        const response = await api.put(`users/${userId}`, formData, {
          params: {
            route: 'waitlist',
          },
        });

        setUserData({
          name: response.data.name,
          email: response.data.email,
          created_at: response.data.created_at,
        });

        setStep(3);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          if (errors.celphone) {
            setCelPhoneError(errors.celphone);
          }
        } else {
          const err = error as AxiosError;
          if (err.response?.data.message === 'Phone already in use.') {
            swalError({
              message: 'Celphone already in use, please log in',
              textButton: 'Login',
            });
            history.push(`${process.env.PUBLIC_URL}/`);
          } else {
            swalError({
              message:
                'Looks like what you were trying to do didn’t work, please try again.',
              textButton: 'Try Again',
            });
          }
        }
      }
    },
    [celPhone, history, setUserData, userId]
  );

  const handleClickAccessDashboard = useCallback(async () => {
    const response = await api.post(`users/sessions/first-access/${userId}`);
    await signIn(
      {
        email: response.data.email,
        password: response.data.password,
      },
      'lessons/welcome'
    );
  }, [signIn, userId]);

  const handleShare = useCallback(async () => {
    try {
      await navigator.share({
        url: `https://autoaffiliate.ai/i/${referralCode}`,
      });
    } catch (error) {
      // console.error('Error sharing:', error);
    }
  }, [referralCode]);

  const handleClickFacebook = useCallback(() => {
    setFacebookCheck(true);
  }, []);

  const handleClickWhatsapp = useCallback(() => {
    setWhatsappCheck(true);
  }, []);

  const handleClickZoom = useCallback(() => {
    setZoomCheck(true);
  }, []);

  return (
    <Container>
      <div className="d-flex justify-content-center justify-content-sm-between align-items-center menu">
        <img src={logo} alt="Logo" className="d-none d-sm-block" />
        <div className="w-100 w-sm-auto timeline d-flex justify-content-between justify-content-sm-center">
          <Step
            current={step >= 1}
            complete={step > 2}
            className="d-flex flex-column align-items-center"
          >
            <p>1</p>
            <span>Complete profile</span>
          </Step>
          <StepLine active={step > 2} />
          <Step
            current={step === 3}
            complete={step > 3}
            className="d-flex flex-column align-items-center"
          >
            <p>2</p>
            <span>Verify Passcode</span>
          </Step>
          <StepLine active={step > 3} />
          <Step
            current={step === 4}
            complete={step > 4}
            className="d-flex flex-column align-items-center"
          >
            <p>3</p>
            <span>Access Dashboard</span>
          </Step>
        </div>
      </div>
      <div className="container">
        {showConfeti && (
          <div className="confeti">
            <Lottie
              options={{
                animationData: confeti,
                autoplay: true,
                loop: false,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              isStopped={!showConfeti}
              width={700}
              eventListeners={[
                {
                  eventName: 'complete',
                  callback: handleAnimationComplete,
                },
              ]}
            />
          </div>
        )}
        <div className="row">
          {step === 1 && (
            <div className="col-12 d-flex flex-column align-items-center first-current-position overflow-hidden">
              <img src={rocket} alt="rocket" className="mb-4" />
              <h1>Well Done!</h1>
              <h2 className="mb-5 text-center">You’re on the waitlist.</h2>
              <p className="mb-4 text-center">This is your current position:</p>
              <span className="d-flex align-items-center justify-content-center">
                <b>#</b>
                {position}
              </span>
              <hr className="w-75 w-md-50" />
              <p className="mb-5 mb-sm-4 text-center">
                Want to <b>cut the&nbsp;line</b>&nbsp;and{' '}
                <br className="d-md-none" />
                <b>get early&nbsp;access?</b>
              </p>
              <button
                type="button"
                className="mb-5 mb-lg-0"
                onClick={() => setStep(2)}
              >
                Complete your profile
              </button>
            </div>
          )}
          {step === 2 && (
            <div className="col-12 px-4 px-sm-3 d-flex justify-content-center first-form">
              <div className="d-flex flex-column align-items-center">
                <h2 className="mt-4 mt-lg-0 text-center">
                  Let’s complete your profile
                </h2>
                <p>
                  This is an important step to setup your&nbsp;business
                  correctly.
                </p>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <div className="d-sm-flex justify-content-between align-items-end mb-3">
                    <label className="w-100 w-sm-50 pr-sm-2 mt-3 mt-sm-0">
                      Tell us a bit about you.
                      <Input
                        name="firstName"
                        placeholder="First Name"
                        className="input mt-2"
                      />
                    </label>
                    <label className="w-100 w-sm-50 pl-sm-2 mb-4 mb-sm-0">
                      <Input
                        name="lastName"
                        placeholder="Last Name"
                        className="input mb-2"
                      />
                    </label>
                  </div>
                  <div className="mb-4">
                    <label className="w-100">
                      <span className="d-none d-lg-block">
                        We need to send you a passcode to get into the system
                      </span>
                      <span className="d-lg-none">
                        We'll send a code for you to access the system
                      </span>

                      <PhoneInput
                        placeholder="Mobile Number"
                        country="us"
                        value={celPhone}
                        onChange={(value) => setCelPhone(value)}
                        masks={{
                          br: '(..) .....-....',
                        }}
                        inputClass="input-phone"
                        containerClass="input-container"
                        buttonClass="input-button"
                      />
                      {celPhoneError && (
                        <span className="small text-danger">
                          {celPhoneError}
                        </span>
                      )}
                    </label>
                  </div>
                  <InputCheckbox
                    type="checkbox"
                    name="accept"
                    options={[
                      {
                        label:
                          'By accepting and providing your information, you understand and agree to to our terms and conditions and that you will you will receive emails and or sms related to our company. <b>Your information is 100% safe and secure.</b> and we never share it with third parties. Also, you accept our  <b><a href="https://autoaffiliate.ai/terms-service" target="_blank" rel="noopener noreferrer">terms, conditions</a></b> and <b><a href="https://autoaffiliate.ai/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</b>',
                        value:
                          'By accepting and providing your information, you understand and agree to to our terms and conditions and that you will you will receive emails and or sms related to our company. Your information is 100% safe and secure. and we never share it with third parties. Also, you accept our  terms, conditions and Privacy Policy.',
                        labelHtml: true,
                        selected: checked,
                      },
                    ]}
                    onChange={() => setChecked(!checked)}
                    className="checkbox mb-4"
                  />
                  <button
                    type="submit"
                    className={`mb-5 ${
                      checked ? 'opacity-100' : 'opacity-25 pe-none'
                    }`}
                  >
                    Save and get passcode
                  </button>
                </Form>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="col-12 px-4 px-sm-3 d-flex justify-content-center code-form">
              <div className="d-flex flex-column align-items-center">
                <h2 className="text-center mb-4 mb-lg-2 mt-4 mt-lg-0">
                  Last Step - Joining Our Community
                </h2>
                <p className="mb-0">
                  Don't miss out! Join our exclusive Facebook and WhatsApp
                  groups to get the most out of your business.
                </p>
                <div className="row mt-5">
                  <div className="col-md-4">
                    <a
                      href="https://www.facebook.com/groups/autoaffiliate/"
                      className="link-facebook mb-4 mx-2"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleClickFacebook}
                    >
                      <img
                        src={facebook}
                        alt="Facebook Group"
                        className="w-100"
                      />
                    </a>
                  </div>
                  <div className="col-md-4 my-5 my-md-0">
                    <a
                      href="https://chat.whatsapp.com/CC32Xavj6wC11093mP1XEA"
                      className="link-whatsapp mb-4 mx-2"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleClickWhatsapp}
                    >
                      <img
                        src={whatsapp}
                        alt="Whatsapp Group"
                        className="w-100"
                      />
                    </a>
                  </div>
                  <div className="col-md-4">
                    <a
                      href="http://aieducation.blog:8080/yfyq2"
                      className="link-whatsapp mb-4 mx-2"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleClickZoom}
                    >
                      <img src={zoom} alt="Zoom Group" className="w-100" />
                    </a>
                  </div>
                  <div className="col-12 text-center mt-5">
                    <button
                      type="button"
                      className="btn-next"
                      onClick={() => setStep(4)}
                      disabled={!facebookCheck || !whatsappCheck || !zoomCheck}
                    >
                      Complete Setup
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="col-12 d-flex flex-column align-items-center last-current-position overflow-hidden">
              <h1 className="mt-4 mt-lg-0">Well Done!</h1>
              <h2 className="mb-5 text-center">
                You’ve cut the line quite a bit
              </h2>
              <div className="d-flex align-items-center">
                <h4 className="mb-0 mr-4">
                  This is your current&nbsp;position:
                </h4>
                <span className="d-flex align-items-center justify-content-center position">
                  <b>#</b>
                  {position}
                </span>
              </div>
              <div className="w-100 px-2">
                <hr className="w-100 w-md-50 mx-auto" />
              </div>
              <h3 className="mb-3 mb-lg-4 text-center">
                Don’t stop now, continue moving faster!
              </h3>
              <p>Refer your friends and move up the list</p>
              <div className="d-lg-flex social-media mb-5 mt-3">
                <div className="d-flex mb-3 mb-lg-0 pr-lg-1">
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=https://autoaffiliate.ai/i/${referralCode}&quote=%F0%9F%8C%9F%20Discover%20how%20to%20earn%20online%20with%20AutoAffiliate.%20Start%20building%20your%20additional%20income%20streams%20today.%20No%20hidden%20fees%2C%20just%20opportunities.%0A%0ACreate%20your%20free%20account%20now%20and%20explore%20the%20possibilities%20in%20affiliate%20marketing!%20%40autoaffiliatehq%20%23autoaffiliate`}
                    className="d-flex align-items-center justify-content-center"
                    target="blank"
                  >
                    <div className="icon d-flex align-items-center justify-content-center mr-2">
                      <FaFacebookF size={17} color="" />
                    </div>
                    <span>Share</span>
                  </a>
                  <a
                    href={`https://api.whatsapp.com/send?text=%F0%9F%8C%9F%20Hey%20friend!%20Exciting%20news%20-%20you%20can%20now%20build%20your%20online%20income%20with%20AutoAffiliate.%20It's%20a%20unique%20chance%20to%20get%20into%20affiliate%20marketing%20and%20develop%20extra%20income%20streams%2C%20and%20it's%20totally%20free%20to%20start!%0A%0ADon't%20miss%20out%2C%20sign%20up%20while%20it's%20still%20free!%20Here's%20your%20link%20%E2%9E%A1%EF%B8%8F\nhttps://autoaffiliate.ai/i/${referralCode}`}
                    className="d-flex align-items-center justify-content-center"
                    target="blank"
                  >
                    <div className="icon d-flex align-items-center justify-content-center mr-2">
                      <FaWhatsapp size={17} color="" />
                    </div>
                    <span>Share</span>
                  </a>
                  <a
                    href={`https://telegram.me/share/url?url=https://autoaffiliate.ai/i/${referralCode}&text=%F0%9F%8C%9F%20Hey%20there%2C%20friend!%20Great%20news%20%E2%80%93%20AutoAffiliate%20is%20changing%20the%20game%20in%20online%20earning.%20Dive%20into%20the%20world%20of%20affiliate%20marketing%20and%20create%20multiple%20income%20streams.%20And%20guess%20what%3F%20Starting%20is%20absolutely%20free!%20%0AGrab%20this%20opportunity%20and%20join%20for%20free%20now!%20%0AHere's%20your%20link%20to%20get%20started%20%E2%9E%A1%EF%B8%8F%20\nhttps://autoaffiliate.ai/i/${referralCode}%20%40autoaffiliatehq`}
                    className="d-flex align-items-center justify-content-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="icon d-flex align-items-center justify-content-center mr-2">
                      <FaTelegramPlane size={17} color="" />
                    </div>
                    <span>Share</span>
                  </a>
                </div>
                <div className="d-flex pl-lg-3">
                  <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=https://autoaffiliate.ai/i/${referralCode}&summary=%F0%9F%9A%80%20Discover%20how%20to%20create%20additional%20income%20streams%20online%20with%20affiliate%20marketing.%20Get%20free%20access%20to%20AutoAffiliate%2C%20offering%20tools%20and%20insights%20to%20help%20you%20explore%20this%20opportunity.%20No%20catch%20-%20start%20your%20journey%20today!%0ASign%20up%20now%20and%20unlock%20the%20potential%20of%20affiliate%20marketing.%20%23autoaffiliate`}
                    className="d-flex align-items-center justify-content-center"
                    target="blank"
                  >
                    <div className="icon d-flex align-items-center justify-content-center mr-2">
                      <FaLinkedinIn size={17} color="" />
                    </div>
                    <span>Share</span>
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet?url=https://autoaffiliate.ai/i/${referralCode}&text=%F0%9F%8C%9F%20Ready%20for%20online%20success%3F%20Join%20AutoAffiliate%20and%20explore%20affiliate%20marketing%20with%20our%20free%20platform.%20No%20hidden%20fees%2C%20just%20exciting%20opportunities!%0A%0AStart%20now%20for%20free%20and%20unlock%20your%20potential%20in%20the%20world%20of%20online%20income.%0A%0A%40autoaffiliatehq%20%23autoaffiliate`}
                    className="d-flex align-items-center justify-content-center"
                    target="blank"
                  >
                    <div className="icon d-flex align-items-center justify-content-center mr-2">
                      <FaTwitter size={17} color="" />
                    </div>
                    <span>Tweet</span>
                  </a>
                  <a
                    href={`mailto:?subject=Exciting%20Opportunity%20Just%20For%20You!&body=Hey%20there!%0D%0A%0D%0AI%20just%20stumbled%20upon%20something%20incredible%20and%20immediately%20thought%20of%20you.%20It's%20a%20unique%20platform%20called%20AutoAffiliate%2C%20offering%20a%20chance%20to%20dive%20into%20affiliate%20marketing%20for%20free!%20Yes%2C%20you%20read%20that%20right%20-%20it's%20completely%20free%20to%20get%20started.%0D%0A%0D%0AAutoAffiliate%20is%20all%20about%20helping%20you%20create%20multiple%20income%20streams%20online%20without%20any%20hidden%20fees.%20The%20platform%20is%20user-friendly%2C%20and%20you%20don't%20need%20any%20prior%20experience%20to%20begin.%0D%0A%0D%0AThis%20opportunity%20is%20too%20good%20to%20pass%20up%2C%20especially%20since%20it's%20currently%20free.%20I'm%20not%20sure%20how%20long%20it'll%20stay%20that%20way%2C%20so%20I%20wanted%20to%20make%20sure%20you%20don't%20miss%20out.%0D%0A%0D%0ACheck%20it%20out%20here%20%E2%9E%A1%EF%B8%8F%20https://autoaffiliate.ai/i/${referralCode}%0D%0A%0D%0ALooking%20forward%20to%20hearing%20your%20thoughts%20on%20it!%0D%0A%0D%0ABest%2C%0D%0A%5BYour%20Name%5D`}
                    className="d-flex align-items-center justify-content-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="icon d-flex align-items-center justify-content-center mr-2">
                      <FaEnvelope size={17} color="" />
                    </div>
                    <span>Email</span>
                  </a>
                </div>
              </div>

              <div className="d-none w-100 justify-content-center d-lg-flex">
                <Copy
                  content={`https://autoaffiliate.ai/i/${referralCode}`}
                  className="copy"
                />
              </div>

              <button
                type="button"
                className="d-flex d-lg-none justify-content-between align-items-center my-lg-2 copy"
                onClick={handleShare}
              >
                <span>{`https://autoaffiliate.ai/i/${referralCode}`}</span>
                <div className="border-0 bg-transparent">
                  <img src={copy} alt="Copy" />
                </div>
              </button>

              <p>
                You have referred: <b>0 Friends</b>
              </p>
              <button
                type="button"
                className="mt-4 mb-5"
                onClick={handleClickAccessDashboard}
              >
                Access Dashboard
              </button>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Waitlist;
