import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import api from '~/services/api';
import { useLanguage } from '~/hooks/Language';

import {
  Container,
  Welcome,
  Values,
  Table,
  Avatar,
  Others,
  Skeleton,
} from './styles';

import user from '~/assets/icons/student-icon.svg';
import affiliate from '~/assets/icons/affiliate-icon.svg';
import franchise from '~/assets/icons/franchise-purple-icon.svg';

interface IAffiliateOffer {
  offer_id: string;
}

interface IUser {
  id: string;
  name: string;
  email: string;
  referral_code: string;
  avatar: {
    id: string;
    avatar_url: string;
  };
  affiliate: {
    id: string;
    active_affiliate: boolean;
    franchise_owner: boolean;
    product?: {
      id: string;
      title: string;
    };
    affiliatesOffers: IAffiliateOffer[];
  };
}

interface IAffiliateOffer {
  offer_id: string;
}

interface IReferrerResponse {
  id: string;
  user: {
    id: string;
    name: string;
    email: string;
    referral_code: string;
    avatar: {
      id: string;
      avatar_url: string;
    };
    affiliate: {
      id: string;
      active_affiliate: boolean;
      franchise_owner: boolean;
      product?: {
        id: string;
        title: string;
      };
      affiliatesOffers: IAffiliateOffer[];
    };
    orders: IOrder[];
  };
  created_at: string;
}

interface IReferrer {
  id: string;
  avatar: string;
  name: string;
  email: string;
  type: string;
  typeIcon: string;
  product: string;
  joined: string;
  code_referrer: string;
  qualified: boolean;
}

interface IOrder {
  id: string;
  amount_paid: string;
  status: string;
  course?: {
    id: string;
    title: string;
  };
}

interface IParams {
  slug: string;
}

const TeamView: React.FC = () => {
  const { language } = useLanguage();
  const params = useParams<IParams>();
  const [loading, setLoading] = useState(true);
  const [referrer, setReferrer] = useState<IUser>({} as IUser);
  const [referrers, setReferrers] = useState<IReferrer[]>([]);
  const [typeUser, setTypeUser] = useState('New Member');
  const [typeIconUser, setTypeIconUser] = useState(user);

  useEffect(() => {
    let totalData = 0;
    api
      .get<IUser>(`users/${params.slug}`)
      .then(async (responseUser) => {
        if (
          responseUser.data.affiliate &&
          responseUser.data.affiliate.affiliatesOffers &&
          responseUser.data.affiliate.affiliatesOffers.length > 0
        ) {
          const qualified = !!responseUser.data.affiliate.affiliatesOffers.find(
            (affiliateOffer) =>
              affiliateOffer.offer_id === '2ffe225b-fbad-4521-a68e-c98e43e4f99b'
          );

          const superAffiliate = !!responseUser.data.affiliate.affiliatesOffers.find(
            (affiliateOffer) =>
              affiliateOffer.offer_id === 'cee44e14-66f7-42b2-ba1e-adccf8a47b60'
          );

          if (superAffiliate) {
            setTypeUser('Super Affiliate');
            setTypeIconUser(franchise);
          } else if (qualified) {
            setTypeUser('Affiliate');
            setTypeIconUser(affiliate);
          }
        }

        const response = await api.get<IReferrerResponse[]>(
          `referrers/${responseUser.data.affiliate.id}`
        );
        const data = response.data.map((referrerData) => {
          const nameParts = referrerData.user.name
            ? referrerData.user.name.split(' ')
            : undefined;
          const joined = `Joined ${format(
            parseISO(referrerData.created_at),
            'MMM dd, yyyy'
          )}`;
          let type = 'New Member';
          let typeIcon = user;
          let qualifiedData = false;
          const product =
            referrerData.user.orders[referrerData.user.orders.length - 1] &&
            referrerData.user.orders[referrerData.user.orders.length - 1].course
              ?.title;

          totalData = referrerData.user.orders.reduce(
            (acumulador: number, currentValue: IOrder) => {
              if (currentValue.status === 'succeeded') {
                return acumulador + parseFloat(currentValue.amount_paid);
              }
              return acumulador + 0;
            },
            totalData
          );

          if (
            referrerData.user.affiliate &&
            referrerData.user.affiliate.affiliatesOffers &&
            referrerData.user.affiliate.affiliatesOffers.length
          ) {
            qualifiedData = !!referrerData.user.affiliate.affiliatesOffers.find(
              (affiliateOffer) =>
                affiliateOffer.offer_id ===
                '2ffe225b-fbad-4521-a68e-c98e43e4f99b'
            );

            const superAffiliateData = !!referrerData.user.affiliate.affiliatesOffers.find(
              (affiliateOffer) =>
                affiliateOffer.offer_id ===
                'cee44e14-66f7-42b2-ba1e-adccf8a47b60'
            );

            if (superAffiliateData) {
              type = 'Super Affiliate';
              typeIcon = franchise;
            } else if (qualifiedData) {
              type = 'Affiliate';
              typeIcon = affiliate;
            }
          }
          //
          return {
            id: referrerData.id,
            avatar: referrerData.user.avatar.avatar_url,
            name: nameParts
              ? `${nameParts[0]} ${nameParts[nameParts.length - 1][0]}.`
              : 'Not added',
            email: referrerData.user.email,
            type,
            typeIcon,
            product: product || 'N/A',
            joined,
            code_referrer: referrerData.user.referral_code,
            qualified: qualifiedData,
          };
        });

        setReferrer(responseUser.data);
        setReferrers(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.slug]);

  return (
    <>
      <Container className="container px-0">
        <div className="w-100">
          <Welcome>
            <div className="row">
              <div className="col-12 my-4">
                <h1 className="h4 h2-sm text-center text-lg-left">
                  {language.my_referrals.h1}
                </h1>
              </div>
            </div>
          </Welcome>
          {loading && (
            <Skeleton className="row justify-content-center">
              <Values className="col-11 col-xxl-10 mb-n5 d-md-flex justify-content-center mt-3 py-4">
                <div className="col-lg-4 text-center border-md-right">
                  <p className="h4 mb-0 mb-sm-3">
                    <span className="skeleton">##</span>
                  </p>
                  <p className="mb-0 dark-gray">Total Members</p>
                </div>

                <hr className="w-25 mx-auto d-md-none" />
                <div className="col-lg-4 text-center">
                  <p className="h4 mb-0 mb-sm-3">
                    <span className="skeleton">######</span>
                  </p>
                  <p className="mb-0 dark-gray">Qualified</p>
                </div>
              </Values>
              <Table className="w-100 mx-3 py-5">
                <div className="col-12 my-5 d-flex flex-column align-items-center">
                  <div className="skeleton rounded-circle mt-5 mb-4 img-height" />
                  <div className="d-flex align-items-center mb-2">
                    <p className="skeleton mb-0 pl-3 font-weight-600">
                      ##########
                    </p>
                  </div>
                  <p className="skeleton mb-2 font-weight-600">
                    ###############
                  </p>
                  <p className="skeleton mb-0 font-weight-400 text-color">
                    ##### ####### #####
                  </p>
                </div>
              </Table>
            </Skeleton>
          )}
          {Object.keys(referrer).length > 0 && (
            <>
              <div className="row justify-content-center">
                <Values className="col-11 col-xxl-10 mb-n5 d-md-flex justify-content-center mt-3 py-4">
                  <div className="col-lg-4 text-center border-md-right">
                    <p className="h4 mb-0 mb-sm-3">
                      <span>
                        {referrers.length.toString().padStart(2, '0')}
                      </span>
                    </p>
                    <p className="mb-0 dark-gray">Total Members</p>
                  </div>
                  {/* <hr className="w-25 mx-auto d-md-none" />
                  <div className="col-lg-4 text-center border-md-right">
                    <p className="h4 mb-0 mb-sm-3">
                      <span>
                        {referrers
                          .filter((referrerData) => !referrerData.qualified)
                          .length.toString()
                          .padStart(2, '0')}
                      </span>
                    </p>
                    <p className="mb-0 dark-gray">Waitlist</p>
                  </div> */}

                  <hr className="w-25 mx-auto d-md-none" />
                  <div className="col-lg-4 text-center">
                    <p className="h4 mb-0 mb-sm-3">
                      <span>
                        {referrers
                          .filter((referrerData) => referrerData.qualified)
                          .length.toString()
                          .padStart(2, '0')}
                      </span>
                    </p>
                    <p className="mb-0 dark-gray">Qualified</p>
                  </div>
                </Values>
              </div>

              <Table className="py-5 px-5">
                <div className="col-12 my-5 d-flex flex-column align-items-center">
                  <Avatar
                    src={referrer.avatar.avatar_url}
                    className="mt-5 mb-4"
                  />
                  <div className="d-flex align-items-center mb-2">
                    <img src={typeIconUser} alt="Fire Ball" />
                    <p className="type mb-0 pl-1 font-weight-600">{typeUser}</p>
                  </div>
                  <p className="mb-2 font-weight-600">
                    {referrer.referral_code}
                  </p>
                  <p className="mb-0 font-weight-400 text-center text-color">
                    {referrer.name}
                  </p>
                </div>
                <div className="overflow-auto scroll">
                  <div className="w-content mx-auto d-flex pt-5 mb-4">
                    {referrers.map((referrerData) => (
                      <Link
                        to={`${process.env.PUBLIC_URL}/my-referrals/team-view/${referrerData.code_referrer}`}
                        key={referrerData.id}
                        className="text-center mx-3"
                      >
                        <Others src={referrerData.avatar} />
                        <p className="mb-0 mt-2 name-referrer">
                          {referrerData.name}
                        </p>
                      </Link>
                    ))}
                  </div>
                </div>
              </Table>
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default TeamView;
