import styled from 'styled-components';

export const Container = styled.div`
  .h-40vh {
    height: 40vh;
  }

  .btn-download {
    transition-duration: 0.3s;
    background: #e3e3e3;
    color: #021027;
    border-radius: 20px;
    padding: 20px;
    text-decoration: none;

    svg {
      transition-duration: 0.3s;
      color: #626262;
      min-width: 25px;
      min-height: 25px;
    }
  }

  .text-no-register {
    color: var(--ultraWhite);
    font-weight: 600;
  }
`;

export const Skeleton = styled.div`
  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
