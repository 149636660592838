import styled, { css } from 'styled-components';

interface IStep {
  current: boolean;
  complete: boolean;
}

interface IStepLine {
  active: boolean;
}

export const Container = styled.div`
  min-height: 100vh;

  .fw-600 {
    font-weight: 600 !important;
  }

  .copy {
    background: #fafafa;
    border: 1px solid #ababab;
    border-radius: 24px;
    height: 70px;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 32px;
    color: #3d3d3e;
    width: 100%;
    max-width: 568px;
    padding: 0 33px 0 53px;
    margin-bottom: 10px;
  }

  .confeti {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    pointer-events: none;
  }

  .menu {
    padding: 33px 43px;
  }

  //teste

  h1 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 45px;
    text-align: center;
    width: 658px;
    background: linear-gradient(
      270.04deg,
      #fb5a43 0.03%,
      #c341ae 30.44%,
      #9445c2 57.35%,
      #079cfe 95.73%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  h2 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    color: #151517;
  }

  h3 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 45px;
    color: #151517;
  }

  h4 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: #151517;
  }

  .first-current-position {
    p:nth-child(4) {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      line-height: 32px;
    }

    span {
      background: linear-gradient(90deg, #5a80e0 0%, #6911da 100%);
      box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.2);
      height: 69.5234146118164px;
      width: 155px;
      border-radius: 30px;
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 45px;
      color: #ffffff;

      b {
        font-weight: 300;
      }
    }

    hr {
      //width: 50%;
      height: 1px;
      background: #bebdbd;
      margin: 45.79px 0 25.69px;
      opacity: 1;
    }

    p:nth-child(7) {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 45px;
      color: #116bff;

      b {
        font-weight: 700;
      }
    }

    button {
      background: var(--technologicalGray);
      border-radius: 18px;
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 24px;
      letter-spacing: -0.04em;
      color: #ffffff;
      border: none;
      height: 65px;
      width: 494px;
    }
  }

  .input-button {
    background: #fff;
    border: none;
    height: 30px;
    top: 17px;
    left: 17px;
    transform: scale(1.5);
  }

  .input-container {
    border-radius: 21px;
    border: 1px solid #116bff;
    height: 65px;
    transform: 0.3s;
  }

  .input-phone {
    font-family: 'Archivo';
    font-size: 16px;
    height: 42px;
    width: 100%;
    color: #333;
    padding: 0 10px 0 60px;
    border: none;
    top: 11px;
    margin: 0 auto;
    width: 95%;
    border: none;
    :focus {
      box-shadow: none;
    }
    :placeholder {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      color: #ababab;
    }
  }

  .first-form {
    > div {
      width: 494px;

      p {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        text-align: center;
        color: #151517;
      }

      form {
        width: 100%;

        label {
          font-weight: 300;
          color: #5f5f61;
        }

        .input {
          background: #ffffff;
          border: 1px solid #116bff;
          border-radius: 21px;
          height: 65px;
          padding: 27px 24px;

          input {
            font-family: 'Archivo';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 32px;

            ::placeholder {
              color: #ababab;
            }
          }
        }

        .checkbox {
          label {
            align-items: flex-start;

            div {
              width: 18px;
              height: 18px;
              margin-top: 1px;
              border-radius: 5px;
            }

            span {
              width: calc(100% - 25px);
              font-family: 'Archivo';
              font-style: normal;
              font-weight: 100;
              font-size: 12px;
              line-height: 14px;
              color: #606060;
              b {
                font-weight: 600 !important;
              }
              b a {
                text-decoration: underline !important;
              }
            }
          }
        }

        button {
          background: var(--technologicalGray);
          border-radius: 18px;
          font-family: 'Archivo';
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 24px;
          letter-spacing: -0.04em;
          color: #ffffff;
          border: none;
          height: 65px;
          width: 494px;
          transition: 0.3s;
        }

        .opacity-100 {
          opacity: 1;
        }

        .opacity-25 {
          opacity: 0.25;
        }

        .pe-none {
          pointer-events: none;
        }
      }
    }
  }

  .code-form {
    > div {
      width: 800px;

      p {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        text-align: center;
        color: #151517;
      }

      img {
        box-shadow: 45px 30px 75px 0px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
      }

      .btn-next {
        border-radius: 18px;
        background: #116bff;
        width: 100%;
        height: 65px;
        border: none;
        color: #fff;
        text-align: center;
        font-family: Archivo;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.88px;
        max-width: 600px;
        :disabled {
          background-color: #c2c2c2;
          cursor: default;
        }
      }
    }
  }

  .last-current-position {
    p:nth-child(6) {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 300;
      font-size: 22px;
      line-height: 32px;
      color: #5f5f61;
    }

    span.position {
      background: linear-gradient(90deg, #5a80e0 0%, #6911da 100%);
      box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.2);
      height: 69.5234146118164px;
      width: 155px;
      border-radius: 30px;
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 45px;
      color: #ffffff;

      b {
        font-weight: 300;
      }
    }

    hr {
      width: 50%;
      height: 1px;
      background: #bebdbd;
      margin: 45.79px 0 25.69px;
      opacity: 1;
    }

    .social-media {
      a {
        width: 120px;
        height: 49px;
        border-radius: 22px;
        text-decoration: none !important;

        .icon {
          width: 30px;
          height: 30px;
          background: #ffffff;
          border-radius: 50%;
        }

        span {
          font-family: 'Archivo';
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 32px;
          color: #ffffff;
        }

        + a {
          margin-left: 20px;
        }
      }
    }

    .social-media :first-child {
      a {
        :nth-child(1) {
          background-color: #204fa9;

          svg {
            fill: #204fa9;
          }
        }

        :nth-child(2) {
          background-color: #0dc143;

          svg {
            fill: #0dc143;
          }
        }

        :nth-child(3) {
          background-color: #16a7c7;

          svg {
            fill: #16a7c7;
          }
        }
      }
    }

    .social-media :last-child {
      a {
        :nth-child(1) {
          background-color: #447ae4;

          svg {
            fill: #447ae4;
          }
        }

        :nth-child(2) {
          background-color: #00acee;

          svg {
            fill: #00acee;
          }
        }

        :nth-child(3) {
          background-color: #0098ee;

          svg {
            fill: #0098ee;
          }
        }
      }
    }

    p:nth-child(9) {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 32px;
      color: #151517;

      b {
        font-weight: 700;
      }
    }

    button:not(.copy) {
      background: var(--technologicalGray);
      border-radius: 18px;
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 24px;
      letter-spacing: -0.04em;
      color: #ffffff;
      border: none;
      height: 65px;
      width: 494px;
    }
  }

  @media screen and (max-width: 991px) {
    .last-current-position button:not(.copy) {
      max-width: 376px;
    }
    .code-form > div form button {
      max-width: 353px;
    }
    .last-current-position {
      h2 {
        font-size: 26px;
      }

      h3 {
        line-height: 32px;
      }

      h4 {
        font-size: 24px;
      }
    }
    .last-current-position p:nth-child(6) {
      font-size: 20px;
    }

    .last-current-position .social-media a + a {
      margin-left: 8px;
    }
  }

  @media screen and (max-width: 575px) {
    .menu {
      padding: 33px 28px;
    }
    .first-current-position button {
      width: 85%;
    }
    .first-form > div {
      width: 100%;
    }
    .first-form > div form button {
      width: 100%;
    }
    .first-form > div p {
      font-size: 20px;
    }

    .code-form > div p {
      font-size: 20px;
    }

    .copy {
      font-size: 20px;
      padding: 0 16px 0 20px;
    }
  }

  @media screen and (max-width: 410px) {
    .code-form > div form .input .pincode-input-container input {
      width: 65.29px !important;
    }

    .copy {
      font-size: 18px;
    }
  }
`;

export const Step = styled.div<IStep>`
  width: 75px;

  p {
    font-family: 'Archivo', sans-serif;
    height: 38px;
    width: 38px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 20px;
  }

  span {
    font-family: 'Archivo', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: #2a65f2;
    text-align: center;
  }

  ${(props) => {
    if (props.complete) {
      return css`
        p {
          color: #fff;
          border: 2px solid #2a65f2;
          background-color: #2a65f2;
        }

        span {
          color: #2a65f2;
        }
      `;
    }

    if (props.current) {
      return css`
        p {
          color: #2a65f2;
          border: 2px solid #2a65f2;
        }

        span {
          color: #2a65f2;
        }
      `;
    }

    return css`
      p {
        color: #fff;
        border: 2px solid #dbdbdb;
        background-color: #dbdbdb;
      }

      span {
        color: #dbdbdb;
      }
    `;
  }};
`;

export const StepLine = styled.hr<IStepLine>`
  width: 45px;
  height: 1px;
  background-color: ${(props) => (props.active ? '#2A65F2' : '#b7b7b7')};
  opacity: 1;
`;
