import styled from 'styled-components';

interface IPrice {
  withdraw: boolean;
}

export const Container = styled.div`
  div {
    color: #8c8c8c;
    font-size: 16px;
  }

  .title {
    pointer-events: none;
    color: #021027;
    font-size: 18px;
  }

  .rdt_TableHeadRow {
    div {
      color: #626262;
      font-weight: 600;
      font-size: 16px;
    }
  }

  input.inputDate {
    padding: 8px 10px !important;
  }

  a.inputDate,
  input.inputDate {
    background: #f9f9f9 !important;
  }

  .size-wallet {
    font-size: 12px !important;
  }

  .link-group {
    z-index: 10;

    a {
      color: #8c8c8c;
      text-decoration: none;
    }

    .active {
      color: #242526;
    }
  }

  .rdt_TableRow div:nth-child(3) p {
    font-weight: 500;
  }

  .rdt_TableHead,
  .rdt_TableBody,
  .rdt_TableRow {
    min-width: 0px !important;
  }

  .rdt_TableHeadRow,
  .rdt_TableRow {
    justify-content: space-between;
  }

  .rdt_TableCol,
  .rdt_TableCell {
    width: 180px;
    max-width: 180px;
  }

  @media screen and (min-width: 450px) {
    input.inputDate {
      width: 120px !important;
    }

    a.inputDate {
      width: 70px !important;
      padding: 5px 20px !important;
    }
  }
`;

export const Price = styled.p<IPrice>`
  color: ${(props) => (props.withdraw ? '#FF1A50' : '#8C8C8C')};
  font-weight: ${(props) => (props.withdraw ? '700' : '500')} !important;
`;

export const DeleteButton = styled.button`
  border: none;
  background-color: transparent;
`;

export const Skeleton = styled.div`
  .skeleton {
    background-color: #f1f1f1 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 0) 10%,
        rgba(227, 227, 227, 0.2) 20%,
        rgba(227, 227, 227, 0.5) 40%,
        rgba(227, 227, 227, 0.8) 50%,
        rgba(227, 227, 227, 0.2) 80%,
        rgba(227, 227, 227, 0) 90%,
        rgba(227, 227, 227, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
