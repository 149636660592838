import React, { useState } from 'react';

// import { PDFDownloadLink } from '@react-pdf/renderer';

import { FiDownload } from 'react-icons/fi';
import { format, parseISO } from 'date-fns';
import { useLanguage } from '~/hooks/Language';
import { useAuth } from '~/hooks/Auth';
// import GeneratePDF from '~/hooks/Terms/GeneratePDF';

import { Container, Skeleton } from './styles';

import noRegister from '~/assets/icons/no-register-icon.svg';

interface IDocumentsProps {
  active: boolean;
}

const Documents: React.FC<IDocumentsProps> = ({ active }) => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const [loading] = useState(false);

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <div className="container-fluid">
        <div className="row">
          {loading && (
            <>
              <Skeleton className="col-md-6">
                <div className="order-box p-2">
                  <button
                    type="button"
                    className="skeleton d-flex justify-content-between align-items-center py-3 px-3 order w-100 border-0"
                  >
                    <p className="mb-0 font-weight-bold">#############</p>
                  </button>
                </div>
              </Skeleton>
              <Skeleton className="col-md-6">
                <div className="order-box p-2">
                  <button
                    type="button"
                    className="skeleton d-flex justify-content-between align-items-center py-3 px-3 order w-100 border-0"
                  >
                    <p className="mb-0 font-weight-bold">#############</p>
                  </button>
                </div>
              </Skeleton>
            </>
          )}
          {user.termUser ? (
            <div className="col-lg-6 px-0 px-sm-3">
              <div className="p-sm-2">
                {/* <PDFDownloadLink
                  document={
                    (
                      <GeneratePDF
                        name={user.termUser.name}
                        dateSigned={format(
                          parseISO(user.termUser.date_signed),
                          'MM/dd/yyyy HH:mm:ss'
                        )}
                        ip={user.termUser.ip}
                        effectiveDate={format(
                          parseISO(user.termUser.date_signed),
                          'MMMM dd, yyyy'
                        )}
                      />
                    ) as React.ReactElement
                  }
                  fileName="autoaffiliate-affiliate-agreement.pdf"
                  className="btn-download d-flex justify-content-between align-items-center px-3 px-xxl-4 w-100 border-0"
                >
                  <b>Autoaffiliate Affiliate Agreement</b>
                  <FiDownload />
                </PDFDownloadLink> */}
              </div>
            </div>
          ) : (
            <div className={`${loading === true ? 'd-none' : ''} col-12`}>
              <div className="row h-40vh align-items-center justify-content-center">
                <div className="col-md-8 d-md-flex text-center align-items-md-center justify-content-md-center">
                  <img
                    src={noRegister}
                    className="mb-3 mb-md-0"
                    alt="No Register icon"
                  />
                  <p className="h5 mb-0 pl-md-3 text-no-register text-center">
                    {language.no_register.p}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Documents;
