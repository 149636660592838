import React, { FC, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { HiArrowLeft } from 'react-icons/hi';
import { useLanguage } from '~/hooks/Language';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, AnimationContainer } from './styles';
import Input from '~/components/Input';

import logo from '~/assets/logotipos/logo.png';

import api from '~/services/api';
import BannersTestimonials from '~/components/BannersTestimonials';
import SocialBlueMobile from '~/components/SocialBlueMobile';
import swalError from '~/utils/swalError';
import swalSuccess from '~/utils/swalSuccess';

interface ForgortPasswordFormData {
  email: string;
  password: string;
}

interface IParams {
  slug: string;
}

const ForgortPassword: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formRefPassword = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const { language } = useLanguage();
  const location = useLocation();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: ForgortPasswordFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email(language.sign_in.erro_1)
            .required(language.sign_in.erro_2),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users/sessions/forgot-password', {
          email: data.email,
        });

        swalSuccess({
          title: 'Email sent with success',
          message:
            'please check your email to continue with the password change.',
          textButton: 'Close',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          swalSuccess({
            title: 'Email sent with success',
            message:
              'please check your email to continue with the password change.',
            textButton: 'Close',
          });
        }
      }
    },
    [language.sign_in.erro_1, language.sign_in.erro_2]
  );

  const handleSubmitPassword = useCallback(
    async (data: ForgortPasswordFormData) => {
      try {
        formRefPassword.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().required('new password is required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users/sessions/change-password', {
          password: data.password,
          token: params.slug,
        });

        swalSuccess({
          title: 'Password changed successfully',
          message: 'please log in with the new password',
          textButton: 'Close',
        });
        history.push(`${process.env.PUBLIC_URL}/`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRefPassword.current?.setErrors(errors);
        } else {
          swalError({
            message:
              'Looks like what you were trying to do didn’t work, please try again.',
            textButton: 'Try Again',
          });
        }
      }
    },
    [history, params.slug]
  );

  return (
    <Container className="d-flex align-items-lg-center">
      <div className="container-fluid h-100">
        <div className="row justify-content-center justify-content-lg-start align-items-start h-100">
          <AnimationContainer className="col-sm-11 col-lg-6 mt-4 px-0">
            <div className="d-flex justify-content-between pl-4 pl-sm-0">
              <img src={logo} alt="Logo" className="size-mobile" />
            </div>
            <div className="align-items-center px-sm-3">
              <div className="p-xxl-6 py-lg-4 px-lg-0 p-xl-4 p-sm-5 p-4 login">
                <h1 className="font-weight-bold mb-3 mt-4">Forgot Password?</h1>

                {location.pathname === '/forgot-password' ? (
                  <>
                    <h2 className="mb-1 mb-md-3">
                      No worries. We’ll send you reset instructions.
                    </h2>
                    <Form
                      ref={formRef}
                      onSubmit={handleSubmit}
                      className="mt-5"
                    >
                      <label
                        htmlFor="email"
                        className="d-block mb-2 mt-3 small"
                      >
                        Enter your email below to receive it.
                      </label>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        className="bg-transparent"
                      />
                      <button type="submit" className="button-login mt-4 w-100">
                        <span className="text-white d-block px-5 py-3">
                          Reset Password
                        </span>
                      </button>
                      <Link
                        to={`${process.env.PUBLIC_URL}/`}
                        className="small mb-xl-3 mb-xxl-5 w-100 mt-5 mt-lg-4 d-flex align-items-center justify-content-center"
                      >
                        <HiArrowLeft
                          size={24}
                          color="#5F5F61"
                          className="mr-2"
                        />
                        Back to log in
                      </Link>
                    </Form>
                  </>
                ) : (
                  <>
                    <h2 className="mb-1 mb-md-3">
                      No worries. Enter your new password below.
                    </h2>
                    <Form
                      ref={formRefPassword}
                      onSubmit={handleSubmitPassword}
                      className="mt-5"
                    >
                      <label
                        htmlFor="password"
                        className="d-block mb-2 mt-3 small"
                      >
                        Enter your new password.
                      </label>
                      <Input
                        type="password"
                        id="password"
                        name="password"
                        className="bg-transparent"
                      />

                      <button type="submit" className="button-login mt-4 w-100">
                        <span className="text-white d-block px-5 py-3">
                          Save New Password
                        </span>
                      </button>
                      <Link
                        to={`${process.env.PUBLIC_URL}/`}
                        className="small mb-xl-3 mb-xxl-5 w-100 mt-5 mt-lg-4 d-flex align-items-center justify-content-center"
                      >
                        <HiArrowLeft
                          size={24}
                          color="#5F5F61"
                          className="mr-2"
                        />
                        Back to log in
                      </Link>
                    </Form>
                  </>
                )}
                <div className="d-lg-none mt-5 pt-5">
                  <SocialBlueMobile />
                </div>
              </div>
            </div>
          </AnimationContainer>
          <div className="d-none d-lg-block col-lg-6 pr-0">
            <BannersTestimonials />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ForgortPassword;
