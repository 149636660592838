/* eslint-disable global-require */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useLayoutEffect,
} from 'react';
import ReactApexChart from 'react-apexcharts';
import { differenceInDays, format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';

import { Container, Welcome, Content, Graph, Search } from './styles';

import referrals from '~/assets/icons/referrals-icon.svg';
import reveues from '~/assets/icons/reveues-icon.svg';
import totalReveues from '~/assets/icons/total-reveues-icon.svg';
import searchIcon from '~/assets/icons/search-icon.svg';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

interface IOrder {
  id: string;
  status: string;
  created_at: string;
}
interface IRefferer {
  user: {
    id: string;
    name: string;
    avatar: {
      avatar_url: string;
    };
    orders: IOrder[];
    created_at: string;
  };
  created_at: string;
}
interface IUser {
  id: string;
  name: string;
  avatar: string;
  created_at: string;
}

interface ICommission {
  id: string;
  earning: string;
  created_at: string;
}

const Home: React.FC = () => {
  const { user } = useAuth();
  const { language, idiom } = useLanguage();
  const [width, setWidth] = useState(0);
  const [newMembers, setNewMembers] = useState<IUser[]>([]);
  const [newReferrals, setNewReferrals] = useState(0);
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [percentReferral, setPercentReferral] = useState(0);
  const [months, setMonths] = useState<string[]>([]);
  const [monthSales, setMonthSales] = useState<number[]>([]);
  const [monthMembers, setMonthMembers] = useState<number[]>([]);
  const [commissionsPercent, setCommissionsPercent] = useState(0);
  const [monthCommissions, setMonthCommissions] = useState('');
  const [totalCommissions, setTotalCommissions] = useState('0');
  const [loading, setLoading] = useState(false);
  const [showMembers, setShowMembers] = useState(5);
  const userName = user.name.split(' ');

  const updateSize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  useEffect(() => {
    if (width >= 1750) {
      setShowMembers(6);
    } else if (width >= 1600) {
      setShowMembers(5);
    } else if (width >= 1350) {
      setShowMembers(4);
    } else if (width >= 1200) {
      setShowMembers(3);
    } else if (width >= 992) {
      setShowMembers(4);
    } else {
      setShowMembers(5);
    }
  }, [width]);

  const compare = useCallback((a, b) => {
    if (a.created_at > b.created_at) {
      return -1;
    }
    if (a.created_at < b.created_at) {
      return 1;
    }
    return 0;
  }, []);

  useEffect(() => {
    setLoading(true);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const salesArr: number[] = [];
    const monthsNames: string[] = [];
    const membersArr: number[] = [];
    const languageSelected =
      idiom === 'en'
        ? require('date-fns/locale/en-US')
        : require('date-fns/locale/es');
    api
      .get<IRefferer[]>(`referrers/${user.affiliate.id}`)
      .then(async (response) => {
        const data: IUser[] = response.data.map((referrer) => {
          let j = 0;
          for (let i = 7; i >= 0; i -= 1) {
            const monthCalc = currentMonth - i;
            let year = currentYear;
            if (monthCalc < 0) {
              year -= 1;
            }
            const date = new Date(year, monthCalc);
            const month = date.getMonth();

            const dataByMonth = referrer.user.orders.filter((orders) => {
              const orderDate = parseISO(orders.created_at);
              const orderMonth = orderDate.getMonth();

              return month === orderMonth && orders.status === 'succeeded';
            });

            if (salesArr[j]) {
              salesArr[j] += dataByMonth.length;
            } else {
              salesArr[j] = dataByMonth.length;
            }
            j += 1;
          }

          return {
            id: referrer.user.id,
            name: referrer.user.name || 'Pending',
            avatar: referrer.user.avatar.avatar_url,
            created_at: referrer.created_at,
          };
        });
        const newReferralsData = response.data.filter((referrer) => {
          return (
            differenceInDays(new Date(), parseISO(referrer.created_at)) <= 30
          );
        });
        for (let i = 7; i >= 0; i -= 1) {
          const monthCalc = currentMonth - i;
          let year = currentYear;
          if (monthCalc < 0) {
            year -= 1;
          }
          const date = new Date(year, monthCalc);
          monthsNames.push(format(date, 'MMMM', { locale: languageSelected }));
          const month = date.getMonth();

          const dataByMonth = response.data.filter((referrer) => {
            const userDate = new Date(referrer.user.created_at);
            const userMonth = userDate.getMonth();
            return month === userMonth;
          });
          membersArr.push(dataByMonth.length);
        }

        let percentNewReferral = 0;
        if (newReferralsData.length === 0) {
          percentNewReferral = 0;
        } else if (response.data.length - newReferralsData.length === 0) {
          percentNewReferral = 100;
        } else {
          percentNewReferral =
            (newReferralsData.length * 100) /
            (response.data.length - newReferralsData.length);
        }

        const responseCommissions = await api.get('commissions');

        const dataCommissions = responseCommissions.data.filter(
          (commission: ICommission) => {
            const commissionsDate = new Date(commission.created_at);
            const commissionsMonth = commissionsDate.getMonth();

            return currentMonth === commissionsMonth;
          }
        );

        const total = responseCommissions.data.reduce(
          (acumulador: number, currentValue: ICommission) => {
            return acumulador + parseFloat(currentValue.earning);
          },
          0
        );

        const newCommissions = dataCommissions.reduce(
          (acumulador: number, currentValue: ICommission) => {
            return acumulador + parseFloat(currentValue.earning);
          },
          0
        );

        let percentCommissions = 0;
        if (dataCommissions.length === 0) {
          percentCommissions = 0;
        } else if (
          responseCommissions.data.length - dataCommissions.length ===
          0
        ) {
          percentCommissions = 100;
        } else {
          percentCommissions =
            (dataCommissions.length * 100) /
            (response.data.length - dataCommissions.length);
        }

        setCommissionsPercent(percentCommissions);
        setMonthCommissions(formatPrice(newCommissions));
        setTotalCommissions(formatPrice(total));
        setMonthMembers(membersArr);
        setMonths(monthsNames);
        setMonthSales(salesArr);
        setPercentReferral(percentNewReferral);
        setNewReferrals(newReferralsData.length);
        setTotalReferrals(response.data.length);
        setNewMembers(data.sort(compare));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [compare, idiom, user.affiliate.id]);

  const series = useMemo(
    () => [
      {
        name: language.dashboard.label_1,
        data: monthMembers,
      },
      {
        name: language.dashboard.label_2,
        data: monthSales,
      },
    ],
    [
      language.dashboard.label_1,
      language.dashboard.label_2,
      monthMembers,
      monthSales,
    ]
  );

  const options = useMemo(
    () => ({
      chart: {
        height: 480,
        type: 'area',
        toolbar: {
          show: false,
        },
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'category',
        categories: months,
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
        theme: 'dark',
      },
      colors: ['#116BFF', '#37c694'],
      markers: {
        strokeColors: '#4E4F51',
      },
      fill: {
        colors: ['rgba(17,107,255,1)', 'rgba(55, 198, 148, 0.8)'],
        type: 'gradient',
        gradient: {
          gradientToColors: [
            ['rgba(17,107,255,1)', 'rgba(17,107,255,0.5)'],
            ['rgba(55, 198, 148, 1)', 'rgba(55, 198, 148, 0.5)'],
          ],
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      grid: {
        show: false,
      },
    }),
    [months]
  );

  return (
    <>
      <Container className="h-100">
        <div className="container zoom">
          <div className="row">
            <div className="col-12 mt-4 mt-md-0 mb-4 mb-md-2">
              <Welcome>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 px-0">
                      <h1 className="h4 h2-sm my-4 text-center text-md-left">
                        {language.dashboard.h1} {userName[0]?.trim()}!
                      </h1>
                    </div>
                  </div>
                </div>
              </Welcome>
            </div>
            <div className="col-12 p-0">
              <Content className="pad-dash">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-8 d-flex flex-column justify-content-between pad-graph">
                      <Graph className="h-100">
                        <div className="d-flex mt-xxl-3 align-items-center justify-content-between title-chart">
                          <h3
                            className={`h6 h4-sm ${loading ? 'skeleton' : ''}`}
                          >
                            {language.dashboard.h3_1}
                          </h3>
                        </div>
                        <div className={loading ? 'skeleton' : ''}>
                          <ReactApexChart
                            type="area"
                            options={options}
                            series={series}
                          />
                        </div>
                      </Graph>
                    </div>
                    <div className="col-lg-4 pad-refferals">
                      <div className="h-100 d-flex flex-column justify-content-between mt-3 mt-lg-0 bg-gray">
                        <div className="d-lg-block">
                          <div className="col-12 col-lg-12 mt-xxl-2 d-md-flex px-0 text-center align-items-center justify-content-center justify-content-xxl-between">
                            <h3 className="h6 size-assigned mb-xxl-0 mr-0 pr-xxl-2 ">
                              {language.dashboard.h3_2}
                            </h3>

                            <Link
                              to={`${process.env.PUBLIC_URL}/my-referrals`}
                              className="h6 mb-0 d-none d-xxl-block px-4 py-2"
                            >
                              {language.dashboard.button}
                            </Link>
                          </div>
                          <Search className="col-12 col-lg-12 mb-3 mt-4 d-flex px-4 align-items-center">
                            <input
                              placeholder={language.dashboard.input}
                              className="w-100"
                            />
                            <img
                              src={searchIcon}
                              alt="Search-icon"
                              className="h-50"
                            />
                          </Search>
                          <ol>
                            <li
                              className={`${
                                loading && 'd-flex'
                              } d-none align-items-center p-3 border-bottom`}
                            >
                              <p className={`${loading && 'skeleton'} mb-0`}>
                                00.
                              </p>
                              <div
                                className={`${
                                  loading && 'skeleton'
                                } avatar-coach mx-3`}
                              />
                              <p className={`${loading && 'skeleton'} mb-0`}>
                                ##### ########
                              </p>
                            </li>
                            <li
                              className={`${
                                loading && 'd-flex'
                              } d-none align-items-center p-3 border-bottom`}
                            >
                              <p className={`${loading && 'skeleton'} mb-0`}>
                                00.
                              </p>
                              <div
                                className={`${
                                  loading && 'skeleton'
                                } avatar-coach mx-3`}
                              />
                              <p className={`${loading && 'skeleton'} mb-0`}>
                                ##### ########
                              </p>
                            </li>
                            <li
                              className={`${
                                loading && 'd-flex'
                              } d-none align-items-center p-3 border-bottom`}
                            >
                              <p className={`${loading && 'skeleton'} mb-0`}>
                                00.
                              </p>
                              <div
                                className={`${
                                  loading && 'skeleton'
                                } avatar-coach mx-3`}
                              />
                              <p className={`${loading && 'skeleton'} mb-0`}>
                                ##### ########
                              </p>
                            </li>
                            {newMembers.map((member, index) => (
                              <>
                                {index < showMembers && (
                                  <li className="d-flex align-items-center p-3 border-bottom">
                                    <p className="mb-0">{index + 1}.</p>
                                    <img
                                      src={member.avatar}
                                      alt={member.name}
                                      className="avatar-coach mx-3"
                                    />
                                    <p className="mb-0">{member.name}</p>
                                  </li>
                                )}
                              </>
                            ))}
                          </ol>
                        </div>
                        <div className="d-xxl-none text-center mt-5">
                          <Link
                            to={`${process.env.PUBLIC_URL}/my-referrals`}
                            className="px-4 px-xxl-5 py-1"
                          >
                            {language.dashboard.button}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row px-md-3 mt-3">
                    <div className="col-sm-6 col-xl-3 mt-3 mt-xxl-4 pad-right">
                      <div className="d-flex flex-column justify-content-between bg-gray p-4 p-sm-3 p-xxl-4 w-100 h-100">
                        <p className="h6 h5-xxl mb-3">
                          {language.dashboard.p_1}
                        </p>
                        <div className="d-flex justify-content-between align-items-end">
                          <img
                            src={referrals}
                            alt={language.dashboard.img_1}
                            className="icons mb-2"
                          />
                          <div className="text-right">
                            <p
                              className={`${
                                loading && 'skeleton mb-2'
                              } mb-0 text-green`}
                            >
                              +{percentReferral.toFixed(2)}%
                            </p>
                            <p
                              className={`${
                                loading && 'skeleton'
                              } h3 h4-xxl text-green mb-0`}
                            >
                              +{newReferrals}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-3 mt-3 mt-xxl-4 pad-left pad-right">
                      <div className="d-flex flex-column justify-content-between bg-gray p-4 p-sm-3 p-xxl-4 h-100 w-100">
                        <p className="h6 h5-xxl mb-3">
                          {language.dashboard.p_2}
                        </p>
                        <div className="d-flex justify-content-between align-items-end">
                          <img
                            src={referrals}
                            alt={language.dashboard.img_2}
                            className="icons mb-2"
                          />
                          <div className="text-right">
                            <p className="mb-0 text-green">&nbsp;</p>
                            <p
                              className={`${
                                loading && 'skeleton px-2'
                              } h3 h4-xxl mb-0 total-referrals`}
                            >
                              {totalReferrals}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-3 mt-3 mt-xxl-4 pad-left pad-right">
                      <div className="d-flex flex-column justify-content-between bg-gray p-4 p-sm-3 p-xxl-4 w-100 h-100">
                        <p className="h6 h5-xxl mb-3">Earnings This Month</p>
                        <div className="d-flex justify-content-between align-items-end">
                          <img
                            src={reveues}
                            alt={language.dashboard.img_3}
                            className="icons mb-2"
                          />
                          <div className="text-right">
                            <p
                              className={`${
                                loading && 'skeleton mb-2'
                              } mb-0 text-green`}
                            >
                              +{commissionsPercent.toFixed(2)}%
                            </p>
                            <p
                              className={`${
                                loading && 'skeleton'
                              } h3 h4-xxl mb-0 text-green`}
                            >
                              +{monthCommissions}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-3 mt-3 mt-xxl-4 pad-left">
                      <div className="d-flex flex-column justify-content-between bg-gray p-4 p-sm-3 p-xxl-4 w-100 h-100">
                        <p className="h6 h5-xxl mb-3">Total Earnings</p>
                        <div className="d-flex justify-content-between align-items-end">
                          <img
                            src={totalReveues}
                            alt={language.dashboard.img_4}
                            className="icons mb-2"
                          />
                          <div className="text-right">
                            <p className="mb-0 text-green">&nbsp;</p>
                            <p
                              className={`${
                                loading && 'skeleton px-3'
                              } h3 h4-xxl mb-0 text-green`}
                            >
                              {totalCommissions}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Content>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Home;
