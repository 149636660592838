/* eslint-disable react/no-danger */
import React, { useCallback, useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { Container, CopyAlert } from './styles';
import { apiRequest } from '~/services/aiConnectorApi';
import bubble from '~/assets/animations/orb.json';
import Select, { IOption } from '~/components/Select';
import btnHistoryImg from '~/assets/icons/btn-history-prompt-pro.svg';
import arrowLeft from '~/assets/icons/arrow-left-prompt-pro.svg';
import sphereLg from '~/assets/defaults/sphere-large.png';
import sphereLgMob from '~/assets/defaults/sphere-large-mob.png';
import sphereSm from '~/assets/defaults/sphere-small.png';
import copy from '~/assets/icons/copy-blue.svg';
import pro from '~/assets/icons/pro.svg';
import max from '~/assets/icons/max-icon.svg';
import min from '~/assets/icons/min-icon.svg';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

interface IResult {
  id: number;
  title: string;
  introduction: string;
  body: string;
}

const PromptEase: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [btnHistory, setBtnHistory] = useState(false);
  const [btnMax, setBtnMax] = useState(false);
  const [idHistory, setIdHistory] = useState<number>();
  const [selectCategoryNiche, setSelectCategoryNiche] = useState('');
  const [optionsCategory, setCategoryData] = useState<IOption[]>([]);
  const [optionsNiche, setNicheData] = useState<IOption[]>([]);
  const [targetData, setTargetData] = useState<IOption[]>([]);
  const [contextData, setContextData] = useState<IOption[]>([]);
  const [sentimentData, setSentimentData] = useState<IOption[]>([]);
  const [roleData, setRoleData] = useState<IOption[]>([]);
  const [copied, setCopied] = useState(false);
  const [category, setCategory] = useState('Category');
  const [niche, setNiche] = useState('Niche');
  const [target, setTarget] = useState('Target');
  const [searchBy, setSearchBy] = useState('');
  const [sentiment, setSentiment] = useState('Sentiment');
  const [role, setRole] = useState('Act As');
  const [context, setContext] = useState('Context');
  const [result, setResult] = useState<IResult[]>([]);
  const [btnGenerate, setBtnGenerate] = useState<string>('Generate Result');
  const [isPromptResultGenerate, setIsPromptResultGenerate] = useState<boolean>(
    false
  );
  const [showPage, setShowPage] = useState(false);
  const [isPromptGenerated, setIsPromptGenerated] = useState<boolean>(false);
  /**
   * load dropdown option data on page load
   */
  const [filterData, setFilterData] = useState<{
    categories?: [];
    targets?: [];
    niche?: [];
    context?: [];
    sentiment?: [];
    roles?: [];
    category?: [];
  }>();

  useEffect(() => {
    api
      .get(
        `affiliates-products/products/${process.env.REACT_APP_PROMPT_EASE_PRO}`
      )
      .then(() => {
        setShowPage(true);
      })
      .catch(() => {
        api
          .get(
            `affiliates-products/products/${process.env.REACT_APP_BLACKFRIDAY}`
          )
          .then(() => {
            setShowPage(true);
          })
          .catch(() => {
            history.push(`${process.env.PUBLIC_URL}/profile/my-purchases`);
          });
      });
  }, [history]);

  useEffect(() => {
    apiRequest('/prompt-ease', 'GET', {})
      .then((response) => {
        setFilterData(response.data);
      })
      .catch(() => {
        // console.error('API call error:', error);
      });
  }, []);

  useEffect(() => {
    apiRequest(`/prompt-ease/history?user_id=${user.id}`, 'GET', {})
      .then((response) => {
        setResult(response.results);
      })
      .catch(() => {
        // console.error('API call error:', error);
      });
  }, [user.id]);

  /**
   * set category drop down value
   */
  const categoryLists = useCallback(() => {
    const categoryArray = [] as IOption[];
    filterData?.categories?.forEach((item) =>
      categoryArray.push({ id: item, value: item, selected: false })
    );
    setCategoryData(categoryArray);
  }, [filterData]);

  const nicheLists = useCallback(() => {
    const nicheArray = [] as IOption[];
    filterData?.niche?.forEach((item) =>
      nicheArray.push({ id: item, value: item, selected: false })
    );
    setNicheData(nicheArray);
  }, [filterData]);

  const targetLists = useCallback(() => {
    const targetsArray = [] as IOption[];
    filterData?.targets?.forEach((item) =>
      targetsArray.push({ id: item, value: item, selected: false })
    );
    setTargetData(targetsArray);
  }, [filterData]);

  const sentimentLists = useCallback(() => {
    const sentimentArray = [] as IOption[];
    filterData?.sentiment?.forEach((item) =>
      sentimentArray.push({ id: item, value: item, selected: false })
    );
    setSentimentData(sentimentArray);
  }, [filterData]);

  const roleLists = useCallback(() => {
    const roleArray = [] as IOption[];
    filterData?.roles?.forEach((item) =>
      roleArray.push({ id: item, value: item, selected: false })
    );
    setRoleData(roleArray);
  }, [filterData]);

  const contextLists = useCallback(() => {
    const contextArray = [] as IOption[];
    filterData?.context?.forEach((item) =>
      contextArray.push({ id: item, value: item, selected: false })
    );
    setContextData(contextArray);
  }, [filterData]);

  const handleClickCopy = useCallback((content) => {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 800);
  }, []);

  const generatePrompt = useCallback(() => {
    setIsPromptGenerated(false);
    if (
      !['', 'traffic'].includes(searchBy) &&
      !['', 'Target'].includes(target) &&
      !['', 'Act As'].includes(role) &&
      !['', 'Sentiment'].includes(sentiment)
    ) {
      setBtnGenerate('Loading...');
      setIsPromptResultGenerate(true);
      apiRequest('/prompt-chat', 'POST', {
        user_id: user.id,
        search_by: searchBy,
        target,
        role,
        sentiment,
        context,
      })
        .then((response) => {
          if (response.status === 'success') {
            setResult(response.results);
          }
          setBtnGenerate('Generate Result');
          setIsPromptGenerated(true);
          setIsPromptResultGenerate(false);
        })
        .catch(() => {
          setBtnGenerate('Generate Result');
          setIsPromptResultGenerate(false);
          Swal.fire('', 'Please try again later!', 'error');
        });
    } else {
      setBtnGenerate('Generate Result');
      setIsPromptResultGenerate(false);
      Swal.fire('', 'Please select all the dropdown values', 'error');
    }
  }, [searchBy, target, role, sentiment, user.id, context]);

  const handleClearSearch = useCallback(() => {
    setCategory('Category');
    setNiche('Niche');
    setSearchBy('traffic');
    setTarget('Target');
    setRole('Act As');
    setSentiment('Sentiment');
    setContext('Context');
    setResult(result);
    setSelectCategoryNiche('');
    setIsPromptGenerated(false);
    setShowPrompt(false);
  }, [result]);

  const handleChangeCategory = useCallback((option) => {
    if (option.value !== 'Category') {
      setSelectCategoryNiche('Category');
    } else {
      setSelectCategoryNiche('');
    }
    setSearchBy(option.value);
    setCategory(option.value);
  }, []);

  const handleChangeNiche = useCallback((option) => {
    if (option.value !== 'Niche') {
      setSelectCategoryNiche('Niche');
    } else {
      setSelectCategoryNiche('');
    }
    setSearchBy(option.value);
    setNiche(option.value);
  }, []);

  const handleChangeTarget = useCallback((option) => {
    setTarget(option.value);
  }, []);

  const handleChangeRole = useCallback((option) => {
    setRole(option.value);
  }, []);

  const handleChangeSentiment = useCallback((option) => {
    setSentiment(option.value);
  }, []);

  const handleChangeContext = useCallback((option) => {
    setContext(option.value);
  }, []);

  const handleBtnMax = useCallback(
    (id) => {
      setIdHistory(id);
      setBtnMax(true);
      if (idHistory === id) {
        setBtnMax(!btnMax);
      }
    },
    [btnMax, idHistory]
  );

  useEffect(() => {
    roleLists();
    sentimentLists();
    contextLists();
    targetLists();
    nicheLists();
    categoryLists();
  }, [
    categoryLists,
    contextLists,
    filterData,
    nicheLists,
    roleLists,
    sentimentLists,
    targetLists,
  ]);

  const handleSubmit = useCallback(() => {
    // console.log(data);
  }, []);

  return (
    <Container>
      {showPage && (
        <>
          <img
            src={sphereLg}
            alt="Sphere"
            className="d-none d-lg-block sphere-lg zoom"
          />
          <img src={sphereLgMob} alt="Sphere" className="d-lg-none sphere-lg" />
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-sm-10 mt-3 mt-lg-4 pe-none">
                <div className="d-flex align-items-center lottie pe-none">
                  <Lottie
                    options={{
                      animationData: bubble,
                      autoplay: true,
                      loop: true,

                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    width={70}
                    height={70}
                  />
                  <h1 className="mb-0 d-flex align-items-center">
                    PromptEASE <img src={pro} alt="Pro" className="pro ml-2" />
                  </h1>
                </div>
              </div>
              <div className="col-2 mt-3 mt-lg-4 text-right">
                <button
                  title={btnHistory ? 'Back Prompt' : 'Prompt History'}
                  type="button"
                  className="btn-history"
                  onClick={() => setBtnHistory(!btnHistory)}
                >
                  <img
                    src={btnHistory ? arrowLeft : btnHistoryImg}
                    alt="history"
                  />
                </button>
              </div>
              {btnHistory ? (
                <div className="col-12 mt-4 mt-lg-0">
                  <div className="row justify-content-center">
                    <div className="col-12 position-relative">
                      <img src={sphereSm} alt="Sphere" className="sphere-sm" />
                    </div>
                    <div className="col-12 px-2 px-lg-3">
                      <div className="bg-all mt-lg-5 py-4 px-4 px-lg-5">
                        <div className="row">
                          <div className="col-12 px-lg-2">
                            <h2 className="mb-3 title">Prompt History</h2>
                            {result.map((resultText) => (
                              <div className="bg-result p-4 pl-lg-5 pb-lg-5 pr-lg-2 position-relative mt-4">
                                <button
                                  onClick={() => {
                                    handleBtnMax(resultText.id);
                                  }}
                                  type="button"
                                  className="bg-transparent border-0 btn-max-min"
                                  title={
                                    btnMax && idHistory === resultText.id
                                      ? 'to reduce'
                                      : 'To increase'
                                  }
                                >
                                  <img
                                    src={
                                      btnMax && idHistory === resultText.id
                                        ? min
                                        : max
                                    }
                                    alt="Icon Max Min"
                                  />
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleClickCopy(
                                      `${resultText.title}\n${resultText.introduction}\n${resultText.body}`
                                    )
                                  }
                                  value={resultText.title}
                                  className="bg-transparent border-0 btn-copy-output"
                                  title="Click to copy"
                                >
                                  <img src={copy} alt="Copy" />
                                </button>
                                <div
                                  className={`${
                                    btnMax && idHistory === resultText.id
                                      ? 'output-open'
                                      : 'output-close'
                                  } ${
                                    btnMax &&
                                    idHistory !== resultText.id &&
                                    'output-min'
                                  }`}
                                >
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h2 className="mb-0">Prompt Output</h2>
                                  </div>
                                  <p className="my-2 prompt-output">
                                    <p>{resultText.title}</p>
                                  </p>

                                  <h2 className="mb-0">Prompt Result</h2>

                                  <div className="mt-4 pr-5 prompt-result">
                                    <p
                                      className="prompt-result"
                                      dangerouslySetInnerHTML={{
                                        __html: resultText.body,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-12">
                  <Form onSubmit={handleSubmit}>
                    <div className="row justify-content-center">
                      <div className="col-lg-10 mt-4">
                        <div className="bd-bottom d-lg-flex justify-content-between pb-4">
                          <h2 className="bd-bottom-title text-center pb-4 pb-lg-0 mb-3 mb-lg-2">
                            What prompt are you looking for?
                          </h2>
                          <div className="d-flex justify-content-center justify-content-lg-start mb-4 mb-lg-0">
                            <button
                              type="button"
                              onClick={handleClearSearch}
                              className="btn-clear mx-2 mx-lg-3"
                            >
                              Clear Search
                            </button>
                            <button
                              type="button"
                              onClick={() => setShowPrompt(true)}
                              className="btn-result mx-2 mx-lg-3"
                            >
                              Generate Prompt
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 position-relative">
                        <img
                          src={sphereSm}
                          alt="Sphere"
                          className="sphere-sm"
                        />
                      </div>
                      <div className="col-12 px-2 px-lg-3">
                        <div className="bg-all mt-lg-5 p-4 p-sm-5">
                          <div className="row justify-content-around">
                            <div className="col-lg-4 mb-5 px-lg-2">
                              <h3 className="d-flex align-items-center mb-4 mb-lg-5">
                                <span className="mr-2 bg-category">1</span>
                                Search by category or niche?
                              </h3>

                              <div className="row">
                                <div className="col-lg-6 pr-lg-2 mb-4 mb-lg-0">
                                  <Select
                                    name="category"
                                    options={optionsCategory}
                                    onlySelect
                                    placeholder="Category"
                                    className={`${
                                      selectCategoryNiche === 'Niche' &&
                                      'disabled-gray'
                                    } bg-category all-boxes p-0`}
                                    onChange={handleChangeCategory}
                                    disabled={selectCategoryNiche === 'Niche'}
                                    value={category}
                                  />
                                </div>
                                <div className="col-lg-6 pl-lg-2">
                                  <Select
                                    name="niche"
                                    options={optionsNiche}
                                    onlySelect
                                    placeholder="Niche"
                                    className={`${
                                      selectCategoryNiche === 'Category' &&
                                      'disabled-gray'
                                    } bg-category all-boxes p-0`}
                                    onChange={handleChangeNiche}
                                    disabled={
                                      selectCategoryNiche === 'Category'
                                    }
                                    value={niche}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2 mb-5 px-lg-2">
                              <h3 className="d-flex align-items-center align-items-lg-start mb-4 mb-lg-5">
                                <span className="mr-2 bg-target">2</span>What do
                                you want to create?
                              </h3>

                              <Select
                                name="target"
                                options={targetData}
                                onlySelect
                                onChange={handleChangeTarget}
                                placeholder="Target"
                                className="all-boxes bg-target p-0"
                                value={target}
                              />
                            </div>
                            <div className="col-lg-2 mb-5 px-lg-2">
                              <h3 className="d-flex align-items-center mb-4 mb-lg-5">
                                <span className="mr-2 bg-act">3</span>Act as
                                what role?
                              </h3>

                              <Select
                                name="act"
                                options={roleData}
                                onlySelect
                                onChange={handleChangeRole}
                                placeholder="Act as"
                                className="all-boxes bg-act p-0"
                                value={role}
                              />
                            </div>
                            <div className="col-lg-2 mb-5 px-lg-2">
                              <h3 className="d-flex align-items-center mb-4 mb-lg-5">
                                <span className="mr-2 bg-sentiment">4</span>What
                                sentiment?
                              </h3>

                              <Select
                                name="sentiment"
                                options={sentimentData}
                                onChange={handleChangeSentiment}
                                onlySelect
                                placeholder="Sentiment"
                                className="all-boxes bg-sentiment p-0"
                                value={sentiment}
                              />
                            </div>
                            <div className="col-lg-2 mb-5 px-lg-2">
                              <h3 className="d-flex align-items-center align-items-lg-start mb-4 mb-lg-5">
                                <span className="mr-2 bg-context">5</span>
                                Context of the setting?
                              </h3>

                              <Select
                                name="context"
                                options={contextData}
                                onChange={handleChangeContext}
                                onlySelect
                                placeholder="Context"
                                className="all-boxes bg-context p-0"
                                value={context}
                              />
                            </div>
                          </div>
                          {showPrompt && (
                            <div className="row">
                              <div className="col-lg-4 px-lg-2">
                                <div className="bg-output p-4 p-lg-5">
                                  <h2 className="mb-4">Prompt Output</h2>
                                  <p className="mb-5">
                                    Act as a{' '}
                                    <span className="color-sentiment">
                                      {sentiment && sentiment !== 'Sentiment'
                                        ? sentiment
                                        : 'confident'}
                                    </span>{' '}
                                    <span className="color-act-as">
                                      {role && role !== 'Act As'
                                        ? role
                                        : 'Celebrity Chef'}
                                    </span>{' '}
                                    in{' '}
                                    <span className="color-context">
                                      {context && context !== 'Context'
                                        ? context
                                        : 'a high class restaurant kitchen'}
                                    </span>{' '}
                                    who needs a{' '}
                                    <span className="color-target">
                                      {target && target !== 'Target'
                                        ? target
                                        : 'blog post'}
                                    </span>{' '}
                                    for{' '}
                                    <span className="color-category-niche">
                                      {searchBy && searchBy !== 'Niche'
                                        ? searchBy
                                        : 'traffic'}
                                    </span>
                                  </p>
                                  <div className="d-flex justify-content-end">
                                    <button
                                      type="button"
                                      onClick={generatePrompt}
                                      disabled={isPromptResultGenerate}
                                    >
                                      {btnGenerate}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {isPromptGenerated && (
                                <div className="col-lg-8 px-lg-2 mt-5 mt-lg-0">
                                  <div className="bg-result p-4 p-lg-5 h-100">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h2 className="mb-0">Prompt Result</h2>
                                      <button
                                        onClick={() =>
                                          handleClickCopy(
                                            `${result[0].title}\n${result[0].introduction}\n${result[0].body}`
                                          )
                                        }
                                        value={result[0].title}
                                        type="button"
                                        className="bg-transparent border-0"
                                        title="Click to copy"
                                      >
                                        <img src={copy} alt="Copy" />
                                      </button>
                                    </div>
                                    <div className="h-result mt-4">
                                      <span
                                        className="prompt-result"
                                        dangerouslySetInnerHTML={{
                                          __html: result[0].body,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          </div>
          <CopyAlert show={copied} className="alert">
            <p className="mb-0">Copied</p>
          </CopyAlert>
        </>
      )}
    </Container>
  );
};

export default PromptEase;
