import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    color: #021027;
    font-family: 'Inter';
    font-size: 32px;
    font-style: normal;
    font-weight: 500;

    line-height: 57px;
  }
  a {
    color: #615f5f;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 400;
    background-color: transparent;
    border: none;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -webkit-text-fill-color: #615f5f;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: transparent;
    :hover {
      color: #021027;
      -webkit-text-fill-color: #021027;
      -webkit-text-stroke-color: #021027;
    }
  }

  .active {
    color: #021027;
    -webkit-text-fill-color: #021027;
    -webkit-text-stroke-color: #021027;
  }

  hr {
    border-top: 2px solid #116bff;
    background: #116bff;
    box-shadow: 0px 4px 15px 0px rgba(17, 107, 255, 0.5);
    margin: 3px 0;
  }

  @media screen and (min-width: 992px) {
    h1 {
      font-size: 36px;
      line-height: 80px;
    }
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1200px) {
    .zoom {
      zoom: 0.9;
    }
  }
  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 1;
    }
  }
`;
