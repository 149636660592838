import styled, { css } from 'styled-components';

interface ContainerProps {
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-radius: 10px;
  border: 1.5px solid #3e3e3e;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;

  + div {
    margin-top: 8px;
  }

  div {
    width: 100%;
    position: relative;

    .button-show {
      position: absolute;
      top: -6px;
      right: -10px;
      width: 70px;
      text-align: center;
      background: none;
      border: 0;
      border-left: 1px solid #606060;
      padding: 6px 6px 6px;
      color: #626262;
      height: 52px;
    }

    .input-signIn {
      height: 37px !important;
    }
  }

  @media screen and (min-width: 1200px) {
    div {
      .input-signIn {
        height: 37px !important;
      }
    }
  }

  input {
    width: 100%;
    flex: 1;
    background: transparent;
    border: 0;
    color: #626262;
    transition-duration: 0.2s;

    ::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
    color: #666360;
    transition-duration: 0.2s;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFilled &&
    css`
      svg {
        color: #b58533;
      }
    `}

  ${(props) =>
    props.isFocuses &&
    css`
      color: #606060;
      border-color: #606060;

      svg {
        color: #b58533;
      }
    `}
`;
