import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

export const Container = styled.div`
  .container-fluid {
    max-width: 1580px;
  }

  .slick-dots {
    z-index: 10 !important;
  }

  @media screen and (min-width: 1200px) {
    .zoom {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1600px) {
    .zoom {
      zoom: 0.9;
    }
  }

  @media screen and (min-width: 1700px) {
    .zoom {
      zoom: 1;
    }
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #021027;
  }
  p {
    color: #303030;
  }

  .search {
    padding: 1px;
    border-radius: 100px;
    border: 1px solid #4a4b4b;
    background-color: #202020;

    input {
      margin: 5px 0;
      padding: 0 10px;
      border: none;
      border-radius: 100px;
      background-color: #202020;
    }
  }
  .add-training {
    border: 1px solid #4a4b4b;
    background-color: #202020;
    color: #e4e4e4;
    text-decoration: none !important;
    font-size: 12px;
    font-weight: 600;
    :hover {
      border-color: ${lighten(0.1, '#4a4b4b')};
      color: ${lighten(0.1, '#e4e4e4')};
      transition-duration: 0.5s;
      img {
        filter: brightness(1.3);
      }
    }
  }
`;

export const Search = styled.div`
  padding: 1px;
  border-radius: 100px;
  border: 1px solid #d0d0d0;
  background-color: transparent;

  input {
    margin: 5px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: transparent;
    color: #8c8c8c;
  }
`;

export const Card = styled(Link)`
  border-radius: 20px;
  overflow: hidden;

  img {
    border-radius: 20px;
  }

  .top_0 {
    top: 0 !important;
  }

  .training-img {
    height: 380px;
  }

  .mkt-img {
    height: 20vw;
    max-height: 340px;
  }

  > div {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0px;
    border-radius: 20px;
    height: 380px;
    width: calc(100% - 1rem);

    /* p {
      width: 55%;
    } */

    .edit {
      background-color: transparent;
      border: none;
      color: rgba(203, 204, 206, 1);
      text-decoration: none;
      img {
        border-radius: 0px;
      }
      :hover {
        color: ${lighten(0.1, 'rgba(203, 204, 206, 1)')};
        transition-duration: 0.5s;
        img {
          filter: brightness(1.3);
        }
      }
    }

    a.btn-more {
      width: 40%;
      background: rgba(111, 110, 110, 1);
      border: none;
      color: rgba(203, 204, 206, 1);
      text-align: center;

      :hover {
        text-decoration: none;
        color: ${lighten(0.1, 'rgba(203, 204, 206, 1)')};
        background-color: ${lighten(0.1, 'rgba(111, 110, 110, 1)')};
        transition-duration: 0.5s;
      }
    }
  }

  .btn-white {
    border-radius: 13px;
    background: #fff;
    color: #021027;
    font-size: 14px;
    font-weight: 600;
    width: 123.848px;
    height: 36.609px;
  }

  .big-btn {
    border-radius: 15px;
    width: 237px;
    height: 51.609px;
  }

  @media screen and (max-width: 768px) {
    > div {
      height: 300px;
    }
    .training-img {
      height: 300px;
    }

    .mkt-img {
      height: 230px;
    }
  }

  @media screen and (max-width: 575px) {
    .mkt-img {
      height: 200px;
    }
    .btn-more {
      padding: 10px 40px;
    }
  }
`;

export const Skeleton = styled.div`
  .img-height {
    height: 380px;
  }

  .bg-skeleton {
    height: 340px;
  }

  .skeleton {
    background-color: #f1f1f1 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 0) 10%,
        rgba(227, 227, 227, 0.2) 20%,
        rgba(227, 227, 227, 0.5) 40%,
        rgba(227, 227, 227, 0.8) 50%,
        rgba(227, 227, 227, 0.2) 80%,
        rgba(227, 227, 227, 0) 90%,
        rgba(227, 227, 227, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (max-width: 575px) {
    .img-height {
      height: 231px;
    }
  }
`;
