import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { HiOutlineTrash } from 'react-icons/hi';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import { Container, Price, DeleteButton, Skeleton } from './styles';
import Table from '~/components/Table';

interface ITransaction {
  id: string;
  wallet_id: string;
  commission_id: string;
  amount: number;
  type: string;
  member: string;
  status: string;
  created_at: string;
}

interface IGiftCard {
  id: string;
  hash: string;
  price: string;
  used: string;
}

interface ITableData {
  type: string;
  date: string;
  member: string;
  amount: string;
  status: string;
  discount: boolean;
}

interface ITableDataGift {
  id: string;
  status: string;
  card: string;
  amount: string;
}

interface ITransactionHistory {
  updateData?: number;
}

interface IParams {
  slug: string;
}

const TransactionHistory: React.FC<ITransactionHistory> = ({ updateData }) => {
  const params = useParams<IParams>();
  const { language } = useLanguage();
  const location = useLocation();
  const {
    user,
    walletHistory,
    setWalletHistory,
    giftCards,
    setGiftCards,
    setWalletPrice,
  } = useAuth();
  const [page, setPage] = useState('transactions');
  const [walletsHistory, setWalletsHistory] = useState<ITableData[]>([
    {
      type: 'No commissions yet',
      date: '-',
      amount: '-',
      member: '-',
      status: '-',
      discount: false,
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [lang] = useState(() => {
    const langSymbol = location.pathname.substr(0, 3);
    if (langSymbol !== '/en' && langSymbol !== '/es') {
      return ``;
    }

    return langSymbol;
  });

  useEffect(() => {
    setPage(params.slug);
  }, [params.slug]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (page === 'transactions') {
      api
        .get<ITransaction[]>(
          `wallets-history/wallets/${user.affiliate.wallet.id}`
        )
        .then((response) => {
          const data: ITableData[] = response.data.map((walletHistoryData) => ({
            type: walletHistoryData.type,
            date: format(parseISO(walletHistoryData.created_at), 'MM-dd-yyyy'),
            amount: `${
              walletHistoryData.status === 'Refund' ||
              walletHistoryData.status === 'Withdraw' ||
              walletHistoryData.status === 'Processing' ||
              walletHistoryData.status === 'Purchased' ||
              walletHistoryData.status === 'Completed'
                ? '-'
                : ''
            }${formatPrice(walletHistoryData.amount)}`,
            discount:
              walletHistoryData.status === 'Refund' ||
              walletHistoryData.status === 'Withdraw' ||
              walletHistoryData.status === 'Processing' ||
              walletHistoryData.status === 'Purchased' ||
              walletHistoryData.status === 'Completed',
            member: walletHistoryData.member,
            status: walletHistoryData.status,
          }));
          setWalletsHistory(data);
          setLoading(false);
        });
    } else {
      api.get<IGiftCard[]>('gift-cards/users').then((response) => {
        const data = response.data.map((giftCard) => ({
          id: giftCard.id,
          status: giftCard.used ? 'Used' : 'Unused',
          card: giftCard.hash,
          amount: formatPrice(parseFloat(giftCard.price)),
        }));
        setGiftCards(data);
        setLoading(false);
      });
    }
  }, [page, setGiftCards, setWalletHistory, updateData, user.affiliate]);

  const handleClickDelete = useCallback(
    async (giftCardId) => {
      try {
        const response = await api.delete(`gift-cards/users/${giftCardId}`);
        if (response.data) {
          const dataWalletHistory = {
            type: response.data.walletHistory.type,
            date: format(
              parseISO(response.data.walletHistory.created_at),
              'MM-dd-yyyy'
            ),
            amount: formatPrice(response.data.walletHistory.amount),
            withdrawal: response.data.walletHistory.amount < 0,
          };

          const newGiftCards = giftCards.filter(
            (giftCard) => giftCard.id !== giftCardId
          );

          setWalletPrice(response.data.wallet.amount);
          setWalletHistory([dataWalletHistory, ...walletHistory]);
          setGiftCards(newGiftCards);
        }
      } catch (error) {
        // console.log(error);
      }
    },
    [giftCards, setGiftCards, setWalletHistory, setWalletPrice, walletHistory]
  );

  const columns = useMemo(() => {
    if (page === 'transactions') {
      return [
        {
          name: language.wallet.type,
          selector: 'type',
          sortable: true,
        },
        {
          name: language.wallet.date,
          selector: 'date',
          sortable: true,
        },
        {
          name: 'Member',
          selector: 'member',
          sortable: true,
        },
        {
          name: language.wallet.amount,
          selector: 'amount',
          sortable: true,
          cell: (row: ITableData) => (
            <Price
              withdraw={
                row.status === 'Refund' ||
                row.status === 'Withdraw' ||
                row.status === 'Processing' ||
                row.status === 'Purchased' ||
                row.status === 'Completed'
              }
              className="mb-0"
            >
              {row.amount}
            </Price>
          ),
        },
        {
          name: 'Status',
          selector: 'status',
          sortable: true,
          cell: (row: ITableData) => (
            <Price
              withdraw={
                row.status === 'Refund' ||
                row.status === 'Withdraw' ||
                row.status === 'Processing' ||
                row.status === 'Purchased' ||
                row.status === 'Completed'
              }
              className="mb-0"
            >
              {row.status}
            </Price>
          ),
        },
      ];
    }

    return [
      {
        name: language.wallet.status,
        selector: 'status',
        sortable: true,
      },
      {
        name: language.wallet.card,
        selector: 'card',
        sortable: true,
      },
      {
        name: language.wallet.amount,
        selector: 'amount',
        sortable: true,
        cell: (row: ITableData) => (
          <Price
            withdraw={row.status === 'Refund' || row.status === 'Withdraw'}
            className="mb-0"
          >
            {row.amount}
          </Price>
        ),
      },
      {
        name: '',
        selector: 'id',
        sortable: false,
        cell: (row: ITableDataGift) => (
          <>
            {row.status === 'Unused' && (
              <DeleteButton
                type="button"
                onClick={() => handleClickDelete(row.id)}
              >
                <HiOutlineTrash size={24} color="#FF1A50" />
              </DeleteButton>
            )}
          </>
        ),
      },
    ];
  }, [
    handleClickDelete,
    language.wallet.amount,
    language.wallet.card,
    language.wallet.date,
    language.wallet.status,
    language.wallet.type,
    page,
  ]);

  const handleClick = useCallback((pageSelected) => {
    setPage(pageSelected);
  }, []);

  return (
    <Container className="h-100">
      <div className="d-flex mt-3 p-relative link-group">
        <NavLink
          to={`${process.env.PUBLIC_URL}${lang}/wallet/transactions`}
          activeClassName="active"
          className="px-3 font-weight-600 title"
          onClick={() => handleClick('transactions')}
        >
          Commission History
        </NavLink>
      </div>
      {loading && (
        <Skeleton className="col-12 h-xxl-100 mt-4 mt-lg-0 py-5 d-flex flex-column">
          <div className="row justify-content-between">
            <div className="col-4 col-sm-3 my-3 small">
              <p className="skeleton mb-0 px-2">Tipo</p>
            </div>
            <div className="col-4 col-sm-3 my-3 small">
              <p className="skeleton mb-0 px-2">Fecha</p>
            </div>
            <div className="col-4 col-sm-3 my-3 small">
              <p className="skeleton mb-0 px-2">Cantidad</p>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-4 col-sm-3 my-3 small">
              <p className="skeleton mb-0 px-2">Tipo</p>
            </div>
            <div className="col-4 col-sm-3 my-3 small">
              <p className="skeleton mb-0 px-2">Fecha</p>
            </div>
            <div className="col-4 col-sm-3 my-3 small">
              <p className="skeleton mb-0 px-2">Cantidad</p>
            </div>
          </div>
          <div className="row pb-1 pt-3 mt-auto w-100 align-items-center justify-content-center menu-table mx-0 sc-jqCOkK hpTZXp">
            <div className="col-lg-6 px-lg-4 text-center text-lg-left">
              <p className="skeleton mb-lg-0">Demostración 1-1 de 1</p>
            </div>
            <div className="col-lg-6">
              <div className="d-flex w-100 justify-content-center justify-content-lg-end">
                <button
                  type="button"
                  className="skeleton border-0 bg-transparent mx-1 px-1"
                >
                  Primero
                </button>
                <button
                  type="button"
                  className="skeleton border-0 bg-transparent mx-0 mx-sm-0 px-1"
                >
                  01
                </button>
                <button
                  type="button"
                  className="skeleton border-0 bg-transparent mx-1 px-1"
                >
                  Último
                </button>
              </div>
            </div>
          </div>
        </Skeleton>
      )}
      <div className={loading === true ? 'd-none' : 'h-100'}>
        <Table
          columns={columns}
          data={walletsHistory}
          pagination
          title="Historico de Transações"
        />
      </div>
    </Container>
  );
};

export default TransactionHistory;
