/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useState } from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Socket, io } from 'socket.io-client';

import { Container, Avatar } from './styles';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

interface INotificationResponse {
  id: string;
  from_user_id: string;
  to_user_id: string;
  title: string;
  content: string;
  read: boolean;
  created_at: string;
  fromUser: {
    avatar: {
      avatar_url: string;
    };
  };
}

interface INotification {
  id: string;
  avatar: string;
  from_user_id: string;
  to_user_id: string;
  title: string;
  content: string;
  timeAgo: string;
  read: boolean;
}

let socket: Socket;

const Notifications: React.FC = () => {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      socket = io(process.env.REACT_APP_API_URL as string, {
        reconnection: true,
      });

      socket.on('connect', () => {
        socket.emit('join-room', { roomID: `notifications-${user.id}` });
      });

      socket.on('user-disconnected', () => {
        // console.log('user disconnected-- closing peers', data);
      });

      socket.on('disconnect', () => {
        // console.log('socket disconnected --');
      });

      socket.on('error', () => {
        // console.log('socket error --', err);
      });

      socket.on(
        'new-notification',
        async (notification: INotificationResponse) => {
          const newNotification = {
            id: notification.id,
            from_user_id: notification.from_user_id,
            to_user_id: notification.to_user_id,
            title: notification.title,
            avatar: notification.fromUser.avatar.avatar_url,
            content: notification.content,
            timeAgo: `${formatDistanceToNow(
              parseISO(notification.created_at)
            )} ago`,
            read: notification.read,
          };
          setNotifications((state) => [newNotification, ...state]);
        }
      );
    }
  }, [user]);

  useEffect(() => {
    setLoading(true);
    api
      .get<INotificationResponse[]>(`notifications/users`)
      .then((response) => {
        const data = response.data.map((notification) => {
          return {
            id: notification.id,
            from_user_id: notification.from_user_id,
            to_user_id: notification.to_user_id,
            title: notification.title,
            avatar: notification.fromUser.avatar.avatar_url,
            content: notification.content,
            timeAgo: `${formatDistanceToNow(
              parseISO(notification.created_at)
            )} ago`,
            read: notification.read,
          };
        });
        setNotifications(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleClickNotification = useCallback(
    async (notification) => {
      api
        .put(`notifications/${notification.id}`, {
          from_user_id: notification.from_user_id,
          to_user_id: notification.to_user_id,
          title: notification.title,
          content: notification.content,
          read: true,
        })
        .then(() => {
          if (socket.connected) {
            socket.emit('check-notification', '');
          }
        });

      const newNotifications = notifications.slice();
      const notificationIndex = newNotifications.findIndex(
        (notificationData) => notificationData.id === notification.id
      );

      if (notificationIndex >= 0) {
        newNotifications[notificationIndex].read = true;
      }

      setNotifications(newNotifications);
    },
    [notifications]
  );

  return (
    <>
      <Container>
        <div className="container py-4">
          <div className="row">
            <div className="col-12 order-0">
              <div className="d-flex title align-items-center">
                <h1 className="h2 h1-lg mb-4">All Notifications</h1>
              </div>
            </div>
            {notifications.length > 0 ? (
              <>
                {notifications.map((notification) => (
                  <button
                    key={notification.id}
                    type="button"
                    className="col-12 mb-3 border-0 bg-transparent text-left"
                    onClick={() => handleClickNotification(notification)}
                  >
                    <div className="box d-flex position-relative p-5">
                      <Avatar src={notification.avatar} />
                      <div className="content">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: notification.content,
                          }}
                        />
                      </div>
                      <div className="time d-flex align-items-center">
                        <small>{notification.timeAgo}</small>
                        {!notification.read && <div className="notify" />}
                      </div>
                    </div>
                  </button>
                ))}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100 no-notification">
                <p className="h1 mb-0">
                  Chirp, Chirp... <b>No Alerts Today</b> 🐦
                </p>
              </div>
            )}
          </div>
        </div>
        {loading && (
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default Notifications;
