import React, { useEffect, useState } from 'react';

import {
  AvatarFirst,
  AvatarOthers,
  AvatarSecond,
  AvatarThird,
  Container,
} from './styles';

import avatar from '~/assets/defaults/profile-photo.png';
import podiumDesk from '~/assets/defaults/banner-leaderboard.svg';
import podiumMob from '~/assets/defaults/banner-leaderboard-mobile-new.svg';
import gold from '~/assets/defaults/gold1.svg';
import silver from '~/assets/defaults/silver2.svg';
import bronze from '~/assets/defaults/bronze3.svg';

const Today: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const positions = [
    {
      name: 'Name Test1',
      avatar,
      type: 'Founder',
      sales: '7',
      referrals: '21',
      position: 'up',
    },
    {
      name: 'Name Test2',
      avatar,
      type: 'Founder',
      sales: '7',
      referrals: '21',
      position: 'down',
    },
    {
      name: 'Name Test3',
      avatar,
      type: 'Founder',
      sales: '7',
      referrals: '21',
      position: 'down',
    },
    {
      name: 'Name Test4',
      avatar,
      type: 'Founder',
      sales: '7',
      referrals: '21',
      position: 'up',
    },
    {
      name: 'Name Test5',
      avatar,
      type: 'Founder',
      sales: '7',
      referrals: '21',
      position: 'down',
    },
    {
      name: 'Name Test6',
      avatar,
      type: 'Founder',
      sales: '7',
      referrals: '21',
      position: 'up',
    },
    {
      name: 'Name Test7',
      avatar,
      type: 'Founder',
      sales: '7',
      referrals: '21',
      position: 'down',
    },
    {
      name: 'Name Test8',
      avatar,
      type: 'Founder',
      sales: '7',
      referrals: '21',
      position: 'down',
    },
    {
      name: 'Name Test9',
      avatar,
      type: 'Founder',
      sales: '7',
      referrals: '21',
      position: 'up',
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [positions]);
  return (
    <Container className="container px-0 px-sm-3">
      <div className="row">
        <div className="col-12 px-0 px-sm-3">
          <div className="position-relative">
            <img src={podiumMob} alt="Podium" className="d-lg-none w-100" />
            <img
              src={podiumDesk}
              alt="Podium"
              className="d-none d-lg-block w-100"
            />
            <div className="position-absolute d-flex flex-column align-items-center  avatar-second zoom">
              <div className="blur-silver" />
              <div className="bd-silver position-relative">
                <div className="bs-silver">
                  {loading ? (
                    <div className=" skeleton loading" />
                  ) : (
                    <AvatarSecond avatar={positions[1].avatar} className="" />
                  )}
                </div>
              </div>
              {loading ? (
                <div className="skeleton text-loading mt-2 mt-lg-4" />
              ) : (
                <>
                  <h2 className="mb-0 mb-sm-1 mt-2 mt-lg-4">
                    {positions[1].name}
                  </h2>
                  <p>{positions[1].type}</p>
                </>
              )}
            </div>
            <div className="d-flex second-place-text align-items-center position-absolute zoom">
              <img src={silver} alt="Silver" className="d-none d-lg-block" />
              <div className="ml-lg-2">
                <h3 className="d-none d-lg-block mb-1">SECOND PLACE</h3>
                <h3 className="d-lg-none mb-0 mb-lg-1">
                  <img
                    src={silver}
                    alt="Silver"
                    className="mr-1 mr-sm-2 d-lg-none"
                  />
                  2nd PLACE
                </h3>

                <p className="mb-0 text-center text-lg-start">
                  <span className="d-block d-lg-inline-block  mr-lg-2">
                    <b className="fw-regular">10</b> Sales
                  </span>
                  <span>
                    <b className="fw-regular">28</b> Referrals
                  </span>
                </p>
              </div>
            </div>
            <div className="position-absolute d-flex flex-column align-items-center  avatar-first zoom">
              <div className="blur-gold" />
              <div className="bd-gold position-relative">
                <div className="bs-gold">
                  {loading ? (
                    <div className=" skeleton loading" />
                  ) : (
                    <AvatarFirst avatar={positions[0].avatar} className="" />
                  )}
                </div>
              </div>
              {loading ? (
                <div className=" skeleton text-loading mt-2 mt-lg-4" />
              ) : (
                <>
                  <h2 className="mb-0 mb-sm-1 mt-2 mt-lg-4">
                    {positions[0].name}
                  </h2>
                  <p>{positions[0].type}</p>
                </>
              )}
            </div>
            <div className="d-flex first-place-text align-items-center position-absolute zoom">
              <img src={gold} alt="Gold" className="d-none d-lg-block" />

              <div className="ml-lg-2">
                <h3 className="d-none d-lg-block mb-1">FIRST PLACE</h3>
                <h3 className="d-lg-none mb-0 mb-lg-1">
                  <img
                    src={gold}
                    alt="Gold"
                    className="mr-1 mr-sm-2 d-lg-none"
                  />
                  1st PLACE
                </h3>
                <p className="mb-0 text-center text-lg-start">
                  <span className="d-block d-lg-inline-block  mr-lg-2">
                    <b className="fw-regular">10</b> Sales
                  </span>
                  <span>
                    <b className="fw-regular">28</b> Referrals
                  </span>
                </p>
              </div>
            </div>

            <div className="position-absolute d-flex flex-column align-items-center  avatar-third zoom">
              <div className="blur-bronze" />
              <div className="bd-bronze position-relative">
                <div className="bs-bronze">
                  {loading ? (
                    <div className=" skeleton loading" />
                  ) : (
                    <AvatarThird avatar={positions[2].avatar} className="" />
                  )}
                </div>
              </div>
              {loading ? (
                <div className=" skeleton text-loading mt-2 mt-lg-4" />
              ) : (
                <>
                  <h2 className="mb-0 mb-sm-1 mt-2 mt-lg-4">
                    {positions[2].name}
                  </h2>
                  <p>{positions[2].type}</p>
                </>
              )}
            </div>
            <div className="d-flex third-place-text align-items-center position-absolute zoom">
              <img src={bronze} alt="Bronze" className="d-none d-lg-block" />

              <div className="ml-lg-2">
                <h3 className="d-none d-lg-block mb-1">THIRD PLACE</h3>
                <h3 className="d-lg-none mb-0 mb-lg-1">
                  <img
                    src={bronze}
                    alt="Bronze"
                    className="mr-1 mr-sm-2 d-lg-none"
                  />
                  3rd PLACE
                </h3>
                <p className="mb-0 text-center text-lg-start">
                  <span className="d-block d-lg-inline-block  mr-lg-2">
                    <b className="fw-regular">10</b> Sales
                  </span>
                  <span>
                    <b className="fw-regular">28</b> Referrals
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 px-2 px-sm-3 mt-4 mt-lg-5 zoom">
          {loading ? (
            <>
              <div className="skeleton loading-height" />
              <div className="skeleton loading-height" />
            </>
          ) : (
            <div className="height">
              {positions.map((pos, index) => (
                <>
                  {index > 2 && (
                    <div
                      key={index}
                      className={`d-flex bd-row px-3 px-md-4 px-lg-5 justify-content-between ${
                        index % 2 === 0 ? 'bg-even ' : 'bg-odd'
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        <span className="position pr-2 pr-lg-4">
                          #{index + 1}
                        </span>
                        <div className="d-flex align-items-center">
                          <div className="bs-avatar mx-3 mx-sm-4">
                            <AvatarOthers avatar={avatar} />
                          </div>
                          <span className="name pr-3 pr-lg-0">{pos.name}</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center sales">
                        <div className="d-lg-flex">
                          <div className="pr-4 pr-lg-5">
                            <span className="d-lg-block font-weight-normal mr-2 mr-lg-0">
                              {pos.sales}
                            </span>
                            <span className="d-lg-block">Sales</span>
                          </div>
                          <div className="pr-4 pr-lg-5">
                            <span className="d-lg-block font-weight-normal text-lg-right mr-2 mr-lg-0">
                              {pos.referrals}
                            </span>
                            <span className="d-lg-block">Referrals</span>
                          </div>
                        </div>
                        <div
                          className={`${
                            pos.position === 'down' && 'rotate180'
                          }`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M10.2484 0.664588C10.3311 0.766293 10.685 1.15032 10.9761 1.50804C12.8069 3.60526 15.8036 9.07628 16.7183 11.9398C16.8652 12.3747 17.1763 13.4741 17.1963 14.0616C17.1963 14.6245 17.0935 15.161 16.8852 15.6731C16.5941 16.3113 16.1361 16.8234 15.5952 17.1039C15.2199 17.2846 14.0969 17.5651 14.0769 17.5651C12.8483 17.8457 10.852 18 8.64589 18C6.54396 18 4.62897 17.8457 3.3818 17.616C3.36182 17.5897 1.96625 17.3091 1.48821 17.0022C0.614906 16.4394 0.0726584 15.3399 0.0726584 14.1633V14.0616C0.0940627 13.2953 0.636312 11.6838 0.65629 11.6838C1.5724 8.97457 4.42206 3.62981 6.31565 1.48173C6.31565 1.48173 6.80224 0.876766 7.10619 0.613736C7.54284 0.20341 8.08366 0 8.62448 0C9.22809 0 9.79032 0.229713 10.2484 0.664588Z"
                              fill={`${
                                pos.position === 'up' ? '#1DF18B' : '#FF1A50'
                              }`}
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Today;
