import React, { createContext, useContext, useEffect, useState } from 'react';
import IntercomComponent from 'react-intercom';
import { useAuth } from './Auth';

interface IntercomContextData {
  Intercom: any;
  setUserData: React.Dispatch<React.SetStateAction<IUser>>;
}

export const IntercomContext = createContext<IntercomContextData>(
  {} as IntercomContextData
);

interface IUser {
  name?: string;
  email?: string;
  created_at?: string;
}

export const IntercomProvider: React.FC = ({ children }) => {
  const [userData, setUserData] = useState({} as IUser);
  const { user } = useAuth();

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      setUserData({
        name: user.name || 'Pending',
        email: user.email,
        created_at: user.created_at,
      });
    }
  }, [user]);

  return (
    <IntercomContext.Provider
      value={{
        Intercom:
          Object.keys(userData).length > 0 && userData.name !== 'Pending' ? (
            <IntercomComponent appID="leiyim3q" {...userData} />
          ) : (
            <IntercomComponent appID="leiyim3q" />
          ),
        setUserData,
      }}
    >
      {children}
    </IntercomContext.Provider>
  );
};

export function useIntercom(): IntercomContextData {
  const context = useContext(IntercomContext);

  if (!context) {
    throw new Error('useIntercom must be used within an IntercomProvider');
  }

  return context;
}
