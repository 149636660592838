import styled from 'styled-components';
import support from '~/assets/defaults/support-new.png';

export const Container = styled.div`
  background-image: url(${support});
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  min-height: 300px;

  /* div {
    background: rgba(255, 255, 255, 0.29);
    opacity: 0.6;
    backdrop-filter: blur(15px);
    border-radius: 12px;
    width: 100%;
    height: 50px;
  } */

  h4 {
    background: #ff1a50;

    backdrop-filter: blur(15px);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #fff !important;
    padding: 12px;
    border-radius: 12px;
  }

  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #200e32;
  }

  .link-facebook {
    display: block;
    background: rgba(255, 255, 255, 0.68);

    backdrop-filter: blur(15px);
    border-radius: 12px;
    text-decoration: none;
    span {
      display: block;
      color: #000;
      text-align: center;
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 400;
      line-height: normal;
    }
    .title {
      display: block;
      color: #f5f5f5;
      text-align: center;
      font-family: 'Inter';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 12px;
      background: #116bff;
      padding: 13px;
    }
  }

  .link-whatsapp {
    display: block;
    background: rgba(255, 255, 255, 0.68);

    backdrop-filter: blur(15px);
    border-radius: 12px;
    text-decoration: none;
    span {
      display: block;
      color: #000;
      text-align: center;
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 400;
      line-height: normal;
    }
    .title {
      display: block;
      color: #f5f5f5;
      text-align: center;
      font-family: 'Inter';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 12px;
      background: #0dc143;
      padding: 13px;
    }
  }

  .link-zoom {
    display: block;
    background: rgba(255, 255, 255, 0.68);

    backdrop-filter: blur(15px);
    border-radius: 12px;
    text-decoration: none;
    span {
      display: block;
      color: #000;
      text-align: center;
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 400;
      line-height: normal;
    }
    .title {
      display: block;
      color: #f5f5f5;
      text-align: center;
      font-family: 'Inter';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 12px;
      background: #151616;
      padding: 13px;
    }
  }

  @media screen and (max-width: 1199px) {
    min-height: 194px;
  }

  @media screen and (max-width: 991px) {
    min-height: 300px;
  }
`;
