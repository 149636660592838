import React, { createContext, useCallback, useContext, useState } from 'react';

import { Container } from './styles';
import Chat from './Chat';

interface IChat {
  id: string;
  name: string;
  minimize: boolean;
}

interface ChatContextData {
  addChat(chat: IChat): void;
}

export const ChatContext = createContext<ChatContextData>(
  {} as ChatContextData
);

export const ChatProvider: React.FC = ({ children }) => {
  const [chats, setChats] = useState<IChat[]>([]);

  const handleAddChat = useCallback(
    (chat: IChat) => {
      const checkChat = chats.find((chatData) => chatData.id === chat.id);

      if (!checkChat) {
        setChats([...chats, chat]);
      }
    },
    [chats]
  );

  const handleMinimize = useCallback(
    (chat: IChat, minimize) => {
      const newChats = chats.slice();
      const chatIndex = newChats.findIndex(
        (chatData) => chatData.id === chat.id
      );
      if (chatIndex >= 0) {
        newChats[chatIndex].minimize = minimize;
      }
      setChats(newChats);
    },
    [chats]
  );

  const handleClose = useCallback(
    (chat: IChat) => {
      const newChats = chats.filter((chatData) => chatData.id !== chat.id);
      setChats(newChats);
    },
    [chats]
  );

  return (
    <ChatContext.Provider value={{ addChat: handleAddChat }}>
      {children}
      <Container className="px-3 d-flex align-items-end overflow-auto">
        {chats.map((chat) => (
          <Chat
            key={chat.id}
            data={chat}
            onClose={handleClose}
            onMinimize={handleMinimize}
          />
        ))}
      </Container>
    </ChatContext.Provider>
  );
};

export function useChat(): ChatContextData {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be used within an ChatProvider');
  }

  return context;
}
