import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import api from '~/services/api';
import Categories from '~/components/Categories';
import { Container, Welcome, Search, Card, Skeleton } from './styles';
import { useLanguage } from '~/hooks/Language';

import search from '~/assets/icons/search-icon.svg';

interface ITrainingResponse {
  id: string;
  title: string;
  description: string;
  thumbnail: {
    thumbnail_url: string;
  };
  slug: string;
}

interface ITrainingCategoryResponse {
  id: string;
  training: ITrainingResponse;
}

interface ITraining {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  slug: string;
}

interface IParamsProps {
  slug?: string;
}

const TrainingList: React.FC = () => {
  const { language } = useLanguage();
  const params = useParams<IParamsProps>();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [category, setCategory] = useState(
    `${language.affiliates_categories.nav}`
  );
  const [trainings, setTrainings] = useState<ITraining[]>([]);
  const [loading, setLoading] = useState(true);
  const [carousel, setCarousel] = useState<ITraining[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.slug) {
      setSearchValue('');
      api
        .get(`categories/users/${params.slug}`, {
          params: {
            type: 'trainings',
          },
        })
        .then(async (response) => {
          const data = response.data.trainingCategory.map(
            (trainingCategory: ITrainingCategoryResponse) => ({
              id: trainingCategory.training.id,
              title: trainingCategory.training.title,
              description: trainingCategory.training.description,
              thumbnail: trainingCategory.training.thumbnail.thumbnail_url,
              slug: trainingCategory.training.slug,
            })
          );
          setTrainings(data);

          setCategory(response.data.name);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      api
        .get('trainings/users/')
        .then(async (response) => {
          const data = response.data.map((training: ITrainingResponse) => ({
            id: training.id,
            title: training.title,
            description: training.description,
            thumbnail: training.thumbnail.thumbnail_url,
            slug: training.slug,
          }));
          setTrainings(data);
          setCarousel(data);
          setCategory(`${language.affiliates_categories.nav}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [language.affiliates_categories.nav, params.slug]);

  const handleSearch = useCallback(
    async (e) => {
      history.push(`${process.env.PUBLIC_URL}/trainings`);
      setSearchValue(e.target.value);
      const response = await api.get('trainings/users', {
        params: {
          title: e.target.value,
        },
      });

      if (response.data) {
        const data = response.data.map((training: ITrainingResponse) => ({
          id: training.id,
          title: training.title,
          description: training.description,
          thumbnail: training.thumbnail.thumbnail_url,
          slug: training.slug,
        }));
        setTrainings(data);
      }
    },
    [history]
  );

  return (
    <Container>
      <div className="container-fluid px-xxl-4 zoom">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-9">
                    <h1 className="h2 mb-0">
                      {language.affiliates_training.h1}
                    </h1>
                    <p className="h5 fw-normal mt-4 font-weight-300">
                      Here you will find trainings, guides, and content designed
                      to help you grow your business.
                    </p>
                  </div>
                  <div className="d-none d-lg-block col-lg-3 mt-4 mt-sm-0 text-center text-sm-left">
                    <Search className="d-flex">
                      <input
                        className="w-100"
                        placeholder={`${language.affiliates_training.placeholder_1}`}
                        onChange={handleSearch}
                        value={searchValue}
                      />
                      <img src={search} alt="Search" className="mx-2" />
                    </Search>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          {loading && (
            <>
              <Skeleton className="col-xl-9 mt-4">
                <div className="skeleton img-height w-100" />
              </Skeleton>

              <Skeleton className="mt-5 col-md-4 col-xl-3">
                <div className="h-100">
                  <h2 className="h5 skeleton text-right text-md-left py-1 px-5 mb-0">
                    Categorías
                  </h2>
                  <div className="skeleton py-4 w-100 mt-3 mt-md-4" />
                </div>
              </Skeleton>

              <Skeleton className="col-md-8 col-xl-12">
                <div className="row">
                  <div className="col-12 pt-5">
                    <h2 className="skeleton px-3 py-1 h5">Todos</h2>
                  </div>
                  <div className="col-sm-6 col-xl-3 my-3">
                    <div className="skeleton bg-skeleton w-100" />
                  </div>
                  <div className="col-sm-6 col-xl-3 my-3">
                    <div className="skeleton bg-skeleton w-100" />
                  </div>
                  <div className="col-sm-6 col-xl-3 my-3">
                    <div className="skeleton bg-skeleton w-100" />
                  </div>
                  <div className="col-sm-6 col-xl-3 my-3">
                    <div className="skeleton bg-skeleton w-100" />
                  </div>
                </div>
              </Skeleton>
            </>
          )}
          {!loading && (
            <>
              <div className="col-xl-9 mt-4">
                <Slider
                  autoplaySpeed={5000}
                  autoplay
                  dots
                  infinite
                  speed={2000}
                  slidesToShow={1}
                >
                  {carousel.map((trainingCarrousel) => (
                    <Card
                      to={`${process.env.PUBLIC_URL}/trainings/${trainingCarrousel.slug}`}
                      className="p-relative text-white px-2"
                    >
                      <>
                        <img
                          src={trainingCarrousel.thumbnail}
                          alt={trainingCarrousel.title}
                          className="w-100 training-img"
                        />
                        <div className="d-flex justify-content-between p-absolute p-4">
                          <p className="mb-0 pl-4 pr-0 pr-sm-4 pt-4 h4 h2-sm h1-xxl">
                            {trainingCarrousel.title}
                          </p>
                          <button
                            type="button"
                            className="btn btn-white big-btn align-self-end"
                          >
                            See More
                          </button>
                        </div>
                      </>
                    </Card>
                  ))}
                </Slider>
              </div>
              <div className="mt-4 col-md-4 col-xl-3 pl-xxl-4">
                <Categories type="trainings" />
              </div>
              <div className="col-md-8 col-xl-12 mt-0">
                <div className="row justify-content-lg-between justify-content-xl-start">
                  <div className="col-12 pt-5">
                    <h2 className="h5">
                      {searchValue ? `Filter: ${searchValue}` : category}
                    </h2>
                  </div>

                  {trainings.length > 0 ? (
                    <>
                      {trainings.map((training) => (
                        <Fragment key={training.id}>
                          <div className="col-sm-6 col-xl-3 my-3">
                            <Card
                              to={`${process.env.PUBLIC_URL}/trainings/${training.slug}`}
                              className="p-relative d-block text-white"
                            >
                              <img
                                src={training.thumbnail}
                                alt={training.title}
                                className="w-100 mkt-img"
                              />
                              <div className="p-absolute w-100 h-100 top_0">
                                <div className="w-100 h-100 row mx-0">
                                  <div className="col-12 d-flex flex-column justify-content-between p-4">
                                    <p className="mb-0 h4 h2-xxl">
                                      {training.title}
                                    </p>
                                    <button
                                      type="button"
                                      className="btn btn-white"
                                    >
                                      See More
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          </div>
                        </Fragment>
                      ))}
                    </>
                  ) : (
                    <div className="col-12">
                      <p className="h5 my-5 text-center">
                        {language.affiliates_training.p_2}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default TrainingList;
