import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useLanguage } from '~/hooks/Language';
import { Note } from './styles';
import notes from '~/assets/icons/notes.svg';
import api from '~/services/api';

interface ILesson {
  id: string;
}

interface IAnnotation {
  id: string;
  content: string;
}

let typingTimer = 0;

const Notes: React.FC<ILesson> = ({ id }) => {
  const [textareaSelected, setTextareaSelected] = useState(false);
  const [textareaContent, setTextareaContent] = useState(0);
  const { language } = useLanguage();

  const [annotation, setAnnotation] = useState<IAnnotation>({
    id: '',
    content: '',
  });

  const handleClick = useCallback(() => {
    setTextareaSelected(true);
  }, []);

  const handleFocus = useCallback(() => {
    setTextareaSelected(true);
  }, []);

  const handleBlur = useCallback(() => {
    setTextareaSelected(false);
  }, []);

  const handleDoneTyping = useCallback(
    async (content: string) => {
      const formData = {
        lesson_id: id,
        content,
      };

      let response: AxiosResponse;

      if (annotation.id) {
        response = await api.put(`annotations/${annotation.id}`, formData);
      } else {
        response = await api.post('annotations', formData);
      }

      setAnnotation(response.data);
    },
    [annotation.id, id]
  );

  const handleChange = useCallback(
    (e) => {
      const content = e.target.value;
      setAnnotation((state) => ({
        id: state.id,
        content,
      }));
      setTextareaContent(e.target.value.length);
      clearTimeout(typingTimer);
      typingTimer = setTimeout(handleDoneTyping, 2000, e.target.value);
    },
    [handleDoneTyping]
  );

  useEffect(() => {
    api.get(`annotations/users/${id}`).then((response) => {
      setAnnotation(response.data);
      setTextareaContent(response.data.content.length);
    });
  }, [id]);

  return (
    <Note
      className="mb-3 px-3 py-4"
      textareaSelected={textareaSelected || textareaContent !== 0}
    >
      {!textareaSelected && textareaContent === 0 && (
        <button
          type="button"
          className="border-0 bg-transparent d-flex align-items-center"
          onClick={handleClick}
        >
          <img src={notes} alt="File" className="mr-1" />
          <p className="mb-0 ml-1">{language.notes.p}</p>
        </button>
      )}
      <textarea
        id="notes"
        value={annotation.content}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        className="form-control"
      />
    </Note>
  );
};

export default Notes;
