import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntercom } from '~/hooks/Intercom';

const AuthLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const { Intercom } = useIntercom();

  //

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {children}
      {Intercom}
    </>
  );
};

export default AuthLayout;
