import React, { useMemo, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import SliderSlick, { Settings } from 'react-slick';

import { TbCloudDownload } from 'react-icons/tb';
import { Container, Item, ButtonLeft, ButtonRight } from './styles';

import Player from '../PlayerPostEase';

interface IVideo {
  thumbnail: string;
  src: string;
  isImage: boolean;
}

interface ICarousel {
  className?: string;
  video: IVideo[];
  onSlideChange(): void;
}

interface IButtonProps {
  className?: string;
  onClick?(): void;
}

const PrevArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  const disabled = useMemo(() => {
    if (className) {
      if (className.match('slick-disabled')) {
        return true;
      }
      return false;
    }
    return false;
  }, [className]);

  return (
    <ButtonLeft
      type="button"
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <IoIosArrowBack size={36} />
      </div>
    </ButtonLeft>
  );
};

const NextArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  const disabled = useMemo(() => {
    if (className) {
      if (className.match('slick-disabled')) {
        return true;
      }
      return false;
    }
    return false;
  }, [className]);

  return (
    <ButtonRight
      type="button"
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <IoIosArrowForward size={36} />
      </div>
    </ButtonRight>
  );
};

const Carousel: React.FC<ICarousel> = ({ className, video, onSlideChange }) => {
  const [videoDownload, setVideoDownload] = useState('');
  const settings: Settings = useMemo(
    () => ({
      infinite: false,
      slidesToShow: 1,
      centerPadding: '0px',
      autoplay: false,
      autoplaySpeed: 3000,
      speed: 500,
      arrows: true,
      touchMove: true,
      dots: true,

      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      beforeChange: () => {
        setVideoDownload('');
        onSlideChange();
      },
    }),
    [onSlideChange]
  );

  return (
    <Container className={className}>
      <SliderSlick {...settings}>
        {video.map((item, index) => (
          <>
            {item.isImage ? (
              <Item key={index} className="position-relative">
                <div className="bg-black">
                  <img src={item.src} alt="Thumbnail" className="w-100" />
                </div>
              </Item>
            ) : (
              <Item key={index} className="position-relative">
                <Player
                  id={index.toString()}
                  src={item.src}
                  thumbnail={item.thumbnail}
                  onPlay={() => setVideoDownload(item.src)}
                />
              </Item>
            )}
          </>
        ))}
      </SliderSlick>
      {videoDownload && (
        <div className="d-flex justify-content-center mt-5">
          <a
            href={videoDownload}
            title="Download Video"
            download
            target="_blank"
            rel="noopener noreferrer"
            className="h4 text-white download"
          >
            Download Content
          </a>
        </div>
      )}
    </Container>
  );
};

export default Carousel;
