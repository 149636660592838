import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';

import { Container, Menu } from './styles';

import en from '~/assets/icons/eua-flag.png';
import es from '~/assets/icons/spain-flag.png';
import logo from '~/assets/logotipos/logo.png';
import Notications from './Notications';

const Header: React.FC = () => {
  const { user, signOut } = useAuth();
  const { language, handleSelectIdiom, idiom } = useLanguage();
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [lang] = useState(() => {
    const langSymbol = location.pathname.substr(0, 3);
    if (langSymbol !== '/en' && langSymbol !== '/es') {
      return ``;
    }

    return langSymbol;
  });

  const nextStep = localStorage.getItem(`next_step_${user.id}`);

  useEffect(() => {
    setActive(false);
  }, [location]);

  return (
    <Container className="w-100 vh-lg-100 d-flex flex-lg-column justify-content-between align-items-center text-center menu-header">
      <div className="d-flex flex-column py-lg-3">
        <Link to="/">
          <img src={logo} alt="logo" className="pl-2 pl-lg-0 w-75" />
        </Link>
      </div>
      <Menu active={active} className="h-lg-100 w-auto w-lg-100">
        <button
          type="button"
          className="bg-transparent d-lg-none border-0 mx-3"
          onClick={() => setActive(!active)}
        >
          <span />
          <span />
          <span />
        </button>
        <div className="h-lg-100 d-flex flex-column p-absolute p-lg-relative menu-group overflow active">
          <div className="d-flex flex-column px-lg-0 pt-5 py-lg-1 py-xl-3 h-lg-100 menu">
            <Link
              to={`${process.env.PUBLIC_URL}${lang}/lessons/welcome${
                nextStep !== null ? `/${nextStep}` : ''
              }`}
              className={`menu-item px-2 px-lg-0 my-2 my-lg-0 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center ${
                location.pathname.match(/\/lessons.*/) ? 'active' : ''
              }`}
            >
              <div className="trainings d-none d-lg-block" />
              <small>Onboarding</small>
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}${lang}/dashboard`}
              className={`menu-item px-2 px-lg-0 my-2 my-lg-0 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center ${
                location.pathname.match(/\/dashboard.*/) ? 'active' : ''
              }`}
            >
              <div className="dashboard d-none d-lg-block" />
              <small>{language.header.small_1}</small>
            </Link>
            {false && (
              <Link
                to={`${process.env.PUBLIC_URL}${lang}/traffic/how-to-get-traffic-to-your-links`}
                className={`menu-item px-2 px-lg-0 my-2 my-lg-0 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center ${
                  location.pathname.match(/\/traffic.*/) ? 'active' : ''
                }`}
              >
                <div className="traffic d-none d-lg-block" />
                <small>Traffic</small>
              </Link>
            )}
            <Link
              to={`${process.env.PUBLIC_URL}${lang}/trainings`}
              className={`menu-item px-2 px-lg-0 my-2 my-lg-0 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center ${
                location.pathname.match(/\/trainings.*/) ? 'active' : ''
              }`}
            >
              <div className="trainings-course d-none d-lg-block" />
              <small>Trainings</small>
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}${lang}/profile/my-profile`}
              className={`menu-item px-2 px-lg-0 my-2 my-lg-0 py-xl-3 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center ${
                location.pathname.match(/\/profile.*/) ? 'active' : ''
              }`}
            >
              <div className="profile d-none d-lg-block" />
              <small>{language.header.small_7}</small>
            </Link>
            {false && (
              <Link
                to={`${process.env.PUBLIC_URL}${lang}/resources`}
                className={`menu-item px-2 px-lg-0 my-2 my-lg-0 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center ${
                  location.pathname.match(/\/resources.*/) ? 'active' : ''
                }`}
              >
                <div className="resources d-none d-lg-block" />
                <small>
                  {language.header.small_3_1} {language.header.small_3_2}
                </small>
              </Link>
            )}
            <Link
              to={`${process.env.PUBLIC_URL}${lang}/wallet/transactions`}
              className={`menu-item px-2 px-lg-0 my-2 my-lg-0 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center ${
                location.pathname.match(/\/wallet.*/) ? 'active' : ''
              }`}
            >
              <div className="wallets d-none d-lg-block" />
              <small>{language.header.small_2}</small>
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}${lang}/my-referrals`}
              className={`menu-item px-2 px-lg-0 my-2 my-lg-0 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center ${
                location.pathname.match(/\/my-referrals.*/) ? 'active' : ''
              }`}
            >
              <div className="referrals d-none d-lg-block" />
              <small>{language.header.small_4}</small>
            </Link>

            <Link
              to={`${process.env.PUBLIC_URL}${lang}/promotional-material`}
              className={`menu-item px-2 px-lg-0 my-2 my-lg-0 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center ${
                location.pathname.match(/\/promotional-material.*/)
                  ? 'active'
                  : ''
              }`}
            >
              <div className="social-media d-none d-lg-block" />
              <small>
                {language.header.small_6_1} {language.header.small_6_2}
              </small>
            </Link>
            {false && (
              <Link
                to={`${process.env.PUBLIC_URL}${lang}/leaderboard/today`}
                className={`menu-item px-2 px-lg-0 my-2 my-lg-0 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center ${
                  location.pathname.match(/\/leaderboard.*/) ? 'active' : ''
                }`}
              >
                <div className="leaderboard d-none d-lg-block" />
                <small>Leaderboard</small>
              </Link>
            )}
            <Notications />
          </div>
          <div className="d-flex flex-column px-4 px-lg-0 py-sm-3 logoff">
            {false && (
              <div className="d-flex justify-content-center flex-lg-column">
                <button
                  type="button"
                  className={`${
                    idiom === 'en' ? 'flag-text-light' : ''
                  } min-height border-0 bg-transparent menu-item pb-lg-3 pb-xxl-4 w-lg-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center btn-idiom mx-2 mx-lg-0`}
                  onClick={() => handleSelectIdiom('en')}
                >
                  <img
                    src={en}
                    alt="EN"
                    className={`${
                      idiom === 'en' ? 'active-idiom' : ''
                    } mr-1 mr-lg-0`}
                  />
                  <span className="d-none d-lg-block">
                    {language.header.button_1}
                  </span>
                </button>

                <button
                  type="button"
                  className={`${
                    idiom === 'es' ? 'flag-text-light' : ''
                  } min-height border-0 bg-transparent menu-item mt-0 mt-xxl-2 pb-lg-3 pb-xxl-4 w-lg-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center btn-idiom mx-2 mx-lg-0`}
                  onClick={() => handleSelectIdiom('es')}
                >
                  <img
                    src={es}
                    alt="ES"
                    className={`${
                      idiom === 'es' ? 'active-idiom' : ''
                    } mr-1 mr-lg-0`}
                  />
                  <span className="d-none d-lg-block">
                    {language.header.button_2}
                  </span>
                </button>
              </div>
            )}
            <button
              type="button"
              onClick={() => {
                signOut();
                sessionStorage.removeItem('moduleSelected');
              }}
              className="border-0 my-5 my-lg-0 menu-item px-2 px-lg-0 my-2 my-lg-0 py-lg-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center"
            >
              <div className="logout ml-0 mx-lg-auto d-none d-lg-block" />
              <small>{language.header.small_9}</small>
            </button>
          </div>
        </div>
      </Menu>
    </Container>
  );
};

export default Header;
