import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { MdClose, MdMinimize } from 'react-icons/md';
import { io } from 'socket.io-client';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';

import Lottie from 'react-lottie';
import { BsPatchCheck } from 'react-icons/bs';
import { useAuth } from '~/hooks/Auth';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import swalError from '~/utils/swalError';

import { Container, Loading } from './styles';

import message01 from '~/assets/animations/message-01.json';
import message02 from '~/assets/animations/message-02.json';
import message03 from '~/assets/animations/message-03.json';
import message04 from '~/assets/animations/message-04.json';
import message05 from '~/assets/animations/message-05.json';
import message06 from '~/assets/animations/message-06.json';
import message07 from '~/assets/animations/message-07.json';
import message08 from '~/assets/animations/message-08.json';
import message09 from '~/assets/animations/message-09.json';
import message10 from '~/assets/animations/message-10.json';
import message11 from '~/assets/animations/message-11.json';
import message12 from '~/assets/animations/message-12.json';
import checkMessage from '~/assets/icons/check-message.svg';

interface IData {
  id: string;
  name: string;
  minimize: boolean;
}

interface IMessageResponse {
  id: string;
  user_id: string;
  content: string;
}

interface IMessage {
  id: string;
  title: string;
  text: string;
  lottie: unknown;
  disabled: boolean;
}

interface IChat {
  data: IData;
  onClose(data: IData): void;
  onMinimize(data: IData, minimize: boolean): void;
}

const Chat: React.FC<IChat> = ({ data: chatData, onClose, onMinimize }) => {
  const { user } = useAuth();
  const chatRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormHandles>(null);
  const [chatClosed, setChatClosed] = useState(false);
  const [messages, setMessages] = useState<IMessage[]>([
    {
      id: '1',
      title: 'Message 1: For a New Referral',
      text:
        "Hey [Name], welcome to AutoAffiliate! 🎉\n\nI noticed you recently joined, and I wanted to extend a warm welcome. If you're wondering what the next steps are, check out the 'Getting Started' guide in your dashboard. \n\nExcited for the journey ahead!\n\nLooking forward to achieving success together!",
      lottie: message01,
      disabled: false,
    },
    {
      id: '2',
      title: 'Message 2: Masterclass Reminder',
      text:
        "Hey [Name], I noticed you haven't started the Masterclass yet. ⌛\n\nTrust me, it's designed to set you up for success. If you have any questions, you can use the 'Support' button on your dashboard.\n\nAlso, feel free to join our Facebook group where we all hang out!\n\nCheers!",
      lottie: message02,
      disabled: false,
    },
    {
      id: '3',
      title: 'Message 3: Intro to Affiliate Program',
      text:
        "Hey [Name], did you know you can become a part of our exclusive  VIP Affiliate Program? 🌟\n\nBy upgrading within 7 Days, you’ll gain access to incredible commissions of up to 60%, free access to all our base products, and cutting-edge tools to boost your earnings. Plus, you'll be part of our special training sessions and benefit from advanced support to maximize your success! You have 7 days to claim this opportunity at 70% off\n\nDon’t miss out on this amazing opportunity—upgrade now and start earning!",
      lottie: message03,
      disabled: false,
    },
    {
      id: '4',
      title: 'Message 4: Masterclass Incomplete',
      text:
        "Hey [Name], you're almost there! ⏰\n\nCompleting the Masterclass will help you make the most out of AutoAffiliate. Need help? Click the 'Support' button on your dashboard or join our Facebook group for guidance.\n\nSee you in the community!",
      lottie: message04,
      disabled: false,
    },
    {
      id: '5',
      title: 'Message 5: Offering Help',
      text:
        "Hey [Name], if you're feeling stuck or need help figuring things out, remember we're just a click away! 👉\n\nOur support team is ready to assist you. Just click the 'Support' button on your dashboard or join our Facebook group for guidance.\n\nWe are here for you!",
      lottie: message05,
      disabled: false,
    },
    {
      id: '6',
      title: 'Message 6: Follow-Up on Affiliate',
      text:
        "Hey [Name], you're so close! 🎯\n\nJust a few more steps and you'll be part of our VIP Affiliate Program, unlocking up to 60% commissions, free access to all our base products, exclusive training, and advanced tools to skyrocket your success.\n\nTime is of the essence—complete your upgrade in the next 7 days to unlock these incredible perks and discount!\n\nNeed help? Click the 'Support' button on your dashboard or join our Facebook group for guidance.\n\nDon't miss out!",
      lottie: message06,
      disabled: false,
    },
    {
      id: '7',
      title: 'Message 7: VIP Affiliate Last Chance',
      text:
        "[Name] Time's almost up! 🕒\n\nThis is your last chance to join our exclusive VIP Affiliate Program at 70% discount and unlock amazing perks, including up to 60% commissions, free access to all our base products, special training, and advanced tools. Don't let this opportunity slip away!\n\nFor any last-minute questions or issues, our support team is here to help. Act now and secure your spot!\n\nTo your success!",
      lottie: message07,
      disabled: false,
    },
    {
      id: '8',
      title: 'Message 8: Masterclass Complete',
      text:
        "Awesome, you've completed the Masterclass! 🌟\n\nNext, check out the Founders Club opportunity, It is life changing!. Don't forget, the 'Support' button and Facebook group are your go-to for assistance\n\nKeep it up [Name]!",
      lottie: message08,
      disabled: false,
    },
    {
      id: '9',
      title: 'Message 9: Reminder to Activate Offer',
      text:
        "🎉 We see you've completed the Masterclass but haven't activated an offer yet.\n\nThese offers are your ticket to earning! Use the 'Support' button or our Facebook group if you have questions.\n\nLooking forward to achieving success together [Name]!",
      lottie: message09,
      disabled: false,
    },
    {
      id: '10',
      title: 'Message 10: No Activity for 7 Days',
      text:
        "[Name] Hi It's been a while since we've seen you! 😕\n\nDon't miss out on great earning opportunities. Got questions? Use the 'Support' button on your dashboard or join our vibrant Facebook group.\n\nLooking forward to seeing your success!",
      lottie: message10,
      disabled: false,
    },
    {
      id: '11',
      title: 'Message 11:  Celebrating First Offer',
      text:
        "Wow, congrats on activating your first offer! 🎉\n\nThis is a big step towards earning through AutoAffiliate. Remember, if you need any help you can always hit the 'Support' button on your dashboard or join our vibrant Facebook group.\n\nProud of you [Name]!\n\nCheers!",
      lottie: message11,
      disabled: false,
    },
    {
      id: '12',
      title: 'Message 12: Encourage Multiple offers',
      text:
        "Amazing! You've activated another offer—that's awesome! 🌟\n\n[Name] You're really taking your AutoAffiliate game to the next level. Got questions or need some tips? Don't hesitate to use the 'Support' button or connect with us in the Facebook group.\n\nExcited for the journey ahead!",
      lottie: message12,
      disabled: false,
    },
  ]);
  const [messageSelected, setMessageSelected] = useState({} as IMessage);
  const [messageSent, setMessageSent] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL as string, {
      reconnection: true,
    });

    socket.on('connect', () => {
      socket.emit('join-room', { roomID: chatData.id });
    });

    socket.on('user-disconnected', () => {
      // console.log('user disconnected-- closing peers', data);
    });

    socket.on('disconnect', () => {
      // console.log('socket disconnected --');
    });

    socket.on('error', () => {
      // console.log('socket error --', err);
    });
  }, [chatData.id]);

  useEffect(() => {
    api
      .get<IMessageResponse[]>(`chats-messages/${chatData.id}`)
      .then((response) => {
        setMessages((state) => {
          const newMessages = state.map((message) => {
            const content = `${message.text
              .replace('[Name]', chatData.name.split(' ')[0])
              .replace(/\n/g, '<br>')}<br><br><b>-${user.name}</b>`;
            const contentAux = `${message.text
              .replace('[Name]', chatData.name)
              .replace(/\n/g, '<br>')}<br><br><b>-${user.name}</b>`;

            const messageSelectedData = response.data.find(
              (messageData) =>
                messageData.content === content ||
                messageData.content === contentAux
            );

            if (messageSelectedData) {
              return { ...message, disabled: true };
            }
            return message;
          });

          return newMessages;
        });
      });
  }, [chatData.id, chatData.name, user.name]);

  const handleClickName = useCallback(() => {
    if (chatClosed) {
      setChatClosed(false);
      onMinimize(chatData, false);
    }
  }, [chatClosed, chatData, onMinimize]);

  const handleClickMinimize = useCallback(() => {
    onMinimize(chatData, !chatClosed);
    setChatClosed((state) => !state);
  }, [chatClosed, chatData, onMinimize]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          message: yup.string().when('$messageData', {
            is: (messageData: boolean) => messageData,
            then: yup.string().required('Message is required'),
            otherwise: yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            messageData: Object.keys(messageSelected).length === 0,
          },
        });

        const content = `${messageSelected.text
          .replace('[Name]', chatData.name.split(' ')[0])
          .replace(/\n/g, '<br>')}<br><br><b>-${user.name}</b>`;

        const formData = {
          chat_id: chatData.id,
          user_id: user.id,
          title: messageSelected.title,
          content,
          referral_code: user.referral_code,
          name: user.name,
        };

        await api.post('chats-messages/users', formData, {
          params: {
            sendNotification: true,
          },
        });

        const newMessages = messages.slice();

        const messageIndex = newMessages.findIndex(
          (message) => message.id === messageSelected.id
        );

        if (messageIndex >= 0) {
          newMessages[messageIndex].disabled = true;
        }

        setMessageSent(true);
        setMessages(newMessages);

        formRef.current?.reset();
        setMessageSelected({} as IMessage);
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          swalError({
            message:
              'Looks like what you were trying to do didn’t work, please try again.',
            textButton: 'Try Again',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      chatData.id,
      chatData.name,
      messageSelected,
      messages,
      user.id,
      user.name,
      user.referral_code,
    ]
  );

  return (
    <Container ref={formRef} onSubmit={handleSubmit} close={chatClosed}>
      <div className="header w-100 py-2 px-3 d-flex align-items-center justify-content-between border-0">
        <button
          type="button"
          className="border-0 bg-transparent text-left"
          onClick={handleClickName}
        >
          <small className="text-white font-weight-normal">
            {chatData.name}
          </small>
          <br />
          <small className="text-white font-weight-normal">
            Choose a message below to send
          </small>
        </button>
        <div className="d-flex align-items-center justify-content-between">
          <button
            type="button"
            className="border-0 bg-transparent mr-1"
            onClick={handleClickMinimize}
          >
            <MdMinimize size={20} color="#fff" />
          </button>
          <button
            type="button"
            className="border-0 bg-transparent"
            onClick={() => onClose(chatData)}
          >
            <MdClose size={20} color="#fff" />
          </button>
        </div>
      </div>
      <div className="body w-100">
        <div
          ref={chatRef}
          className="w-100 h-100 overflow-auto scroll-smooth pb-3"
        >
          {messages.map((message) => (
            <button
              type="button"
              key={message.id}
              className={`btn-message bg-white d-flex p-3 text-left ${
                messageSelected.id === message.id ? 'selected' : ''
              }`}
              onClick={() => setMessageSelected(message)}
              disabled={message.disabled}
              title={message.text.replace('[Name]', 'there')}
            >
              <div className="lottie">
                <Lottie
                  options={{
                    animationData: message.lottie,
                    autoplay: true,
                    loop: true,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  width={36}
                />
              </div>
              <div className="message">
                <div className="p-relative d-flex">
                  <p className="mb-0">{message.title}</p>
                  {message.disabled && (
                    <BsPatchCheck color="#116BFF" size={16} className="stamp" />
                  )}
                </div>
                <p className="mb-0">
                  {message.text.replace('[Name]', 'there')}
                </p>
              </div>
            </button>
          ))}
        </div>
      </div>
      <div className="footer w-100">
        <div className="box-input d-flex p-3">
          <div>
            {messageSelected.title ? (
              <p>{messageSelected.title}</p>
            ) : (
              <>
                {messageSent && (
                  <div className="message-sent d-flex align-items-center">
                    <img src={checkMessage} alt="checkMessage" />
                    <p className="ml-1 mb-0">Message sent successfuly </p>
                  </div>
                )}
              </>
            )}
          </div>
          {loading ? (
            <Loading className="btn-loading p-2 ml-3 d-flex align-items-center justify-content-center">
              <div className="spinner" />
            </Loading>
          ) : (
            <button type="submit" className="border-0 p-2 ml-3">
              <RiSendPlane2Fill size={25} color="#fff" />
            </button>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Chat;
