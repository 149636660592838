import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
  isBefore,
  differenceInMilliseconds,
  parseISO,
  addDays,
} from 'date-fns';

import { IoMdClose } from 'react-icons/io';
import { BsArrowRightCircle } from 'react-icons/bs';
import { useAuth } from '~/hooks/Auth';
import { useNextStep } from '~/hooks/NextStep';
import { useLanguage } from '~/hooks/Language';

import {
  Container,
  Profile,
  Social,
  CopyAlert,
  Avatar,
  BoxWelcome,
  Waitlist,
  AvatarWaitlist,
  AffiliateLink,
  Modal,
  BoxAiBot,
} from './styles';
import Support from '~/pages/Lesson/Support';
import api from '~/services/api';
import copy from '~/assets/icons/copy.svg';
import attention from '~/assets/icons/attention-icon.svg';
import arrows from '~/assets/icons/arrows-double-right.svg';
import twitter from '~/assets/icons/twitter-icon-blue.svg';
import facebook from '~/assets/icons/facebook-icon-blue.svg';
import telegram from '~/assets/icons/telegram-icon-blue.svg';
import instagram from '~/assets/icons/instagram-icon-blue.svg';
import founderIcon from '~/assets/icons/founder-icon.svg';
import bgFirstMobile from '~/assets/defaults/bg-first-mobile-new.png';
import bgSecondMobile from '~/assets/defaults/bg-second-mobile.png';
import bgLast from '~/assets/defaults/vip-last.png';
import aiConnector from '~/assets/defaults/bg-ai-connector.png';
import thumbModal from '~/assets/defaults/thumb-video-modal.png';

import Player from '../Player';
import Payment, { IOption } from '../Payment';

interface IParams {
  slug: string;
  slugLesson: string;
}

const Info: React.FC = () => {
  const { user, updateUser } = useAuth();
  const { language } = useLanguage();
  const params = useParams<IParams>();
  const history = useHistory();
  const { nextStep } = useNextStep();
  const [copied, setCopied] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [miniBox, setMiniBox] = useState(false);
  const [referrers, setReferrers] = useState('00');
  const [link, setLink] = useState('');
  const [showPayment, setShowPayment] = useState(false);
  const [showPaymentVip, setShowPaymentVip] = useState(false);
  const [isAffiliate, setIsAffiliate] = useState(true);
  const [isVip, setIsVip] = useState(true);
  const [orderDate, setOrderDate] = useState<Date | undefined>(undefined);
  const lessonCardBot = [
    'the-difference-between-traditional-and-online-businesses',
    'the-truth-about-money',
    'time-management',
    'paradigm-shift',
    'setup-your-ai-connector-bot',
  ];

  const lessonsMiniBox = [
    'setting-up-your-offers',
    'installing-metamask',
    'setting-up-the-profiti-offer',
    'buying-ethereum-with-debit-card',
    'buy-ethereum-at-gemini',
  ];

  const pageLessons = location.pathname.match(/\/lessons.*/);

  const options = useMemo<IOption[]>(() => {
    if (user.affiliate.type.toLowerCase().includes('vip')) {
      return [
        {
          price: 47,
          src: 'https://cdn.autoaffiliate.ai/files/become-affiliate-app.png',
          period: 1,
          type: 'subscription',
          interval: 'month',
          name: 'affiliate',
          nameSummary: 'Affiliate Program Community Access & AI Tools',
          productSlug: 'affiliate',
          selected: true,
          unSelectable: true,
          trial_period_days: user.affiliate.type
            .toLowerCase()
            .includes('founder')
            ? 45
            : undefined,
        },
      ];
    }

    return [
      {
        price: 47,
        src: 'https://cdn.autoaffiliate.ai/files/become-affiliate-app.png',
        period: 1,
        type: 'subscription',
        interval: 'month',
        name: 'affiliate',
        nameSummary: 'Affiliate Program Community Access & AI Tools',
        productSlug: 'affiliate',
        selected: true,
        unSelectable: true,
        trial_period_days: user.affiliate.type.toLowerCase().includes('founder')
          ? 90
          : undefined,
      },
      {
        price: user.affiliate.type.toLowerCase().includes('founder vip')
          ? 0
          : 97,
        src: 'https://cdn.autoaffiliate.ai/files/upgrade-vip-97-app.png',
        type: 'lifetime',
        name: 'vip',
        secondary: true,
        nameSummary: 'VIP Affiliate Upgrade',
        productSlug: 'vip-upgrade',
      },
    ];
  }, [user.affiliate.type]);

  const timeZone = 'America/New_York';

  const memoizedTargetDate = useMemo(() => {
    const date = zonedTimeToUtc(orderDate || 0, timeZone);

    return date;
  }, [orderDate]);

  const calculateTimeRemaining = useCallback(() => {
    const now = new Date();
    const difference = differenceInMilliseconds(memoizedTargetDate, now);

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }, [memoizedTargetDate]);

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [calculateTimeRemaining]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    api
      .get('orders/users/b060defe-695a-4ddd-9827-5350c3302f00')
      .then((response) => {
        setOrderDate(addDays(parseISO(response.data.created_at), 7));
        setIsAffiliate(true);
      })
      .catch(() => {
        setIsAffiliate(false);
      });
  }, []);

  useEffect(() => {
    api
      .get('orders/users/16ea3f78-caaa-4af0-afcd-503cbdcb465e')
      .then(() => {
        setIsVip(true);
      })
      .catch(() => {
        setIsVip(false);
      });
  }, []);

  useEffect(() => {
    api.get(`referrers/${user.affiliate.id}`).then((response) => {
      setReferrers(response.data.length.toString().padStart(2, '0'));
    });
  }, [user.affiliate.id]);

  useEffect(() => {
    if (
      params.slugLesson === 'setting-up-your-offers' ||
      params.slugLesson === 'setting-up-the-profiti-offer'
    ) {
      api
        .get(
          `offers/affiliates/${user.referrer?.affiliate_id}/2ffe225b-fbad-4521-a68e-c98e43e4f99b`
        )
        .then((response) => {
          if (
            response.data.affiliateOffer &&
            Object.keys(response.data.affiliateOffer).length > 0
          ) {
            setLink(response.data.affiliateOffer.url);
          } else {
            setLink(response.data.link);
          }
        });
    }
  }, [params.slugLesson, user.referrer]);

  const copyUrl = useCallback(() => {
    const el = document.createElement('textarea');
    el.value = `${process.env.REACT_APP_URL}/i/${user.referral_code}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [user.referral_code]);

  const handleShare = useCallback(async () => {
    try {
      await navigator.share({
        url: `https://autoaffiliate.ai/i/${user.referral_code}`,
      });
    } catch (error) {
      // console.error('Error sharing:', error);
    }
  }, [user.referral_code]);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (lessonsMiniBox.includes(nextStep.slug)) {
      setMiniBox(true);
    } else {
      setMiniBox(false);
    }
  }, [lessonsMiniBox, nextStep.slug]);

  const handleSuccessfullyPaid = useCallback(
    (data) => {
      const vipProduct = data.find(
        (dataAux: any) =>
          dataAux.order.product_id === '16ea3f78-caaa-4af0-afcd-503cbdcb465e'
      );

      let type = vipProduct ? 'Vip Affiliate' : 'Affiliate';
      if (user.affiliate.type.toLowerCase().includes('founder')) {
        type = vipProduct ? 'Vip Founder' : 'Founder';
      }
      updateUser({
        ...user,
        affiliate: {
          ...user.affiliate,
          type,
        },
      });
      setShowPayment(false);
      setShowPaymentVip(false);

      if (vipProduct) {
        setIsVip(true);
        setIsAffiliate(true);
      } else {
        setIsAffiliate(true);
        setIsVip(false);
        setOrderDate(addDays(new Date(), 7));
      }
    },
    [updateUser, user]
  );

  const showCardVip = useMemo(() => {
    const nowUtc = new Date();
    const compareDate = zonedTimeToUtc(orderDate || 0, timeZone);
    return isBefore(nowUtc, compareDate);
  }, [orderDate]);

  return (
    <Container className="container container-xl-fluid px-3 px-sm-0 px-xl-3 mb-4 mb-lg-0">
      {pageLessons && (
        <>
          {location.pathname !== '/lessons/welcome' &&
            location.pathname !== '/lessons/welcome/' && (
              <BoxWelcome className="text-center mt-4">
                <div className="text-center">
                  <img src={attention} alt="Attention icon" className="mb-3" />
                  <h2>Ready to take action and set up your&nbsp;offers?</h2>
                </div>
                <hr />
                <h3 className="mb-3">
                  If you've got a solid understanding of everything, let's get
                  started and set up your first offer! Otherwise, continue
                  watching the onboarding videos
                </h3>
                <button
                  type="button"
                  onClick={() =>
                    history.push(
                      `${process.env.PUBLIC_URL}/profile/my-purchases`
                    )
                  }
                >
                  Set up Your First Offer{' '}
                  <img src={arrows} alt="Double arrows" />
                </button>
              </BoxWelcome>
            )}
          {miniBox && (
            <BoxAiBot
              id="box-bot"
              className={`${
                lessonCardBot.includes(params.slugLesson) && 'd-none'
              } text-center mt-4`}
            >
              <div className="text-center">
                <img
                  src={aiConnector}
                  alt="Ai"
                  className="img-width radius-20 mb-3"
                />
              </div>

              <button type="button" onClick={handleShow}>
                Setup Your AI Bot
              </button>
            </BoxAiBot>
          )}

          {!isAffiliate && (
            <AffiliateLink className="mt-4">
              <div>
                <div className="bg-title">
                  <h2 className="title-waitlist text-center mb-0">
                    Earn 1K Per Referral!
                  </h2>
                </div>
                <div className="pad-waitlist position-relative">
                  <img
                    src={bgFirstMobile}
                    alt="First"
                    className="img-width radius-20"
                  />
                  <div className="position-absolute positions">
                    <h2>First</h2>
                    <p>Income Stream </p>
                  </div>
                  <div className="descriptions mt-3">
                    <p>
                      Upgrade to our affiliate program open the gates to
                      incredible commissions and{' '}
                      <span className="fw-semibold">
                        gain free access to all our base products.
                      </span>
                    </p>
                    <p>
                      By joining our affiliate program,{' '}
                      <span className="font-weight-bold">
                        you can earn up to 60% commissions.
                      </span>{' '}
                      Experience easy earning potential with our cutting-edge
                      tools and support.
                    </p>
                    <button
                      type="button"
                      onClick={() => setShowPayment(true)}
                      className="text-center"
                    >
                      BECOME AN AFFILIATE TODAY!
                    </button>
                  </div>
                </div>
              </div>

              {false && (
                <>
                  <div>
                    <div className="bg-title">
                      <h2 className="title-waitlist text-center mb-0">
                        Get Your Affiliate Link
                      </h2>
                    </div>
                    <div className="pad-waitlist position-relative">
                      <img
                        src={bgSecondMobile}
                        alt="First"
                        className="img-width radius-20"
                      />
                      <div className="position-absolute positions">
                        <h2>Third</h2>
                        <p>Income Stream </p>
                      </div>
                      <div className="descriptions mt-3">
                        <p>
                          Click the link below to get your affiliate link for
                          Travel Gig the perfect blend of travel and income in
                          the gig economy with Travel Gig.
                        </p>
                        <p>
                          By guiding others to affordable vacation experiences,
                          you create a second income income stream and unlock
                          exclusive discounted prices for your own incredible
                          getaways.
                        </p>
                        <a
                          href="/"
                          className="text-center"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          SIGN UP FOR TRAVEL GIG
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {lessonCardBot.includes(params.slugLesson) && (
                <div>
                  <div className="bg-title">
                    <h2 className="title-waitlist text-center mb-0">
                      Setup Your AI Bot
                    </h2>
                  </div>
                  <div className="pad-waitlist position-relative">
                    <img
                      src={aiConnector}
                      alt="Ai"
                      className="img-width radius-20"
                    />
                    <div className="position-absolute positions">
                      <h2>
                        AI <br />
                        Connector
                      </h2>
                    </div>
                    <div className="descriptions mt-3">
                      <p>
                        Automate your success with the AI Connector Bot! Unlock
                        it after signing up for our offers to streamline your
                        online business and skyrocket your income. Don't miss
                        out on this opportunity to maximize your earning
                        potential.
                      </p>
                      <p>
                        Set up your AI Connector Bot today and watch your
                        business thrive!
                      </p>

                      <a
                        href="https://chrome.google.com/webstore/detail/ai-connector/fmcefkadmedfpbfbjnkfgbfkjfpijadh"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-center btn-bot"
                      >
                        Download AI Connector
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </AffiliateLink>
          )}
          {!isVip && showCardVip && (
            <AffiliateLink className="mt-4">
              <div>
                <div className="bg-title-last mt-4">
                  <h2 className="title-last text-center mb-0">
                    VIP LAST CHANCE
                  </h2>
                </div>
                <div>
                  <div className="pad-waitlist position-relative">
                    <img
                      src={bgLast}
                      alt="Last chance"
                      className="img-width radius-20"
                    />
                    <div className="position-absolute positions-last">
                      <div className="d-flex justify-content-between">
                        <div className="w-100 w-sm-50 d-flex justify-content-between justify-content-sm-around">
                          <div className="timer text-center">
                            <span className="d-block">
                              {timeRemaining.days}
                            </span>
                            <span className="d-block">
                              {timeRemaining.days > 1 ? 'Days' : 'Day'}
                            </span>
                          </div>
                          <div className="timer text-center">
                            <span className="d-block">
                              {timeRemaining.hours}
                            </span>
                            <span className="d-block">
                              {timeRemaining.hours > 1 ? 'Hrs' : 'Hr'}
                            </span>
                          </div>
                        </div>
                        <div className="w-100 w-sm-50 d-flex justify-content-between justify-content-sm-around">
                          <div className="timer text-center">
                            <span className="d-block">
                              {timeRemaining.minutes}
                            </span>
                            <span className="d-block">
                              {timeRemaining.minutes > 1 ? 'Mins' : 'Min'}
                            </span>
                          </div>
                          <div className="timer text-center">
                            <span className="d-block">
                              {timeRemaining.seconds}
                            </span>
                            <span className="d-block">
                              {timeRemaining.seconds > 1 ? 'Secs' : 'Sec'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="descriptions-last mt-3">
                    <p>
                      <span>Earn 60% commissions</span> on all our base
                      products!
                    </p>
                    <p>
                      <span>Upgrade within 7 days</span> of becoming an
                      affiliate to get a super discounted rate.
                    </p>
                    <p className="red-color font-weight-600">
                      The price triples once the timer hits zero. Don’t miss
                      out!”
                    </p>
                    <button
                      type="button"
                      onClick={() => setShowPaymentVip(true)}
                      className="text-center"
                    >
                      UPGRADE TO 60% NOW!
                    </button>
                  </div>
                </div>
              </div>
            </AffiliateLink>
          )}

          <Waitlist className="mt-4">
            <div className="bg-title">
              <h2 className="title-waitlist text-center mb-0">Member Status</h2>
            </div>
            <div className="pad-waitlist">
              <div className="text-center w-auto">
                <div className="w-100 p-relative mb-4">
                  <AvatarWaitlist
                    src={user.avatar.avatar_url}
                    className={
                      loading === true ? 'skeleton rounded-circle' : ''
                    }
                  />
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <h2
                    className={`${
                      loading && 'skeleton'
                    } h5 mb-0 font-weight-bold`}
                  >
                    {user.name.trim()}
                  </h2>
                  {user.affiliate.type === 'Founder' && (
                    <img
                      src={founderIcon}
                      alt="Founder"
                      className="founder-icon ml-2"
                    />
                  )}
                </div>
                <p className={`${loading && 'd-none'} new text-center`}>
                  {user.affiliate.type}
                </p>
              </div>
              <div className="d-flex boxes">
                {/* <div className="w-50 mr-1">
                  <h3>
                    Waitlist
                    <br />
                    Position
                  </h3>
                  <p>
                    {user.waitlist && user.waitlist.position > 0
                      ? user.waitlist.position.toString().padStart(2, '0')
                      : '00'}
                  </p>
                </div> */}
                <div className="w-100 text-center">
                  <h3>Your Referrals</h3>
                  <p>{referrers}</p>
                </div>
              </div>
              <div className="text-center">
                <p className="text-copy mt-3 mt-xl-4 mb-2">
                  Refer your friends & earn rewards
                </p>
                <button
                  className="d-none d-lg-flex btn-copy justify-content-around"
                  type="button"
                  onClick={copyUrl}
                >
                  {`autoafiiliate.ai/i/${user.referral_code}`}
                  <img src={copy} alt="copy" className="ml-2" />
                </button>

                <button
                  type="button"
                  className="d-flex d-lg-none btn-copy justify-content-around"
                  onClick={handleShare}
                >
                  {`autoaffiliate.ai/i/${user.referral_code}`}

                  <img src={copy} alt="Copy" className="ml-auto" />
                </button>
              </div>
            </div>
          </Waitlist>
        </>
      )}
      {!pageLessons && (
        <div className="mt-3 mb-lg-3 mb-xl-0 w-100 mt-xl-5 d-md-flex d-xl-block justify-content-around align-items-center bg-user py-4 py-xxl-5 px-3 pl-md-2 pr-0 px-xl-4">
          <Profile className="text-center w-md-50 w-lg-auto">
            <div className="w-100 p-relative mb-4">
              <Avatar
                src={user.avatar.avatar_url}
                className={loading === true ? 'skeleton rounded-circle' : ''}
              />
            </div>

            <h2 className={`${loading && 'skeleton'} h5 mb-0 font-weight-bold`}>
              {user.name.trim()}
            </h2>
            <p className={`${loading && 'd-none'} new text-center`}>
              {user.affiliate.type}
            </p>
          </Profile>
          <div className="ml-lg-4 ml-xl-0 text-center text-md-left text-xl-center">
            <h2 className="h4 mt-3 mt-xl-5 text-lg-center fw-600 py-3">
              {language.footer.h2}
            </h2>
            <p className="color-gray mt-3 mt-xl-5 mb-2">
              {language.footer.p_1}
            </p>
            <button
              className="d-none d-lg-flex btn-copy justify-content-around"
              type="button"
              onClick={copyUrl}
            >
              {`autoafiiliate.ai/i/${user.referral_code}`}
              <img src={copy} alt="copy" className="ml-auto" />
            </button>

            <button
              type="button"
              className="d-flex d-lg-none btn-copy justify-content-around"
              onClick={handleShare}
            >
              {`autoaffiliate.ai/i/${user.referral_code}`}

              <img src={copy} alt="Copy" className="ml-auto" />
            </button>
          </div>
        </div>
      )}
      <Social className={`${pageLessons ? 'pl-0' : 'pl-0 mb-3'}`}>
        <div className="bg-title mt-4 mt-lg-3 mt-xl-4">
          <h2 className="title-social text-center mb-0">
            Join our community and follow us on social media
          </h2>
        </div>
        <div className={`${pageLessons ? 'bg-gray-lessons' : 'bg-gray'} px-3`}>
          <p className="text-center mb-1 py-4 px-2">
            IMPORTANT: Stay connected with us and the community, enjoying and
            learning more.
          </p>
          <div className="d-flex justify-content-center pb-4">
            <a
              href="https://www.twitter.com/AutoAffiliateHQ"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-2"
            >
              <img src={twitter} alt="" />
            </a>
            <a
              href="https://www.facebook.com/AutoAffiliatePage"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-2"
            >
              <img src={facebook} alt="" />
            </a>
            <a
              href="https://t.me/AutoAffiliateHQ"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-2"
            >
              <img src={telegram} alt="" />
            </a>
            <a
              href="https://www.instagram.com/AutoAffiliateHQ"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-2"
            >
              <img src={instagram} alt="" />
            </a>
          </div>
        </div>
      </Social>
      {pageLessons && (
        <div className="d-md-none mt-4">
          <Support />
        </div>
      )}
      <CopyAlert show={copied} className="alert alert-success">
        <p className="mb-0">{language.footer.copy}</p>
      </CopyAlert>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        className="modal-link"
        centered
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          <IoMdClose color="#151517" size={24} />
        </button>
        <Modal.Header className="justify-content-center border-0 px-3 px-sm-5 pb-0 pt-5">
          <div className="mt-4 overflow-hidden w-100 px-sm-4">
            <Modal.Title className="pr-3">
              <h2 className="text-center">
                Congrats on taking action!{' '}
                <span role="img" aria-label="party">
                  🎉
                </span>
              </h2>
              <p className="text-center">
                You were selected to skip the waitlist and start{' '}
                <span>seeing for yourself how AI Connector&nbsp;works</span>
              </p>
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="px-3 px-sm-5">
          <div className="px-xl-5">
            <Player
              src="https://cdn.autoaffiliate.ai/videos/Installing+the+extension.mp4"
              thumbnail={thumbModal}
              className="mb-3 player"
            />
          </div>
          <p className="text-center mb-0 mt-3">
            <span>
              You can now download and install the AI Connector bot by clicking
              the button&nbsp;below.
            </span>
          </p>
          <p className="text-center mb-0">
            {' '}
            Keep in mind that in order to start earning with it you still need
            to signup and connect your offer affiliate&nbsp;links.
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 px-3 px-sm-5 pt-0 pb-3 mb-2 mb-sm-5">
          <div className="px-xl-5 w-100">
            <a
              href="https://chrome.google.com/webstore/detail/ai-connector/fmcefkadmedfpbfbjnkfgbfkjfpijadh"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-save"
            >
              Download AI Connector Now
            </a>
            <a
              href="https://docs.google.com/presentation/d/e/2PACX-1vRoRADgKTyR8x6L1CKEHk5q71jLMQ9A-P_Quf4KSx87Va6UkfJPcYUhoVOIJBF2_BZ6ti5Hxz4VvS1M/pub?start=false&loop=false&delayms=3000"
              target="_blank"
              rel="noopener noreferrer"
              className="guide mt-3 text-center"
            >
              <BsArrowRightCircle className="mr-2" color="#116bff " size={18} />
              Here is the step by step guide shown in the video
            </a>
          </div>
        </Modal.Footer>
      </Modal>
      <Payment
        btnText="Yes, Process My Order Now!"
        onSuccessfullyPaid={handleSuccessfullyPaid}
        productName="Affiliate Program Community Access & AI Tools"
        show={showPayment}
        onHide={() => setShowPayment(false)}
        options={options}
      />
      <Payment
        btnText="Yes, Process My Order Now!"
        onSuccessfullyPaid={handleSuccessfullyPaid}
        productName="Upgrade to VIP"
        show={showPaymentVip}
        onHide={() => setShowPaymentVip(false)}
        options={[
          {
            price: 97,
            src: 'https://cdn.autoaffiliate.ai/files/upgrade-vip-97-app.png',
            type: 'lifetime',
            name: 'vip',
            selected: true,
            unSelectable: true,
            nameSummary: 'VIP Affiliate Upgrade',
            productSlug: 'vip-upgrade',
          },
        ]}
      />
    </Container>
  );
};

export default Info;
