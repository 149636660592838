import styled from 'styled-components';

interface IAvatarProps {
  src: string;
}

export const Container = styled.div`
  width: 100%;

  h1,
  a {
    color: #303030;
  }

  .box {
    border-radius: 20px;
    border: 1px solid #dedede;
    background: #fff;
    filter: drop-shadow(4px 15px 25px rgba(0, 0, 0, 0.08));

    .content {
      width: calc(100% - 89px);
      margin-left: 25px;
    }

    .time {
      position: absolute;
      bottom: 2.5rem;
      right: 2.5rem;

      .notify {
        width: 10px;
        height: 10px;
        background-color: #ff1a50;
        border-radius: 50%;
        margin-left: 8px;
      }
    }
  }

  .no-notification {
    height: 75vh;
    opacity: 0.5;
  }
`;

export const Avatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;
  background-color: #303030;
`;
