import styled from 'styled-components';

interface IFlagProps {
  show: boolean;
}

interface IThumbnailProps {
  src: string;
}

export const Container = styled.div`
  .more {
    border-radius: 16px;
    border: 1px solid #116bff;
    background: #116bff;
    width: 237px;
    color: #fff;
    font-family: 'Archivo';
    font-size: 24px;
    font-weight: 500;
    padding: 12px;
    transition-duration: 0.3s;
    :hover {
      opacity: 0.8;
    }
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #021027;
  }
  p {
    color: #515151;
    font-size: 18px;
    font-weight: 400;
    line-height: 146.023%;
  }

  .group-btn {
    overflow: unset;
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: transparent;
    }

    ::-webkit-scrollbar {
      background-color: transparent;
      width: 5px;
      height: 5px;
    }
  }

  .btn-funnels-articles {
    color: #116bff;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    border-radius: 33px;
    border: 1px solid #116bff;
    background: #fafafa;
    padding-right: 15px;
    display: flex;
    align-items: center;
    height: 51px;
    transition-duration: 0.3s;
    :hover {
      opacity: 0.8;
    }

    span {
      width: 106.5px;
      display: block;
    }

    svg,
    img {
      padding: 9px 15px;
      border-radius: 27px;
      background: #116bff;
      margin: 4px;
      margin-right: 10px;
      width: 54px;
      height: 41px;
    }
  }

  .btn-active {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    border-radius: 33px;
    border: 1px solid #116bff;
    background: #116bff;
    padding-right: 15px;
    display: flex;
    align-items: center;
    height: 51px;
    transition-duration: 0.3s;
    :hover {
      opacity: 0.8;
    }

    span {
      width: 106.5px;
      display: block;
    }

    svg,
    img {
      padding: 9px 15px;
      border-radius: 27px;
      background: #fff;
      margin: 4px;
      margin-right: 10px;
      width: 54px;
      height: 41px;
    }
  }

  .search {
    padding: 1px;
    border-radius: 100px;
    border: 1px solid #4a4b4b;
    background-color: #202020;

    input {
      margin: 5px 0;
      padding: 0 10px;
      border: none;
      border-radius: 100px;
      background-color: #202020;
    }
  }

  .border-golden {
    :hover {
      color: #e4e4e4;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 1399px) {
    p {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 991px) {
    p {
      font-size: 16px;
    }
    .group-btn {
      overflow: auto;
    }
  }

  @media screen and (max-width: 400px) {
    h1 {
      font-size: 24px !important;
    }
  }
`;

export const Search = styled.div`
  padding: 1px;
  border-radius: 100px;
  border: 1px solid #4a4b4b;
  background-color: #202020;

  input {
    margin: 5px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: #202020;
  }
`;

export const AddButton = styled.button`
  border-radius: 20px;
  overflow: hidden;
  background-color: #202020;
  min-height: 526px;

  .tinytext {
    color: #828282;
  }

  img {
    border-radius: 20px;
  }
`;

export const Card = styled.div`
  border-radius: 20px;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  min-height: 526px;

  h3 {
    color: #242526;
    padding: 0.375rem 0.75rem;
    font-size: 18px;

    font-weight: 600;
    line-height: normal;
  }

  textarea {
    color: #515151 !important;
    max-height: 300px !important;
    height: 135px !important;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 300;
    line-height: 140%;
  }

  .input-tag {
    font-size: 14px;
    color: #515151;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #f2f2f2;
    height: 50px;
    text-align: center;
  }

  .btn-code {
    color: #116bff;
    width: 43%;
    text-align: center;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    border-radius: 27px;
    background: #fff;
    border: 2px solid #116bff;
    padding: 2px;
    :hover {
      color: #fff;
      background: #116bff;
      border: 2px solid #116bff;
    }
  }

  .btn-code-active {
    color: #fff;
    background: #116bff;
    border: 2px solid #116bff;
  }

  .radius {
    border-radius: 20px !important;
  }

  .height-default {
    height: 310px;
  }

  .image-banner {
    max-width: fit-content;
    border-radius: 15px;
    border: 2px solid #116bff;
  }

  .article-title {
    color: #242526;
    font-size: 18px;
    font-weight: 600;
    min-height: 44px;
  }

  .description-h {
    height: 95px;
    overflow: auto;
  }

  .img-profile {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 2px rgb(228 228 228 / 50%);
    box-sizing: border-box;
    //filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.24));
    background-color: #303030;
  }

  textarea {
    height: 29px;
    max-height: 180px;
    resize: none;
    overflow: hidden;
    cursor: pointer;
    background-color: transparent;
    border-radius: 5px;

    :focus {
      outline: none;
      cursor: unset;
      background-color: #18191a;
      box-shadow: none;
    }

    ::placeholder {
      color: #828282;
    }
  }

  .username {
    color: #242526;
  }

  .tinytext {
    color: #8c8c8c;
    font-weight: 300;
  }

  small {
    color: #8c8c8c;
  }

  button.btn-top {
    padding: 1px;
    width: 40%;
    text-align: center;

    :hover {
      text-decoration: none;
    }
  }

  .btn-share {
    background-color: #116bff !important;
  }

  button {
    transition-duration: 0.2s;
  }

  button:hover {
    opacity: 0.8;
  }

  button.border-delete {
    color: #ff4d77;
  }

  img {
    border-radius: 20px;
  }

  .border-golden {
    padding: 1px !important;
  }

  @media screen and (max-width: 767px) {
    .description-h {
      height: auto;
    }
  }
`;

export const ThumbnailLink = styled.a<IThumbnailProps>`
  height: 222px;
  background-color: #f1f1f1;
  border-radius: 20px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #116bff;
  transition-duration: 0.2s;
  :hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 767px) {
    height: 200px;
  }

  @media screen and (max-width: 575px) {
    height: 160px;
  }
`;

export const ThumbnailBlog = styled.div<IThumbnailProps>`
  height: 222px;
  background-color: #f1f1f1;
  border-radius: 20px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #116bff;

  @media screen and (max-width: 767px) {
    height: 200px;
  }

  @media screen and (max-width: 575px) {
    height: 160px;
  }
`;

export const LinkCopy = styled.div`
  background: #f2f2f2;

  textarea {
    height: auto;
    color: #626262;
  }
  input {
    cursor: pointer;
    font-size: 14px;
    color: #515151;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Flag = styled.div<IFlagProps>`
  right: -15px;
  top: -20px;
  border-radius: 10px;
  background: #f1f1f1;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};

  ::after {
    content: '';
    width: 5px;
    height: 5px;
    background: #f1f1f1;
    position: absolute;
    bottom: -2px;
    z-index: 10000;
    left: 50%;
    transform: rotate(45deg);
  }
`;

export const Skeleton = styled.div`
  .img-height {
    height: 222px;
  }

  .profile-height {
    height: 43px;
    width: 43px;
  }

  .skeleton {
    background-color: #f1f1f1 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 0) 10%,
        rgba(227, 227, 227, 0.2) 20%,
        rgba(227, 227, 227, 0.5) 40%,
        rgba(227, 227, 227, 0.8) 50%,
        rgba(227, 227, 227, 0.2) 80%,
        rgba(227, 227, 227, 0) 90%,
        rgba(227, 227, 227, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
