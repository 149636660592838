import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

import video from '~/assets/temp/video.png';

interface ILessonData {
  coachLocked?: boolean;
}

interface IProgress {
  progress: number;
}

interface IHeightModule {
  active: boolean;
}

export const Container = styled.div`
  @media screen and (min-width: 1200px) {
    zoom: 0.7;
  }

  @media screen and (min-width: 1400px) {
    zoom: 0.8;
  }

  @media screen and (min-width: 1600px) {
    zoom: 0.9;
  }

  @media screen and (min-width: 1700px) {
    zoom: 1;
  }
`;

export const Skeleton = styled.div`
  .skeleton-player {
    height: 400px !important;
    width: 100% !important;
  }

  .skeleton-arrow {
    width: 38px;
    height: 38px;
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (max-width: 575px) {
    .skeleton-player {
      height: 200px !important;
    }
  }
`;

export const Welcome = styled.div`
  position: relative;
  h1 {
    color: #021027;
  }

  h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #777;
    margin-bottom: 20px;
    span {
      font-weight: 600;
      color: #514f4f;
    }
  }

  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #021027;
    b {
      font-weight: 600;
    }
  }

  .progress-card {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 19px 40px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;
    display: flex;
    flex-wrap: wrap;

    h2 {
      color: #3d5066;
      font-family: 'Inter';
      font-size: 9px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 2px;
    }

    a {
      color: #514f4f;
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 10px;
    }

    .completed {
      color: #116bff;
      font-weight: 500;
    }

    .next-step {
      color: #021027;
      font-size: 13px;
      font-weight: 600;
    }

    .minutes {
      color: #021027;
      font-size: 10px;
      font-weight: 300;
    }
  }

  @media screen and (max-width: 575px) {
    .arrow-xs {
      width: 30px;
      height: 30px;
    }
  }
`;

export const Locked = styled.div`
  img {
    width: 50px;
    height: 50px;
  }

  .lock-message {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 330px;
    z-index: 1;
    padding: 10px;
    border-radius: 10px;
    right: 20px;
    top: 55px;
    border: solid 2px var(--greyMineral);
    background: var(--coal);
    transition-duration: 0.3s;
  }

  :hover {
    .lock-message {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const LessonData = styled.div<ILessonData>`
  position: relative;

  .video-js .vjs-big-play-button {
    background-color: #116bff !important;
    border: none !important;
    border-radius: 50% !important;
    font-size: 5em !important;
    line-height: 1.5em !important;
    height: 1.5em !important;
    width: 1.5em !important;
    margin-left: 0px !important;
    transition: 0.3s;
    transform: translate(-50%, -50%);
    margin-top: 0px;
  }

  .video-js:hover .vjs-big-play-button,
  .video-js .vjs-big-play-button:focus {
    -webkit-box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 1);
  }

  .vjs-control-bar {
    border-radius: 0 0 15px 15px !important;
  }

  .lock-message {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 330px;
    z-index: 1;
    padding: 10px;
    border-radius: 10px;
    right: 20px;
    top: -70px;
    border: solid 2px var(--greyMineral);
    background: var(--coal);
    transition-duration: 0.3s;
  }

  :hover {
    .lock-message {
      opacity: ${(props) => (props.coachLocked ? '1' : '0')};
      visibility: ${(props) => (props.coachLocked ? 'visible' : 'hidden')};
    }
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #021027;
  }

  h4 {
    color: #242424;
    font-weight: 600;
    font-size: 18px;
  }

  .player {
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    video {
      border-radius: 15px !important;
    }
  }

  hr {
    background-color: var(--closedGray);
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #514f4f;
  }

  .description {
    height: 100px;
    overflow: auto;

    ::-webkit-scrollbar {
      background-color: transparent;
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #c0c0c0;
    }
  }

  .btn-profiti-now {
    position: relative;
    z-index: 1;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    background: #116bff;
    border-radius: 15px;
    padding: 20px;
    text-decoration: none;
  }

  .btn-metamask {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #116bff;
    background: transparent;
    border-radius: 15px;
    border: 4px solid #116bff;
    padding: 16px;
    text-decoration: none;
  }

  .border-gray {
    border-color: var(--grayImmensity) !important;
  }

  .guide {
    position: relative;
    z-index: 1;
    display: block;
    color: #116bff;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    border: solid 1px #116bff;
    border-radius: 15px;
    padding: 15px;
  }

  @media screen and (max-width: 1800px) {
    .player-old {
      height: 26.7vw;
    }
  }

  @media screen and (max-width: 1750px) {
    .player-old {
      height: 26.5vw;
    }
  }

  @media screen and (max-width: 1700px) {
    .player-old {
      height: 475px;
    }
  }

  @media screen and (max-width: 1650px) {
    .player-old {
      height: 29vw;
    }
  }

  @media screen and (max-width: 1599px) {
    .player-old {
      height: 31vw;
      width: 55vw;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 1399px) {
    .player-old {
      height: 415px;
      width: 100%;
    }
  }

  @media screen and (max-width: 1250px) {
    .player-old {
      height: 33vw;
    }
  }

  @media screen and (max-width: 1199px) {
    .player-old {
      height: 520px;
    }
  }

  @media screen and (max-width: 1100px) {
    .player-old {
      height: 48vw;
    }
  }

  @media screen and (max-width: 991px) {
    .player-old {
      height: 387px;
    }
  }

  @media screen and (max-width: 767px) {
    p {
      font-size: 14px;
    }

    .player-old {
      height: 286px;
    }
  }

  @media screen and (max-width: 575px) {
    .player-old {
      height: 53vw;
    }
  }

  @media screen and (max-width: 384px) {
    .player-old {
      height: 51vw;
    }
  }
`;

export const Video = styled.div`
  width: 100%;
  height: 350px;
  background: var(--midnight);
  border-radius: 20px;
  background-image: url(${video});
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LessonsBox = styled.div`
  overflow-y: auto;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #c0c0c0;
  }

  @media screen and (min-width: 1200px) {
    height: 390px !important;
  }

  @media screen and (min-width: 1500px) {
    height: 450px !important;
  }

  @media screen and (max-width: 1199px) {
    height: 131px !important;
  }

  @media screen and (max-width: 991px) {
    height: 227px !important;
  }
`;

export const Modules = styled.div``;

export const Calendar = styled.aside`
  width: 380px;

  .DayPicker {
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    border-radius: 10px;
    background-color: var(--coal);
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-NavButton {
    color: var(--technologicalGray) 591 !important;
  }

  .DayPicker-NavButton--prev {
    right: auto;
    left: 1.5em;
    margin-right: 0;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px 0 0 0;
    padding: 16px;
    border-radius: 0 0 10px 10px;
  }

  .DayPicker-Caption {
    margin-bottom: -10px;
    padding: 0 1em 1em;
    color: var(--technologicalGray);

    > div {
      text-align: center;
    }
  }

  .DayPicker-Weekday {
    color: var(--blizzard);
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: transparent;
    border-radius: 50%;
    color: var(--technologicalGray);
    border: 1px solid var(--technologicalGray);
    font-weight: bold;
    transition-duration: 0.3s;
  }

  .DayPicker-Day--outside {
    background: transparent;
    border-radius: 50%;
    color: var(--greyMineral);
    border: 1px solid var(--greyMineral);
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: var(--technologicalGrayDarken);
    color: var(--closedGray) !important;
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: var(--deepGrey) !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    border: 1px solid var(--technologicalGray) !important;
    background: var(--technologicalGray) !important;
    color: var(--closedGray) !important;
    font-weight: bold;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    background: #f9f9f9 !important;
    border-radius: 35px;
    position: relative;

    h2 {
      color: #116bff;
      font-size: 38px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      line-height: 35px;
    }

    p {
      color: #151517 !important;
      font-size: 17px;
      font-family: 'Archivo', sans-serif;
      font-weight: 300;
      line-height: 28px;
      span {
        font-weight: 600;
        color: #151517;
      }
    }

    .player {
      border-radius: 25px;
      overflow: hidden;
      position: relative;
      video {
        border-radius: 25px !important;
      }
    }

    .vjs-control-bar {
      border-radius: 0 0 25px 25px !important;
    }

    .video-js .vjs-big-play-button {
      background-color: #116bff !important;
      border: none !important;
      border-radius: 50% !important;
      font-size: 5em !important;
      line-height: 1.5em !important;
      height: 1.5em !important;
      width: 1.5em !important;
      margin-left: 0px !important;
      transition: 0.3s;
      transform: translate(-50%, -50%);
      margin-top: 0px;
    }

    .video-js:hover .vjs-big-play-button,
    .video-js .vjs-big-play-button:focus {
      -webkit-box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 1);
      -moz-box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 1);
    }

    .modal-title {
      font-weight: 600;
      font-size: 24px;
      color: #021027;
    }

    .modal-close {
      color: #021027;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .btn-save {
      width: 100%;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
      background: #116bff;
      border-radius: 20px;
      transition-duration: 0.3s;
      padding: 18px;

      :hover {
        opacity: 0.8;
      }
    }

    .guide {
      display: block;
      color: #116bff;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      width: 100%;
      text-decoration-line: underline;
    }
  }
  @media screen and (min-width: 1200px) {
    .modal-xl {
      zoom: 0.7;
    }
  }
  @media screen and (min-width: 1400px) {
    .modal-xl {
      max-width: 1040px;
      zoom: 1;
    }
  }

  @media screen and (max-width: 991px) {
    .modal-content h2 {
      font-size: 23px;
    }

    .modal-content p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 575px) {
    .modal-content h2 {
      font-size: 5vw;
    }

    .modal-content p {
      font-size: 15px;
    }
  }
`;

export const ModalCoach = styled(ModalComponent)`
  .group {
    max-width: 380px;

    .input-calendar {
      border: 1px solid var(--closedGray);
      border-radius: 15px;
      height: 45px;
    }
  }
`;

export const SideBar = styled.div`
  min-width: 321px;
  max-width: 322px;
  background-color: var(--coal);

  h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #242424;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8c8c8c;
  }

  @media (min-width: 992px) and (max-width: 1399px) {
    min-width: 269px;
    max-width: 270px;
  }

  @media screen and (max-width: 1199px) {
    background-color: transparent;
  }

  @media screen and (max-width: 991px) {
    max-width: unset;
  }
`;

export const ProgressBar = styled.div<IProgress>`
  height: 20px;
  border-radius: 8px;
  background-color: #f1f1f1;

  .progress-bar {
    background-color: #4a87ff;
    width: ${(props) => props.progress}%;
  }
`;

export const Descriptions = styled.div`
  margin-top: -190px;

  .mg-top-profiti {
    margin-top: 70px;
  }

  .mg-top-ai-bot {
    margin-top: 140px;
  }
`;
