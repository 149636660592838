import React, { useMemo } from 'react';
import SliderSlick, { Settings } from 'react-slick';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';
import { Background, ButtonLeft, ButtonRight } from './styles';

import autoAffiliateName from '~/assets/logotipos/autoAffiliate-name.svg';
import quote from '~/assets/icons/quote.svg';
import twitter from '~/assets/icons/twitter.svg';
import facebook from '~/assets/icons/facebook.svg';
import telegram from '~/assets/icons/telegram.svg';
import instagram from '~/assets/icons/instagram.svg';
import { useLanguage } from '~/hooks/Language';

interface ICarousel {
  className?: string;
}

interface IButtonProps {
  className?: string;
  onClick?(): void;
}

const PrevArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  const disabled = useMemo(() => {
    if (className) {
      if (className.match('slick-disabled')) {
        return true;
      }
      return false;
    }
    return false;
  }, [className]);

  return (
    <ButtonLeft
      type="button"
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <HiArrowLeft size={28} />
        <p>see</p>
      </div>
    </ButtonLeft>
  );
};

const NextArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  const disabled = useMemo(() => {
    if (className) {
      if (className.match('slick-disabled')) {
        return true;
      }
      return false;
    }
    return false;
  }, [className]);

  return (
    <ButtonRight
      type="button"
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <HiArrowRight size={28} />
        <p>more</p>
      </div>
    </ButtonRight>
  );
};

const BannersTestimonials: React.FC<ICarousel> = () => {
  const { language } = useLanguage();

  const settings: Settings = useMemo(
    () => ({
      infinite: true,
      slidesToShow: 1,
      centerPadding: '0px',
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 800,
      arrows: true,
      touchMove: true,

      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }),
    []
  );
  return (
    <Background>
      <div className="quotation">
        <img src={autoAffiliateName} alt="AutoAffiliate" />
        <p>
          Don’t have an account? <a href="https://autoaffiliate.ai/">Signup</a>
        </p>
      </div>
      <div className="social d-flex align-items-end justify-content-between content">
        <div className="text-center">
          <div className="d-flex justify-content-center">
            <a
              href="https://www.twitter.com/AutoAffiliateHQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={twitter}
                alt={language.sign_in.img_1}
                className="mx-2"
              />
            </a>
            <a
              href="https://www.facebook.com/AutoAffiliatePage"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={facebook}
                alt={language.sign_in.img_2}
                className="mx-2"
              />
            </a>
            <a
              href="https://t.me/AutoAffiliateHQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={telegram}
                alt={language.sign_in.img_3}
                className="mx-2"
              />
            </a>
            <a
              href="https://www.instagram.com/AutoAffiliateHQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={instagram}
                alt={language.sign_in.img_4}
                className="mx-2"
              />
            </a>
          </div>
          <p className="d-block my-2 text-white">@autoaffiliateHQ</p>
        </div>
      </div>
      <SliderSlick {...settings}>
        <div className="banner banner-1 d-lg-flex flex-column justify-content-between text-center p-5">
          <div className="d-flex flex-column align-items-center position-relative">
            <div className="d-flex align-items-start testimonial">
              <img src={quote} alt="quote" className="" />
              <h5 className="text-left ml-4 mb-4">
                AutoAffiliate is a total game-changer”
              </h5>
            </div>

            <p className="phrase">
              “Automation, AI, and a done-for-you system that accentuates my
              lifestyle. It's prospecting, building relationships, and creating
              multiple income streams for me. It's my freedom business!"
            </p>
          </div>
        </div>
        <div className="banner banner-2 d-lg-flex flex-column justify-content-between text-center p-5">
          <div className="d-flex flex-column align-items-center position-relative">
            <div className="d-flex align-items-start testimonial">
              <img src={quote} alt="quote" className="" />
              <h5 className="text-left ml-4 mb-4">
                AutoAffiliate is more than just a platform.”
              </h5>
            </div>
            <p className="phrase">
              “It's a community of like-minded individuals striving for
              financial freedom. Their robust tools and unwavering support have
              helped me build a significant passive income stream."
            </p>
          </div>
        </div>
        <div className="banner banner-3 d-lg-flex flex-column justify-content-between text-center p-5">
          <div className="d-flex flex-column align-items-center position-relative">
            <div className="d-flex align-items-start testimonial">
              <img src={quote} alt="quote" className="" />
              <h5 className="text-left ml-4 mb-4">
                Once skeptical, I'm now all-in with AutoAffiliate.”
              </h5>
            </div>
            <p className="phrase">
              “The earning potential and unique no-selling approach have me
              excited and I am even losing sleep! It's unmatched in the
              affiliate space.”
            </p>
          </div>
        </div>
        <div className="banner banner-4 d-lg-flex flex-column justify-content-between text-center p-5">
          <div className="d-flex flex-column align-items-center position-relative">
            <div className="d-flex align-items-start testimonial">
              <img src={quote} alt="quote" className="" />
              <h5 className="text-left ml-4 mb-4">
                The AutoAffiliate system is revolutionizing.”
              </h5>
            </div>
            <p className="phrase">
              “The platform is user-friendly, and their bot is a marvel. I'm
              making money even while I sleep. If you're serious about affiliate
              marketing, this is the platform for you”
            </p>
          </div>
        </div>
      </SliderSlick>
    </Background>
  );
};

export default BannersTestimonials;
