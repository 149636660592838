import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';
import { Container, Action, Options, Content } from './styles';
import { IAnswers, IQuestion } from '~/components/Quizzes';
import whatsapp from '~/assets/icons/whatsapp-icon.svg';
import mail from '~/assets/icons/mail-icon.svg';
import skype from '~/assets/icons/skype-icon.svg';
import coach from '~/assets/defaults/photo-coach.png';
import comment from '~/assets/icons/send.svg';
import profile from '~/assets/defaults/photo-profile.png';

interface IParams {
  slug: string;
  slugLesson: string;
}

interface ILessonUser {
  id: string;
  user_id: string;
  time_watched: string;
  completed: boolean;
  answered_quiz: boolean;
  coach_released: boolean;
  status: string;
  deleted_at: string;
  block: boolean;
}

interface ICourseLesson {
  id: string;
  title: string;
  duration: string;
  slug: string;
  lessonUser: ILessonUser[];
  watch_full_video: boolean;
  answer_quiz: boolean;
  coach_unlock: boolean;
  notes: IAnswers[];
}

interface ICourseUser {
  id: string;
  user_id: string;
  locked_down: boolean;
  end_date?: Date;
}

interface IExamUser {
  id: string;
  exam_id: string;
  note: string;
}

interface IModuleResponse {
  id: string;
  title: string;
  locked: boolean;
  slug: string;
  moduleUser: {
    id: string;
    locked: boolean;
  };
  lessons: ILesson[];
  exam?: {
    id: string;
    module_id: string;
    questions: IQuestion[];
    examUser: IExamUser;
    examsUsers: IExamUser[];
  };
}

interface IResponseCourse {
  id: string;
  title: string;
  subtitle: string;
  presented_by: string;
  description: string;
  categories: string;
  page_title: string;
  meta_description: string;
  video: {
    video_url: string;
    media_id: string;
    duration: string;
  };
  thumbnail: {
    thumbnail_url: string;
  };
  modules: IModuleResponse[];
  lessons: ICourseLesson[];
  courseUser: ICourseUser[];
}

interface ILessonDownload {
  id: string;
  name: string;
  download_url: string;
  path: string;
  thumbnail: {
    thumbnail_url: string;
  };
  deleted_at: string | null;
}

interface ILesson {
  id: string;
  title: string;
  description: string;
  video: {
    duration: string;
  };
  watch_full_video: boolean;
  answer_quiz: boolean;
  coach_button: boolean;
  coach_unlock: boolean;
  allow_about: boolean;
  allow_next_step: boolean;
  allow_special_note: boolean;
  allow_discussion: boolean;
  allow_downloads: boolean;
  special_notes_coach: boolean;
  about: string;
  next_step: string;
  special_note: string;
  slug: string;
  video_url: string;
  thumbnail_url: string;
  downloads: ILessonDownload[];
  lessonUser: ILessonUser[];
  quiz: IQuestion[];
  notes: IAnswers[];
}

interface IAction {
  lesson: ILesson;
  className?: string;
}

const Actions: React.FC<IAction> = ({ lesson, className }) => {
  const { user, setCredentials } = useAuth();
  const location = useLocation();
  const { language } = useLanguage();

  const params = useParams<IParams>();

  const [optionSelected, setOptionSelected] = useState('about');
  const [optionMobile, setOptionMobile] = useState(language.lesson.button_1);
  const [loading, setLoading] = useState(true);

  const [courseConclude, setCourseConclude] = useState(false);

  const checkIfReleaseCertificate = useCallback(
    async (modulesData: IModuleResponse[], courseIsConclude?: boolean) => {
      if (!courseConclude && !courseIsConclude) {
        try {
          const results: boolean[] = [];
          let allLessonWatched = true;
          modulesData.forEach((module) => {
            if (module.exam) {
              const result = module.exam.examsUsers.find(
                (examUser) =>
                  module.exam?.module_id === module.id &&
                  examUser.note === '10.00'
              );
              results.push(!!result);
            }

            if (allLessonWatched) {
              const lessons: ILesson[] = [];
              module.lessons.forEach((lessonData) => {
                const lessonUser = lessonData.lessonUser.filter(
                  (lessonUserData) =>
                    lessonUserData.user_id === user.id &&
                    !lessonUserData.deleted_at
                );
                // eslint-disable-next-line no-param-reassign
                lessonData.lessonUser = lessonUser;
                lessons.push(lessonData);
              });
              const lessonSelected = lessons.find(
                (lessonData) => !lessonData.lessonUser[0].completed
              );
              allLessonWatched = !lessonSelected;
            }
          });

          let noReleaseCertificate: boolean | undefined;

          if (results.length > 0) {
            noReleaseCertificate = results.find((result) => !result);
          }

          if (
            typeof noReleaseCertificate === typeof undefined &&
            allLessonWatched
          ) {
            await api.post(`certificates/${params.slug}`);
            setCourseConclude(true);
          }
        } catch (error) {
          // console.log(error);
        }
      }
    },
    [params.slug, courseConclude, user.id]
  );

  useEffect(() => {
    return () => {
      setCredentials(undefined);
    };
  }, [setCredentials]);

  useEffect(() => {
    setLoading(true);
    if (params.slugLesson === 'introduction') {
      api
        .get<IResponseCourse>(`/courses/users/${params.slug}`)
        .then((response) => {
          const courseIsConclude = response.data.courseUser.find(
            (courseUser) =>
              courseUser.user_id === user.id && !!courseUser.end_date
          );

          checkIfReleaseCertificate(response.data.modules, !!courseIsConclude);

          setCourseConclude(!!courseIsConclude);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      api
        .get<IResponseCourse>(`/courses/users/${params.slug}`)
        .then(async (response) => {
          const responseLesson = await api.get(
            `lessons/users/${response.data.id}/${params.slugLesson}`
          );

          if (responseLesson.data) {
            const courseIsConclude = response.data.courseUser.find(
              (courseUser) =>
                courseUser.user_id === user.id && !!courseUser.end_date
            );

            checkIfReleaseCertificate(
              response.data.modules,
              !!courseIsConclude
            );

            setCourseConclude(!!courseIsConclude);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    checkIfReleaseCertificate,
    language.lesson.h2_1,
    params.slug,
    params.slugLesson,
    user.id,
  ]);

  useEffect(() => {
    api
      .get<IResponseCourse>(`/courses/users/${params.slug}`)
      .then(async (response) => {
        const lessons: ILesson[] = [];
        response.data.modules.forEach((module) => {
          lessons.push(...module.lessons);
        });
        const videos = lessons.length;
        const complete = lessons.filter((lessonCourse) => {
          return (
            lessonCourse.lessonUser[0].completed ||
            (!lessonCourse.watch_full_video &&
              !lessonCourse.coach_unlock &&
              !lessonCourse.answer_quiz)
          );
        }).length;
        const percentage = parseFloat(((complete * 100) / videos).toFixed(2));
        if (percentage === 100) {
          await api.post(`certificates/${params.slug}`);
        }
      });
  }, [params.slug]);

  useEffect(() => {
    switch (optionSelected) {
      case 'next-step':
        setOptionMobile(language.lesson.button_2);
        break;
      case 'special-note':
        setOptionMobile(language.lesson.button_3);
        break;
      case 'discussion':
        setOptionMobile('Discussion');
        break;
      case 'downloads':
        setOptionMobile(language.lesson.button_5);
        break;
      case 'resources':
        setOptionMobile(language.lesson.button_7);
        break;
      default:
        setOptionMobile('Start Here');
        break;
    }
  }, [language.lesson, optionSelected]);

  const handleClickBox = useCallback((e) => {
    const box = e.target.closest('.options-box');
    if (box) {
      box.classList.toggle('active');
    }
  }, []);

  const handleClickOption = useCallback((value) => {
    setOptionSelected(value);
    const element = document.querySelector('.options-box');
    if (element) {
      element.classList.remove('active');
    }
  }, []);

  return (
    <Container className={`${className}`}>
      <Action
        className={`${loading === true ? 'skeleton' : 'p-3 p-xl-4'} w-100`}
      >
        <Options className={`${loading === true ? 'd-none' : 'mb-3'}`}>
          <div className="options-box">
            <button
              type="button"
              className="d-flex d-lg-none justify-content-between align-items-center py-1 px-3 w-100 border-0 options-button"
              onClick={handleClickBox}
            >
              <p className="w-100 mb-0 bg-btn rounded-pill m-1 my-lg-0 mx-lg-3 p-2 px-xxl-4 py-xxl-2 font-weight-lighter">
                {optionMobile}
              </p>
              <FiChevronDown size={25} />
            </button>
            <div className="options d-lg-flex flex-md-nowrap justify-content-around py-0 px-2 py-lg-3">
              <button
                type="button"
                className={`bg-btn d-block mx-auto w-75 w-lg-auto p-2 col col-lg-2 border-0 bg-transparent ${
                  optionSelected === 'about' ? 'active' : ''
                }`}
                onClick={() => handleClickOption('about')}
              >
                Start Here
              </button>
              <button
                type="button"
                className={`bg-btn d-block mx-auto w-75 w-lg-auto p-2 col col-lg-2 border-0 bg-transparent ${
                  optionSelected === 'next-step' ? 'active' : ''
                }`}
                onClick={() => handleClickOption('next-step')}
              >
                {language.lesson.button_2}
              </button>
              <button
                type="button"
                className={`bg-btn d-block mx-auto w-75 w-lg-auto p-2 col col-lg-2 border-0 bg-transparent ${
                  optionSelected === 'special-note' ? 'active' : ''
                }`}
                onClick={() => handleClickOption('special-note')}
              >
                {language.lesson.button_3}
              </button>
            </div>
          </div>
        </Options>
        <Content
          location={location.pathname}
          className={`${loading && 'd-none'}`}
        >
          <div
            className={`about ${
              optionSelected === 'about' ? 'd-block' : 'd-none'
            }`}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: lesson.about,
              }}
            />
          </div>
          <div
            className={`next-step ${
              optionSelected === 'next-step' ? 'd-block' : 'd-none'
            }`}
          >
            <div className="row justify-content-space-evenly">
              <div
                className={lesson.special_notes_coach ? 'col-md-8' : 'col-12'}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: lesson.next_step,
                  }}
                />
              </div>
              {lesson.special_notes_coach && (
                <div className="col-md-3 coach">
                  <div className="d-flex flex-column align-items-center p-2">
                    <div>
                      <img
                        src={coach}
                        alt="Coach"
                        className="coach-avatar d-block mb-3 mx-auto"
                      />
                      <h4 className="mt-2 mb-0 text-center h5">Monica Ramos</h4>
                      <p className="text-center">Entrenador(a)</p>
                      <div className="d-flex align-items-center">
                        <img src={whatsapp} alt="Whatsapp" />
                        <p className="my-2 small ml-1 color-light">
                          +1 55 565 3322
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <img src={mail} alt="Mail" />
                        <p className="my-2 small ml-1 color-light">
                          mr@millonariodigital.com
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <img src={skype} alt="Skype" />
                        <p className="my-2 small ml-1 color-light">
                          mariaromos11
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={`special-note ${
              optionSelected === 'special-note' ? 'd-block' : 'd-none'
            }`}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: lesson.special_note,
              }}
            />
          </div>
          <div
            className={`discussion ${
              optionSelected === 'discussion' ? 'd-block' : 'd-none'
            }`}
          >
            <div className="row">
              <div className="col-12">
                <div className="p-relative w-100 px-lg-4">
                  <input
                    type="text"
                    className="w-100 py-3 pl-3"
                    placeholder="Comenta aquí"
                  />
                  <img
                    src={comment}
                    alt="comment"
                    className="p-absolute send"
                  />
                </div>
              </div>
              <div className="col-12 mt-5">
                <div className="p-relative px-lg-4">
                  <div className="d-flex flex-wrap justify-content-end">
                    <div className="w-100 d-flex align-items-center">
                      <img
                        src={profile}
                        alt="profile"
                        className="profilePhoto discuss-img"
                      />
                      <div className="ml-3 w-100 w-lg-auto">
                        <div className="comment py-2 px-4">
                          <p>Alexia Myers</p>
                          <p className="mb-0">Like the energy!</p>
                        </div>
                      </div>
                    </div>
                    <div className="w-100 d-flex">
                      <div className="spacer" />
                      <div className="d-flex justify-content-between px-4 comment-actions">
                        <button type="button" className="comment-button">
                          Like
                        </button>
                        <span className="comment-button">-</span>
                        <button type="button" className="comment-button">
                          Reply
                        </button>
                        <span className="comment-button">-</span>
                        <button type="button" className="comment-button">
                          8h
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`downloads ${
              optionSelected === 'downloads' ? 'd-block' : 'd-none'
            }`}
          >
            <h2 className="h5 my-4 text-center">{language.lesson.h2_3}</h2>
            <div className="row">
              {lesson &&
                lesson.downloads &&
                lesson.downloads.length > 0 &&
                lesson.downloads.map((download) => (
                  <a
                    key={download.id}
                    href={download.download_url}
                    className="col-sm-6 col-md-4 p-relative img-height mb-4"
                    target="blank"
                    download
                  >
                    <img
                      src={download.thumbnail.thumbnail_url}
                      alt={download.name}
                      className="w-100 img-height"
                    />
                    <div className="bg-shadow p-absolute h-100 d-flex align-items-end">
                      <p className="mb-0 small">{download.name}</p>
                    </div>
                  </a>
                ))}
            </div>
            <p className="text-center mt-3">{language.lesson.p_4}</p>
          </div>
        </Content>
      </Action>
    </Container>
  );
};

export default Actions;
