import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-input-2/lib/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'video.js/dist/video-js.css';

import Routes from '~/routes';
import GlobalStyles from './styles/global';

import AppProvider from './hooks';

const App: React.FC = () => {
  return (
    <HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
      <BrowserRouter>
        <AppProvider>
          <Routes />
        </AppProvider>
        <GlobalStyles
          ultraWhite="#242526"
          blizzard="#303030"
          graniteGray="#626262"
          technologicalGray="#116BFF"
          grayImmensity="#81807f"
          deepGrey="#6F6E6E"
          greyMineral="#E5E6E6"
          mysteriousGrey="#DEDEDE"
          closedGray="#DEDEDE"
          asphaltGray="#FFFFFF"
          coal="#fff"
          midnight="#F9F9F9"
          error="#ff1a50"
          warning="#ebc034"
          success="#37c694"
          skeletonTransparent="#F9F9F900"
          skeletonQuarter="#F9F9F933"
          skeletonMiddle="#F9F9F980"
          skeletonFull="#F9F9F9"
        />
      </BrowserRouter>
    </HttpsRedirect>
  );
};

export default App;
