import { darken, lighten } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface ICopyAlertProps {
  show: boolean;
}

interface IAvatarProps {
  src: string;
}

export const Container = styled.footer`
  h2 {
    color: #021027;
  }

  .bg-user {
    background: #f9f9f9;
    border-radius: 20px;
  }

  .btn-copy {
    background-color: transparent;
    border: solid 1.5px #dedede;
    border-radius: 15px;
    height: 56px;
    font-size: 13px;
    color: #626262;
    font-weight: 300;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    :hover {
      color: ${darken(0.1, '#626262')};
      border: 1.5px solid ${darken(0.1, '#dedede')};
      transition-duration: 0.5s;
    }

    :disabled {
      pointer-events: none;
    }
  }

  .fw-600 {
    font-weight: 600 !important;
  }

  .new {
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
  }

  .color-gray {
    color: #8c8c8c;
  }

  .skeleton {
    background-color: #f1f1f1 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 0) 10%,
        rgba(227, 227, 227, 0.2) 20%,
        rgba(227, 227, 227, 0.5) 40%,
        rgba(227, 227, 227, 0.8) 50%,
        rgba(227, 227, 227, 0.2) 80%,
        rgba(227, 227, 227, 0) 90%,
        rgba(227, 227, 227, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    .w-65 {
      width: 65%;
    }

    .w-40 {
      width: 40%;
    }
  }

  @media screen and (min-width: 1200px) {
    zoom: 0.85;
    height: 117vh;
    overflow: auto;
  }

  @media screen and (min-width: 1400px) {
    zoom: 1;
    height: 100vh;
  }
`;

export const Profile = styled.div`
  img {
    padding: 3px;
    background-image: linear-gradient(0deg, #202020, #202020),
      radial-gradient(
        circle at top left,
        rgba(228, 228, 228, 0.5),
        rgba(228, 228, 228, 0.5)
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }

  span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ffbb38;
    right: 5px;
    top: 8px;
  }

  p {
    color: rgba(247, 247, 247, 0.5);
  }
`;

export const BoxWelcome = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 18px;

  h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #626262;
  }

  h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #242424;
  }

  button {
    background: #116bff;
    border-radius: 10px;
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    border: none;
    width: 100%;
    padding: 11px;
  }

  @media screen and (min-width: 1200px) {
    background-color: #f9f9f9;
  }
`;

export const BoxAiBot = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 18px;
  //display: none;

  .img-width {
    width: 100%;
    //height: 140px;
  }

  .radius-20 {
    border-radius: 20px;
  }

  button {
    background: #116bff;
    border-radius: 15px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    border: none;
    width: 100%;
    padding: 11px;
  }

  @media screen and (min-width: 1200px) {
    background-color: #f9f9f9;
  }
`;

export const Waitlist = styled.div`
  h2 {
    color: #021027;
  }

  .founder-icon {
    width: 30px;
    height: 30px;
  }

  .pad-waitlist {
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    padding: 18px;
  }

  .text-copy {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #8c8c8c;
  }
  .new {
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
  }

  .title-waitlist {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #242526;
  }

  .bg-gray {
    background: #f9f9f9;
    border-radius: 0 0 20px 20px;
  }

  .bg-title {
    background: #dedede;
    border-radius: 20px 20px 0px 0px;
    padding: 16px 22px;
  }

  .boxes {
    div {
      background: #f1f1f1;
      border: 2px solid #fff;
      border-radius: 20px;
      padding: 15px 17px;
    }
    h3 {
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: #8c8c8c;
    }

    p {
      font-weight: 700;
      font-size: 50px;
      line-height: 61px;
      color: #303030;
      margin-bottom: 0px;
    }
  }

  @media screen and (min-width: 1200px) {
    .pad-waitlist {
      background-color: #f9f9f9;
    }

    .boxes {
      div {
        background: #ffffff;
        border: 2px solid #f1f1f1;
      }
    }
  }
`;

export const AffiliateLink = styled.div`
  h2 {
    color: #021027;
  }

  .btn-bot {
    padding: 13px !important;
  }

  .img-width {
    width: 100%;
    //height: 140px;
  }

  .radius-20 {
    border-radius: 20px;
  }

  .positions {
    top: 30px;
    left: 40px;
    h2 {
      font-weight: 600;
      font-size: 38px;
      line-height: 46px;
      color: #f5f5f5;
      margin-bottom: 0px;
    }
    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #f5f5f5;
    }
  }

  .pad-waitlist {
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    padding: 18px;
  }

  .positions-last {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .timer {
    width: 50px;
    span:nth-child(1) {
      color: #ff1a50;
      text-align: center;
      font-family: 'Quicksand';
      font-size: 35px;
      font-weight: 700;
      line-height: 45px;
    }

    span:nth-child(2) {
      color: #fff;
      text-align: center;
      font-family: 'Archivo';
      font-size: 20px;
      font-weight: 300;
      line-height: 35px;
    }
  }

  .descriptions {
    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 118%;

      color: #514f4f;
      span {
        color: #021027;
      }
    }
    a,
    button {
      width: 100%;
      background: #116bff;
      border: 1.5px solid #dedede;
      border-radius: 15px;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: #ffffff;
      text-decoration: none;
      padding: 17px 10px;
      display: block;
      :hover {
        transition: 0.3s;
        opacity: 0.8;
      }
    }
  }

  .text-copy {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #8c8c8c;
  }
  .new {
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
  }

  .bg-title-last {
    background: #116bff;
    border-radius: 20px 20px 0px 0px;
    padding: 16px 22px;
  }

  .title-last {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
  }

  .title-waitlist {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #021027;
  }

  .bg-gray {
    background: #f9f9f9;
    border-radius: 0 0 20px 20px;
  }

  .bg-title {
    background: #dedede;
    border-radius: 20px 20px 0px 0px;
    padding: 16px 22px;
  }

  .boxes {
    div {
      background: #f1f1f1;
      border: 2px solid #fff;
      border-radius: 20px;
      padding: 15px 17px;
    }
    h3 {
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: #8c8c8c;
    }

    p {
      font-weight: 700;
      font-size: 50px;
      line-height: 61px;
      color: #303030;
      margin-bottom: 0px;
    }
  }

  .descriptions-last {
    p {
      color: #514f4f;
      font-size: 15px;
      font-weight: 400;
      line-height: 118%;
      span {
        color: #021027;
        font-weight: 600;
      }
    }
    button {
      width: 100%;
      background: #116bff;
      border: 1.5px solid #dedede;
      border-radius: 15px;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: #ffffff;
      text-decoration: none;
      padding: 17px 10px;
      display: block;
      :hover {
        transition: 0.3s;
        opacity: 0.8;
      }
    }

    .red-color {
      color: #ff1a50;
    }
  }

  @media screen and (min-width: 1200px) {
    .descriptions a {
      padding: 17px 14px;
    }
    .pad-waitlist {
      background-color: #f9f9f9;
    }

    .boxes {
      div {
        background: #ffffff;
        border: 2px solid #f1f1f1;
      }
    }
  }
`;

export const AvatarWaitlist = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.24));
  background-color: #303030;
`;

export const Avatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 91px;
  height: 91px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;
  background-color: #303030;
`;

export const Social = styled.div`
  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #242424;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8c8c8c;
  }

  .bg-gray {
    background: #f9f9f9;
    border-radius: 0 0 20px 20px;
  }

  .bg-gray-lessons {
    background: #fff;
    border-radius: 0 0 20px 20px;
  }

  .bg-title {
    background: #dedede;
    border-radius: 20px 20px 0px 0px;
    padding: 10px 22px;
  }

  @media screen and (min-width: 1200px) {
    .bg-gray-lessons {
      background: #f9f9f9;
    }
  }
`;

export const BuyGiftCard = styled.div`
  form {
    > div > div {
      border: 1.5px solid #dedede !important;
      border-radius: 15px !important;
    }
    input {
      padding: 6px 0;
      color: #626262;
    }
    label {
      font-size: 14px;
      font-weight: 400;
      color: #626262;
    }
    button:not(.btn-copy) {
      background-color: var(--technologicalGray);
      color: #f5f5f5;
      font-size: 14px;
      font-weight: 500;
      border-radius: 15px;
      border: none;
      :hover {
        background-color: ${lighten(0.1, '#116BFF')};
        color: ${darken(0.1, '#F5F5F5')};
        transition-duration: 0.5s;
      }
    }
  }
  .titleBuyGift {
    background: #dedede;
    border-radius: 20px 20px 0px 0px;
    h4 {
      font-size: 12px;
      font-weight: 500;
      color: #242424;
    }
  }

  .contentBuyGift {
    background: #fff;
    border-radius: 0 0 20px 20px;
  }
`;

export const GiftCard = styled.div`
  form {
    > div {
      border: 1.5px solid #dedede !important;
      border-radius: 15px !important;
    }

    input {
      padding: 6px 0;
      color: #626262;
    }

    button:not(.btn-copy) {
      background-color: var(--technologicalGray);
      color: #f5f5f5;
      font-size: 14px;
      font-weight: 500;
      border-radius: 15px;
      border: none;
      :hover {
        background-color: ${lighten(0.1, '#116BFF')};
        color: ${darken(0.1, '#F5F5F5')};
        transition-duration: 0.5s;
      }
    }
  }

  .titleGift {
    background: #dedede;
    border-radius: 20px 20px 0px 0px;
    h4 {
      font-size: 12px;
      font-weight: 500;
      color: #242424;
    }
  }

  .contentGift {
    background: #fff;
    border-radius: 0 0 20px 20px;
  }
`;

export const Introduction = styled.div`
  background-color: rgba(36, 37, 38, 1);
  border-radius: 20px;
  h3 {
    color: rgba(203, 204, 206, 1);
  }
  hr {
    background-color: rgba(51, 51, 51, 1);
  }
  p {
    color: rgba(96, 96, 96, 1);
  }
  button {
    border: none;
    border-radius: 20px;
    background-color: rgba(150, 150, 150, 1);
    color: rgba(24, 25, 26, 1);
  }
`;

export const CopyAlert = styled.div<ICopyAlertProps>`
  transition-duration: 0.3s;
  position: fixed;
  right: ${(props) => (props.show ? '25px' : '-150px')};
  bottom: 50px;
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    background: #f9f9f9 !important;
    border-radius: 35px;
    position: relative;

    h2 {
      color: #116bff;
      font-size: 38px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      line-height: 35px;
    }

    p {
      color: #151517 !important;
      font-size: 17px;
      font-family: 'Archivo', sans-serif;
      font-weight: 300;
      line-height: 28px;
      span {
        font-weight: 600;
        color: #151517;
      }
    }

    .player {
      border-radius: 25px;
      overflow: hidden;
      position: relative;
    }

    .video-js .vjs-big-play-button {
      background-color: #116bff !important;
      border: none !important;
      border-radius: 50% !important;
      font-size: 5em !important;
      line-height: 1.5em !important;
      height: 1.5em !important;
      width: 1.5em !important;
      margin-left: 0px !important;
      transition: 0.3s;
      transform: translate(-50%, -50%);
      margin-top: 0px;
    }

    .video-js:hover .vjs-big-play-button,
    .video-js .vjs-big-play-button:focus {
      -webkit-box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 1);
      -moz-box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 1);
    }

    .modal-title {
      font-weight: 600;
      font-size: 24px;
      color: #021027;
    }

    .modal-close {
      color: #021027;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .btn-save {
      width: 100%;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
      background: #116bff;
      border-radius: 20px;
      transition-duration: 0.3s;
      padding: 18px;

      :hover {
        opacity: 0.8;
      }
    }

    .guide {
      display: block;
      color: #116bff;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      width: 100%;
      text-decoration-line: underline;
    }
  }
  @media screen and (min-width: 1200px) {
    .modal-xl {
      zoom: 0.7;
    }
  }
  @media screen and (min-width: 1400px) {
    .modal-xl {
      max-width: 1040px;
      zoom: 1;
    }
  }

  @media screen and (max-width: 991px) {
    .modal-content h2 {
      font-size: 23px;
    }

    .modal-content p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 575px) {
    .modal-content h2 {
      font-size: 5vw;
    }

    .modal-content p {
      font-size: 15px;
    }
  }
`;
