import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Modal } from 'react-bootstrap';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { useLanguage } from '~/hooks/Language';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container } from './styles';
import CreditCard from '~/components/CreditCard';

import visa from '~/assets/icons/cc-visa.svg';
import mastercard from '~/assets/icons/cc-mastercard.svg';
import discover from '~/assets/icons/cc-discover.svg';
import amex from '~/assets/icons/cc-amex.svg';

interface ISubscriptionsCardsProps {
  active: boolean;
}

interface IApiCreditCard {
  id: string;
  name: string;
  number: string;
  expirity: string;
  brand: string;
  address: string;
  primary_card: boolean;
}

interface IReponseCreditCard {
  id: string;
  name: string;
  number: string;
  expiration: string;
  flag: string;
  primary_card: boolean;
}

interface ICreditCard {
  id?: string;
  name: string;
  number: string;
  expiration: string;
  cvc: string;
  flag: string;
  primary_card?: boolean;
}

const SubscriptionsCards: React.FC<ISubscriptionsCardsProps> = ({ active }) => {
  const formCreditCardRef = useRef<FormHandles>(null);
  const { language } = useLanguage();
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [creditCards, setCreditCards] = useState<IReponseCreditCard[]>([]);
  const [creditCardSelected, setCreditCardSelected] = useState<
    IReponseCreditCard
  >({} as IReponseCreditCard);

  useEffect(() => {
    api.get('payments/customers/credit-cards').then((response) => {
      const data = response.data.map((card: IApiCreditCard) => ({
        id: card.id,
        name: card.name,
        number: `XXXX-${card.number}`,
        expiration: card.expirity,
        flag: card.brand,
        primary_card: card.primary_card,
      }));
      setCreditCards(data);
    });
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
    setShowDelete(false);
  }, []);

  const handleShow = useCallback(() => setShow(true), []);

  const handleShowDelete = useCallback((card: IReponseCreditCard) => {
    setCreditCardSelected(card);
    setShowDelete(true);
  }, []);

  const handleSubmitCreditCard = useCallback(
    async (data: ICreditCard) => {
      try {
        formCreditCardRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Name on card is a required'),
          number: Yup.string().required('Card number is a required'),
          expiration: Yup.string().required('Expiration date is a required'),
          cvc: Yup.string().required('CVC is a required'),
          flag: Yup.string().required('Flag is a required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, number, expiration, cvc, flag } = data;
        const [exp_month, exp_year] = expiration.split('/');

        const formData = {
          name,
          number: number.replace(/ /g, ''),
          exp_month,
          exp_year,
          cvc,
          primary_card: creditCards.length === 0,
        };

        const response = await api.post(
          'payments/customers/credit-cards',
          formData
        );

        if (response.data) {
          const numberMask = `XXXX-${number.substr(-4)}`;
          const creditCardForm: IReponseCreditCard = {
            id: response.data.id,
            name,
            number: numberMask,
            expiration,
            flag,
            primary_card: creditCards.length === 0,
          };
          setCreditCards((state) => [...state, creditCardForm]);
          handleClose();
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formCreditCardRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', language.error_component.error_message, 'error');
        }
      }
    },
    [creditCards.length, handleClose, language.error_component.error_message]
  );

  const handleDeleteCreditCard = useCallback(async () => {
    try {
      if (creditCardSelected.primary_card && creditCards.length > 1) {
        const newCreditCardsList = creditCards.slice();
        if (creditCardSelected === creditCards[0]) {
          await api.patch(
            `payments/customers/credit-cards/${newCreditCardsList[1].id}`
          );
          newCreditCardsList[1].primary_card = true;
        } else {
          await api.patch(
            `payments/customers/credit-cards/${newCreditCardsList[0].id}`
          );
          newCreditCardsList[0].primary_card = true;
        }
        setCreditCards(newCreditCardsList);
      }
      await api.delete(
        `payments/customers/credit-cards/${creditCardSelected.id}`
      );
      const cards = creditCards.filter(
        (card) => card.id !== creditCardSelected.id
      );
      setCreditCards(cards);
      Swal.fire('Okay!', 'Successfully deleted.', 'success').then(() => {
        handleClose();
      });
    } catch (error) {
      Swal.fire('Opss...', language.error_component.error_message, 'error');
    }
  }, [
    creditCardSelected,
    creditCards,
    handleClose,
    language.error_component.error_message,
  ]);

  const handleChange = useCallback(
    async (index) => {
      const newCreditCardsList = creditCards.slice();
      newCreditCardsList.forEach((card) => {
        // eslint-disable-next-line no-param-reassign
        card.primary_card = false;
      });
      newCreditCardsList[index].primary_card = true;

      await api.patch(
        `payments/customers/credit-cards/${newCreditCardsList[index].id}`
      );

      setCreditCards(newCreditCardsList);
    },
    [creditCards]
  );

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 py-3">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex align-items-center">
                <h2 className="h5 h4-md mb-0">
                  {language.subscriptions_cards.h2}
                </h2>
                <div className="d-none d-md-flex mx-2">
                  <img src={visa} alt="Visa" className="mx-1" />
                  <img src={mastercard} alt="Mastercard" className="mx-1" />
                  <img src={discover} alt="Discover" className="mx-1" />
                  <img src={amex} alt="Amex" className="mx-1" />
                </div>
              </div>
              <button
                type="button"
                className="border-golden"
                onClick={handleShow}
              >
                <span>{language.subscriptions_cards.button_1}</span>
              </button>
            </div>
            <div className="table-responsive bg-gray p-2">
              <table>
                <tr className="border-bottom">
                  <th className="cardId pb-2">#</th>
                  <th className="cardNumber pb-2">
                    {language.subscriptions_cards.th_1}
                  </th>
                  <th className="cardExpirationDate pb-2">
                    {language.subscriptions_cards.th_2}
                  </th>
                  <th className="cardName pb-2">
                    {language.subscriptions_cards.th_3}
                  </th>
                  <th className="cardBillingAddress pb-2">
                    {language.subscriptions_cards.th_4}
                  </th>
                  <th className="cardPrimary pb-2">
                    {language.subscriptions_cards.th_5}
                  </th>
                  <th className="cardAction pb-2">
                    {language.subscriptions_cards.th_6}
                  </th>
                </tr>
                {creditCards.map((card, index) => (
                  <tr key={card.id}>
                    <td className="pt-2">{index + 1}</td>
                    <td className="pt-2">
                      {card.flag}: <br className="d-lg-block d-xl-none" />
                      {card.number}
                    </td>
                    <td className="pt-2">{card.expiration}</td>
                    <td className="pt-2">{card.name}</td>
                    <td className="pt-2">
                      34 Ave of the Arts Costa Mesa, CA 92666 United States
                    </td>
                    <td className="pt-2 text-center">
                      <input
                        type="checkbox"
                        onChange={() => handleChange(index)}
                        checked={card.primary_card}
                      />
                    </td>
                    <td className="pt-2 text-center">
                      <button
                        type="button"
                        className="border-golden mt-1"
                        onClick={() => handleShowDelete(card)}
                      >
                        <span>{language.subscriptions_cards.button_2}</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal className="modal-credit-card" show={show} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>{language.subscriptions_cards.h4_1}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto w-100">
          <Form ref={formCreditCardRef} onSubmit={handleSubmitCreditCard}>
            <CreditCard
              type="change"
              onChangeCreditCardSelected={(creditCard) =>
                console.log(creditCard)
              }
            />
            <div className="d-flex mt-2">
              <button
                type="button"
                className="w-100 mr-1 border-silver"
                onClick={handleClose}
              >
                <span>{language.subscriptions_cards.button_3}</span>
              </button>
              <button type="submit" className="w-100 ml-1 border-golden">
                <span>{language.subscriptions_cards.button_4}</span>
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0" />
      </Modal>

      <Modal
        className="modal-credit-card"
        show={showDelete}
        onHide={handleClose}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>{language.subscriptions_cards.h4_2}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto">
          <p className="h5 mt-n3 mb-4 px-5 text-center">
            {language.subscriptions_cards.p}
          </p>
          <div className="d-flex mt-2">
            <button
              type="button"
              className="w-100 mr-1 border-silver"
              onClick={handleClose}
            >
              <span>{language.subscriptions_cards.button_5}</span>
            </button>
            <button
              type="button"
              onClick={handleDeleteCreditCard}
              className="w-100 ml-1 border-golden"
            >
              <span>{language.subscriptions_cards.button_6}</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0" />
      </Modal>
    </Container>
  );
};

export default SubscriptionsCards;
