import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  textarea {
    border-radius: 20px;
  }

  h1,
  a {
    color: #021027;
  }

  h3 {
    color: #021027;
  }

  p {
    color: #8c8c8c;
    font-weight: 400;
  }

  textarea {
    resize: none;
    ::-webkit-scrollbar-thumb {
      background: #303030;
    }
    ::-webkit-scrollbar {
      background-color: transparent;
    }
  }

  .btn-traffic {
    border-radius: 15px;
    background: #116bff;
  }

  .bg-text {
    background-color: #202020;
    color: #606060;
  }

  .old-price {
    color: #ff1a50;
  }

  .vjs-poster {
    background-size: cover;
  }

  .video-js .vjs-big-play-button {
    background-color: #116bff !important;
    border: none !important;
    border-radius: 50% !important;
    font-size: 5em !important;
    line-height: 1.5em !important;
    height: 1.5em !important;
    width: 1.5em !important;
    margin-left: 0px !important;
    transition: 0.3s;
    transform: translate(-50%, -50%);
    margin-top: 0px;
  }

  .video-js:hover .vjs-big-play-button,
  .video-js .vjs-big-play-button:focus {
    -webkit-box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 1);
  }

  .vjs-control-bar {
    border-radius: 0 0 20px 20px !important;
  }

  .player {
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    video {
      border-radius: 20px !important;
      cursor: pointer;
    }
  }

  .bg-white {
    background-color: #fff;
    border-radius: 30px;
  }

  .fw-600 {
    font-weight: 600;
  }

  .opacity-80 {
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
  }

  .others .img-thumb {
    border-radius: 20px;
  }

  .others .title {
    color: #333;

    font-family: 'Lato';
    font-size: 18px;
    font-weight: 600;
  }

  .others .autor {
    color: #828282;

    font-family: 'Lato';
    font-size: 13px;
    font-weight: 400;
  }

  .duration {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-weight: 600;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 2px 8px;
  }

  .avatar {
    width: 23px;
    height: 23px;
    border-radius: 20px;
  }

  .max-h {
    height: auto;
  }

  @media screen and (min-width: 992px) {
    .container-lg-fluid {
      max-width: 1580px !important;
    }

    .h-others {
      height: 55vw;
      max-height: 820px;
      overflow: auto;
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #021027;
      }
    }

    .max-h {
      max-height: 100px;
      overflow: auto;
      padding-right: 10px;
      ::-webkit-scrollbar-thumb {
        background: #303030;
      }
      ::-webkit-scrollbar {
        background-color: transparent;
      }
    }

    .notes > div {
      padding-top: 3rem !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .notes > div {
      margin-right: 3rem !important;
    }

    .zoom {
      zoom: 0.7;
    }

    .h-others {
      height: 51vw;
      max-height: 820px;
      overflow: auto;
    }

    .max-h {
      max-height: 80px;
      overflow: auto;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom {
      zoom: 0.8;
    }
    .h-others {
      height: 50vw;
      max-height: 820px;
      overflow: auto;
    }

    .max-h {
      max-height: 150px;
      overflow: auto;
    }
  }

  @media screen and (min-width: 1600px) {
    .zoom {
      zoom: 0.9;
    }

    .h-others {
      height: 48vw;
      max-height: 820px;
      overflow: auto;
    }
  }

  @media screen and (min-width: 1700px) {
    .zoom {
      zoom: 1;
    }
  }
`;

export const Courses = styled.div`
  .thumb {
    border-radius: 20px;
    height: 230px;
  }

  a {
    height: 100%;
    border-radius: 20px;

    .short-title {
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0px 0px 20px 20px;

      p {
        color: #fff;
      }
    }
  }
`;
