import styled from 'styled-components';

export const Container = styled.div`
  .credentials-box {
    background: #116bff;
    border: 2px solid #021027;
    border-radius: 20px;
    padding: 7px;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));

    img {
      width: 10px;
      height: 10px;
    }

    h4 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 19px;
      line-height: 23px;
      color: #ffffff;
      margin-bottom: 15px;
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 81%;
      color: #ffffff;
    }

    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 130.34%;
      color: #ffffff;

      a {
        color: #ffffff;
        font-weight: 700;
      }
    }

    .dark-box {
      background: #021027;
      border-radius: 15px;
      padding: 19px 17px;

      h6 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 0px;
      }

      p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .credentials-box {
      padding: 17px;

      h4 {
        font-size: 18px;
      }
      p,
      span {
        display: block;
        line-height: 130%;
      }
    }
  }
`;
