import styled from 'styled-components';

export const Container = styled.div`
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #8c8c8c;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #626262;
  }

  hr {
    width: 90%;
    border-top: 1px solid #e1e0e0;
  }

  .bg-check-border {
    padding: 2px;
    background: #fff;
    border: 2px solid #f2f2f2;
    border-radius: 18px;
    img {
      padding: 9px;
      border-radius: 14px;
      width: 48px;
      height: 48px;
      background: #116bff;
      box-shadow: -1px 4px 10px 0px rgba(17, 107, 255, 0.5);
    }
  }

  .completed {
    border-radius: 22px;
    border: 1px solid #e9e9e9;
    background: #fff;
    padding: 10px 15px;
  }

  .completed p {
    color: #232528;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
  }

  .completed span {
    color: #8a8a8a;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
    line-height: 19.5px;
  }

  .positions {
    top: 19px;
    left: 15px;
    h2 {
      font-weight: 600;
      font-size: 36px;
      line-height: 46px;
      color: #f5f5f5;
      margin-bottom: 0px;
    }
    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #f5f5f5;
    }
  }

  .sign {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    line-height: 15px;
    color: #ffffff;
    background: #116bff;
    border-radius: 10px;
    padding: 9px 20px;
    width: 180px;
    transition: 0.3s;
    text-decoration: none;
    :hover {
      opacity: 0.8;
    }
  }

  .sign.course {
    background-color: #ffc584;
    color: #000000;
    font-weight: 600;
  }

  .add {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    line-height: 15px;
    color: #242526;
    background: #d0d0d0;
    border-radius: 10px;
    padding: 9px 20px;
    width: 180px;
    transition: 0.3s;
    text-decoration: none;
    :hover {
      opacity: 0.8;
    }
  }

  .text-no-register {
    color: var(--ultraWhite);
    font-weight: 600;
  }

  .h-40vh {
    height: 40vh;
  }

  .text-no-certificates {
    color: #606060;
    font-weight: 600;
  }

  small,
  .price {
    color: var(--graniteGray);
  }

  .radius-20 {
    border-radius: 20px;
  }

  .bg-gray {
    background: var(--coal);
    border-radius: 20px;
  }

  button {
    background-color: transparent;
    border: none;
    color: var(--ultraWhite);
  }

  .filters {
    background: var(--midnight);
    z-index: 99;
    border-radius: 12px;
    right: 0;
    bottom: -130px;

    hr {
      width: 75%;
      margin: 0 auto;
      background-color: var(--mysteriousGrey);
    }
  }

  .button-cancel {
    background-color: transparent;
    color: var(--ultraWhite);
    text-decoration: none !important;
    border: 1px solid var(--greyMineral);
    border-radius: 12px;
    :hover {
      background-color: var(--greyMineral);
    }
  }

  button,
  .button {
    cursor: pointer;
  }

  .graph {
    width: 11%;
    opacity: 0.1;
  }

  div {
    ::-webkit-scrollbar {
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: var(--technologicalGrayOpacity);
    }
  }

  .img-width {
    width: 100%;
    //height: 140px;
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    //display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (min-width: 768px) {
    .skeleton.img-width {
      width: 186px;
      height: 170px;
    }
  }

  @media screen and (max-width: 767px) {
    .img-width {
      width: 400px;
      max-width: 100%;
    }

    .skeleton.img-width {
      max-height: 260px;
      height: 49vw;
    }

    .add,
    .sign {
      font-size: 16px;
      width: 100%;
      padding: 15px 20px;
      border-radius: 15px;
    }
  }
`;
