import React, { useCallback, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { Container } from './styles';

import closeIcon from '~/assets/icons/close.svg';
import { useAuth } from '~/hooks/Auth';

const Credentials: React.FC = () => {
  const { credentials, setCredentials } = useAuth();
  const location = useLocation();
  const [langSelected] = useState(() => {
    return location.pathname.substr(0, 3) === '/en' ||
      location.pathname.substr(0, 3) === '/es'
      ? location.pathname.substr(0, 3)
      : '';
  });

  const handleClickClose = useCallback(() => {
    setCredentials(undefined);
  }, [setCredentials]);
  return (
    <Container>
      <div className="credentials-box d-md-flex justify-content-between align-items-start">
        <button
          type="button"
          onClick={handleClickClose}
          className="d-flex d-md-block close-button ml-auto ml-md-2"
        >
          <img src={closeIcon} alt="Close" />
        </button>
        <div className="align-self-center">
          <h4 className="title">
            Our system generated a password for your account
          </h4>
          <p className="mb-2 mb-md-0">
            Save them so that you can access your account, we also sent it to
            your email.{' '}
          </p>
          <span className="mb-3 mb-md-0">
            You can change your password in the profile settings.{' '}
            <Link
              to={`${process.env.PUBLIC_URL}${langSelected}/profile/my-profile`}
            >
              Click Here
            </Link>
          </span>
        </div>
        <div className="dark-box">
          <div>
            <h6>Your username:</h6>
            <p>{credentials?.email}</p>
          </div>
          <div>
            <h6>Your password:</h6>
            <p className="mb-0">{credentials?.password}</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Credentials;
