import React, { useEffect, useState } from 'react';
import { useLanguage } from '~/hooks/Language';

import { Container, Welcome, Option, Board } from './styles';
import TransactionHistory from '~/components/TransactionHistory';
import MyEarnings from '~/components/MyEarnings';

import calendar from '~/assets/icons/calendar-wallet.svg';
import watch from '~/assets/icons/watch-wallet.svg';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

interface ICommission {
  id: string;
  earning: string;
  amount_paid: string;
  created_at: string;
}

const Wallet: React.FC = () => {
  const { language } = useLanguage();
  const [availableNextWeek, setAvailableNextWeek] = useState(0);
  const [outstanding, setOutstanding] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    api
      .get('commissions', {
        params: {
          type: 'availableNextWeek',
        },
      })
      .then((response) => {
        const gains = response.data.reduce(
          (acumulador: number, currentValue: ICommission) => {
            return acumulador + parseFloat(currentValue.earning);
          },
          0
        );

        setAvailableNextWeek(gains);
      });
  }, []);

  useEffect(() => {
    api
      .get('commissions', {
        params: {
          type: 'lock',
        },
      })
      .then((response) => {
        const gains = response.data.reduce(
          (acumulador: number, currentValue: ICommission) => {
            return acumulador + parseFloat(currentValue.earning);
          },
          0
        );

        setOutstanding(gains);
      });
  }, []);

  return (
    <Container className="d-flex align-items-center">
      <div className="container zoom">
        <div className="row mb-4 mt-4">
          <div className="col-12 mb-md-2">
            <Welcome>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <div className="mr-2">
                  <span className="h6-xs h4 h2-lg font-weight-bold">
                    <Option>
                      <h1 className="h4 h2-sm">Wallet</h1>
                    </Option>
                  </span>
                </div>
              </div>
            </Welcome>
          </div>
        </div>

        <div className="row">
          <div className="bg-gray col p-3 p-xl-5">
            <div className="row justify-content-xl-between">
              <div className="col-12 col-xl-8 px-0 px-sm-3 table-xxl">
                <TransactionHistory />
              </div>
              <div className="col-12 col-xl-4 px-3 px-lg-0">
                <div className="Data order-0 order-lg-1 pb-1 px-lg-3 mb-0 mt-4 mt-lg-0">
                  <div className="row align-items-xl-center justify-content-center">
                    <div className="col-xl-12 col-md-9 mt-lg-3 mt-xl-0 pr-lg-0 px-xl-2">
                      <MyEarnings />
                    </div>
                    <div className="col-xl-12 col-md-3 pl-md-0 px-lg-3 h-100">
                      <div className="row px-3 px-sm-0 pt-xl-2 mt-xl-4">
                        <div className="col-12 col-sm-12 col-xl-6 mt-3 mb-2 mt-xl-0 mb-xl-0 pl-0  pl-sm-3 pr-0 pr-sm-3 pr-xl-2 pr-xxl-3 pl-xl-2">
                          <Board>
                            <div className="p-3 py-5 py-xl-4">
                              <div className="BoardImg mb-4">
                                <img src={watch} alt={language.wallet.img_1} />
                              </div>
                              <p className="text-center Title">
                                {language.wallet.p_1_1}

                                {language.wallet.p_1_2}
                              </p>
                              <p
                                className={`${
                                  loading && 'skeleton px-2 mx-auto'
                                } Value mb-lg-1 mb-xl-2 h5`}
                              >
                                {formatPrice(availableNextWeek)}
                              </p>
                            </div>
                          </Board>
                        </div>
                        <div className="col-12 col-sm-12 col-xl-6 mt-3 mt-xl-0 pl-0 pl-sm-3 pl-xl-2 pl-xxl-3 pr-0 pr-sm-3 pr-xl-2">
                          <Board>
                            <div className="p-3 py-5 py-xl-4">
                              <div className="BoardImg mb-4">
                                <img
                                  src={calendar}
                                  alt={language.wallet.img_2}
                                />
                              </div>
                              <p className="text-center Title">
                                {language.wallet.p_2_1}

                                {language.wallet.p_2_2}
                              </p>
                              <p
                                className={`${
                                  loading && 'skeleton px-2 mx-auto'
                                } Value h5 mb-2 mb-lg-0 mb-xl-3 mb-xl-2`}
                              >
                                {formatPrice(outstanding)}
                              </p>
                            </div>
                          </Board>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Wallet;
